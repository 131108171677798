export var translations = {
    'en': {
        'generalChat': 'General Chat',
        'generalChannel': 'General Channel'
    },
    'ru': {
        'generalChat': 'Общий чат',
        'generalChannel': 'Общий канал'
    },
    'es': {
        'generalChat': 'Chat general',
        'generalChannel': 'Canal general'
    },
    'kk': {
        'generalChat': 'Жалпы чат',
        'generalChannel': 'Жалпы арна'
    },
    'uz': {
        'generalChat': 'Umumiy chat',
        'generalChannel': 'Umumiy kanal'
    },
    'de': {
        'generalChat': 'Allgemeiner Chat',
        'generalChannel': 'Allgemeiner Kanal'
    },
    'fr': {
        'generalChat': 'Chat général',
        'generalChannel': 'Canal général'
    },
    'it': {
        'generalChat': 'Chat generale',
        'generalChannel': 'Canale generale'
    }
};
