var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useAudioUtils } from '@common/hooks/audio/useAudioUtils';
import { generateUuid } from '@common/utils/Helpers/Helpers';
var AudioContextWrapper = /** @class */ (function () {
    function AudioContextWrapper() {
    }
    AudioContextWrapper.getInstance = function () {
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        if (!AudioContextWrapper.instance) {
            AudioContextWrapper.instance = {
                audioContext: new AudioContext(),
                activeSources: []
            };
        }
        if (AudioContextWrapper.instance.audioContext.state === 'closed') {
            AudioContextWrapper.instance = {
                audioContext: new AudioContext(),
                activeSources: []
            };
        }
        return AudioContextWrapper.instance;
    };
    AudioContextWrapper.createSource = function (blob, id) {
        return __awaiter(this, void 0, void 0, function () {
            var audioUtils, source, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        audioUtils = useAudioUtils();
                        source = AudioContextWrapper.getInstance().audioContext.createBufferSource();
                        _a = source;
                        return [4 /*yield*/, audioUtils.blobToAudioBuffer(blob)];
                    case 1:
                        _a.buffer = _b.sent();
                        source.connect(AudioContextWrapper.instance.audioContext.destination);
                        AudioContextWrapper.setActiveSource(id, source);
                        return [2 /*return*/];
                }
            });
        });
    };
    AudioContextWrapper.stopActiveSource = function (excludeId) {
        var e_1, _a;
        var _b;
        var newActiveSources = [];
        if (((_b = AudioContextWrapper.instance) === null || _b === void 0 ? void 0 : _b.activeSources.length) > 0) {
            try {
                for (var _c = __values(AudioContextWrapper.instance.activeSources), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var source = _d.value;
                    if (excludeId && source.id === excludeId) {
                        newActiveSources.push(source);
                        continue;
                    }
                    // eslint-disable-next-line unicorn/prefer-add-event-listener
                    source.source.onended = null;
                    if (source.isStarted) {
                        source.source.stop(0);
                    }
                    source.source.disconnect();
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            AudioContextWrapper.instance.activeSources = newActiveSources;
        }
    };
    AudioContextWrapper.getSource = function (id) {
        return AudioContextWrapper.instance.activeSources.find(function (s) { return s.id === id; });
    };
    AudioContextWrapper.setActiveSource = function (id, source) {
        var newSource = AudioContextWrapper.instance.activeSources.filter(function (s) { return s.id !== id; });
        AudioContextWrapper.instance.activeSources = __spreadArray(__spreadArray([], __read(newSource), false), [{
                id: id,
                source: source,
                isStarted: false, uuid: generateUuid()
            }], false);
    };
    return AudioContextWrapper;
}());
export { AudioContextWrapper };
