export var translations = {
    'en': {
        'today': 'Today',
        'yesterday': 'Yesterday',
        'today_at': 'Today at',
        'today_at2': 'today at',
        'tomorrow_at': 'Tomorrow at',
        'yesterday_at': 'Yesterday at',
        'yesterday_at2': 'yesterday at',
        'at': 'at',
        'hours': 'h',
        'minutes': 'min',
        'time': {
            'second': 'second',
            'seconds': 'seconds',
            'seconds_plural': 'seconds',
            'minute': 'minute',
            'minutes': 'minutes',
            'minutes_plural': 'minutes',
            'hour': 'hour',
            'hours': 'hours',
            'hours_plural': 'hours',
            'day': 'day',
            'days': 'days',
            'days_plural': 'days',
            'month': 'month',
            'months': 'months',
            'months_plural': 'months',
            'year': 'year',
            'years': 'years',
            'years_plural': 'years'
        }
    },
    'ru': {
        'today': 'Сегодня',
        'yesterday': 'Вчера',
        'today_at': 'Сегодня в',
        'today_at2': 'сегодня в',
        'tomorrow_at': 'Завтра в',
        'yesterday_at': 'Вчера в',
        'yesterday_at2': 'вчера в',
        'at': 'в',
        'hours': 'ч',
        'minutes': 'мин',
        'time': {
            'second': 'секунда',
            'seconds': 'секунды',
            'seconds_plural': 'секунд',
            'minute': 'минута',
            'minutes': 'минуты',
            'minutes_plural': 'минут',
            'hour': 'час',
            'hours': 'часа',
            'hours_plural': 'часов',
            'day': 'день',
            'days': 'дня',
            'days_plural': 'дней',
            'month': 'месяц',
            'months': 'месяца',
            'months_plural': 'месяцев',
            'year': 'год',
            'years': 'года',
            'years_plural': 'лет'
        }
    },
    'es': {
        'today': 'Hoy',
        'yesterday': 'Ayer',
        'today_at': 'Hoy a las',
        'today_at2': 'hoy a las',
        'tomorrow_at': 'Mañana a las',
        'yesterday_at': 'Ayer a las',
        'yesterday_at2': 'ayer a las',
        'at': 'a las',
        'hours': 'h',
        'minutes': 'min',
        'time': {
            'second': 'segundo',
            'seconds': 'segundos',
            'seconds_plural': 'segundos',
            'minute': 'minuto',
            'minutes': 'minutos',
            'minutes_plural': 'minutos',
            'hour': 'hora',
            'hours': 'horas',
            'hours_plural': 'horas',
            'day': 'día',
            'days': 'días',
            'days_plural': 'días',
            'month': 'mes',
            'months': 'meses',
            'months_plural': 'meses',
            'year': 'año',
            'years': 'años',
            'years_plural': 'años'
        }
    },
    'kk': {
        'today': 'Бүгін',
        'yesterday': 'Кеше',
        'today_at': 'Бүгін сағат',
        'today_at2': 'бүгін сағат',
        'tomorrow_at': 'Ертең сағат',
        'yesterday_at': 'Кеше сағат',
        'yesterday_at2': 'кеше сағат',
        'at': 'сағат',
        'hours': 'сағат',
        'minutes': 'мин',
        'time': {
            'second': 'секунд',
            'seconds': 'секунд',
            'seconds_plural': 'секунд',
            'minute': 'минут',
            'minutes': 'минут',
            'minutes_plural': 'минут',
            'hour': 'сағат',
            'hours': 'сағат',
            'hours_plural': 'сағат',
            'day': 'күн',
            'days': 'күн',
            'days_plural': 'күн',
            'month': 'ай',
            'months': 'ай',
            'months_plural': 'ай',
            'year': 'жыл',
            'years': 'жыл',
            'years_plural': 'жыл'
        }
    },
    'uz': {
        'today': 'Bugun',
        'yesterday': 'Kecha',
        'today_at': 'Bugun soat',
        'today_at2': 'bugun soat',
        'tomorrow_at': 'Ertaga soat',
        'yesterday_at': 'Kecha soat',
        'yesterday_at2': 'kecha soat',
        'at': 'soat',
        'hours': 'soat',
        'minutes': 'min',
        'time': {
            'second': 'soniya',
            'seconds': 'soniyalar',
            'seconds_plural': 'soniyalar',
            'minute': 'minut',
            'minutes': 'minutlar',
            'minutes_plural': 'minutlar',
            'hour': 'soat',
            'hours': 'soatlar',
            'hours_plural': 'soatlar',
            'day': 'kun',
            'days': 'kunlar',
            'days_plural': 'kunlar',
            'month': 'oy',
            'months': 'oylar',
            'months_plural': 'oylar',
            'year': 'yil',
            'years': 'yillar',
            'years_plural': 'yillar'
        }
    },
    'de': {
        'today': 'Heute',
        'yesterday': 'Gestern',
        'today_at': 'Heute um',
        'today_at2': 'heute um',
        'tomorrow_at': 'Morgen um',
        'yesterday_at': 'Gestern um',
        'yesterday_at2': 'gestern um',
        'at': 'um',
        'hours': 'Std.',
        'minutes': 'Min.',
        'time': {
            'second': 'Sekunde',
            'seconds': 'Sekunden',
            'seconds_plural': 'Sekunden',
            'minute': 'Minute',
            'minutes': 'Minuten',
            'minutes_plural': 'Minuten',
            'hour': 'Stunde',
            'hours': 'Stunden',
            'hours_plural': 'Stunden',
            'day': 'Tag',
            'days': 'Tage',
            'days_plural': 'Tage',
            'month': 'Monat',
            'months': 'Monate',
            'months_plural': 'Monate',
            'year': 'Jahr',
            'years': 'Jahre',
            'years_plural': 'Jahren'
        }
    },
    'fr': {
        'today': 'Aujourd\'hui',
        'yesterday': 'Hier',
        'today_at': 'Aujourd\'hui à',
        'today_at2': 'aujourd\'hui à',
        'tomorrow_at': 'Demain à',
        'yesterday_at': 'Hier à',
        'yesterday_at2': 'hier à',
        'at': 'à',
        'hours': 'h',
        'minutes': 'min',
        'time': {
            'second': 'seconde',
            'seconds': 'secondes',
            'seconds_plural': 'secondes',
            'minute': 'minute',
            'minutes': 'minutes',
            'minutes_plural': 'minutes',
            'hour': 'heure',
            'hours': 'heures',
            'hours_plural': 'heures',
            'day': 'jour',
            'days': 'jours',
            'days_plural': 'jours',
            'month': 'mois',
            'months': 'mois',
            'months_plural': 'mois',
            'year': 'an',
            'years': 'ans',
            'years_plural': 'ans'
        }
    },
    'it': {
        'today': 'Oggi',
        'yesterday': 'Ieri',
        'today_at': 'Oggi alle',
        'today_at2': 'oggi alle',
        'tomorrow_at': 'Domani alle',
        'yesterday_at': 'Ieri alle',
        'yesterday_at2': 'ieri alle',
        'at': 'alle',
        'hours': 'ore',
        'minutes': 'min',
        'time': {
            'second': 'secondo',
            'seconds': 'secondi',
            'seconds_plural': 'secondi',
            'minute': 'minuto',
            'minutes': 'minuti',
            'minutes_plural': 'minuti',
            'hour': 'ora',
            'hours': 'ore',
            'hours_plural': 'ore',
            'day': 'giorno',
            'days': 'giorni',
            'days_plural': 'giorni',
            'month': 'mese',
            'months': 'mesi',
            'months_plural': 'mesi',
            'year': 'anno',
            'years': 'anni',
            'years_plural': 'anni'
        }
    }
};
