export var GrantedActionsEnum;
(function (GrantedActionsEnum) {
    /**
     * Текущий пользователь имеет доступ к школе как сотрудник
     * Используется для проверки общей авторизации для общих разделов
     */
    GrantedActionsEnum["ACTION_SCHOOL_AUTHORIZE_EMPLOYEE"] = "ACTION_SCHOOL_AUTHORIZE_EMPLOYEE";
    /**
     * Возможность Добавлять/ Редактировать / Удалять способы оплаты
     */
    GrantedActionsEnum["ACTION_SCHOOL_PAYMENT_GATEWAY"] = "ACTION_SCHOOL_PAYMENT_GATEWAY";
    /**
     * Возможность Создавать / Редактировать / Удалять промокоды
     */
    GrantedActionsEnum["ACTION_SCHOOL_DISCOUNT_MODIFY"] = "ACTION_SCHOOL_DISCOUNT_MODIFY";
    /**
     * Возможность Смотреть список промокодов
     */
    GrantedActionsEnum["ACTION_SCHOOL_DISCOUNT"] = "ACTION_SCHOOL_DISCOUNT";
    /**
     * Доступ в раздел API ключи и webhook
     */
    GrantedActionsEnum["ACTION_SCHOOL_API"] = "ACTION_SCHOOL_API";
    /**
     * Доступ к разделу реквизиты
     */
    GrantedActionsEnum["ACTION_SCHOOL_REQUISITES"] = "ACTION_SCHOOL_REQUISITES";
    /**
     * Просмотр баланса школы
     */
    GrantedActionsEnum["ACTION_SCHOOL_BALANCE"] = "ACTION_SCHOOL_BALANCE";
    /**
     * Вывод средств из школы
     */
    GrantedActionsEnum["ACTION_SCHOOL_BALANCE_WITHDRAW"] = "ACTION_SCHOOL_BALANCE_WITHDRAW";
    /**
     * Авторизованный сотрудник
     */
    GrantedActionsEnum["ACTION_SCHOOL_EMPLOYEE_AUTHORIZE"] = "ACTION_SCHOOL_EMPLOYEE_AUTHORIZE";
    /**
     * Возможность изменять настройки доступа/роли для сотрудников
     */
    GrantedActionsEnum["ACTION_SCHOOL_EMPLOYEE_MODIFY"] = "ACTION_SCHOOL_EMPLOYEE_MODIFY";
    /**
     * Возможность изменять настройки доступа/роли для сотрудников
     */
    GrantedActionsEnum["ACTION_SCHOOL_EMPLOYEE_ROLE_MODIFY"] = "ACTION_SCHOOL_EMPLOYEE_ROLE_MODIFY";
    /**
     * Возможность менять настройки чатов
     */
    GrantedActionsEnum["ACTION_SCHOOL_CHAT_MODIFY"] = "ACTION_SCHOOL_CHAT_MODIFY";
    /**
     * Изменение настроек White label
     */
    GrantedActionsEnum["ACTION_SCHOOL_WHITE_LABEL_MODIFY"] = "ACTION_SCHOOL_WHITE_LABEL_MODIFY";
    /**
     * Доступ к разделу настроек
     */
    GrantedActionsEnum["ACTION_SCHOOL_SETTINGS"] = "ACTION_SCHOOL_SETTINGS";
    /**
     * Возможность редактировать настройки школы
     */
    GrantedActionsEnum["ACTION_SCHOOL_SETTINGS_MODIFY"] = "ACTION_SCHOOL_SETTINGS_MODIFY";
    /**
     * Редактирование страницы курсов
     * Плашка, тексты, категории, порядок курсов
     */
    GrantedActionsEnum["ACTION_COURSE_PAGE"] = "ACTION_COURSE_PAGE";
    /**
     * Просмотр курсов
     */
    GrantedActionsEnum["ACTION_COURSE_VIEW"] = "ACTION_COURSE_VIEW";
    /**
     * Создание курсов
     */
    GrantedActionsEnum["ACTION_COURSE_CREATE"] = "ACTION_COURSE_CREATE";
    /**
     * Удаление курсов
     */
    GrantedActionsEnum["ACTION_COURSE_REMOVE"] = "ACTION_COURSE_REMOVE";
    /**
     * Создание / обновление / удаление / клонирование курсов
     */
    GrantedActionsEnum["ACTION_COURSE_MODIFY"] = "ACTION_COURSE_MODIFY";
    /**
     * Редактирование контента курса
     */
    GrantedActionsEnum["ACTION_COURSE_CONTENT_MODIFY"] = "ACTION_COURSE_CONTENT_MODIFY";
    /**
     * Просмотр списка входящих домашних работ
     */
    GrantedActionsEnum["ACTION_COURSE_HOMEWORK_USER_LIST"] = "ACTION_COURSE_HOMEWORK_USER_LIST";
    /**
     * Проверка и оценка входящих домашних работ
     */
    GrantedActionsEnum["ACTION_COURSE_HOMEWORK_USER_ANSWER"] = "ACTION_COURSE_HOMEWORK_USER_ANSWER";
    /**
     * Доступ к чату тех поддержки Skillspace (Carrotquest)
     */
    GrantedActionsEnum["ACTION_SUPPORT_CHAT_ACCESS"] = "ACTION_SUPPORT_CHAT_ACCESS";
    /**
     * Доступ к чату школы
     */
    GrantedActionsEnum["ACTION_SCHOOL_CHAT_ACCESS"] = "ACTION_SCHOOL_CHAT_ACCESS";
    /**
     * Смотреть список учеников чата
     */
    GrantedActionsEnum["ACTION_SCHOOL_CHAT_STUDENT_LIST"] = "ACTION_SCHOOL_CHAT_STUDENT_LIST";
    /**
     * Писать в общие чаты школы / групп
     */
    GrantedActionsEnum["ACTION_SCHOOL_CHAT_WRITE_SCHOOL_CHATS"] = "ACTION_SCHOOL_CHAT_WRITE_SCHOOL_CHATS";
    /**
     * Начинать диалог с учениками
     */
    GrantedActionsEnum["ACTION_SCHOOL_CHAT_FIRST_WRITE_STUDENTS"] = "ACTION_SCHOOL_CHAT_FIRST_WRITE_STUDENTS";
    /**
     * Доступ к разделу с тарифами и оплатой
     */
    GrantedActionsEnum["ACTION_SCHOOL_TARIFF_AND_PAYMENT"] = "ACTION_SCHOOL_TARIFF_AND_PAYMENT";
    /**
     * Просмотр списка учеников (Если есть доступ к курсу/Всех учеников)
     */
    GrantedActionsEnum["ACTION_STUDENT_LIST"] = "ACTION_STUDENT_LIST";
    /**
     * Добавление учеников
     */
    GrantedActionsEnum["ACTION_STUDENT_INVITE"] = "ACTION_STUDENT_INVITE";
    /**
     * Редактирование данных в карточке ученика / удаление
     */
    GrantedActionsEnum["ACTION_STUDENT_MODIFY"] = "ACTION_STUDENT_MODIFY";
    /**
     * Выгрузка учеников в Excel
     */
    GrantedActionsEnum["ACTION_STUDENT_EXPORT_EXCEL"] = "ACTION_STUDENT_EXPORT_EXCEL";
    /**
     * Список групп
     */
    GrantedActionsEnum["ACTION_COURSE_GROUP"] = "ACTION_COURSE_GROUP";
    /**
     * Создание / удаление / изменение групп
     */
    GrantedActionsEnum["ACTION_COURSE_GROUP_MODIFY"] = "ACTION_COURSE_GROUP_MODIFY";
    /**
     * Изменение настроек групп - управление настройками онлайн-чата для группы
     */
    GrantedActionsEnum["ACTION_COURSE_GROUP_CHAT"] = "ACTION_COURSE_GROUP_CHAT";
    /**
     * Изменение настроек групп - управление доступами к группе и расписанием
     */
    GrantedActionsEnum["ACTION_COURSE_GROUP_ACCESS_AND_SCHEDULE"] = "ACTION_COURSE_GROUP_ACCESS_AND_SCHEDULE";
    /**
     * Просмотр списка пользовательских полей
     */
    GrantedActionsEnum["ACTION_USER_FIELD_LIST"] = "ACTION_USER_FIELD_LIST";
    /**
     * Настройка лендинга и изменение лендинга
     */
    GrantedActionsEnum["ACTION_COURSE_LANDING_MODIFY"] = "ACTION_COURSE_LANDING_MODIFY";
    /**
     * Настройка курса
     */
    GrantedActionsEnum["ACTION_COURSE_SETTINGS"] = "ACTION_COURSE_SETTINGS";
    /**
     * Возможность получения списка сотрудников
     */
    GrantedActionsEnum["ACTION_SCHOOL_EMPLOYEE_LIST"] = "ACTION_SCHOOL_EMPLOYEE_LIST";
    /**
     * Доступ к подразделу Сотрудники
     */
    GrantedActionsEnum["ACTION_SCHOOL_EMPLOYEE"] = "ACTION_SCHOOL_EMPLOYEE";
    /**
     * Просмотр вебинаров (может видеть скрытые от публикации вебинары)
     */
    GrantedActionsEnum["ACTION_WEBINAR_VIEW"] = "ACTION_WEBINAR_VIEW";
    /**
     * Создание вебинаров (может создавать, редактировать и удалять те, которые создал)
     */
    GrantedActionsEnum["ACTION_WEBINAR_CREATE"] = "ACTION_WEBINAR_CREATE";
    /**
     * Удаление вебинаров (может удалять любые вебинары)
     */
    GrantedActionsEnum["ACTION_WEBINAR_REMOVE"] = "ACTION_WEBINAR_REMOVE";
    /**
     * Создание / обновление / удаление / клонирование вебинаров
     */
    GrantedActionsEnum["ACTION_WEBINAR_MODIFY"] = "ACTION_WEBINAR_MODIFY";
})(GrantedActionsEnum || (GrantedActionsEnum = {}));
