import { ref, watch } from 'vue';
var title = ref('');
var description = ref('');
var image = ref('');
export var useMeta = function () {
    // Наблюдение за изменениями и обновление заголовка страницы
    watch(title, function (newTitle) {
        document.title = newTitle || '';
        updateOrCreateMetaTag('property', 'og:title', newTitle);
        updateOrCreateMetaTag('name', 'twitter:title', newTitle);
    });
    // Наблюдение за изменениями и обновление метатегов для описания
    watch(description, function (newDescription) {
        updateOrCreateMetaTag('name', 'description', newDescription);
        updateOrCreateMetaTag('property', 'og:description', newDescription);
        updateOrCreateMetaTag('name', 'twitter:description', newDescription);
    });
    // Наблюдение за изменениями и обновление метатегов для изображения
    watch(image, function (newImage) {
        updateOrCreateMetaTag('property', 'og:image', newImage);
        updateOrCreateMetaTag('name', 'twitter:image:src', newImage);
        updateOrCreateMetaTag('property', 'vk:image', newImage);
    });
    var updateOrCreateMetaTag = function (attributeType, attributeName, content) {
        var selector = "meta[".concat(attributeType, "=\"").concat(attributeName, "\"]");
        var metaTag = document.querySelector(selector);
        if (content === null || content.trim() === '') {
            if (metaTag) {
                metaTag.remove();
            }
        }
        else {
            if (metaTag) {
                metaTag.setAttribute('content', content);
            }
            else {
                var newMetaTag = document.createElement('meta');
                newMetaTag.setAttribute(attributeType, attributeName);
                newMetaTag.setAttribute('content', content);
                document.head.append(newMetaTag);
            }
        }
    };
    var setTitle = function (newTitle) {
        title.value = newTitle || '';
    };
    var setDescription = function (newDescription) {
        description.value = newDescription || '';
    };
    var setImage = function (newImage) {
        image.value = newImage || '';
    };
    return {
        title: title,
        description: description,
        image: image,
        setTitle: setTitle,
        setDescription: setDescription,
        setImage: setImage,
    };
};
