import { useLightboxStore } from '@common/stores/lightboxStore';
import { usePdfViewerStore } from '@common/stores/pdfViewerStore';
import { useEmployeeCardStore } from '@common/stores/employeeCardStore';
import { usePartnerProgramStore } from '@common/stores/partnerProgramStore';
import { useChatStore } from '@common/stores/chatStore';
import { useCertificateConstructorStore } from '@common/stores/certificateConstructorStore';
import { useCommonCoreStore } from '@common/stores/coreStore';
import CurrentPinia from '@common/utils/CurrentPinia';
var piniaInstance = CurrentPinia.getInstance();
export var LightboxStore = useLightboxStore(piniaInstance);
export var PdfViewerStore = usePdfViewerStore(piniaInstance);
export var EmployeeCardStore = useEmployeeCardStore(piniaInstance);
export var PartnerProgramStore = usePartnerProgramStore(piniaInstance);
export var ChatStore = useChatStore(piniaInstance);
export var CoreStore = useCommonCoreStore(piniaInstance);
export var CertificateConstructorStore = useCertificateConstructorStore(piniaInstance);
