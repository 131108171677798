var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { MaskInput } from './mask-input';
var masks = new WeakMap();
var checkValue = function (input) {
    setTimeout(function () {
        var _a;
        if (((_a = masks.get(input)) === null || _a === void 0 ? void 0 : _a.needUpdateValue(input)) === true) {
            input.dispatchEvent(new CustomEvent('input'));
        }
    });
};
export var vMaska = function (el, binding) {
    var _a;
    var input = el instanceof HTMLInputElement ? el : el.querySelector('input');
    var opts = (_a = __assign({}, binding.arg)) !== null && _a !== void 0 ? _a : {};
    if (input == null)
        return;
    checkValue(input);
    var existed = masks.get(input);
    if (existed != null) {
        if (!existed.needUpdateOptions(input, opts)) {
            return;
        }
        existed.destroy();
    }
    if (binding.value != null) {
        var binded_1 = binding.value;
        var onMaska = function (detail) {
            binded_1.masked = detail.masked;
            binded_1.unmasked = detail.unmasked;
            binded_1.completed = detail.completed;
        };
        opts.onMaska =
            opts.onMaska == null
                ? onMaska
                : (Array.isArray(opts.onMaska)
                    ? __spreadArray(__spreadArray([], __read(opts.onMaska), false), [onMaska], false) : [opts.onMaska, onMaska]);
    }
    masks.set(input, new MaskInput(input, opts));
};
