/** @deprecated пока некуда использовать */
export var RectSideEnum;
(function (RectSideEnum) {
    RectSideEnum["TOP_LEFT"] = "TOP_LEFT";
    RectSideEnum["TOP_RIGHT"] = "TOP_RIGHT";
    RectSideEnum["BOTTOM_LEFT"] = "BOTTOM_LEFT";
    RectSideEnum["BOTTOM_RIGHT"] = "BOTTOM_RIGHT";
    RectSideEnum["TOP"] = "TOP";
    RectSideEnum["BOTTOM"] = "BOTTOM";
    RectSideEnum["LEFT"] = "LEFT";
    RectSideEnum["RIGHT"] = "RIGHT";
})(RectSideEnum || (RectSideEnum = {}));
