import { generateId, generateUuid } from '@common/utils/Helpers/Helpers';
export var defaultTestAnswers = function () { return ({
    select: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        text: '',
        correct: false
    },
    select_image: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        text: '',
        correct: false,
        image: null,
        imageOrientation: 'vertical'
    },
    text: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        text: '',
        correct: true
    },
    digit: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        digit: null,
        rangeMin: null,
        rangeMax: null
    },
    fill_words: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        textTagUuid: '',
        type: 'fill',
        words: []
    },
    arrange_words: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        textTagUuid: '',
        text: ''
    },
    relation: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        relationFirst: '',
        relationSecond: ''
    },
    formula: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        text: '',
        correct: false
    },
    audio: {
        id: generateId(),
        uuid: generateUuid(),
        order: 100,
        comment: null,
        isRemoved: false,
        commentFiles: [],
        audio: null,
        correct: false
    }
}); };
