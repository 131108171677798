export var VariableTypeEnum;
(function (VariableTypeEnum) {
    VariableTypeEnum["TEXT"] = "TEXT";
    VariableTypeEnum["NAME"] = "NAME";
    VariableTypeEnum["COURSE"] = "COURSE";
    VariableTypeEnum["SCHOOL"] = "SCHOOL";
    VariableTypeEnum["LOGO"] = "LOGO";
    VariableTypeEnum["QR"] = "QR";
    VariableTypeEnum["ISSUED_AUTOMATICALLY"] = "ISSUED_AUTOMATICALLY";
    VariableTypeEnum["DOMAIN"] = "DOMAIN";
    VariableTypeEnum["NUMBER_AND_DATE"] = "NUMBER_AND_DATE";
})(VariableTypeEnum || (VariableTypeEnum = {}));
