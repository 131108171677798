export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set account password"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the password that will be used to log into your personal account"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot contain spaces"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please repeat the password"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password recovery link has already been used"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already used this link to set a password or the link has expired"])}
        }
      },
      "ru": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите пароль от аккаунта"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль, который будет использоваться для входа в Ваш личный кабинет"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придумайте пароль"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите пароль"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен состоять минимум из 8 символов"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не может содержать пробел"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, повторите пароль"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка восстановления пароля уже была использована"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже использовали эту ссылку для установки пароля или ссылка была просрочена"])}
        }
      },
      "es": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establezca una contraseña para su cuenta"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese la contraseña que se utilizará para acceder a su cuenta personal"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree una contraseña"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita la contraseña"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese una contraseña"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe tener al menos 8 caracteres"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña no puede contener espacios"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, repita la contraseña"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace para restablecer la contraseña ya ha sido utilizado"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya ha utilizado este enlace para establecer una contraseña o el enlace ha expirado"])}
        }
      },
      "kk": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт паролін орнатыңыз"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке кабинеттіңізге кіру үшін қолданылатын паролді енгізіңіз"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль ойлап табыңыз"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парольді қайталаңыз"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелу"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парольді енгізіңіз"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль кемінде 8 таңбадан тұруы керек"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парольде бос орын болмауы керек"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парольді қайталаңыз"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парольдер сәйкес келмейді"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парольді қалпына келтіру сілтемесін бұрын қолдандыңыз"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз осы сілтемені пароль орнату үшін пайдаландыңыз немесе сілтеме мерзімі өтіп кетті"])}
        }
      },
      "uz": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hisob parolingizni o‘rnating"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shaxsiy kabinetingizga kirishda foydalaniladigan parolni kiriting"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol yarating"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolni takrorlang"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ro‘yxatdan o‘tish"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, parolni kiriting"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol kamida 8 ta belgidan iborat bo'lishi kerak"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolda bo‘shliq bo‘lishi mumkin emas"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, parolni takrorlang"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parollar mos kelmaydi"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolni tiklash havolasi allaqachon ishlatilgan"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz ushbu havolani parol o‘rnatish uchun allaqachon ishlatgansiz yoki havola muddati o‘tgan"])}
        }
      },
      "de": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort für Ihr Konto festlegen"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie das Passwort ein, das für den Zugriff auf Ihr persönliches Konto verwendet wird"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein Passwort"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein Passwort ein"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen lang sein"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort darf keine Leerzeichen enthalten"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wiederholen Sie das Passwort"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Link zur Passwortwiederherstellung wurde bereits verwendet"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben diesen Link zur Einrichtung eines Passworts bereits verwendet oder der Link ist abgelaufen"])}
        }
      },
      "fr": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez le mot de passe du compte"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le mot de passe qui sera utilisé pour vous connecter à votre compte personnel"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un mot de passe"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un mot de passe"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne peut pas contenir d'espaces"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer le mot de passe"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien de réinitialisation du mot de passe a déjà été utilisé"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà utilisé ce lien pour définir un mot de passe ou le lien a expiré"])}
        }
      },
      "it": {
        "modal": {
          "setPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta la password dell'account"])},
          "enterPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la password che verrà utilizzata per accedere al tuo pannello personale"])},
          "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una password"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la password"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])}
        },
        "errors": {
          "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la password"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere composta da almeno 8 caratteri"])},
          "noSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non può contenere spazi"])},
          "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, ripeti la password"])},
          "passwordsMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password non corrispondono"])},
          "linkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link per il ripristino della password è già stato utilizzato"])},
          "linkExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già utilizzato questo link per impostare la password o il link è scaduto"])}
        }
      }
    }
  })
}
