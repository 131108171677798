import { defineStore } from 'pinia';
/**
 * Сторедж для работы модалки PdfViewerModal
 */
export var usePdfViewerStore = defineStore('pdfViewer', {
    state: function () { return ({
        src: null,
    }); },
    actions: {
        show: function (src) {
            this.src = src;
        },
        hide: function () {
            this.src = null;
        },
    }
});
