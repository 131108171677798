import onboardingCards from '../data/onboardingCards.json';
import onboardingEvents from '../data/onboardingEvents.json';
import passData from '@common/utils/PassData';
import analytics from '@common/utils/Analytics/Analytics';
import { defineStore } from 'pinia';
import Api from '@school/modules/api/api';
/**
 * Сторедж онбординга (туториалы с карточками)
 */
export var useOnboardingStore = defineStore('onboarding', {
    state: function () { return ({
        // Отображать обучающий туториал
        visible: false,
        // Ключ обучающего туториала
        tutorial: '',
        // Список уже просмотренных туториалов
        completedTutorials: passData.completedTutorials,
        // Список карточек открытого туториала
        cards: [],
    }); },
    actions: {
        /**
         * Показать попап туториала
         *
         * @param {object} data data
         * @param {string} data.key ключ туториала
         * @param {Array<object>} data.cards карточки туториала
         * @returns {Promise}
         */
        show: function (_a) {
            var key = _a.key, cards = _a.cards;
            this.visible = true;
            this.tutorial = key;
            this.cards = cards;
        },
        /**
         * Скрыть попап туториала
         *
         * @returns {Promise}
         */
        hide: function () {
            this.tutorial = '';
            this.visible = false;
        },
        /**
         * Завершить просмотр туториала
         *
         * @returns {Promise}
         */
        finish: function () {
            var _this = this;
            var tutorial = this.tutorial;
            analytics.trackDetail({
                'Раздел': 'Прошел туториал',
                'Событие': onboardingEvents[tutorial],
            });
            if (!this.completedTutorials.includes(tutorial)) {
                Api.addCompletedTutorial(tutorial)
                    .then(function (res) {
                    var data = res.data;
                    _this.completedTutorials = data;
                });
            }
            this.hide();
        },
        /**
         * Открыть туториал по запросу пользователя
         *
         * @param {string} key ключ туториала
         * @param {boolean} manually пользователь сам запросил туториал
         * @returns {Promise}
         */
        open: function (key, manually) {
            if (manually === void 0) { manually = false; }
            // Какой-либо туториал уже открыт, либо туториал уже был прочитан
            if (this.visible
                || (this.completedTutorials.includes(key)
                    && manually !== true))
                return;
            this.show({
                key: key,
                cards: onboardingCards[key]
            });
        },
        /**
         * Пользовать нажал "пропустить обучение",
         * помечаем все туториалы пройденными
         *
         * @returns {Promise}
         */
        skipAllTutorials: function () {
            var _this = this;
            var tutorialCodes = Object.keys(onboardingCards);
            this.hide();
            analytics.trackDetail({ 'Раздел': 'Пропустил все туториалы', });
            Api.addCompletedTutorials(tutorialCodes)
                .then(function (res) {
                var data = res.data;
                _this.completedTutorials = data;
            });
        },
    },
});
