import { FileMeta } from '@common/utils/Catalyst/FileMeta';
var CatalystBlob = /** @class */ (function () {
    function CatalystBlob(file, url) {
        this._type = 'file';
        this._file = file;
        // ! Написать логику удаления objectUrl при unmount компонентов их использующих
        this._link = (url !== null && url !== void 0 ? url : URL.createObjectURL(this._file));
        this._meta = new FileMeta(this._file.name, this._file.size, this._file.type, this._file.lastModified);
    }
    CatalystBlob.prototype.getExtension = function () {
        return this._meta.getExtension();
    };
    CatalystBlob.prototype.getName = function () {
        return this._meta.getName();
    };
    CatalystBlob.prototype.getSize = function () {
        return this._meta.getSize();
    };
    CatalystBlob.prototype.getType = function () {
        return this._type;
    };
    CatalystBlob.prototype.getLink = function () {
        return this._link;
    };
    CatalystBlob.prototype.getFile = function () {
        return this._file;
    };
    CatalystBlob.prototype.getMime = function () {
        return this._meta.getMime();
    };
    return CatalystBlob;
}());
export { CatalystBlob };
