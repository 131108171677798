import { useEventBus, useWebSocket } from '@vueuse/core';
import { EventBusEnum } from '@common/enums/eventBus.enum';
var busSocket = useEventBus(EventBusEnum.WEBSOCKET_MESSAGE);
var busWsConnected = useEventBus(EventBusEnum.WEBSOCKET_CONNECTED);
var wsReconnectInterval = null;
var _a = useWebSocket("wss://".concat(window.location.hostname, "/api/ws"), {
    onMessage: function (ws, event) {
        var data = JSON.parse(event.data);
        busSocket.emit(data.event, data.data);
    },
    onError: function (ws, event) {
        console.error(event);
    },
    onConnected: function () {
        busWsConnected.emit();
        if (wsReconnectInterval || status.value === 'OPEN')
            return;
        wsReconnectInterval = setInterval(function () {
            openWsConnection();
        }, 5000);
    },
    heartbeat: {
        message: JSON.stringify({
            event: 'ping',
            data: ''
        }),
        interval: 20000,
        pongTimeout: 20000,
    },
    immediate: false,
    autoClose: true,
}), openWsConnection = _a.open, data = _a.data, closeWsConnection = _a.close, sendMessage = _a.send, status = _a.status, ws = _a.ws;
export { openWsConnection, closeWsConnection, sendMessage, };
