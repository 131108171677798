export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 participants"]), _normalize([_interpolate(_named("n")), " participant"]), _normalize([_interpolate(_named("n")), " participants"])])}
        }
      },
      "ru": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чат"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 участников"]), _normalize([_interpolate(_named("n")), " участник"]), _normalize([_interpolate(_named("n")), " участника"]), _normalize([_interpolate(_named("n")), " участников"])])}
        }
      },
      "es": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 participantes"]), _normalize([_interpolate(_named("n")), " participante"]), _normalize([_interpolate(_named("n")), " participantes"])])}
        }
      },
      "kk": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чат"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабар жіберу"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 қатысушы"]), _normalize([_interpolate(_named("n")), " қатысушы"]), _normalize([_interpolate(_named("n")), " қатысушы"])])}
        }
      },
      "uz": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabar yuborish"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ishtirokchi"]), _normalize([_interpolate(_named("n")), " ishtirokchi"]), _normalize([_interpolate(_named("n")), " ishtirokchilar"])])}
        }
      },
      "de": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 Teilnehmer"]), _normalize([_interpolate(_named("n")), " Teilnehmer"]), _normalize([_interpolate(_named("n")), " Teilnehmer"])])}
        }
      },
      "fr": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 participants"]), _normalize([_interpolate(_named("n")), " participant"]), _normalize([_interpolate(_named("n")), " participants"])])}
        }
      },
      "it": {
        "recipients": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia messaggio"])},
          "participant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 partecipanti"]), _normalize([_interpolate(_named("n")), " partecipante"]), _normalize([_interpolate(_named("n")), " partecipanti"])])}
        }
      }
    }
  })
}
