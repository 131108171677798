export var translations = {
    en: {
        today_at: 'Today at ',
        yesterday_at: 'Yesterday at ',
    },
    ru: {
        today_at: 'Сегодня в ',
        yesterday_at: 'Вчера в '
    },
    es: {
        today_at: 'Hoy a las ',
        yesterday_at: 'Ayer a las '
    },
    kk: {
        today_at: 'Бүгін сағат ',
        yesterday_at: 'Кеше сағат '
    },
    uz: {
        today_at: 'Bugun soat ',
        yesterday_at: 'Kecha soat '
    },
    de: {
        today_at: 'Heute um ',
        yesterday_at: 'Gestern um '
    },
    it: {
        today_at: 'Oggi alle ',
        yesterday_at: 'Ieri alle '
    },
    fr: {
        today_at: 'Aujourd\'hui à ',
        yesterday_at: 'Hier à '
    }
};
