import passData from '@common/utils/PassData';
import { yandexAnalytics, YMEventsEnum } from "@common/utils/Analytics/YandexAnalytics";
import { GAEventsEnum, googleAnalytics } from "@common/utils/Analytics/GoogleAnalytics";
var Analytics = /** @class */ (function () {
    function Analytics() {
        this.carrotquest = window.carrotquest;
        this.mixpanel = window.mixpanel;
    }
    Analytics.prototype.setVkPixel = function () {
        // Наш локальный вк пиксель, только для Skillspace, не школ
        try {
            var _tmr = window._tmr || (window._tmr = []);
            _tmr.push({ id: "3489847", type: "pageView", start: (new Date()).getTime() });
            (function (d, w, id) {
                if (d.getElementById(id))
                    return;
                var ts = d.createElement("script");
                ts.type = "text/javascript";
                ts.async = true;
                ts.id = id;
                ts.src = "https://top-fwz1.mail.ru/js/code.js";
                var f = function () { var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s); };
                if (w.opera == "[object Opera]") {
                    d.addEventListener("DOMContentLoaded", f, false);
                }
                else {
                    f();
                }
            })(document, window, "tmr-code");
        }
        catch (err) {
            console.error('При включении VKPixel произошла ошибка:', err);
        }
    };
    Analytics.prototype.track = function (name, params) {
        if (passData.environment === 'dev') {
            // Для дебага
            console.info("%c \u0421\u043E\u0431\u044B\u0442\u0438\u0435: ".concat(name), 'color: #bada55');
        }
        if (!this.carrotquest)
            return;
        if (Array.isArray(name)) {
            name = name.join(' → ');
        }
        if (this.mixpanel) {
            try {
                if (params) {
                    this.mixpanel.track(name, params);
                }
                else {
                    this.mixpanel.track(name);
                }
            }
            catch (_a) {
                // console.error('Ошибка трекинга аналитики', e);
            }
        }
        if (this.carrotquest) {
            try {
                if (params) {
                    this.carrotquest.track(name, params);
                }
                else {
                    this.carrotquest.track(name);
                }
            }
            catch (_b) {
                // console.error('Ошибка трекинга аналитики', e);
            }
        }
    };
    Analytics.prototype.trackDetail = function (params) {
        try {
            var name = "".concat(params['Раздел']);
            if (params['Событие']) {
                name += " \u2192 ".concat(params['Событие']);
            }
            if (params['Подсобытие']) {
                name += " \u2192 ".concat(params['Подсобытие']);
            }
            this.track(name, params);
        }
        catch (_a) {
            // console.error('Ошибка трекинга аналитики', e);
        }
    };
    Analytics.prototype.registerOnPlatform = function () {
        yandexAnalytics.sendPlatformEvent(YMEventsEnum.REGISTER);
        googleAnalytics.sendPlatformEvent(GAEventsEnum.REGISTER);
    };
    return Analytics;
}());
var analytics = new Analytics();
export default analytics;
