export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Chats"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Chats"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by Last Message Date"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by Name"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No chats found with the applied filters =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search for \"", _interpolate(_named("search")), "\" did not find any chats =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no chats yet."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add school-wide chats in message settings and individual chats in each student group's settings."])}
        }
      },
      "ru": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все чаты"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групповые чаты"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По дате последнего сообщения"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По названию"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По данным фильтрам ни один чат не найден =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поиск по \"", _interpolate(_named("search")), "\" не нашёл ни одного чата =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас пока нет ни одного чата."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете добавить общешкольные чаты в настройках сообщений и отдельные чаты в настройках каждой группы учеников."])}
        }
      },
      "es": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los chats"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats grupales"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canales"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por último mensaje"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por nombre"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún chat con estos filtros =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La búsqueda por \"", _interpolate(_named("search")), "\" no encontró ningún chat =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes ningún chat por el momento."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes agregar chats escolares generales en la configuración de mensajes y chats individuales en la configuración de cada grupo de estudiantes."])}
        }
      },
      "kk": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық чаттар"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топтық чаттар"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арналар"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы хабарлама күні бойынша сұрыптау"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы бойынша сұрыптау"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілген сүзгілер бойынша ешқандай чат табылмады =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("search")), "\" бойынша іздеу ешқандай чат табылмады =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізде қазірге дейін ешқандай чат жоқ."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз хабарламалар баптауларында жалпы мектептегі чаттарды және әрбір студенттер тобының баптауларында жеке чаттарды қосуға болады."])}
        }
      },
      "uz": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcha chatlar"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qidiruv"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guruh chatlari"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanallar"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So'nggi xabar sanasiga ko'ra"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomiga ko'ra"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berilgan filtrlar asosida hech qanday chat topilmadi =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["«", _interpolate(_named("search")), "» bo'yicha qidiruv hech qanday chat topmadi =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizda hozircha hech qanday chat yo'q."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz xabar sozlamalarida umumiy maktab chatlarini va har bir o'quv guruhining sozlamalarida alohida chatlarni qo'shishingiz mumkin."])}
        }
      },
      "de": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Chats"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen-Chats"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanäle"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Datum der letzten Nachricht"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen sortieren"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Chats mit diesen Filtern gefunden =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Suche nach \"", _interpolate(_named("search")), "\" hat keine Chats gefunden =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keine Chats."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können allgemeine Chats in den Nachrichteneinstellungen hinzufügen und separate Chats in den Einstellungen jeder Schülergruppe."])}
        }
      },
      "fr": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les chats"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats de groupe"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canaux"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par date du dernier message"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par nom"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun chat trouvé avec ces filtres =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La recherche de \"", _interpolate(_named("search")), "\" n'a trouvé aucun chat =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez encore aucun chat."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter des chats scolaires dans les paramètres des messages et des chats individuels dans les paramètres de chaque groupe d'élèves."])}
        }
      },
      "it": {
        "chats": {
          "allChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le chat"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])},
          "groupChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat di gruppo"])},
          "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canali"])},
          "sortByLastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per data dell'ultimo messaggio"])},
          "sortByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per nome"])},
          "noChatsByFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna chat trovata con questi filtri =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La ricerca per \"", _interpolate(_named("search")), "\" non ha trovato nessuna chat =("])},
          "noChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora nessuna chat."])},
          "noChatsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi aggiungere chat scolastiche nelle impostazioni dei messaggi e chat individuali nelle impostazioni di ciascun gruppo di studenti."])}
        }
      }
    }
  })
}
