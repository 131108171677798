import axios from 'axios';
var HttpClient = /** @class */ (function () {
    function HttpClient(config) {
        this._client = axios.create(config);
        this.interceptors = this._client.interceptors;
    }
    HttpClient.prototype.get = function (url, config) {
        return this._client.get(url, config);
    };
    HttpClient.prototype.post = function (url, data, config) {
        return this._client.post(url, data, config);
    };
    HttpClient.prototype.delete = function (url, config) {
        return this._client.delete(url, config);
    };
    HttpClient.prototype.put = function (url, data, config) {
        return this._client.put(url, data, config);
    };
    HttpClient.prototype.patch = function (url, data, config) {
        return this._client.patch(url, data, config);
    };
    return HttpClient;
}());
export { HttpClient };
