var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var parseJson = function (value) { return JSON.parse(value.replaceAll("'", '"')); };
export var parseInput = function (input, defaults) {
    if (defaults === void 0) { defaults = {}; }
    var opts = __assign({}, defaults);
    if (input.dataset.maska != null && input.dataset.maska !== '') {
        opts.mask = parseMask(input.dataset.maska);
    }
    if (input.dataset.maskaEager != null) {
        opts.eager = parseOpts(input.dataset.maskaEager);
    }
    if (input.dataset.maskaReversed != null) {
        opts.reversed = parseOpts(input.dataset.maskaReversed);
    }
    if (input.dataset.maskaTokensReplace != null) {
        opts.tokensReplace = parseOpts(input.dataset.maskaTokensReplace);
    }
    if (input.dataset.maskaTokens != null) {
        opts.tokens = parseTokens(input.dataset.maskaTokens);
    }
    return opts;
};
var parseOpts = function (value) {
    return value !== '' ? Boolean(JSON.parse(value)) : true;
};
var parseMask = function (value) {
    return value.startsWith('[') && value.endsWith(']') ? parseJson(value) : value;
};
var parseTokens = function (value) {
    var e_1, _a;
    if (value.startsWith('{') && value.endsWith('}')) {
        return parseJson(value);
    }
    var tokens = {};
    try {
        for (var _b = __values(value.split('|')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var token = _c.value;
            var parts = token.split(':');
            tokens[parts[0]] = {
                pattern: new RegExp(parts[1]),
                optional: parts[2] === 'optional',
                multiple: parts[2] === 'multiple',
                repeated: parts[2] === 'repeated',
                escape: parts[2] === 'escape'
            };
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return tokens;
};
