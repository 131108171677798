var CurrentI18n = /** @class */ (function () {
    function CurrentI18n() {
    }
    CurrentI18n.setInstance = function (instance) {
        this.instance = instance;
    };
    CurrentI18n.getInstance = function () {
        return this.instance;
    };
    return CurrentI18n;
}());
export default CurrentI18n;
