var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import dayjs from 'dayjs';
import passData from '@common/utils/PassData';
import { defineStore } from 'pinia';
import { daysLeftToDate } from '@common/utils/Datetime/Datetime';
import { apiErrorsHandle } from '@common/utils/Helpers/Helpers';
var Api = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, (window.__buildName === 'appSchool' ? import('@school/modules/api/api') : import('@student/modules/api/api'))];
        case 1: return [2 /*return*/, (_a.sent()).default];
    }
}); }); };
export var useCommonCoreStore = defineStore('core', {
    state: function () {
        var _a, _b, _c;
        return ({
            // Дата окончания подписки школы
            schoolSubscriptionEndDate: (_b = (_a = passData === null || passData === void 0 ? void 0 : passData.schoolPlan) === null || _a === void 0 ? void 0 : _a.subscription) === null || _b === void 0 ? void 0 : _b.endAt,
            // Видимость модального окна о недоступности курсов
            schoolSubscriptionModalVisible: false,
            // Отображать окно с лимитом тарифа
            planLimitVisible: false,
            // Тип лимита тарифа для отображения
            planLimitType: '',
            // Текущий пользователь
            // @sync
            user: {},
            // Текущая школы
            // @sync
            school: passData.school,
            // Настройки школы
            // @sync
            schoolSettings: passData.schoolSettings,
            // Тариф школы
            // @sync
            plan: passData.schoolPlan,
            // Использованные лимиты по тарифу
            // @sync
            planLimitsUsage: {},
            // Подписка школы (с инфой об окончании)
            // @sync
            subscription: (_c = passData.schoolPlan) === null || _c === void 0 ? void 0 : _c.subscription,
            // Функции, что доступны на тарифе школы
            // @sync
            schoolFeaturesAccess: passData.schoolFeaturesAccess,
            // Информация о промокодах школы
            discount: passData.applyDiscount,
        });
    },
    getters: {
        /**
         * Остаток дней на текущем тарифе
         *
         * @param state
         * @param store
         * @returns {number}
         */
        daysLeft: function (store) {
            var formattedDays = daysLeftToDate(store.subscription.endAt);
            if (formattedDays <= 0)
                return 0;
            return formattedDays;
        },
        /**
         * Школа на бесплатном тарифе
         * @param store
         */
        isStarterPlan: function (store) { return store.plan.code === 'new-school-free' || store.plan.code === 'new-school-free-new'; },
        schoolSubscriptionIsOverdue: function (store) {
            var today = dayjs();
            var blockDate = dayjs(store.schoolSubscriptionEndDate);
            return store.schoolSubscriptionEndDate ? today.isAfter(blockDate) : false;
        },
    },
    actions: {
        /**
         * Доступна ли фича данной школе
         *
         * @param {string} featureCode Код фичи
         * @returns {boolean}
         */
        canUseFeature: function (featureCode) {
            return this.schoolFeaturesAccess.includes(featureCode);
        },
        /**
         * appSchool:
         * Получить использованные на тарифе лимиты
         *
         * @returns {Promise}
         */
        getLimitsUsage: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, Api()];
                        case 1: return [4 /*yield*/, (_a.sent()).getLimitsUsage()];
                        case 2:
                            data = (_a.sent()).data;
                            this.planLimitsUsage = data;
                            return [2 /*return*/, data];
                        case 3:
                            err_1 = _a.sent();
                            apiErrorsHandle(err_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * appSchool:
         * Показать окно достигнутого лимита
         *
         * @param {'course'|'storage'|'employee'|'student'} type Тип окна
         */
        showPlanLimit: function (type) {
            this.planLimitType = type;
            this.planLimitVisible = true;
        },
    }
});
export var coreStore = useCommonCoreStore;
