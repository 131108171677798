export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or Username"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email or username"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login via mos.ru"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter email or username"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user found with this email"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password"])}
          }
        }
      },
      "ru": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в аккаунт"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет аккаунта?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email или логин"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш email или логин"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить меня"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не получается войти"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через mos.ru"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите email или логин"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь с таким email не существует"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный логин или пароль"])}
          }
        }
      },
      "es": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tienes una cuenta?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico o nombre de usuario"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico o nombre de usuario"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuérdame"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No puedes iniciar sesión?"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión a través de mos.ru"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce correo electrónico o nombre de usuario"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce contraseña"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe un usuario con ese correo electrónico"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario o contraseña incorrectos"])}
          }
        }
      },
      "kk": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазбаға кіру"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазбаңыз жоқ па?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелу"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық пошта немесе кіру аты"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің электрондық поштаңыз немесе кіру атыңыз"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мені жатта"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру мүмкін болмады"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mos.ru арқылы кіру"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық поштаңызды немесе кіру атыңызды енгізіңіз"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөзді енгізіңіз"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұндай электрондық поштаға ие қолданушы жоқ"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате кіру аты немесе құпия сөз"])}
          }
        }
      },
      "uz": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hisobga kirish"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hisobingiz yo‘qmi?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ro‘yxatdan o‘tish"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email yoki foydalanuvchi nomi"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailingiz yoki foydalanuvchi nomingiz"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meni eslab qol"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolni unutdingizmi?"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirish"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mos.ru orqali kirish"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email yoki foydalanuvchi nomini kiriting"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolni kiriting"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunday emailga ega foydalanuvchi mavjud emas"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noto‘g‘ri foydalanuvchi nomi yoki parol"])}
          }
        }
      },
      "de": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Konto?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail oder Benutzername"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail oder Ihr Benutzername"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet bleiben"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden über mos.ru"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie E-Mail oder Benutzername ein"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie das Passwort ein"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer mit dieser E-Mail existiert nicht"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Anmeldedaten"])}
          }
        }
      },
      "fr": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion au compte"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte ?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou identifiant"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email ou identifiant"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se souvenir de moi"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de se connecter"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter via mos.ru"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre email ou identifiant"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utilisateur avec cet email n'existe pas"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant ou mot de passe incorrect"])}
          }
        }
      },
      "it": {
        "login": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso all'account"])},
          "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai un account?"])},
          "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email o nome utente"])},
          "emailOrLoginPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua email o nome utente"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricordami"])},
          "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile accedere"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
          "mosruLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi tramite mos.ru"])},
          "validation": {
            "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci email o nome utente"])},
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci password"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente trovato con questa email"])},
            "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente o password non validi"])}
          }
        }
      }
    }
  })
}
