var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { tokens } from './tokens';
var Mask = /** @class */ (function () {
    function Mask(defaults) {
        var e_1, _a;
        if (defaults === void 0) { defaults = {}; }
        var _b;
        this.opts = {};
        this.memo = new Map();
        var opts = __assign({}, defaults);
        if (opts.tokens != null) {
            opts.tokens = opts.tokensReplace
                ? __assign({}, opts.tokens) : __assign(__assign({}, tokens), opts.tokens);
            try {
                for (var _c = __values(Object.values(opts.tokens)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var token = _d.value;
                    if (typeof token.pattern === 'string') {
                        token.pattern = new RegExp(token.pattern);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            opts.tokens = tokens;
        }
        if (Array.isArray(opts.mask)) {
            opts.mask = opts.mask.length > 1 ? __spreadArray([], __read(opts.mask), false).sort(function (a, b) { return a.length - b.length; }) : (_b = opts.mask[0]) !== null && _b !== void 0 ? _b : '';
        }
        if (opts.mask === '') {
            opts.mask = null;
        }
        this.opts = opts;
    }
    Mask.prototype.masked = function (value) {
        return this.process(value, this.findMask(value));
    };
    Mask.prototype.unmasked = function (value) {
        return this.process(value, this.findMask(value), false);
    };
    Mask.prototype.isEager = function () {
        return this.opts.eager === true;
    };
    Mask.prototype.isReversed = function () {
        return this.opts.reversed === true;
    };
    Mask.prototype.completed = function (value) {
        var mask = this.findMask(value);
        if (this.opts.mask == null || mask == null)
            return false;
        var length = this.process(value, mask).length;
        if (typeof this.opts.mask === 'string') {
            return length >= this.opts.mask.length;
        }
        else if (typeof this.opts.mask === 'function') {
            return length >= mask.length;
        }
        else {
            return (this.opts.mask.filter(function (m) { return length >= m.length; }).length ===
                this.opts.mask.length);
        }
    };
    Mask.prototype.findMask = function (value) {
        var _this = this;
        var _a, _b;
        var mask = this.opts.mask;
        if (mask == null) {
            return null;
        }
        else if (typeof mask === 'string') {
            return mask;
        }
        else if (typeof mask === 'function') {
            return mask(value);
        }
        var l = this.process(value, (_a = mask.slice(-1).pop()) !== null && _a !== void 0 ? _a : '', false);
        return ((_b = mask.find(function (el) { return _this.process(value, el, false).length >= l.length; })) !== null && _b !== void 0 ? _b : '');
    };
    Mask.prototype.isEscape = function (ch) {
        if (ch === '!' && !this.opts.tokensReplace) {
            return true;
        }
        else {
            for (var tokensKey in this.opts.tokens) {
                if (tokensKey === ch && this.opts.tokens[tokensKey].escape) {
                    return true;
                }
            }
        }
        return false;
    };
    Mask.prototype.escapeMask = function (maskRaw) {
        var e_2, _a;
        var chars = [];
        var escaped = [];
        try {
            for (var _b = __values(maskRaw.split('').entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), i = _d[0], ch = _d[1];
                if (this.isEscape(ch) && !this.isEscape(maskRaw[i - 1])) {
                    escaped.push(i - escaped.length);
                }
                else {
                    chars.push(ch);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return { mask: chars.join(''), escaped: escaped };
    };
    Mask.prototype.process = function (value, maskRaw, masked) {
        var _this = this;
        var _a;
        if (masked === void 0) { masked = true; }
        if (maskRaw == null)
            return value;
        var key = "value=".concat(value, ",mask=").concat(maskRaw, ",masked=").concat(masked ? 1 : 0);
        if (this.memo.has(key))
            return this.memo.get(key);
        var _b = this.escapeMask(maskRaw), mask = _b.mask, escaped = _b.escaped;
        var result = [];
        var tokens = this.opts.tokens != null ? this.opts.tokens : {};
        var offset = this.isReversed() ? -1 : 1;
        var method = this.isReversed() ? 'unshift' : 'push';
        var lastMaskChar = this.isReversed() ? 0 : mask.length - 1;
        var check = this.isReversed()
            ? function () { return m > -1 && v > -1; }
            : function () { return m < mask.length && v < value.length; };
        var notLastMaskChar = function (m) {
            return (!_this.isReversed() && m <= lastMaskChar) ||
                (_this.isReversed() && m >= lastMaskChar);
        };
        var lastRawMaskChar;
        var repeatedPos = -1;
        var m = this.isReversed() ? mask.length - 1 : 0;
        var v = this.isReversed() ? value.length - 1 : 0;
        while (check()) {
            var maskChar = mask.charAt(m);
            var token = tokens[maskChar];
            var valueChar = (token === null || token === void 0 ? void 0 : token.transform) != null
                ? token.transform(value.charAt(v))
                : value.charAt(v);
            if (!escaped.includes(m) && token != null) {
                if (valueChar.match(token.pattern) != null) {
                    result[method](valueChar);
                    if (token.repeated) {
                        if (repeatedPos === -1) {
                            repeatedPos = m;
                        }
                        else if (m === lastMaskChar && m !== repeatedPos) {
                            m = repeatedPos - offset;
                        }
                        if (lastMaskChar === repeatedPos) {
                            m -= offset;
                        }
                    }
                    else if (token.multiple) {
                        m -= offset;
                    }
                    m += offset;
                }
                else if (token.multiple) {
                    var hasValue = ((_a = result[v - offset]) === null || _a === void 0 ? void 0 : _a.match(token.pattern)) != null;
                    var nextMask = mask.charAt(m + offset);
                    if (hasValue && nextMask !== '' && tokens[nextMask] == null) {
                        m += offset;
                        v -= offset;
                    }
                    else {
                        result[method]('');
                    }
                }
                else if (valueChar === lastRawMaskChar) {
                    // matched the last untranslated (raw) mask character that we encountered
                    // likely an insert offset the mask character from the last entry;
                    // fall through and only increment v
                    lastRawMaskChar = undefined;
                }
                else if (token.optional) {
                    m += offset;
                    v -= offset;
                }
                else {
                    // invalid input
                }
                v += offset;
            }
            else {
                if (masked && !this.isEager()) {
                    result[method](maskChar);
                }
                if (valueChar === maskChar && !this.isEager()) {
                    v += offset;
                }
                else {
                    lastRawMaskChar = maskChar;
                }
                if (!this.isEager()) {
                    m += offset;
                }
            }
            if (this.isEager()) {
                while (notLastMaskChar(m) &&
                    (tokens[mask.charAt(m)] == null || escaped.includes(m))) {
                    if (masked) {
                        result[method](mask.charAt(m));
                    }
                    else if (mask.charAt(m) === value.charAt(v)) {
                        v += offset;
                    }
                    m += offset;
                }
            }
        }
        this.memo.set(key, result.join(''));
        return this.memo.get(key);
    };
    return Mask;
}());
export { Mask };
