import { LessonTypeEnum } from '@common/enums/lessonType.enum';
import dayjs from 'dayjs';
import { LessonProgressStatusEnum } from '@common/enums/lessonProgressStatus.enum';
var byHomeworkStatus = {
    autoaccept_pending: LessonProgressStatusEnum.CHECK,
    student_sent: LessonProgressStatusEnum.CHECK,
    closed_good: LessonProgressStatusEnum.COMPLETED,
    autoaccept_accepted: LessonProgressStatusEnum.COMPLETED,
    teacher_sent: LessonProgressStatusEnum.REWORK,
    closed_bad: LessonProgressStatusEnum.REJECTED,
};
// eslint-disable-next-line complexity
var isPrevLessonBlocked = function (group, prevLesson) {
    // if (
    //     prevLesson.type === LessonTypeEnum.HOMEWORK &&
    //     // Если домашки заблокированы, то не проверяем их
    //     !group.homeworkBlock
    // ) {
    //     const hasHomeworkApproveRequired = group.hardSequenceHomework === 'APPROVMENT_REQUIRED'
    //     const hasHomeworkSendRequiredAndUserSendAnswers = group.hardSequenceHomework === 'SEND_REQUIRED' && (!prevLesson.homeworkUser || prevLesson.homeworkUser.answers.length <= 0)
    //
    //     if (hasHomeworkApproveRequired || hasHomeworkSendRequiredAndUserSendAnswers) {
    //         return true
    //     }
    // } else
    // if (prevLesson.type === LessonTypeEnum.TEST) {
    //     const isPassRequired = group.hardSequenceTest === 'PASS_REQUIRED';
    //     const isPassRequiredOnceAndFailTried = group.hardSequenceTest === 'PASS_REQUIRED_ONCE' && !prevLesson.tried;
    //
    //     if (isPassRequired || isPassRequiredOnceAndFailTried) {
    //         return true
    //     }
    // } else
    // if ([LessonTypeEnum.WEBINAR, LessonTypeEnum.DEFAULT].includes(prevLesson.type)) {
    //     return true
    // }
    return false;
};
var lessonIsBlocked = function (group, lesson, lessonIndex, lessons) {
    // Порядок выключен или занятие уже пройдено
    var isCompletedOrOffSequence = !group.hardSequence || lesson.completed;
    // Первое занятие
    var isFirstLesson = lessonIndex === 0;
    // Истекший вебинар
    var isExpiredWebinar = lesson.type === LessonTypeEnum.WEBINAR && Date.now() > lesson.webinarFlow.startAt + lesson.webinarFlow.duration;
    if (isCompletedOrOffSequence || isFirstLesson || isExpiredWebinar) {
        return false;
    }
    var prevLesson = lessonIndex > 0 ? lessons[lessonIndex - 1] : null;
    if (!prevLesson) {
        return false;
    }
    // У урока обязательно должен быть доступ и он должен быть не завершен
    var hasAccessAndNotCompleted = prevLesson.access && !prevLesson.completed;
    return hasAccessAndNotCompleted ? isPrevLessonBlocked(group, prevLesson) : false;
};
// eslint-disable-next-line complexity
export var getLessonStatus = function (lesson, lessonIndex, lessons, group) {
    // если домашка приходит с фейлом
    var isFailedHomework = lesson.type === LessonTypeEnum.HOMEWORK && lesson.completedFail;
    // если у теста исчерпались лимиты
    var isExpiredTestLimits = !lesson.completed && lesson.type === LessonTypeEnum.TEST && (lesson.test ? lesson.test.attemptsLimit > 0 && ((lesson.test.attemptsLimit - lesson.test.attemptsCount) === 0) : false);
    if (isFailedHomework || isExpiredTestLimits) {
        return LessonProgressStatusEnum.REJECTED;
    }
    if (!lesson.access || lesson.accessHardSequence === false || group && lessonIsBlocked(group, lesson, lessonIndex, lessons)) {
        return LessonProgressStatusEnum.BLOCKED;
    }
    if (lesson.type === LessonTypeEnum.WEBINAR && lesson.webinarFlow) {
        var wbStart = dayjs(lesson.webinarFlow.startAt);
        var wbFinish = wbStart.add(lesson.webinarFlow.duration, 'minutes');
        if (wbStart.unix() < dayjs().unix() && dayjs().unix() < wbFinish.unix()) {
            return LessonProgressStatusEnum.WEBINAR_GOING;
        }
    }
    else if (lesson.type === LessonTypeEnum.HOMEWORK && lesson.homeworkUser) {
        return (byHomeworkStatus[lesson.homeworkUser.status] || LessonProgressStatusEnum.NOT_COMPLETED);
    }
    return lesson.completed ? LessonProgressStatusEnum.COMPLETED : LessonProgressStatusEnum.NOT_COMPLETED;
};
