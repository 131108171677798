export var EntityTypeEnum;
(function (EntityTypeEnum) {
    EntityTypeEnum["TEXT"] = "TEXT";
    EntityTypeEnum["IMAGE"] = "IMAGE";
    EntityTypeEnum["STANDARD_IMAGE"] = "STANDARD_IMAGE";
    EntityTypeEnum["EDITABLE_TEXT"] = "EDITABLE_TEXT";
    EntityTypeEnum["QR"] = "QR";
    EntityTypeEnum["DOMAIN"] = "DOMAIN";
    EntityTypeEnum["NUMBER_AND_DATE"] = "NUMBER_AND_DATE";
})(EntityTypeEnum || (EntityTypeEnum = {}));
