var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Удаляем дубли из массива
 */
export function distinctArray(array) {
    return __spreadArray([], __read(new Set(array)), false);
}
/**
 * Перемешивает элементы массива случайным образом
 *
 * @param array
 * @returns array
 */
export function shuffleArray(array) {
    var _a;
    var newArray = __spreadArray([], __read(array), false);
    for (var i = newArray.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        _a = __read([newArray[j], newArray[i]], 2), newArray[i] = _a[0], newArray[j] = _a[1];
    }
    return newArray;
}
/**
 *
 * @param array{Array<any>}
 * @param array
 * @returns {*}
 */
export function getArrayRandomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
}
/**
 *
 * @param array Массив
 * @param {string | Function} property Свойство по которому нужно провести сортировку или функция, которая вернет
 * нужное значение для сортировки
 * @param dir {'asc'|'desc'} Направление сортировки
 * @returns {*}
 * @deprecated Функция изменяет исходный массив. Для сортировки используется sortArray функция
 */
export function sortArrayByProperty(array, property, dir) {
    if (dir === void 0) { dir = 'asc'; }
    return array.sort(function (a, b) {
        if (typeof property === 'function') {
            return dir === 'asc' ? property(a) - property(b) : property(b) - property(a);
        }
        else {
            return dir === 'asc' ? a[property] - b[property] : b[property] - a[property];
        }
    });
}
export var sortArray = function (array, property, dir) {
    if (dir === void 0) { dir = 'asc'; }
    return array.toSorted(function (a, b) {
        if (typeof property === 'function') {
            return dir === 'asc' ? property(a) - property(b) : property(b) - property(a);
        }
        else {
            return dir === 'asc' ? a[property] - b[property] : b[property] - a[property];
        }
    });
};
/**
 * Нормализует свойство order у элементов массива (приводя их к сотням) в соответствии с их порядком
 *
 * @param {Array<object>} array массив для нормализации сортировки,
 * @param {string} property свойство по которому сортировать
 * @param {number} step шаг значений (100 по умолчанию)
 * @returns {Array}
 */
export function normalizeArrayOrder(array, property, step) {
    if (property === void 0) { property = 'order'; }
    if (step === void 0) { step = 100; }
    // Сортируем
    var sortedArray = sortArray(array, property);
    // Нормализуем сортировку по шагам
    return sortedArray.map(function (el, index) {
        var _a;
        return (__assign(__assign({}, el), (_a = {}, _a[property] = (index + 1) * step, _a)));
    });
}
/**
 * Сортирует первый массив объектов в соответствии с порядком второго массива по указанному свойству
 */
export function sortByArray(firstArray, secondArray, property) {
    var copyFirstArray = __spreadArray([], __read(firstArray), false);
    copyFirstArray.sort(function (a, b) {
        var aIndex = secondArray.findIndex(function (element) { return element[property] === a[property]; });
        var bIndex = secondArray.findIndex(function (element) { return element[property] === b[property]; });
        if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex;
        }
        if (aIndex !== -1) {
            return -1;
        }
        if (bIndex !== -1) {
            return 1;
        }
        return 0;
    });
    return copyFirstArray;
}
/**
 * Make array from array-like collection
 *
 * @param {ArrayLike} collection - collection to convert to array
 * @returns {Array}
 */
export function toArray(collection) {
    return Array.prototype.slice.call(collection);
}
/**
 * Переварить массив в массив из SortEntityType[]
 *
 * @param array
 */
export function getOrderArray(array) {
    return array.map(function (el) { return ({
        uuid: el.uuid,
        order: el.order,
    }); });
}
