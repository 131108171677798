var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { convertToFormData } from '@common/utils/Helpers/Helpers';
import { ApiCommonClass } from '@common/api/apiCommon';
import { httpClientApiStudent } from '@common/utils/HttpClient';
var Api = /** @class */ (function (_super) {
    __extends(Api, _super);
    function Api() {
        return _super.call(this) || this;
    }
    /**
     * Course
     *
     * @param {string} courseId
     */
    Api.prototype.getCourse = function (courseId) {
        return httpClientApiStudent.get("/course/".concat(courseId));
    };
    /**
     * Достаем дополнительную инфу по курсу - условия для завершения и условия для получения сертификата
     * @param courseUuid
     */
    Api.prototype.checkCourseInfo = function (courseUuid) {
        return httpClientApiStudent.get("/course/".concat(courseUuid, "/check-available"));
    };
    Api.prototype.getCourses = function () {
        return httpClientApiStudent.get("/course/list");
    };
    /**
     * Lesson
     */
    Api.prototype.getLesson = function (lessonId) {
        return httpClientApiStudent.get("/lesson/".concat(lessonId));
    };
    /**
     * Lesson complete
     */
    Api.prototype.getStudentProgress = function () {
        return httpClientApiStudent.get('/course/progress');
    };
    Api.prototype.addLessonCompleted = function (lessonUuid) {
        return httpClientApiStudent.post("/lesson/".concat(lessonUuid, "/completed"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Lesson Test
     */
    Api.prototype.checkoutLessonTest = function (lessonId, data) {
        data = convertToFormData(data);
        return httpClientApiStudent.post("/lesson/".concat(lessonId, "/test-checkout"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * создание / получение попытки теста
     *
     * @param {string} uuid
     * @param {boolean} check
     */
    Api.prototype.createAttempt = function (uuid, check) {
        if (check === void 0) { check = false; }
        var params = check ? '?check=Y' : '';
        return httpClientApiStudent.get("/lesson/test/".concat(uuid, "/attempt/create").concat(params));
    };
    /**
     * Старт следующего вопроса
     *
     * @param {string} test uuid теста
     * @param {string} attempt uuid попытки
     * @param {string} question uuid следующего вопроса
     */
    Api.prototype.startQuestion = function (test, attempt, question) {
        return httpClientApiStudent.post("/lesson/test/".concat(test, "/attempt/").concat(attempt, "/question/").concat(question))
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Отправка ответа на вопрос в тесте
     *
     * @param {string} testUuid uuid теста
     * @param {string} attemptUuid uuid попытки
     * @param {string} questionUuid uuid вопроса
     * @param {number | null} questionTimer время в секундах, потраченное на вопрос
     * @param userAnswers ответ
     * @param {string} nextQuestion uuid cледующего вопроса
     */
    Api.prototype.sendAnswer = function (testUuid, attemptUuid, questionUuid, userAnswers, questionTimer, nextQuestion) {
        if (questionTimer === void 0) { questionTimer = null; }
        if (nextQuestion === void 0) { nextQuestion = null; }
        // приводим все к массиву
        var arr = [userAnswers].flat();
        var fields = {
            answers: arr,
            timer: questionTimer,
        };
        if (nextQuestion) {
            fields['nextQuestion'] = nextQuestion;
        }
        var answers = convertToFormData(fields);
        return httpClientApiStudent.post("/lesson/test/".concat(testUuid, "/attempt/").concat(attemptUuid, "/answer/").concat(questionUuid), answers)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Завершение теста
     *
     * @param {string} test
     * @param {string} attempt
     */
    Api.prototype.verifyTest = function (test, attempt) {
        return httpClientApiStudent.get("/lesson/test/".concat(test, "/attempt/").concat(attempt, "/verify"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * User homework
     */
    Api.prototype.sendHomework = function (homeworkId, data) {
        data = convertToFormData(data);
        return httpClientApiStudent.post("/homework/".concat(homeworkId, "/answer/create"), data);
    };
    Api.prototype.getUserHomework = function (homeworkId) {
        return httpClientApiStudent.get("/homework/".concat(homeworkId, "/answer/list"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Payment
     */
    Api.prototype.getCourseLandingPaymentLink = function (courseId, data) {
        return httpClientApiStudent.post("/payment/landing/course/".concat(courseId), data);
    };
    Api.prototype.getCoursePlanPaymentLink = function (cardPlanId, data) {
        return httpClientApiStudent.post("/payment/landing/course-plan/".concat(cardPlanId), data);
    };
    /**
     * School User Settings
     */
    Api.prototype.getSchoolUserSettings = function () {
        return httpClientApiStudent.get("/settings/school-user")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Api.prototype.setSchoolUserSettings = function (fields) {
        var data = convertToFormData({ fields: fields, });
        return httpClientApiStudent.post("/settings/school-user/update", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Api.prototype.getUserFields = function () {
        return httpClientApiStudent.get('/user-fields/get');
    };
    Api.prototype.setUserFields = function (data) {
        return httpClientApiStudent.post('/user-fields/set', data);
    };
    return Api;
}(ApiCommonClass));
export default new Api();
