import { sortArrayByProperty } from '@common/utils/Array/Array';
import { clone, isObject, isString } from '@common/utils/Helpers/Helpers';
/**
 * Функция сравнивает два объекта и возвращает новый объект, который содержит только свойства,
 * присутствующие в первом объекте и имеющие отличные от соответствующих во втором объекте значения.
 * Если один из аргументов не является объектом, бросается исключение.
 *
 * @param {object} objectA Первый объект для сравнения. По умолчанию это пустой объект.
 * @param {object} objectB Второй объект для сравнения. По умолчанию это пустой объект.
 *
 * @throws {Error} Если один из входных параметров не является объектом.
 *
 * @returns {object} Объект, содержащий свойства, присутствующие только в первом объекте
 * и имеющие отличные значения от соответствующих во втором объекте.
 */
export function getObjectDifference(objectA, objectB) {
    if (objectA === void 0) { objectA = {}; }
    if (objectB === void 0) { objectB = {}; }
    if (!isObject(objectA) || !isObject(objectB)) {
        throw new Error('[object.js] getDifference: Один из аргументов не является объектом');
    }
    var differentProperties = {};
    for (var key in objectA) {
        if (!objectA.hasOwnProperty(key)) {
            return;
        }
        if (objectA[key] !== objectB[key]) {
            differentProperties[key] = objectA[key];
        }
    }
    return differentProperties;
}
/**
 * Функция нормализует сортировку объекта по заданному шагу,
 * преобразуя значения в порядковые номера, умноженные на шаг.
 * Объект сначала преобразуется в массив пар ключ-значение,
 * затем сортируется по значению и нормализуется по шагам.
 * В конце процесса массив пар ключ-значение преобразуется обратно в объект.
 *
 * @param {object} obj Объект для нормализации сортировки, например {'2': 50, '3': 71, '4': 60}
 * @param {number} step Шаг нормализации, по умолчанию равен 100
 *
 * @returns {object} Нормализованный объект, например {'2': 100, '4': 200, '3': 300}
 */
export function normalizeObjectOrder(obj, step) {
    if (step === void 0) { step = 100; }
    // Обьект в массив для сортировки
    var entries = Object.entries(obj);
    // Сортируем массив по значению
    var sortedArray = sortArrayByProperty(entries, 1);
    // Нормализуем сортировку по шагам
    var normalizedArray = sortedArray.map(function (v, index) {
        v[1] = (index + 1) * step;
        return v;
    });
    // Обратно в обьект и возвращаем
    return Object.fromEntries(normalizedArray);
}
/**
 * Функция извлекает значение из объекта по заданному пути. Путь представлен как строка,
 * где свойства объекта разделены точками ('propA.propB.propC'). Если пути не существует в объекте,
 * функция вернет null.
 *
 * @param {object} object Объект, из которого извлекается значение.
 * @param {string} prop Строка, представляющая путь к свойству в формате 'propA.propB.propC'.
 *
 * @returns {any|null} Значение найденного свойства или null, если свойство не найдено.
 */
export var getValueByPath = function (object, prop) {
    var localProp = isString(prop) ? prop : '';
    var paths = localProp.split('.');
    var current = object;
    var result = null;
    for (var i = 0, j = paths.length; i < j; i++) {
        var path = paths[i];
        if (!current) {
            break;
        }
        if (i === j - 1) {
            result = current[path];
            break;
        }
        current = current[path];
    }
    return result;
};
/**
 * Извлекает значение свойства из объекта по заданному пути.
 * Путь представлен в формате строки, где свойства разделены точками, например, 'propA.propB.propC'.
 * Функция может работать в двух режимах: строгом и нестрогом.
 * В строгом режиме, если свойство на пути отсутствует, функция вызовет ошибку.
 * В нестрогом режиме, если свойства нет на пути, функция просто прекратит поиск и вернет текущий объект, ключ и значение (null) по достигнутому пути.
 *
 * @param {object} obj Объект, из которого нужно получить значение.
 * @param {string} path Путь до свойства в формате 'propA.propB.propC'
 * @param {boolean} strict Флаг, обозначающий строгий режим. Если true, то функция вызовет ошибку при отсутствии
 * свойства на пути.
 *
 * @returns {object} {
 *      o: Объект на последнем уровне найденного пути,
 *      k: последний ключ на пути,
 *      v: значение последнего свойства на пути или null, если его нет
 *  }
 */
export function getPropByPath(obj, path, strict) {
    var tempObj = obj;
    path = path.replace(/\[(\w+)]/g, '.$1');
    path = path.replace(/^\./, '');
    var keyArr = path.split('.');
    var i = 0;
    for (var len = keyArr.length; i < len - 1; ++i) {
        if (!tempObj && !strict) {
            break;
        }
        var key = keyArr[i];
        if (tempObj.hasOwnProperty(key)) {
            tempObj = tempObj[key];
        }
        else {
            if (strict) {
                throw new Error('please transfer a valid prop path to form item!');
            }
            break;
        }
    }
    return {
        o: tempObj,
        k: keyArr[i],
        v: tempObj && tempObj.hasOwnProperty(keyArr[i]) ? tempObj[keyArr[i]] : null
    };
}
/**
 * Функция заполняет поля одного объекта (destination) значениями из другого объекта (filler).
 * Значения из filler копируются без сохранения ссылок на исходные сущности.
 * Если в destination нет соответствующего свойства из filler, оно не устанавливается.
 *
 * @param {object} destination Объект, который будет заполнен значениями.
 * @param {object} filler Объект-источник значений.
 */
export var fillObject = function (destination, filler) {
    var fillerFields = clone(filler);
    for (var field in destination) {
        if (!destination.hasOwnProperty(field) || !filler.hasOwnProperty(field)) {
            continue;
        }
        // @ts-ignore
        destination[field] = fillerFields[field];
    }
};
/**
 * Возвращает измененный объект, вызывая на каждом элементе callback
 *
 * @param {object} object Исходный объект
 * @param {Function} callback Вызывается для получения значения каждого элемента
 * @returns {{object}}
 */
export function objectMap(object, callback) {
    var entries = Object.entries(object)
        .map(function (d) {
        // Приводим в серверный формат
        d[1] = callback(d[1], d[0]);
        return d;
    });
    // noinspection JSValidateTypes
    return Object.fromEntries(entries);
}
export var sortKeysInObject = function (object) {
    // eslint-disable-next-line unicorn/no-array-reduce,unicorn/prefer-object-from-entries
    return Object.keys(object).sort().reduce(function (obj, key) {
        obj[key] = typeof object[key] === 'object' ? sortKeysInObject(object[key]) : object[key];
        return obj;
    }, {});
};
export var isEqualObjectsInJSON = function (objectA, objectB) {
    var objectFirst = JSON.stringify(sortKeysInObject(objectA));
    var objectSecond = JSON.stringify(sortKeysInObject(objectB));
    return objectFirst === objectSecond;
};
