import { defineStore } from 'pinia';
import Auth, { AuthEvents } from '@common/modules/auth';
import { useEventBus } from "@vueuse/core";
var busAuthCancelled = useEventBus(AuthEvents.AUTH_CANCELLED);
export var useAuthModalStore = defineStore('authModal', {
    state: function () { return ({
        // Отображать окно авторизации
        visible: false,
        // Раздел открытый в окне авторизации
        // (login - вход, register - регистрация, password-recovery - восстановление пароля)
        action: 'login',
        // email введенный в поле авторизации/регистрации/восстановления
        // (чтобы, введя его в поле входа и переключив на восстановление пароля, сразу заполнить почту)
        email: '',
        // Состояние загрузки (ожидание ответа сервера после входа/регистрации/восстановления)
        loading: false,
    }); },
    actions: {
        /**
         * Показать окно авторизации
         *
         * @param {string<'login'|'register'|'password-recovery'>} action подраздел, который необходимо открыть
         * @returns {Promise}
         */
        show: function (action) {
            // Если юзверь вошёл - не показываем
            if (!Auth.isGuest()) {
                return;
            }
            if (action) {
                this.action = action;
            }
            // Добавляем в хеш название окна
            window.history.replaceState('', '/', window.location.pathname + "#".concat(this.action));
            this.visible = true;
        },
        /**
         * Показать окно с открытым разделом входа
         *
         * @returns {Promise}
         */
        showLogin: function () {
            this.show('login');
        },
        /**
         * Показать окно с открытым разделом входа
         *
         * @returns {Promise}
         */
        showRegister: function () {
            this.show('register');
        },
        /**
         * Скрыть окно авторизации
         *
         * @returns {Promise}
         */
        hide: function () {
            // Если юзверь не вошёл по итогу
            if (Auth.isGuest()) {
                // Уведомляем о том, что пользователь отменил авторизацию
                busAuthCancelled.emit();
            }
            // Удаляем окно из хэша
            window.history.replaceState('', '/', window.location.pathname);
            this.visible = false;
        },
        /**
         * Перейти к разделу входа
         *
         * @returns {Promise}
         */
        goLogin: function () {
            this.action = 'login';
            // Добавляем в хеш название окна
            window.history.replaceState('', '/', window.location.pathname + "#".concat(this.action));
        },
        /**
         * Перейти к разделу восстановления пароля
         *
         * @returns {Promise}
         */
        goPasswordRecovery: function () {
            this.action = 'password-recovery';
        },
        /**
         * Перейти к разделу регистрации
         *
         * @returns {Promise}
         */
        goRegister: function () {
            this.action = 'register';
            // Добавляем в хеш название окна
            window.history.replaceState('', '/', window.location.pathname + "#".concat(this.action));
        },
    }
});
