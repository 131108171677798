var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import * as _ from '@common/utils/Helpers/Helpers';
import { camelCase, trim } from '@common/utils/String/String';
import { toArray } from '@common/utils/Array/Array';
/**
 * DOM manipulations helper
 */
var Dom = /** @class */ (function () {
    function Dom() {
    }
    /**
     * Check if passed tag has no closed tag
     *
     * @param {HTMLElement} tag - element to check
     * @returns {boolean}
     */
    Dom.isSingleTag = function (tag) {
        return !!tag.tagName && [
            'AREA',
            'BASE',
            'BR',
            'COL',
            'COMMAND',
            'EMBED',
            'HR',
            'IMG',
            'INPUT',
            'KEYGEN',
            'LINK',
            'META',
            'PARAM',
            'SOURCE',
            'TRACK',
            'WBR',
        ].includes(tag.tagName);
    };
    /**
     * Check if element is BR or WBR
     *
     * @param {HTMLElement} element - element to check
     * @returns {boolean}
     */
    Dom.isLineBreakTag = function (element) {
        return !!element && !!element.tagName && [
            'BR',
            'WBR',
        ].includes(element.tagName);
    };
    /**
     * Helper for making Elements with class name and attributes
     *
     * @param  {string} tagName - new Element tag name
     * @param  {string[]|string} [classNames] - list or name of CSS class name(s)
     * @param  {object} [attributes] - any attributes
     * @returns {HTMLElement}
     */
    Dom.make = function (tagName, classNames, attributes) {
        var _a;
        if (classNames === void 0) { classNames = null; }
        if (attributes === void 0) { attributes = {}; }
        var el = document.createElement(tagName);
        if (Array.isArray(classNames)) {
            (_a = el.classList).add.apply(_a, __spreadArray([], __read(classNames), false));
        }
        else if (classNames) {
            el.classList.add(classNames);
        }
        for (var attrName in attributes) {
            if (Object.prototype.hasOwnProperty.call(attributes, attrName)) {
                el[attrName] = attributes[attrName];
            }
        }
        return el;
    };
    /**
     * Creates Text Node with the passed content
     *
     * @param {string} content - text content
     * @returns {Text}
     */
    Dom.text = function (content) {
        return document.createTextNode(content);
    };
    /**
     * Append one or several elements to the parent
     *
     * @param  {Element|DocumentFragment} parent - where to append
     * @param  {Element|Element[]|DocumentFragment|Text|Text[]} elements - element or elements list
     */
    Dom.append = function (parent, elements) {
        var e_1, _a;
        if (Array.isArray(elements)) {
            try {
                for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
                    var el = elements_1_1.value;
                    parent.append(el);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            parent.append(elements);
        }
    };
    /**
     * Append element or a couple to the beginning of the parent elements
     *
     * @param {Element} parent - where to append
     * @param {Element|Element[]} elements - element or elements list
     */
    Dom.prepend = function (parent, elements) {
        var e_2, _a;
        if (Array.isArray(elements)) {
            elements = elements.reverse();
            try {
                for (var elements_2 = __values(elements), elements_2_1 = elements_2.next(); !elements_2_1.done; elements_2_1 = elements_2.next()) {
                    var el = elements_2_1.value;
                    parent.prepend(el);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (elements_2_1 && !elements_2_1.done && (_a = elements_2.return)) _a.call(elements_2);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else {
            parent.prepend(elements);
        }
    };
    /**
     * Swap two elements in parent
     *
     * @param {HTMLElement} el1 - from
     * @param {HTMLElement} el2 - to
     * @deprecated
     */
    Dom.swap = function (el1, el2) {
        // create marker element and insert it where el1 is
        var temp = document.createElement('div'), parent = el1.parentNode;
        parent === null || parent === void 0 ? void 0 : parent.insertBefore(temp, el1);
        // move el1 to right before el2
        parent === null || parent === void 0 ? void 0 : parent.insertBefore(el1, el2);
        // move el2 to right before where el1 used to be
        parent === null || parent === void 0 ? void 0 : parent.insertBefore(el2, temp);
        // remove temporary marker node
        parent === null || parent === void 0 ? void 0 : parent.removeChild(temp);
    };
    /**
     * Selector Decorator
     *
     * Returns first match
     *
     * @param {Element} el - element we searching inside. Default - DOM Document
     * @param {string} selector - searching string
     * @returns {Element}
     */
    Dom.find = function (el, selector) {
        if (el === void 0) { el = document; }
        return el.querySelector(selector);
    };
    /**
     * Get Element by Id
     *
     * @param {string} id - id to find
     * @returns {HTMLElement | null}
     */
    Dom.get = function (id) {
        // eslint-disable-next-line unicorn/prefer-query-selector
        return document.getElementById(id);
    };
    /**
     * Selector Decorator.
     *
     * Returns all matches
     *
     * @param {Element|Document} el - element we searching inside. Default - DOM Document
     * @param {string} selector - searching string
     * @returns {NodeList}
     */
    Dom.findAll = function (el, selector) {
        if (el === void 0) { el = document; }
        return el.querySelectorAll(selector);
    };
    Object.defineProperty(Dom, "allInputsSelector", {
        /**
         * Returns CSS selector for all text inputs
         */
        get: function () {
            var allowedInputTypes = ['text', 'password', 'email', 'number', 'search', 'tel', 'url'];
            return '[contenteditable=true], textarea, input:not([type]), ' +
                allowedInputTypes.map(function (type) { return "input[type=\"".concat(type, "\"]"); }).join(', ');
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Find all contenteditable, textarea and editable input elements passed holder contains
     *
     * @param holder - element where to find inputs
     */
    Dom.findAllInputs = function (holder) {
        return toArray(holder.querySelectorAll(Dom.allInputsSelector))
            /**
             * If contenteditable element contains block elements, treat them as inputs.
             */
            // eslint-disable-next-line unicorn/no-array-reduce
            .reduce(function (result, input) {
            if (Dom.isNativeInput(input) || Dom.containsOnlyInlineElements(input)) {
                return __spreadArray(__spreadArray([], __read(result), false), [input], false);
            }
            return __spreadArray(__spreadArray([], __read(result), false), __read(Dom.getDeepestBlockElements(input)), false);
        }, []);
    };
    /**
     * Search for deepest node which is Leaf.
     * Leaf is the vertex that doesn't have any child nodes
     *
     * @description Method recursively goes throw the all Node until it finds the Leaf
     * @param {Node} node - root Node. From this vertex we start Deep-first search
     *                      {@link https://en.wikipedia.org/wiki/Depth-first_search}
     * @param {boolean} [atLast] - find last text node
     * @returns {Node} - it can be text Node or Element Node, so that caret will able to work with it
     */
    // eslint-disable-next-line complexity
    Dom.getDeepestNode = function (node, atLast) {
        if (atLast === void 0) { atLast = false; }
        /**
         * Current function have two directions:
         *  - starts from first child and every time gets first or nextSibling in special cases
         *  - starts from last child and gets last or previousSibling
         *
         * @type {string}
         */
        var child = atLast ? 'lastChild' : 'firstChild', sibling = atLast ? 'previousSibling' : 'nextSibling';
        if (node && node.nodeType === Node.ELEMENT_NODE && node[child]) {
            var nodeChild = node[child];
            /**
             * special case when child is single tag that can't contain any content
             */
            if (Dom.isSingleTag(nodeChild) &&
                !Dom.isNativeInput(nodeChild) &&
                !Dom.isLineBreakTag(nodeChild)) {
                /**
                 * 1) We need to check the next sibling. If it is Node Element then continue searching for deepest
                 * from sibling
                 *
                 * 2) If single tag's next sibling is null, then go back to parent and check his sibling
                 * In case of Node Element continue searching
                 *
                 * 3) If none of conditions above happened return parent Node Element
                 */
                if (nodeChild[sibling]) {
                    nodeChild = nodeChild[sibling];
                }
                else if (nodeChild.parentNode[sibling]) {
                    nodeChild = nodeChild.parentNode[sibling];
                }
                else {
                    return nodeChild.parentNode;
                }
            }
            return this.getDeepestNode(nodeChild, atLast);
        }
        return node;
    };
    /**
     * Check if object is DOM node
     *
     * @param {*} node - object to check
     * @returns {boolean}
     */
    Dom.isElement = function (node) {
        if (_.isNumber(node)) {
            return false;
        }
        return node && node.nodeType && node.nodeType === Node.ELEMENT_NODE;
    };
    /**
     * Check if object is DocumentFragment node
     *
     * @param {object} node - object to check
     * @returns {boolean}
     */
    Dom.isFragment = function (node) {
        if (_.isNumber(node)) {
            return false;
        }
        return node && node.nodeType && node.nodeType === Node.DOCUMENT_FRAGMENT_NODE;
    };
    /**
     * Check if passed element is contenteditable
     *
     * @param {HTMLElement} element - html element to check
     * @returns {boolean}
     */
    Dom.isContentEditable = function (element) {
        return element.contentEditable === 'true';
    };
    /**
     * Checks target if it is native input
     *
     * @param {*} target - HTML element or string
     * @returns {boolean}
     */
    Dom.isNativeInput = function (target) {
        var nativeInputs = [
            'INPUT',
            'TEXTAREA',
        ];
        return target && target.tagName ? nativeInputs.includes(target.tagName) : false;
    };
    /**
     * Checks if we can set caret
     *
     * @param {HTMLElement} target - target to check
     * @returns {boolean}
     */
    Dom.canSetCaret = function (target) {
        var result = true;
        if (Dom.isNativeInput(target)) {
            switch (target.type) {
                case 'file':
                case 'checkbox':
                case 'radio':
                case 'hidden':
                case 'submit':
                case 'button':
                case 'image':
                case 'reset':
                    result = false;
                    break;
            }
        }
        else {
            result = Dom.isContentEditable(target);
        }
        return result;
    };
    /**
     * Checks node if it is empty
     *
     * @description Method checks simple Node without any childs for emptiness
     * If you have Node with 2 or more children id depth, you better use {@link Dom#isEmpty} method
     * @param {Node} node - node to check
     * @returns {boolean} true if it is empty
     */
    Dom.isNodeEmpty = function (node) {
        var _a;
        var nodeText;
        if (this.isSingleTag(node) && !this.isLineBreakTag(node)) {
            return false;
        }
        nodeText = this.isElement(node) && this.isNativeInput(node) ?
            node.value :
            (_a = node.textContent) === null || _a === void 0 ? void 0 : _a.replace('\u200B', '');
        return nodeText.trim().length === 0;
    };
    /**
     * checks node if it is doesn't have any child nodes
     *
     * @param {Node} node - node to check
     * @returns {boolean}
     */
    Dom.isLeaf = function (node) {
        if (!node) {
            return false;
        }
        return node.childNodes.length === 0;
    };
    /**
     * breadth-first search (BFS)
     * {@link https://en.wikipedia.org/wiki/Breadth-first_search}
     *
     * @description Pushes to stack all DOM leafs and checks for emptiness
     * @param {Node} node - node to check
     * @returns {boolean}
     */
    Dom.isEmpty = function (node) {
        /**
         * Normalize node to merge several text nodes to one to reduce tree walker iterations
         */
        node.normalize();
        var treeWalker = [node];
        while (treeWalker.length > 0) {
            node = treeWalker.shift();
            if (!node) {
                continue;
            }
            if (this.isLeaf(node) && !this.isNodeEmpty(node)) {
                return false;
            }
            if (node.childNodes) {
                treeWalker.push.apply(treeWalker, __spreadArray([], __read(node.childNodes), false));
            }
        }
        return true;
    };
    /**
     * Check if string contains html elements
     *
     * @param {string} str - string to check
     * @returns {boolean}
     */
    Dom.isHTMLString = function (str) {
        var wrapper = Dom.make('div');
        wrapper.innerHTML = str;
        return wrapper.childElementCount > 0;
    };
    /**
     * Return length of node`s text content
     *
     * @param {Node} node - node with content
     * @returns {number}
     */
    Dom.getContentLength = function (node) {
        if (Dom.isNativeInput(node)) {
            return node.value.length;
        }
        if (node.nodeType === Node.TEXT_NODE) {
            return node.length;
        }
        return node.textContent.length;
    };
    Object.defineProperty(Dom, "blockElements", {
        /**
         * Return array of names of block html elements
         *
         * @returns {string[]}
         */
        get: function () {
            return [
                'address',
                'article',
                'aside',
                'blockquote',
                'canvas',
                'div',
                'dl',
                'dt',
                'fieldset',
                'figcaption',
                'figure',
                'footer',
                'form',
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                'header',
                'hgroup',
                'hr',
                'li',
                'main',
                'nav',
                'noscript',
                'ol',
                'output',
                'p',
                'pre',
                'ruby',
                'section',
                'table',
                'tbody',
                'thead',
                'tr',
                'tfoot',
                'ul',
                'video',
            ];
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Check if passed content includes only inline elements
     *
     * @param {string|HTMLElement} data - element or html string
     * @returns {boolean}
     */
    Dom.containsOnlyInlineElements = function (data) {
        var wrapper;
        if (_.isString(data)) {
            wrapper = document.createElement('div');
            wrapper.innerHTML = data;
        }
        else {
            wrapper = data;
        }
        var check = function (element) {
            return !Dom.blockElements.includes(element.tagName.toLowerCase()) &&
                // @ts-ignore
                __spreadArray([], __read(element.children), false).every(check);
        };
        // @ts-ignore
        return __spreadArray([], __read(wrapper.children), false).every(check);
    };
    /**
     * Find and return all block elements in the passed parent (including subtree)
     *
     * @param {HTMLElement} parent - root element
     * @returns {HTMLElement[]}
     */
    Dom.getDeepestBlockElements = function (parent) {
        if (Dom.containsOnlyInlineElements(parent)) {
            return [parent];
        }
        // @ts-ignore
        return __spreadArray([], __read(parent.children), false).reduce(function (result, element) {
            return __spreadArray(__spreadArray([], __read(result), false), __read(Dom.getDeepestBlockElements(element)), false);
        }, []);
    };
    /**
     * Helper for get holder from {string} or return HTMLElement
     *
     * @param {string | HTMLElement} element - holder's id or holder's HTML Element
     * @returns {HTMLElement}
     */
    Dom.getHolder = function (element) {
        if (_.isString(element)) {
            // @ts-ignore
            // eslint-disable-next-line unicorn/prefer-query-selector
            return document.getElementById(element);
        }
        return element;
    };
    /**
     * Method checks passed Node if it is some extension Node
     *
     * @param {Node} node - any node
     * @returns {boolean}
     */
    Dom.isExtensionNode = function (node) {
        var extensions = [
            'GRAMMARLY-EXTENSION',
        ];
        return node && extensions.includes(node.nodeName);
    };
    /**
     * Returns true if element is anchor (is A tag)
     *
     * @param {Element} element - element to check
     * @returns {boolean}
     */
    Dom.isAnchor = function (element) {
        return element.tagName.toLowerCase() === 'a';
    };
    /**
     * Return element's offset related to the document
     *
     * @todo handle case when editor initialized in scrollable popup
     * @param el - element to compute offset
     */
    Dom.offset = function (el) {
        var rect = el.getBoundingClientRect();
        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var top = rect.top + scrollTop;
        var left = rect.left + scrollLeft;
        return {
            top: top,
            left: left,
            bottom: top + rect.height,
            right: left + rect.width,
        };
    };
    Dom.on = function (element, event, handler) {
        if (element && event && handler) {
            element.addEventListener(event, handler, false);
        }
    };
    Dom.off = function (element, event, handler) {
        if (element && event) {
            element.removeEventListener(event, handler, false);
        }
    };
    Dom.once = function (el, event, fn) {
        var listener = function () {
            if (fn) {
                // @ts-ignore
                Reflect.apply(fn, this, arguments);
            }
            Dom.off(el, event, listener);
        };
        Dom.on(el, event, listener);
    };
    /**
     * @param el
     * @param cls
     */
    Dom.hasClass = function (el, cls) {
        if (!el || !cls)
            return false;
        if (cls.includes(' '))
            throw new Error('className should not contain space.');
        return el.classList ? el.classList.contains(cls) : (' ' + el.className + ' ').includes(' ' + cls + ' ');
    };
    /**
     * @param el
     * @param cls
     */
    Dom.addClass = function (el, cls) {
        if (!el)
            return;
        var curClass = el.className;
        var classes = (cls || '').split(' ');
        for (var i = 0, j = classes.length; i < j; i++) {
            var clsName = classes[i];
            if (!clsName)
                continue;
            if (el.classList) {
                el.classList.add(clsName);
            }
            else if (!Dom.hasClass(el, clsName)) {
                curClass += ' ' + clsName;
            }
        }
        if (!el.classList) {
            el.className = curClass;
        }
    };
    /**
     * @param el
     * @param cls
     */
    Dom.removeClass = function (el, cls) {
        if (!el || !cls)
            return;
        var classes = cls.split(' ');
        var curClass = ' ' + el.className + ' ';
        for (var i = 0, j = classes.length; i < j; i++) {
            var clsName = classes[i];
            if (!clsName)
                continue;
            if (el.classList) {
                el.classList.remove(clsName);
            }
            else if (Dom.hasClass(el, clsName)) {
                curClass = curClass.replace(' ' + clsName + ' ', ' ');
            }
        }
        if (!el.classList) {
            el.className = trim(curClass);
        }
    };
    Dom.getStyle = function (element, styleName) {
        var _a;
        if (!element || !styleName)
            return null;
        styleName = camelCase(styleName);
        if (styleName === 'float') {
            styleName = 'cssFloat';
        }
        try {
            var computed = (_a = document.defaultView) === null || _a === void 0 ? void 0 : _a.getComputedStyle(element, '');
            return element.style[styleName] || computed ? computed[styleName] : null;
        }
        catch (_b) {
            return element.style[styleName];
        }
    };
    /**
     * @param element
     * @param styleName
     * @param value
     */
    Dom.setStyle = function (element, styleName, value) {
        if (!element || !styleName)
            return;
        if (typeof styleName === 'object') {
            for (var prop in styleName) {
                if (styleName.hasOwnProperty(prop)) {
                    Dom.setStyle(element, prop, styleName[prop]);
                }
            }
        }
        else {
            styleName = camelCase(styleName);
            if (styleName === 'opacity') {
                element.style[styleName] = value;
            }
        }
    };
    Dom.isScroll = function (el, includeHidden, vertical) {
        if (includeHidden === void 0) { includeHidden = true; }
        if (vertical === void 0) { vertical = false; }
        var determinedDirection = vertical !== null || vertical !== undefined;
        // eslint-disable-next-line no-nested-ternary
        var overflow = determinedDirection ? (vertical ? Dom.getStyle(el, 'overflow-y') : Dom.getStyle(el, 'overflow-x')) : Dom.getStyle(el, 'overflow');
        var regexp = includeHidden ? /(scroll|auto|hidden)(\s+!important)?/ : /(scroll|auto)(\s+!important)?/;
        return regexp.test(overflow);
    };
    /**
     * Получить первого родителя со скроллом
     *
     * @param {Node} el Элемент от которого начать поиск по родителям
     * @param {Node} includeHidden Включать элементы с overflow hidden
     * @param {boolean} vertical Вертикальный скролл
     * @returns {Node}
     */
    Dom.getScrollContainer = function (el, includeHidden, vertical) {
        if (includeHidden === void 0) { includeHidden = true; }
        if (vertical === void 0) { vertical = false; }
        var parent = el;
        while (parent) {
            if ([window, document, document.documentElement].includes(parent)) {
                return window;
            }
            if (Dom.isScroll(parent, includeHidden, vertical)) {
                return parent;
            }
            parent = parent.parentNode;
        }
        return parent;
    };
    Dom.isInContainer = function (el, container) {
        if (!el || !container)
            return false;
        var elRect = el.getBoundingClientRect();
        var containerRect;
        containerRect = [window, document, document.documentElement, null, undefined].includes(container) ? {
            top: 0,
            right: window.innerWidth,
            bottom: window.innerHeight,
            left: 0,
        } : container.getBoundingClientRect();
        return elRect.top < containerRect.bottom &&
            elRect.bottom > containerRect.top &&
            elRect.right > containerRect.left &&
            elRect.left < containerRect.right;
    };
    /**
     * Конвертируем HTML строку в DOM дерево
     *
     * @param {string} html HTML строка
     * @returns {Document} DOM документ
     */
    Dom.stringToDom = function (html) {
        return (new DOMParser()).parseFromString('<body>' + html + '</body>', 'text/html');
    };
    /**
     * Конвертируем DOM дерево в HTML строку
     *
     * @param {Document} dom DOM документ
     * @returns {string} HTML строка
     */
    Dom.domToString = function (dom) {
        return dom.documentElement.innerHTML;
    };
    /**
     * Returns the HTML content of passed Document Fragment
     *
     * @param {DocumentFragment} fragment - document fragment to process
     * @returns {string}
     */
    Dom.fragmentToString = function (fragment) {
        var div = Dom.make('div');
        div.append(fragment);
        // @ts-ignore
        if (!div.textContent.trim()) {
            return '';
        }
        return div.innerHTML;
    };
    /**
     * Create dom element
     *
     * @param {string} tagName
     * @param {string[]} classNames
     * @param {object} attributes
     * @returns {*}
     */
    Dom.createElement = function (tagName, classNames, attributes) {
        var _a;
        if (classNames === void 0) { classNames = null; }
        if (attributes === void 0) { attributes = {}; }
        var el = document.createElement(tagName);
        if (Array.isArray(classNames)) {
            // @ts-ignore
            (_a = el.classList).add.apply(_a, __spreadArray([], __read(classNames), false));
        }
        else if (classNames) {
            el.classList.add(classNames);
        }
        for (var attrName in attributes) {
            el[attrName] = attributes[attrName];
        }
        return el;
    };
    /**
     * Creates SVG icon linked to the sprite
     *
     * @param {string} string
     * @returns {HTMLElement}
     */
    Dom.svgFromString = function (string) {
        var parser = new DOMParser();
        return parser.parseFromString(string, 'image/svg+xml').documentElement;
    };
    /**
     * Находит и возвращает все текстовые ноды внутри элемента
     *
     * @param {Node} node
     * @returns {Node[]}
     */
    Dom.findTextNodes = function (node) {
        var A = [];
        if (node) {
            node = node.firstChild;
            while (node != null) {
                if (node.nodeType == 3) {
                    A[A.length] = node;
                }
                else {
                    A = __spreadArray(__spreadArray([], __read(A), false), __read(Dom.findTextNodes(node)), false);
                }
                node = node.nextSibling;
            }
        }
        return A;
    };
    Dom.replaceWithTextNode = function (element, text) {
        var _a;
        // Создаем новый текстовый узел с заданным текстом:
        var newText = document.createTextNode(text);
        // Заменяем HTML элемент на новый текстовый узел:
        (_a = element.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild(newText, element);
    };
    /**
     * Удаляем все теги кроме разрешенных из текста
     *
     * @param {string} input Текст
     * @param {Array} allowed Список разрешенных тегов
     * @param {boolean} placeHtmlEntities Оставлять вместо тега спецсимволы
     * @returns {string} Очищенный текст
     */
    Dom.stripTagsInString = function (input, allowed, placeHtmlEntities) {
        if (allowed === void 0) { allowed = []; }
        if (placeHtmlEntities === void 0) { placeHtmlEntities = false; }
        var allowedTags = allowed.map(function (tag) { return tag.toLowerCase(); });
        var tags = /<\/?([a-z][\da-z]*)\b[^>]*>/gi;
        var commentsAndPhpTags = /<!--[\S\s]*?-->|<\?(?:php)?[\S\s]*?\?>/gi;
        return input.replace(commentsAndPhpTags, '')
            .replace(tags, function (match, tag) {
            var lowerCaseTag = tag.toLowerCase();
            if (allowedTags.includes(lowerCaseTag)) {
                return match;
            }
            else if (placeHtmlEntities) {
                return "&lt;".concat(tag, "&gt;");
            }
            else {
                return '';
            }
        });
        // allowed = allowed.map(e => '<' + e.toLowerCase() + '>').join('');
        // allowed = (((allowed || '') + '')
        //     .match(/<[a-z][\da-z]*>/g) || [])
        //     .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        // const tags = /<\/?([a-z][\da-z]*)\b[^>]*>/gi,
        //     commentsAndPhpTags = /<!--[\S\s]*?-->|<\?(?:php)?[\S\s]*?\?>/gi;
        // return input.replace(commentsAndPhpTags, '')
        //     .replace(tags, function(a, b) {
        //         return allowed.includes('<' + b.toLowerCase() + '>') ? a : (placeHtmlEntities ? `&lt;${b}&gt;` : '');
        //     });
    };
    return Dom;
}());
export default Dom;
