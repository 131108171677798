export default {
    'Afghanistan': [
        'af',
        [
            'Kabul'
        ],
        93,
    ],
    'Albania': [
        'al',
        [
            'Tirane'
        ],
        355
    ],
    'Algeria': [
        'dz',
        [
            'Algiers'
        ],
        213
    ],
    'Andorra': [
        'ad',
        [
            'Andorra'
        ],
        376
    ],
    'Angola': [
        'ao',
        [
            'Luanda'
        ],
        244
    ],
    'Anguilla': [
        'ai',
        [
            'Anguilla'
        ],
        1264
    ],
    'Antarctica': [
        'aq',
        [
            'Casey',
            'Davis',
            'Mawson',
            'Palmer',
            'Rothera',
            'Troll',
            'Vostok',
            'McMurdo',
            'DumontDUrville',
            'Syowa'
        ],
        672
    ],
    'Antigua & Barbuda': [
        'ag',
        [
            'Antigua'
        ],
        1268
    ],
    'Argentina': [
        'ar',
        [
            'Buenos_Aires',
            'Cordoba',
            'Salta',
            'Jujuy',
            'Tucuman',
            'Catamarca',
            'La_Rioja',
            'San_Juan',
            'Mendoza',
            'San_Luis',
            'Rio_Gallegos',
            'Ushuaia'
        ],
        54
    ],
    'Armenia': [
        'am',
        [
            'Yerevan'
        ],
        374
    ],
    'Aruba': [
        'aw',
        [
            'Aruba'
        ],
        297
    ],
    'Australia': [
        'au',
        [
            'Lord_Howe',
            'Macquarie',
            'Hobart',
            'Melbourne',
            'Sydney',
            'Broken_Hill',
            'Brisbane',
            'Lindeman',
            'Adelaide',
            'Darwin',
            'Perth',
            'Eucla'
        ],
        61
    ],
    'Austria': [
        'at',
        [
            'Vienna'
        ],
        43
    ],
    'Azerbaijan': [
        'az',
        [
            'Baku'
        ],
        994
    ],
    'Bahamas': [
        'bs',
        [
            'Nassau'
        ],
        1242
    ],
    'Bahrain': [
        'bh',
        [
            'Bahrain'
        ],
        973
    ],
    'Bangladesh': [
        'bd',
        [
            'Dhaka'
        ],
        880
    ],
    'Barbados': [
        'bb',
        [
            'Barbados'
        ],
        1246
    ],
    'Belarus': [
        'by',
        [
            'Minsk'
        ],
        375
    ],
    'Belgium': [
        'be',
        [
            'Brussels'
        ],
        32
    ],
    'Belize': [
        'bz',
        [
            'Belize'
        ],
        501
    ],
    'Benin': [
        'bj',
        [
            'Porto-Novo'
        ],
        229
    ],
    'Bermuda': [
        'bm',
        [
            'Bermuda'
        ],
        1441
    ],
    'Bhutan': [
        'bt',
        [
            'Thimphu'
        ],
        975
    ],
    'Bolivia': [
        'bo',
        [
            'La_Paz'
        ],
        591
    ],
    'Bosnia & Herzegovina': [
        'ba',
        [
            'Sarajevo'
        ],
        387
    ],
    'Botswana': [
        'bw',
        [
            'Gaborone'
        ],
        267
    ],
    'Brazil': [
        'br',
        [
            'Noronha',
            'Belem',
            'Fortaleza',
            'Recife',
            'Araguaina',
            'Maceio',
            'Bahia',
            'Sao_Paulo',
            'Campo_Grande',
            'Cuiaba',
            'Santarem',
            'Porto_Velho',
            'Boa_Vista',
            'Manaus',
            'Eirunepe',
            'Rio_Branco'
        ],
        55
    ],
    'United Kingdom': [
        'gb',
        [
            'London'
        ],
        44
    ],
    'British Indian Ocean Territory': [
        'io',
        [
            'Chagos'
        ],
        246
    ],
    'Brunei': [
        'bn',
        [
            'Brunei'
        ],
        673
    ],
    'Bulgaria': [
        'bg',
        [
            'Sofia'
        ],
        359
    ],
    'Burkina Faso': [
        'bf',
        [
            'Ouagadougou'
        ],
        226
    ],
    'Burundi': [
        'bi',
        [
            'Bujumbura'
        ],
        257
    ],
    'Cambodia': [
        'kh',
        [
            'Phnom_Penh'
        ],
        855
    ],
    'Cameroon': [
        'cm',
        [
            'Douala'
        ],
        237
    ],
    'Canada': [
        'ca',
        [
            'St_Johns',
            'Halifax',
            'Glace_Bay',
            'Moncton',
            'Goose_Bay',
            'Toronto',
            'Nipigon',
            'Thunder_Bay',
            'Iqaluit',
            'Pangnirtung',
            'Winnipeg',
            'Rainy_River',
            'Resolute',
            'Rankin_Inlet',
            'Regina',
            'Swift_Current',
            'Edmonton',
            'Cambridge_Bay',
            'Yellowknife',
            'Inuvik',
            'Dawson_Creek',
            'Fort_Nelson',
            'Whitehorse',
            'Dawson',
            'Vancouver',
            'Blanc-Sablon',
            'Atikokan',
            'Creston'
        ],
        1,
        1,
        [204, 226, 236, 249, 250, 289, 306, 343, 365, 387, 403, 416, 418, 431, 437, 438, 450, 506, 514, 519, 548, 579, 581, 587, 604, 613, 639, 647, 672, 705, 709, 742, 778, 780, 782, 807, 819, 825, 867, 873, 902, 905]
    ],
    'Cape Verde': [
        'cv',
        [
            'Cape_Verde'
        ],
        238
    ],
    'Caribbean Netherlands': [
        'bq',
        [
            'Kralendijk'
        ],
        599
    ],
    'Cayman Islands': [
        'ky',
        [
            'Cayman'
        ],
        1345
    ],
    'Central African Republic': [
        'cf',
        [
            'Bangui'
        ],
        236
    ],
    'Chad': [
        'td',
        [
            'Ndjamena'
        ],
        235
    ],
    'Chile': [
        'cl',
        [
            'Santiago',
            'Punta_Arenas',
            'Easter'
        ],
        56
    ],
    'China': [
        'cn',
        [
            'Shanghai',
            'Urumqi'
        ],
        86
    ],
    'Christmas Island': [
        'cx',
        [
            'Christmas'
        ],
        61,
        2
    ],
    'Cocos (Keeling) Islands': [
        'cc',
        [
            'Cocos'
        ],
        61,
        1
    ],
    'Colombia': [
        'co',
        [
            'Bogota'
        ],
        57
    ],
    'Comoros': [
        'km',
        [
            'Comoro'
        ],
        269
    ],
    'Congo (DRC)': [
        'cd',
        [
            'Kinshasa',
            'Lubumbashi'
        ],
        243
    ],
    'Congo (Republic) (Congo-Brazzaville)': [
        'cg',
        [
            'Brazzaville'
        ],
        242
    ],
    'Cook Islands': [
        'ck',
        [
            'Rarotonga'
        ],
        682
    ],
    'Costa Rica': [
        'cr',
        [
            'Costa_Rica'
        ],
        506
    ],
    'Croatia': [
        'hr',
        [
            'Zagreb'
        ],
        385
    ],
    'Cuba': [
        'cu',
        [
            'Havana'
        ],
        53
    ],
    'Curaçao': [
        'cw',
        [
            'Curacao'
        ],
        599
    ],
    'Cyprus': [
        'cy',
        [
            'Nicosia',
            'Famagusta'
        ],
        357
    ],
    'Czech Republic': [
        'cz',
        [
            'Prague'
        ],
        420
    ],
    'Côte d’Ivoire': [
        'ci',
        [
            'Abidjan'
        ],
        225
    ],
    'Denmark': [
        'dk',
        [
            'Copenhagen'
        ],
        45
    ],
    'Djibouti': [
        'dj',
        [
            'Djibouti'
        ],
        253
    ],
    'Dominica': [
        'dm',
        [
            'Dominica'
        ],
        1767
    ],
    'Dominican Republic': [
        'do',
        [
            'Santo_Domingo'
        ],
        1,
        2,
        [809, 829, 849]
    ],
    'Timor-Leste (East Timor)': [
        'tl',
        [
            'Dili'
        ],
        670
    ],
    'Ecuador': [
        'ec',
        [
            'Guayaquil',
            'Galapagos'
        ],
        593
    ],
    'Egypt': [
        'eg',
        [
            'Cairo'
        ],
        20
    ],
    'El Salvador': [
        'sv',
        [
            'El_Salvador'
        ],
        503
    ],
    'Equatorial Guinea': [
        'gq',
        [
            'Malabo'
        ],
        240
    ],
    'Eritrea': [
        'er',
        [
            'Asmara'
        ],
        291
    ],
    'Estonia': [
        'ee',
        [
            'Tallinn'
        ],
        372
    ],
    'Swaziland': [
        'sz',
        [
            'Mbabane'
        ],
        268
    ],
    'Ethiopia': [
        'et',
        [
            'Addis_Ababa'
        ],
        251
    ],
    'Falkland Islands': [
        'fk',
        [
            'Stanley'
        ],
        500
    ],
    'Faroe Islands': [
        'fo',
        [
            'Faroe'
        ],
        298
    ],
    'Fiji': [
        'fj',
        [
            'Fiji'
        ],
        679
    ],
    'Finland': [
        'fi',
        [
            'Helsinki'
        ],
        358
    ],
    'France': [
        'fr',
        [
            'Paris'
        ],
        33
    ],
    'French Guiana': [
        'gf',
        [
            'Cayenne'
        ],
        594
    ],
    'French Polynesia': [
        'pf',
        [
            'Tahiti',
            'Marquesas',
            'Gambier'
        ],
        689
    ],
    'French Southern & Antarctic Lands': [
        'tf',
        [
            'Kerguelen'
        ],
        262
    ],
    'Gabon': [
        'ga',
        [
            'Libreville'
        ],
        241
    ],
    'Gambia': [
        'gm',
        [
            'Banjul'
        ],
        220
    ],
    'Georgia': [
        'ge',
        [
            'Tbilisi'
        ],
        995
    ],
    'Germany': [
        'de',
        [
            'Berlin',
            'Busingen'
        ],
        49
    ],
    'Ghana': [
        'gh',
        [
            'Accra'
        ],
        233
    ],
    'Gibraltar': [
        'gi',
        [
            'Gibraltar'
        ],
        350
    ],
    'Greece': [
        'gr',
        [
            'Athens'
        ],
        30
    ],
    'Greenland': [
        'gl',
        [
            'Nuuk',
            'Danmarkshavn',
            'Scoresbysund',
            'Thule'
        ],
        299
    ],
    'Grenada': [
        'gd',
        [
            'Grenada'
        ],
        1473
    ],
    'Guadeloupe': [
        'gp',
        [
            'Guadeloupe'
        ],
        590
    ],
    'Guam': [
        'gu',
        [
            'Guam'
        ],
        1671
    ],
    'Guatemala': [
        'gt',
        [
            'Guatemala'
        ],
        502
    ],
    'Guernsey': [
        'gg',
        [
            'Guernsey'
        ],
        44,
        1
    ],
    'Guinea': [
        'gq',
        [
            'Conakry'
        ],
        240
    ],
    'Guinea-Bissau': [
        'gw',
        [
            'Bissau'
        ],
        245
    ],
    'Guyana': [
        'gy',
        [
            'Guyana'
        ],
        592
    ],
    'Haiti': [
        'ht',
        [
            'Port-au-Prince'
        ],
        509
    ],
    'Honduras': [
        'hn',
        [
            'Tegucigalpa'
        ],
        504
    ],
    'Hong Kong': [
        'hk',
        [
            'Hong_Kong'
        ],
        852
    ],
    'Hungary': [
        'hu',
        [
            'Budapest'
        ],
        36
    ],
    'Iceland': [
        'is',
        [
            'Reykjavik'
        ],
        354
    ],
    'India': [
        'io',
        [
            'Kolkata',
            'Calcutta'
        ],
        246
    ],
    'Indonesia': [
        'id',
        [
            'Jakarta',
            'Pontianak',
            'Makassar',
            'Jayapura'
        ],
        62
    ],
    'Iran': [
        'ir',
        [
            'Tehran'
        ],
        98
    ],
    'Iraq': [
        'iq',
        [
            'Baghdad'
        ],
        964
    ],
    'Ireland': [
        'ie',
        [
            'Dublin'
        ],
        353
    ],
    'Isle of Man': [
        'im',
        [
            'Isle_of_Man'
        ],
        44,
        2
    ],
    'Israel': [
        'il',
        [
            'Jerusalem'
        ],
        972
    ],
    'Italy': [
        'it',
        [
            'Rome'
        ],
        39
    ],
    'Jamaica': [
        'jm',
        [
            'Jamaica'
        ],
        1876
    ],
    'Japan': [
        'jp',
        [
            'Tokyo'
        ],
        81
    ],
    'Jersey': [
        'je',
        [
            'Jersey'
        ],
        44,
        3
    ],
    'Jordan': [
        'jo',
        [
            'Amman'
        ],
        962
    ],
    'Kazakhstan': [
        'kz',
        [
            'Almaty',
            'Qyzylorda',
            'Qostanay',
            'Aqtobe',
            'Aqtau',
            'Atyrau',
            'Oral'
        ],
        [7, 76, 77, 997],
        1
    ],
    'Kenya': [
        'ke',
        [
            'Nairobi'
        ],
        254
    ],
    'Kiribati': [
        'ki',
        [
            'Tarawa',
            'Kanton',
            'Kiritimati'
        ],
        686
    ],
    'Korea (North)': [
        'kp',
        [
            'Pyongyang'
        ],
        850
    ],
    'Korea (South)': [
        'kr',
        [
            'Seoul'
        ],
        82
    ],
    'Kuwait': [
        'kw',
        [
            'Kuwait'
        ],
        965
    ],
    'Kyrgyzstan': [
        'kg',
        [
            'Bishkek'
        ],
        996
    ],
    'Laos': [
        'la',
        [
            'Vientiane'
        ],
        856
    ],
    'Latvia': [
        'lv',
        [
            'Riga'
        ],
        371
    ],
    'Lebanon': [
        'lb',
        [
            'Beirut'
        ],
        961
    ],
    'Lesotho': [
        'ls',
        [
            'Maseru'
        ],
        266
    ],
    'Liberia': [
        'lr',
        [
            'Monrovia'
        ],
        231
    ],
    'Libya': [
        'ly',
        [
            'Tripoli'
        ],
        218
    ],
    'Liechtenstein': [
        'li',
        [
            'Vaduz'
        ],
        423
    ],
    'Lithuania': [
        'lt',
        [
            'Vilnius'
        ],
        370
    ],
    'Luxembourg': [
        'lu',
        [
            'Luxembourg'
        ],
        352
    ],
    'Macau': [
        'mo',
        [
            'Macau'
        ],
        853
    ],
    'Madagascar': [
        'mg',
        [
            'Antananarivo'
        ],
        261
    ],
    'Malawi': [
        'mw',
        [
            'Blantyre'
        ],
        265
    ],
    'Malaysia': [
        'my',
        [
            'Kuala_Lumpur',
            'Kuching'
        ],
        60
    ],
    'Maldives': [
        'mv',
        [
            'Maldives'
        ],
        960
    ],
    'Mali': [
        'ml',
        [
            'Bamako'
        ],
        223
    ],
    'Malta': [
        'mt',
        [
            'Malta'
        ],
        356
    ],
    'Marshall Islands': [
        'mh',
        [
            'Majuro',
            'Kwajalein'
        ],
        692
    ],
    'Martinique': [
        'mq',
        [
            'Martinique'
        ],
        596
    ],
    'Mauritania': [
        'mr',
        [
            'Nouakchott'
        ],
        222
    ],
    'Mauritius': [
        'mu',
        [
            'Mauritius'
        ],
        230
    ],
    'Mayotte': [
        'yt',
        [
            'Mayotte'
        ],
        262,
        1
    ],
    'Mexico': [
        'mx',
        [
            'Mexico_City',
            'Cancun',
            'Merida',
            'Monterrey',
            'Matamoros',
            'Mazatlan',
            'Chihuahua',
            'Ojinaga',
            'Hermosillo',
            'Tijuana',
            'Bahia_Banderas'
        ],
        52
    ],
    'Micronesia': [
        'fm',
        [
            'Chuuk',
            'Pohnpei',
            'Kosrae'
        ],
        691
    ],
    'Moldova': [
        'md',
        [
            'Chisinau'
        ],
        373
    ],
    'Monaco': [
        'mc',
        [
            'Monaco'
        ],
        377
    ],
    'Mongolia': [
        'mn',
        [
            'Ulaanbaatar',
            'Hovd',
            'Choibalsan'
        ],
        976
    ],
    'Montenegro': [
        'me',
        [
            'Podgorica'
        ],
        382
    ],
    'Montserrat': [
        'ms',
        [
            'Montserrat'
        ],
        1664
    ],
    'Morocco': [
        'ma',
        [
            'Casablanca'
        ],
        212
    ],
    'Mozambique': [
        'mz',
        [
            'Maputo'
        ],
        258
    ],
    'Myanmar (Burma)': [
        'mm',
        [
            'Yangon'
        ],
        95
    ],
    'Namibia': [
        'na',
        [
            'Windhoek'
        ],
        264
    ],
    'Nauru': [
        'nr',
        [
            'Nauru'
        ],
        674
    ],
    'Nepal': [
        'np',
        [
            'Kathmandu'
        ],
        977
    ],
    'Netherlands': [
        'nl',
        [
            'Amsterdam'
        ],
        31
    ],
    'New Caledonia': [
        'nc',
        [
            'Noumea'
        ],
        687
    ],
    'New Zealand': [
        'nz',
        [
            'Auckland',
            'Chatham'
        ],
        64
    ],
    'Nicaragua': [
        'ni',
        [
            'Managua'
        ],
        505
    ],
    'Niger': [
        'ne',
        [
            'Niamey'
        ],
        227
    ],
    'Nigeria': [
        'ng',
        [
            'Lagos'
        ],
        234
    ],
    'Niue': [
        'nu',
        [
            'Niue'
        ],
        683
    ],
    'Norfolk Island': [
        'nf',
        [
            'Norfolk'
        ],
        672
    ],
    'North Macedonia': [
        'mk',
        [
            'Skopje'
        ],
        3892
    ],
    'Northern Mariana Islands': [
        'mp',
        [
            'Saipan'
        ],
        1670
    ],
    'Norway': [
        'no',
        [
            'Oslo'
        ],
        47
    ],
    'Oman': [
        'om',
        [
            'Muscat'
        ],
        968
    ],
    'Pakistan': [
        'pk',
        [
            'Karachi'
        ],
        92
    ],
    'Palau': [
        'pw',
        [
            'Palau'
        ],
        680
    ],
    'Palestine': [
        'ps',
        [
            'Gaza',
            'Hebron'
        ],
        970
    ],
    'Panama': [
        'pa',
        [
            'Panama'
        ],
        507
    ],
    'Papua New Guinea': [
        'pg',
        [
            'Port_Moresby',
            'Bougainville'
        ],
        675
    ],
    'Paraguay': [
        'py',
        [
            'Asuncion'
        ],
        595
    ],
    'Peru': [
        'pe',
        [
            'Lima'
        ],
        51
    ],
    'Philippines': [
        'ph',
        [
            'Manila'
        ],
        63
    ],
    'Pitcairn': [
        'pn',
        [
            'Pitcairn'
        ],
        64
    ],
    'Poland': [
        'pl',
        [
            'Warsaw'
        ],
        48
    ],
    'Portugal': [
        'pt',
        [
            'Lisbon',
            'Madeira',
            'Azores'
        ],
        351
    ],
    'Puerto Rico': [
        'pr',
        [
            'Puerto_Rico'
        ],
        1,
        3,
        [787, 939]
    ],
    'Qatar': [
        'qa',
        [
            'Qatar'
        ],
        974
    ],
    'Romania': [
        'ro',
        [
            'Bucharest'
        ],
        40
    ],
    'Russia': [
        'ru',
        [
            'Kaliningrad',
            'Moscow',
            'Simferopol',
            'Kirov',
            'Volgograd',
            'Astrakhan',
            'Saratov',
            'Ulyanovsk',
            'Samara',
            'Yekaterinburg',
            'Omsk',
            'Novosibirsk',
            'Barnaul',
            'Tomsk',
            'Novokuznetsk',
            'Krasnoyarsk',
            'Irkutsk',
            'Chita',
            'Yakutsk',
            'Khandyga',
            'Vladivostok',
            'Ust-Nera',
            'Magadan',
            'Sakhalin',
            'Srednekolymsk',
            'Kamchatka',
            'Anadyr'
        ],
        7
    ],
    'Rwanda': [
        'rw',
        [
            'Kigali'
        ],
        250
    ],
    'Réunion': [
        're',
        [
            'Reunion'
        ],
        262
    ],
    'Samoa (American)': [
        'as',
        [
            'Pago_Pago'
        ],
        1684
    ],
    'Samoa (western)': [
        'ws',
        [
            'Apia'
        ],
        685
    ],
    'San Marino': [
        'sm',
        [
            'San_Marino'
        ],
        378
    ],
    'Sao Tome & Principe': [
        'st',
        [
            'Sao_Tome'
        ],
        239
    ],
    'Saudi Arabia': [
        'sa',
        [
            'Riyadh'
        ],
        966
    ],
    'Senegal': [
        'sn',
        [
            'Dakar'
        ],
        221
    ],
    'Serbia': [
        'rs',
        [
            'Belgrade'
        ],
        381
    ],
    'Seychelles': [
        'sc',
        [
            'Mahe'
        ],
        248
    ],
    'Sierra Leone': [
        'sl',
        [
            'Freetown'
        ],
        232
    ],
    'Singapore': [
        'sg',
        [
            'Singapore'
        ],
        65
    ],
    'Slovakia': [
        'sk',
        [
            'Bratislava'
        ],
        421
    ],
    'Slovenia': [
        'si',
        [
            'Ljubljana'
        ],
        386
    ],
    'Solomon Islands': [
        'sb',
        [
            'Guadalcanal'
        ],
        677
    ],
    'Somalia': [
        'so',
        [
            'Mogadishu'
        ],
        252
    ],
    'South Africa': [
        'za',
        [
            'Johannesburg'
        ],
        27
    ],
    'South Georgia & the South Sandwich Islands': [
        'gs',
        [
            'South_Georgia'
        ],
        500
    ],
    'South Sudan': [
        'ss',
        [
            'Juba'
        ],
        211
    ],
    'Spain': [
        'es',
        [
            'Madrid',
            'Ceuta',
            'Canary'
        ],
        34
    ],
    'Sri Lanka': [
        'lk',
        [
            'Colombo'
        ],
        94
    ],
    'St Barthelemy': [
        'bl',
        [
            'St_Barthelemy'
        ],
        590,
        1
    ],
    'St Helena': [
        'sh',
        [
            'St_Helena'
        ],
        290
    ],
    'St Kitts & Nevis': [
        'kn',
        [
            'St_Kitts'
        ],
        1869
    ],
    'St Lucia': [
        'lc',
        [
            'St_Lucia'
        ],
        1758
    ],
    'St Maarten (Dutch)': [
        'sx',
        [
            'Lower_Princes'
        ],
        1721
    ],
    'St Martin (French)': [
        'mf',
        [
            'Marigot'
        ],
        590,
        2
    ],
    'St Pierre & Miquelon': [
        'pm',
        [
            'Miquelon'
        ],
        508
    ],
    'St Vincent': [
        'vc',
        [
            'St_Vincent'
        ],
        1784
    ],
    'Sudan': [
        'ss',
        [
            'Khartoum'
        ],
        211
    ],
    'Suriname': [
        'sr',
        [
            'Paramaribo'
        ],
        597
    ],
    'Svalbard & Jan Mayen': [
        'sj',
        [
            'Longyearbyen'
        ],
        47
    ],
    'Sweden': [
        'se',
        [
            'Stockholm'
        ],
        46
    ],
    'Switzerland': [
        'ch',
        [
            'Zurich'
        ],
        41
    ],
    'Syria': [
        'sy',
        [
            'Damascus'
        ],
        963
    ],
    'Taiwan': [
        'tw',
        [
            'Taipei'
        ],
        886
    ],
    'Tajikistan': [
        'tj',
        [
            'Dushanbe'
        ],
        992
    ],
    'Tanzania': [
        'tz',
        [
            'Dar_es_Salaam'
        ],
        255
    ],
    'Thailand': [
        'th',
        [
            'Bangkok'
        ],
        66
    ],
    'Togo': [
        'tg',
        [
            'Lome'
        ],
        228
    ],
    'Tokelau': [
        'tk',
        [
            'Fakaofo'
        ],
        690
    ],
    'Tonga': [
        'to',
        [
            'Tongatapu'
        ],
        676
    ],
    'Trinidad & Tobago': [
        'tt',
        [
            'Port_of_Spain'
        ],
        1868
    ],
    'Tunisia': [
        'tn',
        [
            'Tunis'
        ],
        216
    ],
    'Turkey': [
        'tr',
        [
            'Istanbul'
        ],
        90
    ],
    'Turkmenistan': [
        'tm',
        [
            'Ashgabat'
        ],
        993
    ],
    'Turks & Caicos Is': [
        'tc',
        [
            'Grand_Turk'
        ],
        1649
    ],
    'Tuvalu': [
        'tv',
        [
            'Funafuti'
        ],
        688
    ],
    'US minor outlying islands': [
        'um',
        [
            'Wake',
            'Midway'
        ],
        581
    ],
    'Uganda': [
        'ug',
        [
            'Kampala'
        ],
        256
    ],
    'Ukraine': [
        'ua',
        [
            'Kiev',
            'Uzhgorod',
            'Zaporozhye'
        ],
        380
    ],
    'United Arab Emirates': [
        'ae',
        [
            'Dubai'
        ],
        971
    ],
    'United States': [
        'us',
        [
            'New_York',
            'Detroit',
            'Louisville',
            'Monticello',
            'Indianapolis',
            'Vincennes',
            'Winamac',
            'Marengo',
            'Petersburg',
            'Vevay',
            'Chicago',
            'Tell_City',
            'Knox',
            'Menominee',
            'Center',
            'New_Salem',
            'Beulah',
            'Denver',
            'Boise',
            'Phoenix',
            'Los_Angeles',
            'Anchorage',
            'Juneau',
            'Sitka',
            'Metlakatla',
            'Yakutat',
            'Nome',
            'Adak',
            'Honolulu'
        ],
        1
    ],
    'Uruguay': [
        'uy',
        [
            'Montevideo'
        ],
        598
    ],
    'Uzbekistan': [
        'uz',
        [
            'Samarkand',
            'Tashkent'
        ],
        998
    ],
    'Vanuatu': [
        'vu',
        [
            'Efate'
        ],
        678
    ],
    'Vatican City': [
        'va',
        [
            'Vatican'
        ],
        39,
        1
    ],
    'Venezuela': [
        've',
        [
            'Caracas'
        ],
        58
    ],
    'Vietnam': [
        'vn',
        [
            'Ho_Chi_Minh'
        ],
        84
    ],
    'Virgin Islands (UK)': [
        'vg',
        [
            'Tortola'
        ],
        1284
    ],
    'Virgin Islands (US)': [
        'vi',
        [
            'St_Thomas'
        ],
        1340
    ],
    'Wallis & Futuna': [
        'wf',
        [
            'Wallis'
        ],
        681
    ],
    'Western Sahara': [
        'eh',
        [
            'El_Aaiun'
        ],
        212,
        1
    ],
    'Yemen': [
        'ye',
        [
            'Aden'
        ],
        967
    ],
    'Zambia': [
        'zm',
        [
            'Lusaka'
        ],
        260
    ],
    'Zimbabwe': [
        'zw',
        [
            'Harare'
        ],
        263
    ],
    'Åland Islands': [
        'ax',
        [
            'Mariehamn'
        ],
        358,
        1
    ]
};
