import { useStorage } from '@vueuse/core';
/*
* У этого хука есть прикол, если ты прокидываешь по умолчанию не объект (null/undefined), а потом меняешь на объект
* то он будет у себя хранить [Object Object].
*
* Чтобы решить такую проблему, следует использовать сериализаторы https://vueuse.org/core/useStorage/#custom-serialization
* */
export var useCacheSession = function (field, value, options) {
    var storage = useStorage(field, value, sessionStorage, options);
    return storage;
};
