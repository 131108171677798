import $ from '@common/utils/Dom';
import throttle from 'lodash/throttle';
/**
 * Working with selection
 *
 * @typedef {SelectionUtils} SelectionUtils
 */
var SelectionUtils = /** @class */ (function () {
    function SelectionUtils(container) {
        /**
         * This property can store SelectionUtils's range for restoring later
         *
         * @type {Range|null}
         */
        this.savedSelectionRange = null;
        /**
         * Fake background is active
         *
         * @returns {boolean}
         */
        this.isFakeBackgroundEnabled = false;
        /**
         * Native Document's commands for fake background
         */
        this.commandBackground = 'backColor';
        this.commandRemoveFormat = 'removeFormat';
        this.container = container;
    }
    SelectionUtils.prototype.setContainer = function (element) {
        this.container = element;
    };
    Object.defineProperty(SelectionUtils.prototype, "anchorNode", {
        /**
         * Returns selected anchor
         * {@link https://developer.mozilla.org/en-US/docs/Web/API/Selection/anchorNode}
         *
         * @returns {Node|null}
         */
        get: function () {
            var selection = this.get();
            return selection ? selection.anchorNode : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectionUtils.prototype, "anchorElement", {
        /**
         * Returns selected anchor element
         *
         * @returns {Element|null}
         */
        get: function () {
            var selection = this.get();
            if (!selection) {
                return null;
            }
            var anchorNode = selection.anchorNode;
            if (!anchorNode) {
                return null;
            }
            return !$.isElement(anchorNode) ? anchorNode.parentElement : anchorNode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectionUtils.prototype, "anchorOffset", {
        /**
         * Returns selection offset according to the anchor node
         * {@link https://developer.mozilla.org/ru/docs/Web/API/Selection/anchorOffset}
         *
         * @returns {number|null}
         */
        get: function () {
            var selection = this.get();
            return selection ? selection.anchorOffset : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectionUtils.prototype, "isCollapsed", {
        /**
         * Is current selection range collapsed
         *
         * @returns {boolean|null}
         */
        get: function () {
            var selection = this.get();
            return selection ? selection.isCollapsed : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectionUtils.prototype, "isInContainer", {
        /**
         * Check current selection if it is at Editor's zone
         *
         * @returns {boolean}
         */
        get: function () {
            if (!this.container) {
                throw new Error('Selection.ts: Container is not set');
            }
            return this.isSelectionInContainer(this.container);
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Check if passed selection is at Editor's zone
     *
     * @param element
     */
    SelectionUtils.prototype.isSelectionInContainer = function (element) {
        /**
         * Something selected on document
         */
        var selectedNode = (this.get().anchorNode || this.get().focusNode);
        if (selectedNode && selectedNode.nodeType === Node.TEXT_NODE) {
            selectedNode = selectedNode.parentNode;
        }
        return Boolean(selectedNode && (element === null || element === void 0 ? void 0 : element.contains(selectedNode)));
    };
    /**
     * Check if passed range at Editor zone
     *
     * @param range - range to check
     */
    SelectionUtils.prototype.isRangeInContainer = function (range) {
        var _a;
        if (!range) {
            return false;
        }
        var selectedNode = range.startContainer;
        if (selectedNode && selectedNode.nodeType === Node.TEXT_NODE) {
            selectedNode = selectedNode.parentNode;
        }
        return Boolean(selectedNode && ((_a = this.container) === null || _a === void 0 ? void 0 : _a.contains(selectedNode)));
    };
    Object.defineProperty(SelectionUtils.prototype, "isSelectionExists", {
        /**
         * Methods return boolean that true if selection exists on the page
         */
        get: function () {
            var selection = this.get();
            return !!selection.anchorNode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectionUtils.prototype, "range", {
        /**
         * Return first range
         *
         * @returns {Range|null}
         */
        get: function () {
            return this.getRangeFromSelection(this.get());
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Returns range from passed Selection object
     *
     * @param selection - Selection object to get Range from
     */
    SelectionUtils.prototype.getRangeFromSelection = function (selection) {
        return selection && selection.rangeCount ? selection.getRangeAt(0) : null;
    };
    Object.defineProperty(SelectionUtils.prototype, "rect", {
        /**
         * Calculates position and size of selected text
         *
         * @returns {DOMRect | ClientRect}
         */
        get: function () {
            var sel = document.selection;
            var range;
            var rect = {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            };
            if (sel && sel.type !== 'Control') {
                sel = sel;
                range = sel.createRange();
                rect.x = range.boundingLeft;
                rect.y = range.boundingTop;
                rect.width = range.boundingWidth;
                rect.height = range.boundingHeight;
                return rect;
            }
            if (!window.getSelection) {
                console.warn('Selection.ts: Method window.getSelection is not supported');
                return rect;
            }
            sel = this.get();
            if (sel.rangeCount === null || isNaN(sel.rangeCount)) {
                console.warn('Selection.ts: Method SelectionUtils.rangeCount is not supported');
                return rect;
            }
            if (sel.rangeCount === 0) {
                return rect;
            }
            range = sel.getRangeAt(0).cloneRange();
            if (range.getBoundingClientRect) {
                rect = range.getBoundingClientRect();
            }
            // Fall back to inserting a temporary element
            if (rect.x === 0 && rect.y === 0) {
                var span = document.createElement('span');
                if (span.getBoundingClientRect) {
                    // Ensure span has dimensions and position by
                    // adding a zero-width space character
                    span.append(document.createTextNode('\u200B'));
                    range.insertNode(span);
                    rect = span.getBoundingClientRect();
                    var spanParent = span.parentNode;
                    spanParent === null || spanParent === void 0 ? void 0 : spanParent.removeChild(span);
                    // Glue any broken text nodes back together
                    spanParent === null || spanParent === void 0 ? void 0 : spanParent.normalize();
                }
            }
            return rect;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectionUtils.prototype, "text", {
        /**
         * Returns selected text as String
         *
         * @returns {string}
         */
        get: function () {
            var _a, _b;
            // @ts-ignore
            return (_b = (_a = this.get()) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Returns window SelectionUtils
     * {@link https://developer.mozilla.org/ru/docs/Web/API/Window/getSelection}
     *
     * @returns {Selection}
     */
    SelectionUtils.prototype.get = function () {
        return window.getSelection();
    };
    /**
     * Set focus to contenteditable or native input element
     *
     * @param element - element where to set focus
     * @param offset - offset of cursor
     */
    SelectionUtils.prototype.setCursor = function (element, offset) {
        if (offset === void 0) { offset = 0; }
        var range = document.createRange();
        var selection = this.get();
        /** if found deepest node is native input */
        if ($.isNativeInput(element)) {
            if (!$.canSetCaret(element)) {
                return;
            }
            element.focus();
            element.selectionStart = element.selectionEnd = offset;
            return element.getBoundingClientRect();
        }
        range.setStart(element, offset);
        range.setEnd(element, offset);
        selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
        selection === null || selection === void 0 ? void 0 : selection.addRange(range);
        return range.getBoundingClientRect();
    };
    /**
     * Adds fake cursor to the current range
     *
     * @param [container] - if passed cursor will be added only if container contains current range
     */
    SelectionUtils.prototype.addFakeCursor = function (container) {
        var range = this.range;
        var fakeCursor = $.make('span', 'codex-editor__fake-cursor');
        fakeCursor.dataset.mutationFree = 'true';
        if (!range || (container && !container.contains(range.startContainer))) {
            return;
        }
        range.collapse();
        range.insertNode(fakeCursor);
    };
    /**
     * Removes fake cursor from a container
     *
     * @param container - container to look for
     */
    SelectionUtils.prototype.removeFakeCursor = function (container) {
        if (container === void 0) { container = document.body; }
        var fakeCursor = $.find(container, ".codex-editor__fake-cursor");
        fakeCursor && fakeCursor.remove();
    };
    /**
     * Removes fake background
     */
    SelectionUtils.prototype.removeFakeBackground = function () {
        if (!this.isFakeBackgroundEnabled) {
            return;
        }
        this.isFakeBackgroundEnabled = false;
        document.execCommand(this.commandRemoveFormat);
    };
    /**
     * Sets fake background
     */
    SelectionUtils.prototype.setFakeBackground = function () {
        document.execCommand(this.commandBackground, false, '#d4ecff');
        this.isFakeBackgroundEnabled = true;
    };
    /**
     * Save SelectionUtils's range
     */
    SelectionUtils.prototype.save = function () {
        this.savedSelectionRange = this.range;
    };
    /**
     * Restore saved SelectionUtils's range
     */
    SelectionUtils.prototype.restore = function () {
        if (!this.savedSelectionRange) {
            return;
        }
        var sel = this.get();
        sel === null || sel === void 0 ? void 0 : sel.removeAllRanges();
        sel === null || sel === void 0 ? void 0 : sel.addRange(this.savedSelectionRange);
    };
    /**
     * Clears saved selection
     */
    SelectionUtils.prototype.clearSaved = function () {
        this.savedSelectionRange = null;
    };
    /**
     * Collapse current selection
     *
     * @param element
     */
    SelectionUtils.prototype.collapseToEnd = function (element) {
        var sel = this.get();
        var range = document.createRange();
        element = element !== null && element !== void 0 ? element : sel === null || sel === void 0 ? void 0 : sel.focusNode;
        range.selectNodeContents(element);
        range.collapse(false);
        sel === null || sel === void 0 ? void 0 : sel.removeAllRanges();
        sel === null || sel === void 0 ? void 0 : sel.addRange(range);
    };
    /**
     * Перемещает курсор в конец элемента
     *
     * @param element
     */
    SelectionUtils.prototype.moveCursorToEnd = function (element) {
        var range = document.createRange();
        var selection = this.get();
        range.selectNodeContents(element);
        range.collapse(false);
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };
    /**
     * Looks ahead to find passed tag from current selection
     *
     * @param  {string} tagName       - tag to found
     * @param  {string} [className]   - tag's class name
     * @param  {number} [searchDepth] - count of tags that can be included. For better performance.
     * @returns {HTMLElement|null}
     */
    SelectionUtils.prototype.findParentTag = function (tagName, className, searchDepth) {
        if (searchDepth === void 0) { searchDepth = 10; }
        var selection = this.get();
        var parentTag = null;
        /**
         * If selection is missing or no anchorNode or focusNode were found then return null
         */
        if (!selection || !selection.anchorNode || !selection.focusNode) {
            return null;
        }
        /**
         * Define Nodes for start and end of selection
         */
        var boundNodes = [
            /** the Node in which the selection begins */
            selection.anchorNode,
            /** the Node in which the selection ends */
            selection.focusNode,
        ];
        /**
         * For each selection parent Nodes we try to find target tag [with target class name]
         * It would be saved in parentTag variable
         */
        boundNodes.forEach(function (parent) {
            var _a;
            /** Reset tags limit */
            var searchDepthIterable = searchDepth;
            while (searchDepthIterable > 0 && parent.parentNode) {
                /**
                 * Check tag's name
                 */
                if (((_a = parent.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (tagName === null || tagName === void 0 ? void 0 : tagName.toLowerCase())) {
                    /**
                     * Save the result
                     */
                    parentTag = parent;
                    /**
                     * Optional additional check for class-name mismatching
                     */
                    if (className && parent.classList && !parent.classList.contains(className)) {
                        parentTag = null;
                    }
                    /**
                     * If we have found required tag with class then go out from the cycle
                     */
                    if (parentTag) {
                        break;
                    }
                }
                /**
                 * Target tag was not found. Go up to the parent and check it
                 */
                parent = parent.parentNode;
                searchDepthIterable--;
            }
        });
        /**
         * Return found tag or null
         */
        return parentTag;
    };
    /**
     * Expands selection range to the passed parent node
     *
     * @param {HTMLElement} element - element which contents should be selected
     */
    SelectionUtils.prototype.expandToTag = function (element) {
        var selection = this.get();
        selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
        var range = document.createRange();
        range.selectNodeContents(element);
        selection === null || selection === void 0 ? void 0 : selection.addRange(range);
    };
    /**
     * Expands selection range to the passed parent node
     *
     * @param {string} siblingsClass - classes expand to
     */
    SelectionUtils.prototype.expandWithSiblings = function (siblingsClass) {
        // Получаем текущее выделение
        var selection = this.get();
        // Если выделение есть и оно является частью текста, а не элемента страницы
        if (selection.rangeCount > 0 && selection.toString().length > 0) {
            var range = selection.getRangeAt(0);
            var startContainer = range.startContainer;
            var endContainer = range.endContainer;
            // Определяем, какой элемент находится слева, а какой справа
            var isLeftElement = startContainer.compareDocumentPosition(endContainer) & Node.DOCUMENT_POSITION_FOLLOWING;
            var leftElement = isLeftElement ? startContainer.parentNode : endContainer.parentNode;
            var rightElement = isLeftElement ? endContainer.parentNode : startContainer.parentNode;
            // Проверяем, являются ли левый и правый элементы тегами span с классом "highlight"
            var isLeftElementHighlight = leftElement
                && leftElement !== this.container;
            var isRightElementHighlight = rightElement
                && rightElement !== this.container;
            // Создаем новые range'ы для левой и правой частей выделения
            var newRangeLeft = document.createRange();
            var newRangeRight = document.createRange();
            // Сохраняем текущие границы выделения
            newRangeLeft.setStart(startContainer, range.startOffset);
            newRangeLeft.setEnd(startContainer, range.startOffset);
            newRangeRight.setStart(endContainer, range.endOffset);
            newRangeRight.setEnd(endContainer, range.endOffset);
            // Расширение выделения влево, если левый элемент соответствует условию
            if (isLeftElementHighlight) {
                newRangeLeft.setStartBefore(leftElement);
            }
            // Расширение выделения вправо, если правый элемент соответствует условию
            if (isRightElementHighlight) {
                newRangeRight.setStartAfter(rightElement);
            }
            // Объединяем левую и правую части в один range
            var newRange = document.createRange();
            newRange.setStart(newRangeLeft.startContainer, newRangeLeft.startOffset);
            newRange.setEnd(newRangeRight.endContainer, newRangeRight.endOffset);
            // Выделяем расширенный блок
            selection.removeAllRanges();
            selection.addRange(newRange);
        }
    };
    /**
     * Подписаться на изменения выделения внутри контейнера
     *
     * @param func
     */
    SelectionUtils.prototype.onSelectionChange = function (func) {
        var throttleFunc = throttle(func, 10);
        document.addEventListener('selectionchange', throttleFunc);
    };
    /**
     * Отписаться от изменений выделения внутри контейнера
     *
     * @param func
     */
    SelectionUtils.prototype.offSelectionChange = function (func) {
        document.removeEventListener('selectionchange', func);
    };
    /**
     * Убираем из выделения пробелы в начале и конце строки
     */
    SelectionUtils.prototype.trimSpaces = function () {
        var selection = this.get();
        // Получение первого объекта диапазона из выделения
        var range = selection.getRangeAt(0);
        var startContainer = range.startContainer;
        var endContainer = range.endContainer;
        var startOffset = range.startOffset;
        var endOffset = range.endOffset;
        // Обновление начального контейнера
        while (startContainer.nodeType === Node.TEXT_NODE && /^\s+/.test(startContainer.nodeValue.slice(startOffset, startOffset + 1))) {
            startOffset++;
        }
        // Обновление конечного контейнера
        while (endContainer.nodeType === Node.TEXT_NODE && /\s+$/.test(endContainer.nodeValue.slice(endOffset - 1, endOffset))) {
            endOffset--;
        }
        // Создание нового диапазона с обновленными значениями
        var newRange = document.createRange();
        newRange.setStart(startContainer, startOffset);
        newRange.setEnd(endContainer, endOffset);
        // Обновление выделения с использованием нового диапазона
        selection.removeAllRanges();
        selection.addRange(newRange);
    };
    SelectionUtils.prototype.wrapWithTag = function (tagName, classes) {
        if (tagName === void 0) { tagName = 'span'; }
        if (classes === void 0) { classes = ''; }
        if (!this.range) {
            throw new Error('Selection.ts: Range is undefined');
        }
        var range = this.get().getRangeAt(0);
        // Копируем и сохраняем выделенный текст
        var selectedText = range.toString();
        // Создаем новый элемент span
        var newElement = document.createElement(tagName);
        if (classes) {
            newElement.classList.add(classes);
        }
        // Вставляем сохраненный текст в новый span-элемент
        newElement.textContent = selectedText;
        // Заменяем выделенный текст на новый span-элемент
        range.deleteContents();
        range.insertNode(newElement);
        // Устанавливаем выделение внутри нового элемента
        this.expandToTag(newElement);
        return newElement;
        // Устанавливаем выделение вокруг нового элемента
        // this.get().removeAllRanges();
        // this.get().addRange(range);
    };
    /**
     * Unwrap term-tag
     *
     * @param {HTMLElement} element - term wrapper tag
     */
    SelectionUtils.prototype.unwrap = function (element) {
        /**
         * Expand selection to all term-tag
         */
        this.expandToTag(element);
        var sel = this.get();
        if (!sel) {
            console.warn('Selection.ts: Объект Selection не найден');
            return;
        }
        var range = sel.getRangeAt(0);
        var unwrappedContent = range.extractContents();
        /**
         * Remove empty term-tag
         */
        element.remove();
        /**
         * Insert extracted content
         */
        range.insertNode(unwrappedContent);
        /**
         * Restore selection
         */
        sel.removeAllRanges();
        sel.addRange(range);
    };
    return SelectionUtils;
}());
export default SelectionUtils;
