var _a, _b;
import countriesData from '@common/utils/data/Countries';
export var countries = [];
export var countriesIso2 = [];
for (var countryKey in countriesData) {
    var country = countriesData[countryKey];
    countriesIso2.push(country[0].toLowerCase());
    countries.push({
        name: countryKey,
        iso2: country[0].toLowerCase(),
        timezoneCities: country[1],
        phoneCode: country[2],
        priority: (_a = country[3]) !== null && _a !== void 0 ? _a : 0,
        areaCodes: (_b = country[4]) !== null && _b !== void 0 ? _b : [],
    });
}
export var userRegion;
export var userCity;
export var userCountry;
export var userTimeZone;
if (Intl) {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
    var tzArr = timezone.split('/');
    userTimeZone = timezone;
    userRegion = tzArr[0];
    userCity = tzArr[tzArr.length - 1];
    userCountry = countries.find(function (c) { return c.timezoneCities.includes(userCity); });
}
