import { clone } from '../Helpers/Helpers';
/**
 * Фабрика для создания раздельных кэшей.
 * Каждый отдельный экземпляр не пересекается с другими, рекомендуется использовать разные для разных
 * стореджей, разделок и т.п.
 */
var Cache = /** @class */ (function () {
    function Cache() {
        this.data = {};
    }
    /**
     * Создать кэш-элемент с ключем и значением
     *
     * @param {string} key ключ элемента в кэше
     * @param {any} value значение
     * @param {boolean} clearSources сохранять клон значения (очищает значение от любых ссылок на оригинал)
     */
    Cache.prototype.set = function (key, value, clearSources) {
        if (clearSources === void 0) { clearSources = true; }
        if (clearSources) {
            this.data[key] = clone(value);
        }
        else {
            this.data[key] = value;
        }
    };
    /**
     * Проверка существования кэш-элемента по ключу
     *
     * @param {string} key ключ элемента в кэше
     * @returns {boolean}
     */
    Cache.prototype.exists = function (key) {
        return typeof (this.data[key]) !== 'undefined';
    };
    /**
     * Получить значение кэш-элемента по ключу
     *
     * @param {string} key ключ элемента в кэше
     * @returns {*}
     */
    Cache.prototype.get = function (key) {
        return this.data[key];
    };
    /**
     * Очистка всех кэш-элементов в текущем кэше
     */
    Cache.prototype.clear = function () {
        this.data = {};
    };
    return Cache;
}());
export { Cache as CacheFactory, };
