import analytics from '@common/utils/Analytics/Analytics';
import { defineStore } from 'pinia';
export var PLANS = [
    {
        name: 'Старт',
        code: 'new-school-free',
        icon: 'plan-new-school-free',
    },
    {
        name: 'Любитель',
        code: 'advanced',
        icon: 'plan-advanced',
    },
    {
        name: 'Профи',
        code: 'optimal',
        icon: 'plan-optimal',
    },
    {
        name: 'Эксперт',
        code: 'expert',
        icon: 'plan-expert',
    },
    {
        name: 'Бизнес',
        code: 'business',
        icon: 'plan-business',
    },
];
/**
 * Сторедж окна лимита по тарифа
 *
 * @description отображается, когда пользователь пытается воспользоваться функцией недоступной на его текущем тарифе
 */
export var usePlanContentStore = defineStore('planContent', {
    state: function () { return ({
        // Отображать попап лимита
        visible: false,
        // Состояние загрузки при активации пробного периода/смены тарифа
        // @todo: сейчас не используется, надо подключить в релизе ski-1620 и удалить эту строку
        loading: false,
        // Тарифный план на котором доступна заблокированная функция
        plan: {},
        // Заголовок в попапе
        title: '',
        // Ссылка на картинку в попапе
        image: '',
    }); },
    actions: {
        /**
         * Показать окно ограничения на тарифе
         *
         * @param {string} planName название тарифного плана
         * @param {string} title заголовок окна
         * @param {string} image ссылка на изображение в окне
         * @returns {Promise}
         */
        show: function (planName, title, image) {
            this.visible = true;
            this.plan = PLANS.find(function (p) { return p.name === planName; });
            this.title = title;
            this.image = image;
            analytics.trackDetail({
                'Раздел': 'Уведомление об ограниченном функционале',
                'Событие': 'Открыл модальное окно',
            });
        },
        /**
         * Скрыть окно ограничения на тарифе
         *
         * @returns {Promise}
         */
        hide: function () {
            this.visible = false;
        },
    },
});
