var FileMeta = /** @class */ (function () {
    function FileMeta(_name, _size, _mime, _lastModified) {
        if (_name === void 0) { _name = 'Файл'; }
        if (_size === void 0) { _size = 0; }
        this._name = _name;
        this._size = _size;
        this._mime = _mime;
        this._lastModified = _lastModified;
    }
    FileMeta.prototype.getExtension = function () {
        return this._name.split('.').at(-1) || 'unk';
    };
    FileMeta.prototype.getName = function () {
        return this._name;
    };
    FileMeta.prototype.getSize = function () {
        return this._size;
    };
    FileMeta.prototype.getMime = function () {
        return this._mime;
    };
    return FileMeta;
}());
export { FileMeta };
