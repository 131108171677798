var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { apiErrorsHandle, clone } from '@common/utils/Helpers/Helpers';
import { defineStore } from 'pinia';
import { sortArray } from '@common/utils/Array/Array';
import { PublishStatusEnum } from '@common/enums/publishStatus.enum';
import Api from '@school/modules/api/api';
import { CertificateConstructorStore } from '@common/stores';
import { fillObject } from '@common/utils/Object/Object';
import CompletionApi from '@school/modules/api/entities/completion/completion.api';
import CertificateApi from '@school/modules/api/entities/certificate/certificate.api';
/**
 * Сторедж страницы курса
 */
export var useCourseStore = defineStore('courseSchool', {
    state: function () { return ({
        // id открытого курса
        courseId: null,
        // Открытый курс
        // @sync - нужно синхронить с одинаковыми id
        course: {},
        // Состояние загрузки курса
        courseLoading: false,
        // Модули курса
        // @sync - синхроним только если course одинаковый
        steps: [],
        // Состояние загрузки модулей
        stepsLoading: false,
        // Занятия курса
        // @sync - синхроним только если course одинаковый
        lessons: [],
        // Лендинг курса
        // @sync - синхроним с одинаковыми id
        landing: {},
        // Блок лендинга над которым производится изменение
        landingSelectedBlock: null,
        // Карточка лендинга над которой производится изменение
        landingSelectedCard: null,
        // Отображать компонент смены иконки (для карточки/блока)
        landingSelectIconVisibility: false,
        // Позволить загружать кастомное изображение в качестве иконки
        landingSelectIconUpload: false,
        // Отображать компонент смены картинки
        landingSelectImageVisibility: false,
        // Отображать компонент формы заказа
        landingOrderFormVisibility: false,
        // Тарифный план для формы заказа (выбранный на лендинге)
        landingOrderFormPlan: null,
        externalLandingModalVisible: false,
        trash: {
            lessons: [],
            steps: [],
            isShouldFetch: true
        },
        /** Конфиг завершения курса */
        defaultCompletionCourseRule: {
            drawTitle: '',
            drawRule: {},
            drawDisplayShareButtons: false,
        },
        defaultCertificateCourseRule: {
            drawRule: {},
            drawDisplayShareButtons: false,
        },
        completion: {
            courseRules: {
                course: {
                    name: '',
                    uuid: null
                },
                displayShareButtons: false,
                drawDisplayShareButtons: false,
                rule: {},
                drawRule: {},
                title: 'Поздравляем, курс пройден!',
                drawTitle: 'Поздравляем, курс пройден!',
                subtitle: '',
                drawSubtitle: '',
                group: null,
                publishedAt: null,
                status: PublishStatusEnum.PUBLISHED,
                uuid: null,
                drawDisplayStudent: false,
                displayStudent: false
            },
            groupRules: {}
        },
        certificateSettings: {
            courseRules: {
                course: {
                    name: '',
                    uuid: null,
                    enabled: false,
                },
                displayShareButtons: false,
                drawDisplayShareButtons: true,
                rule: {},
                drawRule: {},
                title: 'Поздравляем, курс пройден!',
                drawTitle: 'Поздравляем, курс пройден!',
                group: null,
                publishedAt: null,
                status: PublishStatusEnum.PUBLISHED,
                uuid: null
            },
            groupRules: {}
        }
    }); },
    getters: {
        /**
         * Включена ли ссылка на внешний лендинг
         *
         * @param store
         * @returns {boolean}
         */
        useLandingExternalUrl: function (store) { return store.course.useLandingExternalUrl; },
        /**
         * Ссылка на внешний лендинг
         *
         * @param store
         * @returns {string}
         */
        landingExternalUrl: function (store) { return store.course.landingExternalUrl; },
        stepsWithLessons: function (store) {
            return sortArray(store.steps, 'order').map(function (step) {
                step.lessons = sortArray(store.lessons, 'order')
                    .filter(function (lesson) { var _a; return ((_a = lesson.step) === null || _a === void 0 ? void 0 : _a.uuid) === step.uuid; })
                    .map(function (_a) {
                    var _step = _a.step, lesson = __rest(_a, ["step"]);
                    return lesson;
                });
                return step;
            });
        },
        stepsStatus: function () {
            var e_1, _a;
            var _b, _c, _d, _e, _f;
            var stepsStatuses = {};
            var steps = clone(this.stepsWithLessons);
            var _loop_1 = function (step) {
                var _g;
                if (!step.lessons) {
                    return "continue";
                }
                var status = (_b = step.lessons[0]) === null || _b === void 0 ? void 0 : _b.status;
                stepsStatuses[step.uuid] = {
                    byStatusCount: (_g = {},
                        _g[PublishStatusEnum.PUBLISHED] = (_c = step.lessons.filter(function (lesson) { return lesson.status === PublishStatusEnum.PUBLISHED; })) === null || _c === void 0 ? void 0 : _c.length,
                        _g[PublishStatusEnum.NONE_PUBLISHED] = (_d = step.lessons.filter(function (lesson) { return lesson.status === PublishStatusEnum.NONE_PUBLISHED; })) === null || _d === void 0 ? void 0 : _d.length,
                        _g[PublishStatusEnum.DRAW] = (_e = step.lessons.filter(function (lesson) { return lesson.status === PublishStatusEnum.DRAW; })) === null || _e === void 0 ? void 0 : _e.length,
                        _g[PublishStatusEnum.PUBLISHED_DRAW] = (_f = step.lessons.filter(function (lesson) { return lesson.status === PublishStatusEnum.PUBLISHED_DRAW; })) === null || _f === void 0 ? void 0 : _f.length,
                        _g),
                    // дефолт
                    mainStatus: PublishStatusEnum.PUBLISHED,
                    updatedAt: null,
                    publishedAt: null,
                };
                if (status) {
                    if (step.lessons.every(function (lesson) { return lesson.status === status; })) {
                        stepsStatuses[step.uuid].mainStatus = status;
                        stepsStatuses[step.uuid].updatedAt = new Date(step.lessons.sort(function (a, b) { return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(); })[0].updatedAt);
                        stepsStatuses[step.uuid].publishedAt = new Date(step.lessons.sort(function (a, b) { return new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime(); })[0].publishedAt);
                    }
                    else {
                        if (step.lessons.some(function (lesson) { return lesson.status === PublishStatusEnum.NONE_PUBLISHED; })) {
                            stepsStatuses[step.uuid].mainStatus = PublishStatusEnum.NONE_PUBLISHED;
                        }
                        else if (step.lessons.some(function (lesson) { return lesson.status === PublishStatusEnum.PUBLISHED_DRAW; })) {
                            stepsStatuses[step.uuid].mainStatus = PublishStatusEnum.PUBLISHED_DRAW;
                        }
                        else if (step.lessons.some(function (lesson) { return lesson.status === PublishStatusEnum.DRAW; })) {
                            stepsStatuses[step.uuid].mainStatus = PublishStatusEnum.DRAW;
                        }
                    }
                }
            };
            try {
                for (var steps_1 = __values(steps), steps_1_1 = steps_1.next(); !steps_1_1.done; steps_1_1 = steps_1.next()) {
                    var step = steps_1_1.value;
                    _loop_1(step);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (steps_1_1 && !steps_1_1.done && (_a = steps_1.return)) _a.call(steps_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return stepsStatuses;
        },
        courseStatus: function () {
            var _a, _b;
            if (Object.keys(this.stepsStatus).length === 0) {
                return {
                    mainStatus: PublishStatusEnum.PUBLISHED,
                    updatedAt: null,
                    publishedAt: null,
                    byStatusCount: (_a = {},
                        _a[PublishStatusEnum.DRAW] = 0,
                        _a[PublishStatusEnum.PUBLISHED_DRAW] = 0,
                        _a[PublishStatusEnum.NONE_PUBLISHED] = 0,
                        _a[PublishStatusEnum.PUBLISHED] = 0,
                        _a),
                };
            }
            var stepsStatusValues = Object.values(this.stepsStatus);
            var fStatus = stepsStatusValues[0].mainStatus;
            var courseStatuses = {
                // дефолт
                mainStatus: PublishStatusEnum.PUBLISHED,
                updatedAt: stepsStatusValues.sort(function (a, b) { return new Date(b.updatedAt || 0).getTime() - new Date(a.updatedAt || 0).getTime(); })[0].updatedAt,
                publishedAt: stepsStatusValues.sort(function (a, b) { return new Date(b.publishedAt || 0).getTime() - new Date(a.publishedAt || 0).getTime(); })[0].publishedAt,
                byStatusCount: (_b = {},
                    _b[PublishStatusEnum.DRAW] = stepsStatusValues.reduce(function (a, b) { return a + b.byStatusCount[PublishStatusEnum.DRAW]; }, 0),
                    _b[PublishStatusEnum.PUBLISHED_DRAW] = stepsStatusValues.reduce(function (a, b) { return a + b.byStatusCount[PublishStatusEnum.PUBLISHED_DRAW]; }, 0),
                    _b[PublishStatusEnum.NONE_PUBLISHED] = stepsStatusValues.reduce(function (a, b) { return a + b.byStatusCount[PublishStatusEnum.NONE_PUBLISHED]; }, 0),
                    _b[PublishStatusEnum.PUBLISHED] = stepsStatusValues.reduce(function (a, b) { return a + b.byStatusCount[PublishStatusEnum.PUBLISHED]; }, 0),
                    _b)
            };
            if (stepsStatusValues.every(function (status) { return status.mainStatus === fStatus; })) {
                courseStatuses.mainStatus = fStatus;
            }
            else {
                if (stepsStatusValues.some(function (step) { return step.mainStatus === PublishStatusEnum.NONE_PUBLISHED; })) {
                    courseStatuses.mainStatus = PublishStatusEnum.NONE_PUBLISHED;
                }
                else if (stepsStatusValues.some(function (step) { return step.mainStatus === PublishStatusEnum.PUBLISHED_DRAW; })) {
                    courseStatuses.mainStatus = PublishStatusEnum.PUBLISHED_DRAW;
                }
                else if (stepsStatusValues.some(function (step) { return step.mainStatus === PublishStatusEnum.DRAW; })) {
                    courseStatuses.mainStatus = PublishStatusEnum.DRAW;
                }
            }
            return courseStatuses;
        }
    },
    actions: {
        /**
         * Получить курс
         *
         * @param {number} courseId id курса
         * @returns {Promise}
         */
        getCourse: function (courseId, slug) {
            return __awaiter(this, void 0, void 0, function () {
                var course, landings, error_1;
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.courseLoading = true;
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 7, 8, 9]);
                            if (!slug) return [3 /*break*/, 3];
                            return [4 /*yield*/, Api.getCourseSlug(slug)];
                        case 2:
                            (_a = (_c.sent()).data, course = _a.course, landings = _a.landings);
                            return [3 /*break*/, 6];
                        case 3:
                            if (!courseId) return [3 /*break*/, 5];
                            return [4 /*yield*/, Api.getCourse(courseId)];
                        case 4:
                            (_b = (_c.sent()).data, course = _b.course, landings = _b.landings);
                            return [3 /*break*/, 6];
                        case 5: throw new Error("Не указан courseId или slug");
                        case 6:
                            this.course = course;
                            this.landing = (landings === null || landings === void 0 ? void 0 : landings[0]) || null;
                            return [3 /*break*/, 9];
                        case 7:
                            error_1 = _c.sent();
                            console.error("Ошибка загрузки курса:", error_1);
                            return [3 /*break*/, 9];
                        case 8:
                            this.courseLoading = false;
                            return [7 /*endfinally*/];
                        case 9: return [2 /*return*/, course];
                    }
                });
            });
        },
        // Получить модули курса
        getSteps: function (courseId_1) {
            return __awaiter(this, arguments, void 0, function (courseId, _forceUpdate, hasLoading) {
                var data, lessons, steps;
                if (_forceUpdate === void 0) { _forceUpdate = false; }
                if (hasLoading === void 0) { hasLoading = true; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (hasLoading) {
                                this.stepsLoading = true;
                            }
                            return [4 /*yield*/, Api.getCourseSteps(courseId)];
                        case 1:
                            data = (_a.sent()).data;
                            lessons = [];
                            steps = clone(data).map(function (step, stepIndex) {
                                var e_2, _a;
                                // TODO: попросить халика сделать нормализацию чтобы избавиться от этого
                                var order = 100 + (stepIndex * 100);
                                var newStep = __assign(__assign({}, step), { order: order });
                                if (!step.lessons) {
                                    return newStep;
                                }
                                try {
                                    for (var _b = __values(newStep.lessons), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        var lesson = _c.value;
                                        lessons.push(__assign(__assign({}, lesson), { step: clone(step) }));
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                                var _l = step.lessons, otherFields = __rest(step, ["lessons"]);
                                return otherFields;
                            });
                            this.steps = steps;
                            this.lessons = lessons;
                            this.stepsLoading = false;
                            return [2 /*return*/, steps];
                    }
                });
            });
        },
        /**
         * Получить удаленные занятия
         */
        getTrashedLessons: function () {
            return __awaiter(this, void 0, Promise, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Api.getTrashedLessons(this.course.uuid)];
                        case 1:
                            data = (_a.sent()).data;
                            this.trash = __assign(__assign({}, data), { isShouldFetch: false });
                            return [2 /*return*/];
                    }
                });
            });
        },
        setIsShouldFetchTrash: function (value) {
            this.trash.isShouldFetch = value;
        },
        restoreLessons: function (lessonUuid) {
            return __awaiter(this, void 0, void 0, function () {
                var e_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, Api.restoreLessons(lessonUuid)];
                        case 1:
                            _a.sent();
                            this.trash.lessons = this.trash.lessons.filter(function (lesson) { return lesson.uuid !== lessonUuid; });
                            return [4 /*yield*/, this.getSteps(this.course.id)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_3 = _a.sent();
                            apiErrorsHandle(e_3);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        restoreStep: function (stepUuid) {
            return __awaiter(this, void 0, void 0, function () {
                var e_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, Api.restoreStep(stepUuid)];
                        case 1:
                            _a.sent();
                            this.trash.steps = this.trash.steps.filter(function (step) { return step.uuid !== stepUuid; });
                            return [4 /*yield*/, this.getSteps(this.course.id)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_4 = _a.sent();
                            apiErrorsHandle(e_4);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        getCompletion: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CompletionApi.getFinish(this.course.uuid, {})];
                        case 1:
                            data = (_a.sent()).data;
                            this.defaultCompletionCourseRule = {
                                drawDisplayShareButtons: data.courseRules.drawDisplayShareButtons,
                                drawRule: data.courseRules.drawRule,
                                drawTitle: data.courseRules.drawTitle,
                            };
                            this.completion.courseRules = data.courseRules || this.completion.courseRules;
                            this.completion.groupRules = Array.isArray(data.groupRules) ? {} : data.groupRules;
                            return [2 /*return*/];
                    }
                });
            });
        },
        getCertificateSettings: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, CertificateApi.getCertificate(this.course.uuid, {})];
                        case 1:
                            data = (_d.sent()).data;
                            fillObject(this.defaultCertificateCourseRule, {
                                drawDisplayShareButtons: (_a = data.courseRules) === null || _a === void 0 ? void 0 : _a.drawDisplayShareButtons,
                                drawRule: (_c = (_b = data.courseRules) === null || _b === void 0 ? void 0 : _b.drawRule) !== null && _c !== void 0 ? _c : {},
                            });
                            fillObject(this.certificateSettings, {
                                courseRules: data.courseRules || this.certificateSettings.courseRules,
                                groupRules: Array.isArray(data.groupRules) ? {} : data.groupRules
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateSettingsCompletion: function () {
            return __awaiter(this, void 0, void 0, function () {
                var groupsRule, groupsDisplayStudent, _a, _b, key;
                var e_5, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            groupsRule = {};
                            groupsDisplayStudent = {};
                            try {
                                for (_a = __values(Object.keys(this.completion.groupRules)), _b = _a.next(); !_b.done; _b = _a.next()) {
                                    key = _b.value;
                                    groupsRule[key] = this.completion.groupRules[key].drawRule;
                                    groupsDisplayStudent[key] = this.completion.groupRules[key].drawDisplayStudent;
                                }
                            }
                            catch (e_5_1) { e_5 = { error: e_5_1 }; }
                            finally {
                                try {
                                    if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                }
                                finally { if (e_5) throw e_5.error; }
                            }
                            return [4 /*yield*/, CompletionApi.updateFinish(this.course.uuid, {
                                    courseRules: this.completion.courseRules.drawRule,
                                    courseDisplayStudent: this.completion.courseRules.drawDisplayStudent,
                                    groupsRule: groupsRule,
                                    groupsDisplayStudent: groupsDisplayStudent
                                })];
                        case 1:
                            _d.sent();
                            return [4 /*yield*/, this.getCompletion()];
                        case 2:
                            _d.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateContentCompletion: function () {
            return __awaiter(this, void 0, void 0, function () {
                var groupsRule, groupsDisplayStudent, _a, _b, key;
                var e_6, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            groupsRule = {};
                            groupsDisplayStudent = {};
                            try {
                                for (_a = __values(Object.keys(this.completion.groupRules)), _b = _a.next(); !_b.done; _b = _a.next()) {
                                    key = _b.value;
                                    groupsRule[key] = this.completion.groupRules[key].drawRule;
                                    groupsDisplayStudent[key] = this.completion.groupRules[key].drawDisplayStudent;
                                }
                            }
                            catch (e_6_1) { e_6 = { error: e_6_1 }; }
                            finally {
                                try {
                                    if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                }
                                finally { if (e_6) throw e_6.error; }
                            }
                            return [4 /*yield*/, CompletionApi.updateFinish(this.course.uuid, {
                                    title: this.completion.courseRules.drawTitle,
                                    subtitle: this.completion.courseRules.drawSubtitle,
                                    courseRules: this.defaultCompletionCourseRule.drawRule,
                                    displayShareButtons: this.completion.courseRules.drawDisplayShareButtons,
                                    groupsRule: groupsRule,
                                    groupsDisplayStudent: groupsDisplayStudent
                                })];
                        case 1:
                            _d.sent();
                            return [4 /*yield*/, this.getCompletion()];
                        case 2:
                            _d.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateContentCertificate: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CertificateApi.updateCertificate(this.course.uuid, {
                                courseRules: this.certificateSettings.courseRules.drawRule,
                                displayShareButtons: this.certificateSettings.courseRules.drawDisplayShareButtons,
                                courseEnabled: this.certificateSettings.courseRules.course.enabled
                            })];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.getCertificateSettings()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateCertificateSettings: function () {
            return __awaiter(this, void 0, void 0, function () {
                var groupsRule, groupsEnabled, _a, _b, key;
                var e_7, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            groupsRule = {};
                            groupsEnabled = {};
                            if (Object.keys(this.certificateSettings.groupRules).length > 0) {
                                try {
                                    for (_a = __values(Object.keys(this.certificateSettings.groupRules)), _b = _a.next(); !_b.done; _b = _a.next()) {
                                        key = _b.value;
                                        groupsRule[key] = this.certificateSettings.groupRules[key].drawRule;
                                        groupsEnabled[key] = this.certificateSettings.groupRules[key].group.enabled;
                                    }
                                }
                                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                                finally {
                                    try {
                                        if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                    }
                                    finally { if (e_7) throw e_7.error; }
                                }
                            }
                            return [4 /*yield*/, CertificateApi.updateCertificate(this.course.uuid, {
                                    courseRules: this.certificateSettings.courseRules.drawRule,
                                    courseEnabled: this.certificateSettings.courseRules.course.enabled,
                                    groupsRule: groupsRule,
                                    groupsEnabled: groupsEnabled,
                                })];
                        case 1:
                            _d.sent();
                            return [4 /*yield*/, this.getCertificateSettings()];
                        case 2:
                            _d.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        publishCompletion: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CompletionApi.publishFinish(this.course.uuid, {})];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.getCompletion()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        publishCertificateSettings: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CertificateApi.publishCertificateRules(this.course.uuid, {})];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, CertificateApi.publishCertificate(this.course.uuid)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.getCertificateSettings()];
                        case 3:
                            _a.sent();
                            CertificateConstructorStore.published = true;
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteGroupSettingsCertificate: function (groupUuid) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, CertificateApi.deleteGroupSettings(this.course.uuid, groupUuid)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.getCertificateSettings()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteGroupSettingsCompletion: function (groupUuid) {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 3, , 5]);
                            return [4 /*yield*/, CompletionApi.deleteGroupSettings(this.course.uuid, groupUuid)];
                        case 1:
                            _b.sent();
                            return [4 /*yield*/, this.getCompletion()];
                        case 2:
                            _b.sent();
                            return [3 /*break*/, 5];
                        case 3:
                            _a = _b.sent();
                            return [4 /*yield*/, this.getCompletion()];
                        case 4:
                            _b.sent();
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
    },
});
