export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please specify the fields to continue:"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your name"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the field"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, enjoy using!"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unnamed"])}
        }
      },
      "ru": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите поля, чтобы продолжить:"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите своё имя"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите поле"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо, приятного пользования!"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без имени"])}
        }
      },
      "es": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique los campos para continuar:"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese su nombre"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese el campo"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias, que lo disfrute!"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin nombre"])}
        }
      },
      "kk": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру үшін өрістерді көрсетіңіз:"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінемін, атыңызды енгізіңіз"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінемін, өрісті енгізіңіз"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахмет, жақсылықпен пайдаланыңыз!"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты жоқ"])}
        }
      },
      "uz": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davom etish uchun maydonlarni ko'rsating:"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ism"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saqlash"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, ismingizni kiriting"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, maydonni kiriting"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahmat, foydalanishingiz maroqli bo'lsin!"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismsiz"])}
        }
      },
      "de": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Felder ein, um fortzufahren:"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Namen ein"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie das Feld ein"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke, viel Spaß beim Nutzen!"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Namen"])}
        }
      },
      "fr": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer les champs pour continuer :"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre nom"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir le champ"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci, bonne utilisation !"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans nom"])}
        }
      },
      "it": {
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica i campi per continuare:"])}
        },
        "form": {
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])}
        },
        "validation": {
          "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo nome"])},
          "enterField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il campo"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie, buon utilizzo!"])}
        },
        "user": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza nome"])}
        }
      }
    }
  })
}
