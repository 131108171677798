export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write in Chat"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number copied"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Editor"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Editor"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace Support"])}
        }
      },
      "ru": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Написать в чате"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона скопирован"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактор школы"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преподаватель"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактор курсов"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка Skillspace"])}
        }
      },
      "es": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribir en el chat"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono copiado"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor de la escuela"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor de cursos"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte de Skillspace"])}
        }
      },
      "kk": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чатқа жазу"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон нөмірі көшірілді"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мектеп редакторы"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұғалім"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстар редакторы"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкімші"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иесі"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace қолдауы"])}
        }
      },
      "uz": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatga yozish"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon raqami ko‘chirilgan"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maktab tahrirlovchisi"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O‘qituvchi"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurslar tahrirlovchisi"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egasi"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace qo‘llab-quvvatlash"])}
        }
      },
      "de": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Chat schreiben"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer kopiert"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulredakteur"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursredakteur"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace-Support"])}
        }
      },
      "fr": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrire dans le chat"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone copié"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de l'école"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignant"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de cours"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Skillspace"])}
        }
      },
      "it": {
        "employee": {
          "writeToChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi in chat"])},
          "phoneCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono copiato"])}
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore della scuola"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insegnante"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore dei corsi"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietario"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza Skillspace"])}
        }
      }
    }
  })
}
