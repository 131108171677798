var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { convertToFormData, convertToQueryString, generateUuid } from '@common/utils/Helpers/Helpers';
import { ApiCommonClass } from '@common/api/apiCommon';
import axios from 'axios';
import { httpClientApiSchool } from '@common/utils/HttpClient';
var Api = /** @class */ (function (_super) {
    __extends(Api, _super);
    function Api() {
        return _super.call(this) || this;
    }
    /**
     * School
     */
    /**
     * Обновление параметров школы
     *
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateSchool = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/school/update", data);
    };
    /**
     * Получить кол-во непроверенных домашек
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getUncheckedHomeworksCount = function () {
        return httpClientApiSchool.get('/counts?entities[]=uncheckedHomeworks')
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Course Categories
     */
    /**
     * Получить список категорий курсов
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getCourseCategories = function () {
        return httpClientApiSchool.get("/course/category/list")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Создать категорию курсов
     *
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.createCategory = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/category/create", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Сортировка категорий курсов
     *
     * @param {any} data Список категорий
     * @returns {Promise<any>}
     */
    Api.prototype.sortCategories = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/category/sort", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновить категорию
     *
     * @param {number} categoryId ID категории
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateCategory = function (categoryId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/category/".concat(categoryId, "/update"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Удалить категорию курсов
     *
     * @param {number} categoryId ID категории
     * @returns {Promise<any>}
     */
    Api.prototype.deleteCategory = function (categoryId) {
        return httpClientApiSchool.delete("/course/category/".concat(categoryId, "/remove"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Добавить курс в категорию
     *
     * @param {number} categoryId ID категории
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.categoryAddCourse = function (categoryId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("course/category/".concat(categoryId, "/course-add"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Перенести курс из категории
     *
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.categoryMoveCourse = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/category/course-move", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Сортировать курсы внутри категории
     *
     * @param {number} categoryId ID категории
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.categorySortCourses = function (categoryId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/category/".concat(categoryId, "/course-sort"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * School course
     */
    /**
     * Создать курс
     *
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.createCourse = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/create", data);
    };
    /**
     * Получить курс
     *
     * @param {number} courseId ID курса
     * @returns {Promise<any>}
     */
    Api.prototype.getCourse = function (courseId) {
        return httpClientApiSchool.get("/course/".concat(courseId));
    };
    /**
     * Получить курс по слагу
     *
     * @param {number} slug слаг курса
     * @returns {Promise<any>}
     */
    Api.prototype.getCourseSlug = function (slug) {
        return httpClientApiSchool.get("/course-landing/".concat(slug));
    };
    /**
     * Обновление настроек курса
     *
     * @param {number} courseId ID курса
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateCourse = function (courseId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/".concat(courseId, "/update"), data);
    };
    /**
     * Удаление курса
     *
     * @param {number} courseId ID курса
     * @returns {Promise<any>}
     */
    Api.prototype.deleteCourse = function (courseId) {
        return httpClientApiSchool.delete("/course/".concat(courseId, "/remove"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Клонирование курса
     *
     * @param {number} courseId ID курса
     * @returns {Promise<any>}
     */
    Api.prototype.cloneCourse = function (courseId) {
        return httpClientApiSchool.post("/course/".concat(courseId, "/clone"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Получение списка курсов
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getCourses = function () {
        return httpClientApiSchool.get("/course/list");
    };
    /**
     * Сортировка модулей внутри курса
     *
     * @param {number} courseId ID курса
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.courseStepSort = function (courseId, data) {
        data = convertToFormData({ steps: JSON.stringify(data.steps), });
        return httpClientApiSchool.post("/course/".concat(courseId, "/step/sort"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Step
     */
    /**
     * Создание модуля внутри курса
     *
     * @param {number} courseId ID курса
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.addCourseStep = function (courseId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/course/".concat(courseId, "/step/create"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Получить список модулей курса
     *
     * @param {number} courseId ID курса
     * @returns {Promise<any>}
     */
    Api.prototype.getCourseSteps = function (courseId) {
        return httpClientApiSchool.get("/course/".concat(courseId, "/step/list"));
    };
    /**
     * Получить модуль курса
     *
     * @param {number} stepId ID модуля курса
     * @returns {Promise<any>}
     */
    Api.prototype.getStep = function (stepId) {
        return httpClientApiSchool.get("/step/".concat(stepId))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновить модуль курса
     *
     * @param {number} stepId ID модуля курса
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateStep = function (stepId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/step/".concat(stepId, "/update"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Удаление модуля курса
     *
     * @param {number} stepUuid ID модуля курса
     * @returns {Promise<any>}
     */
    Api.prototype.deleteStep = function (stepUuid) {
        return httpClientApiSchool.delete("/step/".concat(stepUuid, "/remove"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Клонировать занятие в этап
     *
     * @param {string} originalStepUuid Uuid занятия
     * @param {string} courseId Uuid курса
     * @param {number} order Порядок внутри этапа для занятия
     * @returns {Promise<any>}
     */
    Api.prototype.cloneStep = function (originalStepUuid, courseId, order) {
        var data = convertToFormData({
            course: courseId,
            order: order
        });
        return httpClientApiSchool.post("/step/".concat(originalStepUuid, "/clone"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Добавить занятие в модуль курса
     *
     * @param {string} stepUuid uuid модуля курса
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.addStepLesson = function (stepUuid, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/step/".concat(stepUuid, "/lesson/create"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Сортировка занятий внутри модуля курса
     *
     * @param {number} stepUuid ID модуля курса
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.stepLessonSort = function (stepUuid, data) {
        data = convertToFormData({ lessons: JSON.stringify(data.lessons), });
        return httpClientApiSchool.post("/step/".concat(stepUuid, "/lesson/sort"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Lesson
     */
    /**
     * Получить занятие
     *
     * @param {number} lessonId ID занятия
     * @returns {Promise<any>}
     */
    Api.prototype.getLesson = function (lessonId) {
        return __awaiter(this, void 0, void 0, function () {
            var axiosSource, requestUuid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        axiosSource = axios.CancelToken.source();
                        requestUuid = generateUuid();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this.setRequestAbort('getLesson', axiosSource, requestUuid);
                        return [4 /*yield*/, httpClientApiSchool.get("/lesson/".concat(lessonId), { cancelToken: axiosSource.token })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        this.deleteRequestAbort('getLesson', requestUuid);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Обновить занятие
     *
     * @param {number} lessonId ID занятия
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateLesson = function (lessonId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/lesson/".concat(lessonId), data);
    };
    /**
     * Опубликовать занятие
     *
     * @param {number} lessonUuid ID занятия
     * @returns {Promise<any>}
     */
    Api.prototype.publishLesson = function (lessonUuid) {
        return httpClientApiSchool.post("/lesson/".concat(lessonUuid, "/publish"));
    };
    /**
     * Удалить занятие
     *
     * @param {number} lessonId ID занятия
     * @returns {Promise<any>}
     */
    Api.prototype.deleteLesson = function (lessonId) {
        return httpClientApiSchool.delete("/lesson/".concat(lessonId))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Клонировать занятие в этап
     *
     * @param {string} originalLessonUuid Uuid занятия
     * @param {string} stepUuid Uuid этапа
     * @param {number} order Порядок внутри этапа для занятия
     * @returns {Promise<any>}
     */
    Api.prototype.cloneLesson = function (originalLessonUuid, stepUuid, order) {
        var data = convertToFormData({
            step: stepUuid,
            order: order
        });
        return httpClientApiSchool.post("/lesson/".concat(originalLessonUuid, "/clone"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Lesson Pages
     */
    /**
     * Создание страницы внутри занятия
     *
     * @param {number} lessonUuid UUUID занятия
     * @returns {Promise<any>}
     */
    Api.prototype.createLessonPage = function (lessonUuid) {
        return httpClientApiSchool.post("/lesson/".concat(lessonUuid, "/page/create"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновление контента страницы занятия
     *
     * @param {number} pageUuid UUUID страницы занятия
     * @param {any} data Информация о модуле
     * @returns {Promise<any>}
     */
    Api.prototype.updateLessonPage = function (pageUuid, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/lesson/page/".concat(pageUuid, "/update"), data);
    };
    /**
     * Сортировка страниц занятия
     *
     * @param {string} lessonUuid Uuid занятия
     * @param {Array} order Список страниц
     * @returns {Promise<any>}
     */
    Api.prototype.sortLessonPages = function (lessonUuid, order) {
        var data = convertToFormData({
            order: order
        });
        return httpClientApiSchool.post("/lesson/".concat(lessonUuid, "/page/sort"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновление контента страницы занятия
     *
     * @param {number} pageUuid Uuid страницы занятия
     * @returns {Promise<any>}
     */
    Api.prototype.deleteLessonPage = function (pageUuid) {
        return httpClientApiSchool.delete("/lesson/page/".concat(pageUuid, "/remove"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Lesson Modules
     */
    /**
     * Создание модуля внутри занятия
     *
     * @param {number} lessonId ID занятия
     * @param {any} data Информация о модуле
     * @returns {Promise<any>}
     */
    Api.prototype.addLessonModule = function (lessonId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/lesson/".concat(lessonId, "/module/create"), data);
    };
    /**
     * Сортировка модулей внутри занятия
     *
     * @param {number} lessonId ID занятия
     * @param {any} data Порядок сортировки модулей
     * @returns {Promise<any>}
     */
    Api.prototype.lessonModuleSort = function (lessonId, data) {
        data = convertToFormData({ modules: JSON.stringify(data.modules), });
        return httpClientApiSchool.post("/lesson/".concat(lessonId, "/module/sort"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновить модуль занятия
     *
     * @param {number} lessonModuleUuid ID модуля
     * @param {any} data Порядок сортировки модулей
     * @param {Function} onProgress Функция для вызова во время прогресса загрузки
     * @returns {Promise<any>}
     */
    Api.prototype.updateLessonModule = function (lessonModuleUuid, data, onProgress) {
        data = convertToFormData(data);
        var config = {
            onUploadProgress: function (e) {
                var percentCompleted = Math.round((e.loaded * 100) / e.total);
                if (onProgress) {
                    onProgress(percentCompleted);
                }
            },
        };
        return httpClientApiSchool.post("/lesson-module/".concat(lessonModuleUuid, "/update"), data, config)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Удаление модуля занятия
     *
     * @param {number} moduleId ID модуля
     * @returns {Promise<any>}
     */
    Api.prototype.deleteLessonModule = function (moduleId) {
        return httpClientApiSchool.delete("/lesson-module/".concat(moduleId, "/remove"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Lesson Webinar
     */
    /**
     * Обновить группы вебинара
     *
     * @param {number} lessonId ID занятия
     * @param {any} data Данные
     * @returns {Promise<any>}
     */
    Api.prototype.updateWebinarFlows = function (lessonId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/webinar/".concat(lessonId, "/update-flows"), data)
            .then(function (res) {
            var data = res.data;
            if (data.status === 'success')
                return data;
            return data;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * STUDENTS
     */
    /**
     * Получить список студентов
     *
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.getStudents = function (_a) {
        var _b = _a.offset, offset = _b === void 0 ? 0 : _b, _c = _a.search, search = _c === void 0 ? '' : _c, _d = _a.limit, limit = _d === void 0 ? 20 : _d, students = _a.students, _e = _a.sortField, sortField = _e === void 0 ? 'name' : _e, _f = _a.customSortField, customSortField = _f === void 0 ? '' : _f, _g = _a.sortOrder, sortOrder = _g === void 0 ? 'asc' : _g, // asc, desc
        filters = _a.filters;
        var paramsData = {
            offset: offset,
            limit: limit,
            search: search,
            sortField: sortField,
            customSortField: customSortField,
            sortOrder: sortOrder,
            filters: filters
        };
        if (students) {
            paramsData['students'] = students;
        }
        var params = convertToQueryString(paramsData);
        return httpClientApiSchool.get('students/list?' + params.toString());
    };
    /**
     * Список курсов студентов
     *
     * @returns {Promise<AxiosResponse<CourseEntity[]>>}
     */
    Api.prototype.getStudentsCourses = function () {
        return httpClientApiSchool.get('students/courses');
    };
    /**
     * Список групп студентов
     *
     * @returns {Promise}
     */
    Api.prototype.getStudentsGroups = function () {
        return httpClientApiSchool.get('group/list');
    };
    /**
     * Перенести студента в другую группу
     *
     * @param {number} userId ID студента
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.changeStudentGroup = function (userId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool
            .post("students/user/".concat(userId, "/change-group"), data)
            .then(function (res) {
            return res.data;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Уволить ученика с курса
     *
     * @param {number} userId ID ученика
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.expelStudentFromCourse = function (userId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("students/user/".concat(userId, "/expel"), data)
            .then(function (res) {
            return res.data;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Пригласить новых учеников на курсы
     *
     * @param {any} courses Список курсов
     * @param {any} newStudents Список студентов
     * @param type
     * @returns {Promise<any>}
     */
    Api.prototype.inviteNewStudents = function (type, courses, newStudents) {
        var data = convertToFormData({
            courses: courses,
            newStudents: newStudents,
            type: type,
        });
        return httpClientApiSchool.post('students/invite', data);
    };
    /**
     * Пригласить учеников на другой курс
     *
     * @param {object} students {
     *          filter: 'user'|'group'|'course'|'all',
     *          filterValue: Array<Number>,
     *          filterExceptUsers: Array<Number>
     *      }
     * @param {object} filters {comment: ..., courses: ...}
     * @param {Array<number>} courses Список ID курсов
     * @returns {Promise<any>}
     */
    Api.prototype.inviteStudents = function (students, filters, courses) {
        var data = convertToFormData({
            students: students,
            filters: filters,
            courses: courses
        });
        return httpClientApiSchool.post('students/invite', data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Удаление учеников с курса
     *
     * @param {object} students {
     *          filter: 'user'|'group'|'course'|'all',
     *          filterValue: Array<Number>,
     *          filterExceptUsers: Array<Number>
     *      }
     * @param {object} filters {comment: ..., courses: ...}
     * @param {number} courseId ID курса
     * @returns {Promise<any>}
     */
    Api.prototype.expelStudents = function (students, filters, courseId) {
        var data = convertToFormData({
            students: students,
            filters: filters,
            courseId: courseId
        });
        return httpClientApiSchool.post('students/expel', data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Экспорт выбранных учеников
     *
     * @param {object} students {
     *          filter: 'user'|'group'|'course'|'all',
     *          filterValue: Array<Number>,
     *          filterExceptUsers: Array<Number>
     *      }
     * @param {object} filters {comment: ..., courses: ...}
     * @param detailedProgress
     * @returns {Promise<any>}
     */
    Api.prototype.exportStudents = function (students, filters, detailedProgress) {
        if (detailedProgress === void 0) { detailedProgress = false; }
        var params = convertToQueryString({
            students: students,
            filters: filters,
            detailedProgress: detailedProgress
        });
        return httpClientApiSchool.get('students/export?' + params);
    };
    /**
     * Изменить группу выбранным ученикам
     *
     * @param {object} students {
     *          filter: 'user'|'group'|'course'|'all',
     *          filterValue: Array<Number>,
     *          filterExceptUsers: Array<Number>
     *      }
     * @param {object} filters {comment: ..., courses: ...}
     * @param {number} groupToId ID группы в которую перенести учеников
     * @returns {Promise<any>}
     */
    Api.prototype.changeStudentsGroup = function (students, filters, groupToId) {
        var data = convertToFormData({
            students: students,
            filters: filters,
            groupToId: groupToId
        });
        return httpClientApiSchool.post('students/change-group', data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Получить инфу по ученику
     *
     * @param {number} userId ID ученика
     * @returns {Promise<any>}
     */
    Api.prototype.getStudent = function (userId) {
        return httpClientApiSchool.get('students/user/' + userId);
    };
    /**
     * Повторная отправка уведомления студенту с ссылкой на регистрацию
     *
     * @param {number} userId ID ученика
     * @param {boolean} [regenerateUrl] перегенерация ссылки
     * @returns {Promise<any>}
     */
    Api.prototype.sendRegisterUrl = function (userId, regenerateUrl) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, httpClientApiSchool.post("students/user/".concat(userId, "/send-register-url"), { regenerateUrl: regenerateUrl })];
            });
        });
    };
    /**
     * Изменить инфу ученика
     *
     * @param {number} userId ID ученика
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateStudent = function (userId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post('students/user/' + userId, data);
    };
    /**
     * Получить результаты теста занятия
     *
     * @param {number} lessonId ID занятия
     * @param {number} userId ID пользователя
     * @returns {Promise<any>}
     */
    Api.prototype.getStudentLessonTestResults = function (lessonId, userId) {
        return httpClientApiSchool.get("/lesson/".concat(lessonId, "/test/results?userId=").concat(userId));
    };
    /**
     * Сбросить попытки студента
     *
     * @param {number} lessonId ID занятия
     * @param {number} userId ID пользователя
     * @returns {Promise<any>}
     */
    Api.prototype.resetStudentAttempts = function (lessonId, userId) {
        var data = convertToFormData({ updateAttemptAppend: true });
        return httpClientApiSchool.post("/lesson/".concat(lessonId, "/student-options/").concat(userId, "/test"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Получить кастомные поля ученика
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getStudentCustomFields = function () {
        return httpClientApiSchool.get('user-fields/list');
    };
    /**
     * Создать кастомное поле ученика
     *
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.createStudentCustomField = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post('user-fields/create', data);
    };
    /**
     * Обновить кастомное поле ученика
     *
     * @param  {any} data Информация
     * @param {string} fieldId ID поля
     * @returns {Promise<any>}
     */
    Api.prototype.updateStudentCustomField = function (data, fieldId) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("user-fields/".concat(fieldId, "/update"), data);
    };
    /**
     * Удалить кастомное поле ученика
     *
     * @param {number} fieldId ID поля
     * @returns {Promise<any>}
     */
    Api.prototype.deleteStudentCustomField = function (fieldId) {
        return httpClientApiSchool.delete("user-fields/".concat(fieldId, "/remove"))
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Сортировка кастомных полей в школе
     *
     * @param {any} order Новый порядок
     * @returns {Promise<any>}
     */
    Api.prototype.sortCustomFields = function (order) {
        var data = convertToFormData({ order: order });
        return httpClientApiSchool.post("user-fields/sort/student", data)
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Сортировка кастомных полей в лендинге
     *
     * @param {any} order Новый порядок
     * @param {string} landingUUID ID лендинга
     * @returns {Promise<any>}
     */
    Api.prototype.sortLandingCustomFields = function (order, landingUUID) {
        var data = convertToFormData({ order: order });
        return httpClientApiSchool.post("user-fields/sort/landing/".concat(landingUUID), data)
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Создать группу учеников
     *
     * @param {number} courseId ID группы
     * @param {string} name Название группы
     * @returns {Promise<any>}
     */
    Api.prototype.createGroup = function (courseId, name) {
        var data = convertToFormData({
            courseId: courseId,
            name: name
        });
        return httpClientApiSchool.post('group/create', data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновить группу учеников
     *
     * @param {number} groupId ID группы
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateGroup = function (groupId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("group/".concat(groupId, "/update"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Удалить группу учеников
     *
     * @param {number} groupId ID группы
     * @returns {Promise<any>}
     */
    Api.prototype.deleteGroup = function (groupId) {
        return httpClientApiSchool.delete("group/".concat(groupId, "/remove"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Recurrent Cards
     */
    /**
     * Получить список привязанных карт
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getPaymentCards = function () {
        return httpClientApiSchool.get("/payment/recurrent/card/list")
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Привязать карту
     *
     * @returns {Promise<any>}
     */
    Api.prototype.addRecurrentCard = function () {
        return httpClientApiSchool.post("/payment/recurrent/card/create")
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Удалить привязанную карту
     *
     * @param {string} cardUUID UUID карты
     * @returns {Promise<any>}
     */
    Api.prototype.deleteRecurrentCard = function (cardUUID) {
        return httpClientApiSchool.delete("/payment/recurrent/card/".concat(cardUUID, "/remove"))
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Управление подпиской
     */
    /**
     * Включить автоплатеж
     *
     * @returns {Promise<any>}
     */
    Api.prototype.enableSubscriptionRecurrent = function () {
        return httpClientApiSchool.post("/subscription/recurrent/enable")
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Отключить автоплатеж
     *
     * @returns {Promise<any>}
     */
    Api.prototype.disableSubscriptionRecurrent = function () {
        return httpClientApiSchool.post("/subscription/recurrent/disable")
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Повторить автоматическую оплату в случае неудачи
     *
     * @returns {Promise<any>}
     */
    Api.prototype.retryRecurrentCharge = function () {
        return httpClientApiSchool.post("/subscription/recurrent/recharge")
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Получить транзакцию последней попытки списания
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getLastRecurrentTransaction = function () {
        return httpClientApiSchool.get("/subscription/recurrent/last-transaction")
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * PLANS
     */
    /**
     * Проверка на возможность смены тарифа
     *
     * @param {number} id id тарифного плана
     * @param {boolean} subscription
     * @returns {Promise<any>}
     */
    Api.prototype.canChangePlan = function (id, subscription) {
        if (subscription === void 0) { subscription = false; }
        var params = {
            id: id,
            subscription: subscription
        };
        return httpClientApiSchool.get("/payment/can-change-plan", { params: params })
            .then(function (res) {
            return res;
        })
            .catch(function (err) {
            throw err;
        });
    };
    /**
     * Смена тарифа
     *
     * @param {number} planId ID плана
     * @param {boolean} trial пробный период
     * @returns {Promise<any>}
     */
    Api.prototype.changePlan = function (planId, trial) {
        var data = convertToFormData({ trial: trial });
        return httpClientApiSchool.post("/subscription/change-plan/".concat(planId), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Api.prototype.getPlans = function () {
        return httpClientApiSchool.get("/plans")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Api.prototype.getPlanPaymentLink = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/payment/plan", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Применить промокод
     *
     * @param {string} coupon
     * @returns {Promise<any>}
     */
    Api.prototype.getDiscount = function (coupon) {
        coupon = convertToFormData({ coupon: coupon });
        return httpClientApiSchool.post("/subscription/discount/apply", coupon)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Сбросить скидку
     *
     * @returns {Promise<any>}
     */
    Api.prototype.clearDiscount = function () {
        return httpClientApiSchool.post("/subscription/discount/clear")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * School Requisites
     */
    /**
     * Создать реквизиты школы
     *
     * @param {any} data Новые реквизиты
     * @returns {Promise<any>}
     */
    Api.prototype.addSchoolRequisites = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/requisites/create", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Api.prototype.getSchoolRequisites = function () {
        return httpClientApiSchool.get("/requisites/current")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Limits
     */
    Api.prototype.getLimitsUsage = function () {
        return httpClientApiSchool.get("/limit/usages")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * School User Settings
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getSchoolUserSettings = function () {
        return httpClientApiSchool.get("/settings/school-user")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Api.prototype.setSchoolUserSettings = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/settings/school-user/update", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * School Settings
     */
    Api.prototype.setSchoolSettings = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/settings/school/update", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Public API Tokens
     */
    /**
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getPublicApiTokens = function () {
        return httpClientApiSchool.get("/public-token/list")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     *
     * @returns {Promise<any>}
     */
    Api.prototype.createPublicApiToken = function () {
        return httpClientApiSchool.post("/public-token/create")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * @param {number} tokenId
     * @returns {Promise<any>}
     */
    Api.prototype.deletePublicApiToken = function (tokenId) {
        var data = convertToFormData({ tokenId: tokenId, });
        return httpClientApiSchool.post("/public-token/remove", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Onboarding
     */
    /**
     * @param {string} tutorialCode
     * @returns {Promise<any>}
     */
    Api.prototype.addCompletedTutorial = function (tutorialCode) {
        var data = convertToFormData({ tutorialCode: tutorialCode, });
        return httpClientApiSchool.post("/onboarding/complete-tutorial", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * @param {string[]} tutorialCodes
     * @returns {Promise<any>}
     */
    Api.prototype.addCompletedTutorials = function (tutorialCodes) {
        var data = convertToFormData({ tutorialCodes: tutorialCodes, });
        return httpClientApiSchool.post("/onboarding/complete-tutorials", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * @returns {Promise<any>}
     */
    Api.prototype.restartTutorials = function () {
        var data = convertToFormData();
        return httpClientApiSchool.post("/onboarding/restart-tutorials", data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Gateways
     */
    /**
     * Получить список платёжек
     *
     * @returns {Promise<any>}
     */
    Api.prototype.getSchoolGateways = function () {
        return httpClientApiSchool.get("/gateway/list")
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Изменить платёжку
     *
     * @param {string} gatewayId
     * @param {string} name Название
     * @param {string[]} fields Поля платёжки
     * @returns {Promise<any>}
     */
    Api.prototype.editSchoolGateway = function (gatewayId, name, fields) {
        var data = convertToFormData({
            name: name,
            fields: fields,
        });
        return httpClientApiSchool.post("/gateway/".concat(gatewayId, "/update"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Добавить платёжку
     *
     * @param {string} type Тип
     * @param {string} name Название
     * @param {string[]} fields Поля платёжки
     * @returns {Promise<any>}
     */
    Api.prototype.addSchoolGateway = function (type, name, fields) {
        var data = convertToFormData({
            type: type,
            name: name,
            fields: fields,
        });
        return httpClientApiSchool.post("/gateway/create", data);
    };
    /**
     * Удалить платёжку
     *
     * @param {string} gatewayId
     * @returns {Promise<any>}
     */
    Api.prototype.deleteSchoolGateway = function (gatewayId) {
        return httpClientApiSchool.delete("/gateway/".concat(gatewayId, "/remove"));
    };
    /**
     * Работа с лендингами
     */
    /**
     * Обновить поля лендинга
     *
     * @param {string} landingId
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateLanding = function (landingId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/landing/".concat(landingId), data);
    };
    /**
     * Опубликовать изменения на лендинге
     *
     * @param {string} landingId
     * @returns {Promise<any>}
     */
    Api.prototype.publishLanding = function (landingId) {
        return httpClientApiSchool.post("/landing/".concat(landingId, "/publish"))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновить поля блока лендинга
     *
     * @param {string} blockId
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateLandingBlock = function (blockId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/landing/block/".concat(blockId), data);
    };
    /**
     * Добавить карточку для блока лендинга
     *
     * @param {string} blockId
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.createLandingBlockCard = function (blockId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/landing/block/".concat(blockId, "/card"), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновить поля карточки блока лендинга
     *
     * @param {string} cardId
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    Api.prototype.updateLandingBlockCard = function (cardId, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/landing/card/".concat(cardId), data)
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновить поля карточки блока лендинга
     *
     * @param {string} cardId
     * @returns {Promise<any>}
     */
    Api.prototype.deleteLandingBlockCard = function (cardId) {
        return httpClientApiSchool.delete("/landing/card/".concat(cardId))
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Получение удаленных занятий
     *
     * @param {string} courseUuid
     * @returns {Promise<AxiosResponse<GetResponse<GetTrashedLessonsDto>>>}
     */
    Api.prototype.getTrashedLessons = function (courseUuid) {
        return httpClientApiSchool.get("course/".concat(courseUuid, "/trash"));
    };
    /**
     *  Восстановление урока
     *
     * @param {string} lessonUuid
     * @returns {Promise<AxiosResponse<GetResponse<RestoreLessonsDto>>>}
     */
    Api.prototype.restoreLessons = function (lessonUuid) {
        return httpClientApiSchool.post("lesson/".concat(lessonUuid, "/restore"));
    };
    /**
     * Всстановление шага
     *
     * @param {string} stepUuid
     * @returns {Promise<AxiosResponse<GetResponse<RestoreStepDto>>>}
     */
    Api.prototype.restoreStep = function (stepUuid) {
        return httpClientApiSchool.post("step/".concat(stepUuid, "/restore"));
    };
    /**
     * Получение списка версий занятий
     *
     * @param {string} lessonUuid
     * @returns {Promise<AxiosResponse<any>>}
     */
    Api.prototype.getLessonHistory = function (lessonUuid) {
        return httpClientApiSchool.get("lesson/".concat(lessonUuid, "/history"));
    };
    /**
     * Получение списка версии занятия
     *
     * @param {string} versionUuid
     * @returns {Promise<AxiosResponse<any>>}
     */
    Api.prototype.getLessonVersion = function (versionUuid) {
        return httpClientApiSchool.get("lesson/history/".concat(versionUuid));
    };
    /**
     * Восстановление версии занятия
     *
     * @param {string} versionUuid
     * @returns {Promise<AxiosResponse<any>>}
     */
    Api.prototype.restoreLessonVersion = function (versionUuid) {
        return httpClientApiSchool.post("lesson/history/".concat(versionUuid, "/restore"));
    };
    /**
     * Получение списков тех, кто будет иметь доступ к занятиям курса
     *
     * @param {string} courseUuid
     * @returns {Promise<AxiosResponse<GetResponse<GetPrePublishedConfigurationDto>>>}
     */
    Api.prototype.getPrePublishedConfiguration = function (courseUuid) {
        return httpClientApiSchool.get("course/".concat(courseUuid, "/access/pre-published-configuration"));
    };
    /**
     * Установка доступов при публикации занятия
     *
     * @param {string} lessonUuid
     * @param {GetRequest<SetPublishedConfigurationDto>} data
     * @returns {Promise<AxiosResponse<GetResponse<SetPublishedConfigurationDto>>>}
     */
    Api.prototype.setPublishedConfiguration = function (lessonUuid, data) {
        return httpClientApiSchool.post("lesson/".concat(lessonUuid, "/access/published-configuration"), {
            groups: data.groups.length > 0 ? data.groups : [''],
            accessType: data.accessType,
            users: data.users.length > 0 ? data.users : [''],
        });
    };
    /**
     * Перемещение занятия в черновик
     *
     * @param {string} uuid
     * @returns {Promise<AxiosResponse<GetResponse<LessonUnPublishedDto>>>}
     */
    Api.prototype.lessonUnPublished = function (uuid) {
        return httpClientApiSchool.post("lesson/".concat(uuid, "/hide"));
    };
    /**
     * Публикация всех занятий в модуле
     *
     * @param {string} uuid
     * @param {GetRequest<StepPublishDto>} data
     * @returns {Promise<AxiosResponse<GetResponse<StepPublishDto>>>}
     */
    Api.prototype.stepPublish = function (uuid, data) {
        return httpClientApiSchool.post("step/".concat(uuid, "/publish-lessons"), data);
    };
    /**
     * Публикация всех занятий в курсе
     *
     * @param {string} uuid
     * @param {GetRequest<CoursePublishDto>} data
     * @returns {Promise<AxiosResponse<GetResponse<CoursePublishDto>>>}
     */
    Api.prototype.coursePublish = function (uuid, data) {
        return httpClientApiSchool.post("course/".concat(uuid, "/publish-lessons"), data);
    };
    /**
     *
     *
     * @param {string} uuid
     * @returns {Promise<AxiosResponse<GetResponse<GetAccessedLessonEmployeesDto>>>}
     */
    Api.prototype.getAccessedLessonEmployees = function (uuid) {
        return httpClientApiSchool.get("school/employee/can-access/lesson/".concat(uuid));
    };
    /**
     *
     *
     * @param {string} uuid
     * @returns {Promise<AxiosResponse<GetResponse<GetAccessedGroupEmployeesDto>>>}
     */
    Api.prototype.getAccessedGroupEmployees = function (uuid) {
        return httpClientApiSchool.get("school/employee/can-access/group/".concat(uuid));
    };
    /**
     *
     *
     * @param {string} uuid
     * @returns {Promise<AxiosResponse<GetResponse<GetAccessedCourseEmployeesDto>>>}
     */
    Api.prototype.getAccessedCourseEmployees = function (uuid) {
        return httpClientApiSchool.get("school/employee/can-access/course/".concat(uuid));
    };
    /**
     * Проверить валидность данных кастомного SMTP школы
     *
     * @returns {Promise<AxiosResponse<GetResponse<GetAccessedCourseEmployeesDto>>>}
     */
    Api.prototype.testCustomSmtp = function () {
        return httpClientApiSchool.post("school/settings/test-custom-smtp");
    };
    return Api;
}(ApiCommonClass));
export default new Api();
