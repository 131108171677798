var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { FileStatusEnum } from '@common/enums/fileStatus.enum';
import { CatalystUpload } from '@common/utils/Catalyst/upload/CatalystUpload';
import { FileIdentify } from '@common/utils/Catalyst/FileIdentify';
import Message from '@common/ui/EMessage/Message';
import { computed, ref, toValue } from 'vue';
import { useChunkedUpload } from '@common/hooks/useChunkedUpload';
import { apiErrorsHandle } from '@common/utils/Helpers/Helpers';
import { ChatApi } from '@common/api/entities/chat';
import { FileUploadNamespace } from '@common/api/infrastructure/fileUpload';
var chatApi = new ChatApi();
export var useChatFileUpload = function (_chatUuid) {
    var chatUuid = computed(function () { return toValue(_chatUuid); });
    var fileWithTypeList = ref([]);
    var catalystUploadList = ref([]);
    var _a = useChunkedUpload(FileUploadNamespace.CHAT_FILE), uploadPartsToS3 = _a.uploadPartsToS3, CHUNK_SIZE = _a.CHUNK_SIZE;
    var createFile = function (catalystUpload) { return __awaiter(void 0, void 0, void 0, function () {
        var file, countChunks, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    file = catalystUpload.getFileCatalyst();
                    countChunks = catalystUpload.getCountChunksOfFile();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, chatApi.createFile(chatUuid.value, {
                            name: file.getName(),
                            size: file.getSize(),
                            mime: file.getMime(),
                            partsCount: countChunks
                        })];
                case 2: return [2 /*return*/, (_a.sent()).data];
                case 3:
                    err_1 = _a.sent();
                    file.setStatus(FileStatusEnum.FAILED);
                    void apiErrorsHandle(err_1, {});
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var uploadFiles = function (uploadFilesWithType) { return __awaiter(void 0, void 0, void 0, function () {
        var filesUploadCurrent, fileScopes, _loop_1, _a, filesUploadCurrent_1, filesUploadCurrent_1_1, e_1_1;
        var _b, e_1, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    fileWithTypeList.value = uploadFilesWithType;
                    return [4 /*yield*/, Promise.all(uploadFilesWithType
                            .filter(function (e) { return e.file.hasStatus(FileStatusEnum.WAITING); })
                            .map(function (e) { return CatalystUpload.Factory(e.file, CHUNK_SIZE); }))];
                case 1:
                    filesUploadCurrent = _e.sent();
                    catalystUploadList.value = __spreadArray(__spreadArray([], __read(catalystUploadList.value), false), __read(filesUploadCurrent), false);
                    fileScopes = [];
                    _e.label = 2;
                case 2:
                    _e.trys.push([2, 8, 9, 14]);
                    _loop_1 = function () {
                        var catalystUpload, file, fileScope, error_1;
                        return __generator(this, function (_f) {
                            switch (_f.label) {
                                case 0:
                                    _d = filesUploadCurrent_1_1.value;
                                    _a = false;
                                    catalystUpload = _d;
                                    file = catalystUpload.getFileCatalyst();
                                    _f.label = 1;
                                case 1:
                                    _f.trys.push([1, 4, , 5]);
                                    file.setStatus(FileStatusEnum.LOADING);
                                    return [4 /*yield*/, createFile(catalystUpload)];
                                case 2:
                                    fileScope = (_f.sent());
                                    file.setIdentify(new FileIdentify(fileScope.file.uuid, fileScope.file.id));
                                    return [4 /*yield*/, uploadPartsToS3(catalystUpload, fileScope.uuid, fileScope.uploadParts)];
                                case 3:
                                    _f.sent();
                                    // _deleteFileScope(fileScope.file.id)
                                    catalystUploadList.value.filter(function (c) { return c !== catalystUpload; });
                                    fileScopes.push(fileScope);
                                    return [3 /*break*/, 5];
                                case 4:
                                    error_1 = _f.sent();
                                    if (error_1 instanceof Error) {
                                        Message.error('При загрузке файла произошла ошибка. Повторите попытку');
                                        console.error(error_1.message);
                                    }
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    };
                    _a = true, filesUploadCurrent_1 = __asyncValues(filesUploadCurrent);
                    _e.label = 3;
                case 3: return [4 /*yield*/, filesUploadCurrent_1.next()];
                case 4:
                    if (!(filesUploadCurrent_1_1 = _e.sent(), _b = filesUploadCurrent_1_1.done, !_b)) return [3 /*break*/, 7];
                    return [5 /*yield**/, _loop_1()];
                case 5:
                    _e.sent();
                    _e.label = 6;
                case 6:
                    _a = true;
                    return [3 /*break*/, 3];
                case 7: return [3 /*break*/, 14];
                case 8:
                    e_1_1 = _e.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 14];
                case 9:
                    _e.trys.push([9, , 12, 13]);
                    if (!(!_a && !_b && (_c = filesUploadCurrent_1.return))) return [3 /*break*/, 11];
                    return [4 /*yield*/, _c.call(filesUploadCurrent_1)];
                case 10:
                    _e.sent();
                    _e.label = 11;
                case 11: return [3 /*break*/, 13];
                case 12:
                    if (e_1) throw e_1.error;
                    return [7 /*endfinally*/];
                case 13: return [7 /*endfinally*/];
                case 14: return [2 /*return*/, fileScopes];
            }
        });
    }); };
    var cancelUploading = function () {
        var e_2, _a;
        try {
            for (var _b = __values(catalystUploadList.value), _c = _b.next(); !_c.done; _c = _b.next()) {
                var fileUpload = _c.value;
                fileUpload.stopUpload();
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        catalystUploadList.value = [];
    };
    var clearFinishedFiles = function () {
        fileWithTypeList.value = fileWithTypeList.value.filter(function (e) { return !e.file.hasStatus(FileStatusEnum.FINISHED); });
    };
    return {
        fileWithTypeList: fileWithTypeList,
        clearFinishedFiles: clearFinishedFiles,
        cancelUploading: cancelUploading,
        uploadFiles: uploadFiles
    };
};
