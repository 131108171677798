import passData from '@common/utils/PassData';
/**
 * Запущен ли фронтенд внутри приложения
 *
 * @returns {boolean}
 */
export var isMobileApp = function () {
    var _a;
    // @ts-ignore
    return window.application && ((_a = window.application) === null || _a === void 0 ? void 0 : _a.isMobileApp());
};
/**
 * Запущен ли фронтенд внутри IOS приложения
 *
 * @returns {boolean}
 */
export var isIOSApp = function () {
    // @ts-ignore
    return isMobileApp() && window.application.operationSystem === 'ios';
};
// Школа для прохождения модерации
export var isIOSModeratorSchool = function () {
    return passData.school.uuid === '862cba5c-bf2d-438b-8305-d4376b8e1af0' || passData.school.uuid === 'fd2d4770-f10e-41ca-80b9-9393bec238db';
};
export var requestApplePayment = function () {
    try {
        window.webkit.messageHandlers.jsHandler.postMessage({
            shouldStartPayment: true,
            projectID: 'testValue02'
        });
    }
    catch (err) {
        console.error(err);
    }
    return new Promise(function (resolve, reject) {
        window.application.consumeSuccessPurchase = function (text) {
            resolve(text);
        };
        window.application.consumeFailedPurchase = function (err, text) {
            console.error(err, text);
            reject();
        };
    });
};
/**
 * Запущен ли фронтенд внутри Android приложения
 * todo: запилить это внутри самого приложения, пока это не реализовано
 *
 * @returns {boolean}
 */
export var isAndroidApp = function () {
    // @ts-ignore
    return isMobileApp() && window.application.operationSystem === 'android';
};
/**
 * Указать мобильному приложению последнюю школу, в которой был авторизован ученик
 *
 * @param {string} slug слаг школы
 * @returns {boolean}
 */
export var setMobileAppSchool = function (slug) {
    if (isMobileApp()) {
        // @ts-ignore
        window.application.setLastAuthorizeSchoolDomain(slug);
    }
};
