export var LessonProgressStatusEnum;
(function (LessonProgressStatusEnum) {
    LessonProgressStatusEnum["REJECTED"] = "REJECTED";
    LessonProgressStatusEnum["COMPLETED"] = "COMPLETED";
    LessonProgressStatusEnum["NOT_COMPLETED"] = "NOT_COMPLETED";
    LessonProgressStatusEnum["BLOCKED"] = "BLOCKED";
    LessonProgressStatusEnum["CHECK"] = "HOMEWORK_CHECK";
    LessonProgressStatusEnum["REWORK"] = "HOMEWORK_REWORK";
    LessonProgressStatusEnum["WEBINAR_GOING"] = "WEBINAR_GOING";
})(LessonProgressStatusEnum || (LessonProgressStatusEnum = {}));
