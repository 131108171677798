export var HomeworkStatus;
(function (HomeworkStatus) {
    HomeworkStatus["CLOSED_GOOD"] = "closed_good";
    HomeworkStatus["STUDENT_SENT"] = "student_sent";
    HomeworkStatus["AUTOACCEPT_PENDING"] = "autoaccept_pending";
    HomeworkStatus["AUTOACCEPT_ACCEPTED"] = "autoaccept_accepted";
    HomeworkStatus["TEACHER_SENT"] = "teacher_sent";
    HomeworkStatus["CLOSED_BAD"] = "closed_bad";
    HomeworkStatus["ALL"] = "all";
})(HomeworkStatus || (HomeworkStatus = {}));
