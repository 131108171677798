var _a;
import mobile from 'is-mobile';
// @ts-ignore
var platform = ((_a = navigator === null || navigator === void 0 ? void 0 : navigator.userAgentData) === null || _a === void 0 ? void 0 : _a.platform) || (navigator === null || navigator === void 0 ? void 0 : navigator.platform) || 'unknown';
/**
 * Открыт ли браузер IE
 *
 * @returns {boolean}
 */
export function isIE() {
    return navigator.userAgent.includes('MSIE');
}
/**
 * Открыт ли браузер Edge
 *
 * @returns {boolean}
 */
export function isEdge() {
    // @ts-ignore
    return document.documentMode || /Edge/.test(navigator.userAgent);
}
/**
 * Открыт ли браузер Firefox
 *
 * @returns {boolean}
 */
export function isFirefox() {
    return navigator.userAgent.toLowerCase().includes('firefox');
}
/**
 * Открыт ли браузер Safari
 *
 * @returns {boolean}
 */
export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
/**
 *
 */
export function isMobile() {
    return mobile({ tablet: true });
}
/**
 * Запущен ли браузер в MacOS
 *
 * @returns {boolean}
 */
export function isMacOS() {
    return platform.toLowerCase().includes('mac');
}
/**
 * Запущен ли браузер в Windows
 *
 * @returns {boolean}
 */
export function isWindowsOS() {
    return platform.toLowerCase().includes('mac');
}
/**
 *
 */
export function isTouchable() {
    return window.matchMedia('(pointer: coarse)').matches;
}
