import { useEventBus } from '@vueuse/core';
import { onBeforeUnmount } from 'vue';
import { EventBusEnum } from '@common/enums/eventBus.enum';
var busSocket = useEventBus(EventBusEnum.WEBSOCKET_MESSAGE);
export var useCommonWebsocket = function (event, cb) {
    var listener = function (e, payload) {
        if (e === event) {
            cb(payload, e);
        }
    };
    busSocket.on(listener);
    onBeforeUnmount(function () {
        busSocket.off(listener);
    });
};
