export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice message"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])}
        }
      },
      "ru": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображения"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовое сообщение"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчера"])}
        }
      },
      "es": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje de voz"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayer"])}
        }
      },
      "kk": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурет"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суреттер"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дауысты хабарлама"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кеше"])}
        }
      },
      "uz": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fayl"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rasm"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rasmlar"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovozli xabar"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kecha"])}
        }
      },
      "de": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachnachricht"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestern"])}
        }
      },
      "fr": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message vocal"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])}
        }
      },
      "it": {
        "chat": {
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])},
          "voiceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio vocale"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieri"])}
        }
      }
    }
  })
}
