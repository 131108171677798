var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import analytics from '@common/utils/Analytics/Analytics';
import { createResolvedPromise } from '@common/utils/Promise/Promise';
import { defineStore } from 'pinia';
import Api from '@school/modules/api/api';
import { LessonTypeEnum } from '@common/enums/lessonType.enum';
import { getLessonStatus } from '@common/utils/GetLessonStatus';
export var useStudentsStore = defineStore('students', {
    state: function () { return ({
        // Список учеников
        // Не синхроним, потому что здесь загрузка при скролле и нормально их не ссинхронишь, плюс
        // можно потеряться в списке
        students: [],
        // Список курсов школ
        // По-хорошему это объединить с курсами в разделе "курсы школы"
        // @sync
        courses: [],
        // Группы учеников
        // @sync
        groups: [],
        // Состояние загрузки групп
        groupsLoading: false,
        // Ученик, карточка которого открыта
        // @sync - нужно синхронить с одинаковыми id
        student: null,
        // Дополнительные фильтры для выделенных учеников
        selectedStudentsSubfilters: {},
        // Метрики аналитические
        analytics: {},
        // Колонки в таблице учеников
        // @sync
        studentsListColumns: [],
        // Открытый раздел списка студентов курсов
        listLevel: 'school',
        // id открытого курса на странице "Ученики и аналитика"
        listLevelCourseId: null,
        // id открытой группы на странице "Ученики и аналитика"
        listLevelGroupId: null,
        // Колонки в таблице дз
        // @sync
        studentsHomeworkListColumns: [],
        // Состояние загрузки метрик
        analyticsLoading: false,
        // Состояние загрузки списка студентов
        studentsLoading: false,
        // Сколько учеников подгружать с бэкенда при подгрузке и первой загрузке
        limit: 20,
        // Поисковый запрос для фильтра списка учеников
        searchQuery: '',
        // Поле по которому сортировать список учеников
        sortField: 'user_name',
        // Порядок сортировки (asc - от меньшего, desc - от большего)
        sortOrder: 'asc',
        // id кастомного поля для сортировки (передаем при sort_field === 'custom')
        customSortField: '',
        // Кол-во учеников всего в текущем фильтре (включая тех кто еще не загружен)
        totalCount: 0,
        //Выбранные студенты
        storeSelectedStudent: [],
        // Настройки открытой группы
        selectedGroupSettings: null,
        // Состояние загрузки ученика
        studentLoading: false,
        // Описание фильтра для выборки выделенных учеников
        selectedStudentsFilter: null,
        // Отображать окно приглашения существующих учеников
        inviteStudentsVisible: false,
        // Отображать окно приглашения новых учеников
        inviteNewStudentsVisible: false,
        // Флаг для сброса формы, мини-костыль чтобы не создавать несколько ref на три уровня родителей
        inviteNewStudentsClear: false,
        // Отображать окно смены группы для ученика
        changeStudentsGroupVisible: false,
        // Курс, внутри которого перемещается ученик
        changeStudentsGroupCourse: null,
        // Отображать карточку ученика
        studentProfileVisible: false,
        // Окно создания группы
        createGroupVisible: false,
        // Окно редактирования настроек группы
        editGroupVisible: false,
        // Окно настройки колонок в таблице учеников
        studentsListSettingsVisible: false,
        // Окно настройки колонок в таблице дз
        studentsHomeworkListSettingsVisible: false,
        // Окно продления дедлайна
        extendDeadlineVisible: false,
        // кастомные поля школы
        customFields: [],
        // //WEBINAR
        studentsWebinarListColumns: [],
        // Открытый раздел списка студентов вебинаров
        listLevelWebinar: 'schoolWebinars',
        // id открытого вебинара на странице "Ученики и аналитика"
        listLevelWebinarId: null,
        // id открытого стрима на странице "Ученики и аналитика"
        listLevelStreamId: null,
        // Поисковый запрос для фильтра списка учеников вебинара
        searchQueryWebinars: '',
        // Сортировка вебинара
        studentsSortParamsWebinar: {
            //Поле сортировки
            sortField: 'student.name',
            // Порядок сортировки (asc - от меньшего, desc - от большего)
            sortOrder: 'ASC',
            // id кастомного поля для сортировки (передаем при sort_field === 'custom')
            customSortField: '',
        },
        // Дополнительные фильтры для выделенных учеников
        selectedStudentsWebinarsSubfilters: {},
        // Описание фильтра для выборки выделенных учеников
        selectedStudentsWebinarsFilter: null,
        // Окно настройки колонок в таблице вебинаров
        studentsWebinarsListSettingsVisible: false,
    }); },
    getters: {
        /**
         * Открытый курс
         *
         * @param store
         * @returns {object}
         */
        course: function (store) {
            var _this = this;
            return store.courses.find(function (c) { return c.id === Number(_this.listLevelCourseId); });
        },
        /**
         * Группа открытого курса
         * @param store
         */
        group: function (store) {
            var _this = this;
            return store.groups.find(function (g) { return g.id === Number(_this.listLevelGroupId); });
        },
        /**
         * Список групп для открытого курса
         *
         * @param store
         * @returns {Array<object>}
         */
        courseLevelGroups: function (store) {
            var _this = this;
            return store.groups.filter(function (g) { return g.course.id === Number(_this.listLevelCourseId); });
        },
        /**
         * Список групп для указанного курса
         * @param store
         */
        courseGroups: function (store) { return function (courseId) {
            return store.groups.filter(function (g) { return g.course.id === Number(courseId); });
        }; },
        mappedStudent: function (store) {
            if (!store.student)
                return null;
            return __assign(__assign({}, store.student), { courses: store.student.courses.map(function (course) {
                    // группа в которой состоит ученик на данном курсе
                    var userCourseGroup = store.groups.find(function (g) { return g.id === course.flow.id; });
                    var courseLessons = course.steps.flat().flatMap(function (e) { return e.lessons; });
                    var courseLessonIndex = 0;
                    return __assign(__assign({}, course), { userCertificate: course.userCertificate && __assign(__assign({}, course.userCertificate), { link: 'https://' + window.location.host + '/verify/' + (course.userCertificate.number || '') }), steps: course.steps.map(function (step) {
                            var completedLessons = step.lessons.filter(function (e) { return e.completed; }).length;
                            var completedLessonsPercentage = (completedLessons / step.lessons.length) * 100;
                            var completed = completedLessons / step.lessons.length === 1;
                            return __assign(__assign({}, step), { completedLessons: completedLessons, completedLessonsPercentage: completedLessonsPercentage, completed: completed, lessons: step.lessons.map(function (lesson) {
                                    var lessonProgress = (function () {
                                        if (lesson.type === LessonTypeEnum.TEST && lesson.attempt) {
                                            return Math.round((lesson.attempt.correctAnswers / lesson.attempt.questions) * 100);
                                        }
                                        return 0;
                                    })();
                                    var progressStatus = getLessonStatus(lesson, courseLessonIndex, courseLessons, userCourseGroup);
                                    var accessByUser = userCourseGroup ? userCourseGroup.access.lessons === null || userCourseGroup.access.lessons.length === 0 || userCourseGroup.access.lessons.includes(lesson.id) : true;
                                    courseLessonIndex += 1;
                                    return __assign(__assign({}, lesson), { lessonProgress: lessonProgress, progressStatus: progressStatus, accessByUser: accessByUser });
                                }) });
                        }) });
                }) });
        }
    },
    actions: {
        /**
         * Получить список учеников
         *
         * @param {number} offset индекс начиная с которого получать элементы
         * @param {number} limit кол-во элементов которые нужно получить
         * @returns {Promise}
         */
        getStudents: function () {
            return __awaiter(this, arguments, void 0, function (offset, limit) {
                var students, response, _a, data, count;
                var _b;
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 20; }
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            students = { filter: 'all' };
                            if (this.listLevel === 'group') {
                                students.filter = 'group';
                                students.filterValue = [this.listLevelGroupId];
                            }
                            else if (this.listLevel === 'course') {
                                students.filter = 'course';
                                students.filterValue = [this.listLevelCourseId];
                            }
                            if (offset === 0) {
                                this.studentsLoading = true;
                            }
                            return [4 /*yield*/, Api.getStudents({
                                    students: students,
                                    search: this.searchQuery,
                                    offset: offset,
                                    limit: limit,
                                    sortField: this.sortField,
                                    customSortField: this.customSortField,
                                    sortOrder: this.sortOrder,
                                    filters: this.selectedStudentsSubfilters,
                                })];
                        case 1:
                            response = _c.sent();
                            _a = response.data, data = _a.data, count = _a.count;
                            this.storeSelectedStudent = data.map(function (s) { return s.id; });
                            if (offset === 0) {
                                this.students = data || [];
                            }
                            else {
                                (_b = this.students).push.apply(_b, __spreadArray([], __read(data), false));
                            }
                            this.totalCount = count;
                            this.studentsLoading = false;
                            return [2 /*return*/, response];
                    }
                });
            });
        },
        /**
         * Получить дополнительное кол-во учеников в список
         *
         * @returns {Promise}
         */
        getStudentsMore: function () {
            if (this.students.length >= this.totalCount) {
                return createResolvedPromise([]);
            }
            return this.getStudents(this.students.length);
        },
        /**
         * Получить список курсов
         *
         * @deprecated лучше использовать список со страницы курсов
         * @returns {Promise}
         */
        getCourses: function () {
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Api.getStudentsCourses()];
                        case 1:
                            response = _a.sent();
                            this.courses = response.data;
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Получить список групп
         *
         * @returns {Promise}
         */
        getGroups: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.groupsLoading = true;
                            _a = this;
                            return [4 /*yield*/, Api.getStudentsGroups()];
                        case 1:
                            _a.groups = (_b.sent()).data;
                            this.groupsLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Создать группу
         *
         * @param {number} courseId id курса в котором создать группу
         * @param {number} name название новой группы
         * @returns {Promise}
         */
        createGroup: function (courseId, name) {
            return __awaiter(this, void 0, void 0, function () {
                var response, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Api.createGroup(courseId, name)];
                        case 1:
                            response = _a.sent();
                            result = response.data;
                            result.course = { id: courseId };
                            this.groups.push(result);
                            // this.groups = result;
                            return [2 /*return*/, response];
                    }
                });
            });
        },
        /**
         * Получить данные об ученике
         *
         * @param {object} userId id пользователя-ученика
         * @returns {Promise}
         */
        getStudent: function (userId) {
            return __awaiter(this, void 0, void 0, function () {
                var response, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.studentLoading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, Api.getStudent(userId)];
                        case 2:
                            response = _a.sent();
                            this.student = response.data;
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            console.error('Ошибка при загрузке студента:', error_1);
                            return [3 /*break*/, 5];
                        case 4:
                            this.studentLoading = false;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Установить уровень списка учеников
         * ('all' - вся школа, course - курс, group - группа)
         *
         * @param {object} options объект
         * @param {string<'all'|'course'|'group'>} options.listLevel уровень списка учеников
         * @param {number} options.listLevelCourseId id курса из которой нужны ученики
         * @param {number} options.listLevelGroupId id группы из которой нужны ученики
         * @returns {Promise}
         */
        setLevel: function (options) {
            this.listLevel = options.listLevel;
            this.listLevelCourseId = null;
            this.listLevelGroupId = null;
            if (options.listLevelCourseId) {
                this.listLevelCourseId = options.listLevelCourseId;
                analytics.trackDetail({
                    'Раздел': 'Ученики и аналитика',
                    'Событие': 'Открыл курс',
                });
            }
            if (options.listLevelGroupId) {
                this.listLevelGroupId = options.listLevelGroupId;
                analytics.trackDetail({
                    'Раздел': 'Ученики и аналитика',
                    'Событие': 'Открыл группу',
                });
            }
        },
        /**
         * Установить уровень списка учеников вебинаров
         * ('all' - вся школа, course - курс, group - группа)
         *
         * @param {object} options объект
         * @param {string<'all'|'webinar'|'stream'>} options.listLevel уровень списка учеников
         * @param {number} options.listLevelWebinarId id курса из которой нужны ученики
         * @param {number} options.listLevelStreamId id группы из которой нужны ученики
         * @returns {Promise}
         */
        setLevelWebinar: function (options) {
            this.listLevelWebinar = options.listLevelWebinar;
            this.listLevelWebinarId = null;
            this.listLevelStreamId = null;
            if (options.listLevelWebinarId) {
                this.listLevelWebinarId = options.listLevelWebinarId;
                analytics.trackDetail({
                    'Раздел': 'Ученики и аналитика',
                    'Событие': 'Открыл вебинар',
                });
            }
            if (options.listLevelStreamId) {
                this.listLevelStreamId = options.listLevelStreamId;
                analytics.trackDetail({
                    'Раздел': 'Ученики и аналитика',
                    'Событие': 'Открыл стрим',
                });
            }
        },
        /**
         * Изменить сортировку учеников
         *
         * @param {object} data объект
         * @param {string} data.field поле по которому производится сортировка
         * @param {string<'asc'|'desc'>} data.order направление сортировки (asc - от меньшего, desc - от большего)
         * @returns {Promise}
         */
        changeSort: function (_a) {
            var field = _a.field, _b = _a.order, order = _b === void 0 ? 'desc' : _b;
            this.sortField = field;
            this.sortOrder = order;
        },
        changeSortWebinar: function (_a) {
            var field = _a.field, _b = _a.order, order = _b === void 0 ? 'DESC' : _b;
            this.studentsSortParamsWebinar.sortField = field;
            this.studentsSortParamsWebinar.sortOrder = order;
        },
        /**
         * Показать окно приглашения новых учеников
         *
         * @returns {Promise}
         */
        showInviteNewStudents: function () {
            this.inviteNewStudentsVisible = true;
        },
        /**
         * Скрыть окно приглашения новых учеников
         *
         * @returns {Promise}
         */
        hideInviteNewStudents: function () {
            this.inviteNewStudentsVisible = false;
        },
        /**
         * Показать окно приглашения текущих учеников в другой курс
         *
         * @returns {Promise}
         */
        showInviteStudents: function () {
            this.inviteStudentsVisible = true;
            if (this.courses.length === 0)
                this.getCourses();
            if (this.groups.length === 0)
                this.getGroups();
        },
        /**
         * Скрыть окно приглашения текущих учеников в другой курс
         *
         * @returns {Promise}
         */
        hideInviteStudents: function () {
            this.inviteStudentsVisible = false;
        },
        /**
         * Показать окно смены группы ученика
         *
         * @param {object} course курс, куда идет приглашение
         * @returns {Promise}
         */
        showChangeStudentsGroup: function (course) {
            this.changeStudentsGroupVisible = true;
            this.changeStudentsGroupCourse = course;
        },
        /**
         * Скрыть окно смены группы ученика
         *
         * @returns {Promise}
         */
        hideChangeStudentsGroup: function () {
            this.changeStudentsGroupVisible = false;
        },
        /**
         * Показать карточку ученика
         *
         * @param {number} userId id пользователя-ученика
         * @returns {Promise}
         */
        showStudentProfile: function (userId) {
            this.studentProfileVisible = true;
            this.getStudent(userId);
            analytics.trackDetail({
                'Раздел': 'Ученики и аналитика',
                'Событие': 'Открыл карточку ученика'
            });
        },
        /**
         * Скрыть карточку ученика
         *
         * @returns {Promise}
         */
        hideStudentProfile: function () {
            this.studentProfileVisible = false;
            //сбрасываю данные студента при закрытии
            this.student = null;
        },
        /**
         * Показать окно создания группы
         *
         * @returns {Promise}
         */
        showCreateGroup: function () {
            this.createGroupVisible = true;
        },
        /**
         * Скрыть окно создания группы
         *
         * @returns {Promise}
         */
        hideCreateGroup: function () {
            this.createGroupVisible = false;
        },
        /**
         * Показать окно редактирования настроек группы
         *
         * @param {object} group группа
         * @returns {Promise}
         */
        showEditGroupVisible: function (group) {
            this.editGroupVisible = true;
            this.selectedGroupSettings = group;
        },
        /**
         * Обновить данные в выбранной группе
         *
         * @param {object} group группа
         * @returns {Promise}
         */
        updateSelectedGroupSettings: function (group) {
            this.selectedGroupSettings = group;
        },
        /**
         * Скрыть окно редактирования настроек группы
         *
         * @returns {Promise}
         */
        hideEditGroupVisible: function () {
            this.editGroupVisible = false;
        },
        /**
         * Показать окно редактирования колонок таблицы студентов
         *
         * @returns {Promise}
         */
        showStudentsListSettings: function () {
            this.studentsListSettingsVisible = true;
        },
        /**
         * Скрыть окно редактирования колонок таблицы студентов
         *
         * @returns {Promise}
         */
        hideStudentsListSettings: function () {
            this.studentsListSettingsVisible = false;
        },
        /**
         * Показать окно редактирования колонок таблицы студентов
         *
         * @returns {Promise}
         */
        showStudentsHomeworkListSettings: function () {
            this.studentsHomeworkListSettingsVisible = true;
        },
        /**
         * Скрыть окно редактирования колонок таблицы ДЗ
         *
         * @returns {Promise}
         */
        hideStudentsHomeworkListSettings: function () {
            this.studentsHomeworkListSettingsVisible = false;
        },
        /**
         * Показать окно редактирования колонок таблицы вебинаров
         *
         * @returns {Promise}
         */
        showStudentsWebinarsListSettings: function () {
            this.studentsWebinarsListSettingsVisible = true;
        },
        /**
         * Скрыть окно редактирования колонок таблицы вебинаров
         *
         * @returns {Promise}
         */
        hideStudentsWebinarsListSettings: function () {
            this.studentsWebinarsListSettingsVisible = false;
        },
        /**
         * Показать окно продления дедлайна
         *
         * @returns {Promise}
         */
        showExtendDeadline: function () {
            this.extendDeadlineVisible = true;
        },
        /**
         * Скрыть окно продления дедлайна
         *
         * @returns {Promise}
         */
        hideExtendDeadline: function () {
            this.extendDeadlineVisible = false;
        },
        getCustomFields: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, Api.getStudentCustomFields()];
                        case 1:
                            res = _b.sent();
                            this.customFields = res.data;
                            return [3 /*break*/, 3];
                        case 2:
                            _a = _b.sent();
                            return [2 /*return*/, {}];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
    },
});
