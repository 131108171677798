import { defineStore } from 'pinia';
/**
 * Сторедж для хранения данных EditorJS редакторов (для их компонентов)
 */
export var useEditorStore = defineStore('editor', {
    state: function () { return ({
        editors: [],
    }); },
    actions: {
        registerEditor: function (uuid, data) {
            var editorData = { uuid: uuid, data: data };
            this.editors.push(editorData);
            return editorData.data;
        },
        unregisterEditor: function (uuid) {
            this.editors = this.editors.filter(function (e) { return e.uuid !== uuid; });
        },
        getEditorData: function (uuid) {
            return this.editors.find(function (e) { return e.uuid === uuid; }).data;
        },
    }
});
