/**
 * Запущен ли проект в деве окружении
 *
 * @returns {boolean}
 */
export function isDev() {
    var _a;
    return ((_a = window.__passdata) === null || _a === void 0 ? void 0 : _a.environment) === 'dev';
}
/**
 * Запущен ли проект в продакшен окружении
 *
 * @returns {boolean}
 */
export function isProd() {
    var _a;
    return ((_a = window.__passdata) === null || _a === void 0 ? void 0 : _a.environment) === 'prod';
}
