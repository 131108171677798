var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
/**
 * Режем строку если больше определенного размера с добавлением троеточия
 *
 * @param {string} string Строка
 * @param {number} maxLength Максимальная длина строки
 * @param {string} ending Окончание строки (троеточие)
 * @returns {string|*} Строка борезанная либо исходная
 */
import $ from '@common/utils/Dom';
import { regularMozHack, regularSpecialChars, regularUrl } from '@common/utils/RegularExp/RegularExp';
import { isMobile } from '@common/utils/Crossbrowser';
/**
 * Обрезка текста с троеточием
 *
 * @param {string} string Текст
 * @param {number} maxLength Максимальная длина (с учетом троеточия)
 * @param {string} ending Троеточие (или его аналог)
 * @returns {string}
 */
export function truncate(string, maxLength, ending) {
    if (ending === void 0) { ending = '..'; }
    return string.length > maxLength ? string.slice(0, maxLength - ending.length) + ending : string;
}
/**
 * Конвертирует camelCase в kebabCase
 *
 * @param {string} str Исходный текст
 * @returns {string} Сконвертированные текст
 */
export function camelToKebab(str) {
    return __spreadArray([], __read(str), false).map(function (letter, idx) {
        return letter.toUpperCase() === letter
            ? "".concat(idx !== 0 ? '-' : '').concat(letter.toLowerCase())
            : letter;
    }).join('');
}
/**
 * Обработка html текста, сгенерированного wysiwyg редактором
 *
 * @param {string} html html-контент статьи
 * @returns {string} html-спаршенный текст
 */
// eslint-disable-next-line complexity
export function prepareArticleHTML(html) {
    var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e, e_6, _f;
    html = parseTextWithUrl({ text: html, escapeText: false });
    var htmlDoc = $.stringToDom(html);
    var links = htmlDoc.querySelectorAll('a');
    try {
        for (var links_1 = __values(links), links_1_1 = links_1.next(); !links_1_1.done; links_1_1 = links_1.next()) {
            var link = links_1_1.value;
            link.rel = 'noopener noreferrer';
            link.target = '_blank';
            link.textContent = link.textContent.length > 60 ? link.textContent.slice(0, 60) + '..' : link.textContent;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (links_1_1 && !links_1_1.done && (_a = links_1.return)) _a.call(links_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var tables = htmlDoc.querySelectorAll('table');
    try {
        for (var tables_1 = __values(tables), tables_1_1 = tables_1.next(); !tables_1_1.done; tables_1_1 = tables_1.next()) {
            var table = tables_1_1.value;
            var wrapper = htmlDoc.createElement('div');
            wrapper.classList.add('table-wrapper');
            table.parentNode.insertBefore(wrapper, table);
            wrapper.append(table);
            // Устанавливаем ширину колонок
            var colsWithSize = table.querySelectorAll('[data-colwidth]');
            try {
                for (var colsWithSize_1 = (e_3 = void 0, __values(colsWithSize)), colsWithSize_1_1 = colsWithSize_1.next(); !colsWithSize_1_1.done; colsWithSize_1_1 = colsWithSize_1.next()) {
                    var col = colsWithSize_1_1.value;
                    // @ts-ignore
                    col.style.width = col.dataset.colwidth.split(',').reduce(function (a, b) { return +a + +b; });
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (colsWithSize_1_1 && !colsWithSize_1_1.done && (_c = colsWithSize_1.return)) _c.call(colsWithSize_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
            // Ищем минимальную ширину таблицы
            var firstRowColwidth = table.querySelector('tr').querySelectorAll('[data-colwidth]');
            var tableMinWidth = 0;
            try {
                for (var firstRowColwidth_1 = (e_4 = void 0, __values(firstRowColwidth)), firstRowColwidth_1_1 = firstRowColwidth_1.next(); !firstRowColwidth_1_1.done; firstRowColwidth_1_1 = firstRowColwidth_1.next()) {
                    var col = firstRowColwidth_1_1.value;
                    // @ts-ignore
                    tableMinWidth += +col.dataset.colwidth.split(',').reduce(function (a, b) { return +a + +b; });
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (firstRowColwidth_1_1 && !firstRowColwidth_1_1.done && (_d = firstRowColwidth_1.return)) _d.call(firstRowColwidth_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
            table.style.minWidth = String(tableMinWidth);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (tables_1_1 && !tables_1_1.done && (_b = tables_1.return)) _b.call(tables_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    var iframes = htmlDoc.querySelectorAll('iframe');
    try {
        for (var iframes_1 = __values(iframes), iframes_1_1 = iframes_1.next(); !iframes_1_1.done; iframes_1_1 = iframes_1.next()) {
            var iframe = iframes_1_1.value;
            var wrapper = htmlDoc.createElement('div');
            wrapper.classList.add('iframe-wrapper');
            if (iframe.src) {
                var src = iframe.src;
                if (/http(s?):\/\/player\.vimeo\.com/g.test(src) || /http(s?):\/\/\w+.\w+\/api\/vimeo\/embed\/\d+/g.test(src)) {
                    wrapper.classList.add('iframe-wrapper--vimeo');
                }
                else if (/http(s?):\/\/(www\.?)youtube.com\/embed/g.test(src)) {
                    wrapper.classList.add('iframe-wrapper--youtube');
                }
                else if (/http(s?):\/\/forms.office.com/g.test(src)) {
                    wrapper.classList.add('iframe-wrapper--office-forms');
                    iframe.setAttribute('onload', 'this.style.height=(this.contentWindow.document.body.scrollHeight+20)+\'px\';');
                }
                else if (/http(s?):\/\/quizlet.com/g.test(src)) {
                    wrapper.classList.add('iframe-wrapper--quizlet');
                    iframe.setAttribute('onload', 'this.style.height=(this.contentWindow.document.body.scrollHeight+20)+\'px\';');
                }
            }
            iframe.parentNode.insertBefore(wrapper, iframe);
            wrapper.append(iframe);
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (iframes_1_1 && !iframes_1_1.done && (_e = iframes_1.return)) _e.call(iframes_1);
        }
        finally { if (e_5) throw e_5.error; }
    }
    var images = htmlDoc.querySelectorAll('img');
    try {
        for (var images_1 = __values(images), images_1_1 = images_1.next(); !images_1_1.done; images_1_1 = images_1.next()) {
            var img = images_1_1.value;
            var wrapper = htmlDoc.createElement('span');
            wrapper.classList.add('image-wrapper');
            switch (img.dataset.display) {
                case 'left': {
                    wrapper.classList.add('image-wrapper--float-left');
                    break;
                }
                case 'right': {
                    wrapper.classList.add('image-wrapper--float-right');
                    break;
                }
                case 'inline': {
                    wrapper.classList.add('image-wrapper--inline');
                    break;
                }
                // No default
            }
            img.parentNode.insertBefore(wrapper, img);
            wrapper.append(img);
        }
    }
    catch (e_6_1) { e_6 = { error: e_6_1 }; }
    finally {
        try {
            if (images_1_1 && !images_1_1.done && (_f = images_1.return)) _f.call(images_1);
        }
        finally { if (e_6) throw e_6.error; }
    }
    return $.domToString(htmlDoc);
}
/**
 * Чистый текст в текст с html-entities
 *
 * @param {string} html текст
 * @param allowedTags
 * @returns {*} текст с символами вместо тегов
 */
export function escapeHTML(html, allowedTags) {
    var e_7, _a;
    if (allowedTags === void 0) { allowedTags = []; }
    // Make allowed tags array lower case
    allowedTags = allowedTags.map(function (c) { return c.toLowerCase(); });
    // Output is the input, edited
    var output = html;
    // Attempt to match an opening or closing HTML tag
    var reg = /<\/?([\dAZ_a-z]*)[^>]*?>/g;
    // An array that will contain all disallowed tags
    var disallowedTags = [];
    // For each tag in the input, if it's allowed, skip
    // Else, add it to the array.
    var match;
    while ((match = reg.exec(html)) !== null) {
        if (allowedTags.includes(match[1].toLowerCase()))
            continue;
        disallowedTags.push(match[0]);
    }
    try {
        // Replace each disallowed tag with the "escaped" version
        for (var disallowedTags_1 = __values(disallowedTags), disallowedTags_1_1 = disallowedTags_1.next(); !disallowedTags_1_1.done; disallowedTags_1_1 = disallowedTags_1.next()) {
            var tag = disallowedTags_1_1.value;
            var find = tag;
            var replace = tag.replace('<', '&lt;').replace('>', '&gt;');
            output = output.replace(find, replace);
        }
    }
    catch (e_7_1) { e_7 = { error: e_7_1 }; }
    finally {
        try {
            if (disallowedTags_1_1 && !disallowedTags_1_1.done && (_a = disallowedTags_1.return)) _a.call(disallowedTags_1);
        }
        finally { if (e_7) throw e_7.error; }
    }
    return output;
}
/**
 * Текст с html-entities в чистый текст
 *
 * @param {string} html текст
 * @returns {*} чистый текст без "&amp;" сущностей
 */
export function unescapeHTML(html) {
    var span = document.createElement('span');
    return html
        .replace(/&[\d#a-z]+;/gi, function (entity) {
        span.innerHTML = entity;
        return span.textContent;
    });
}
export var escapeRegexpString = function (value) {
    if (value === void 0) { value = ''; }
    return String(value).replace(/[$()*+.?[\\\]^{|}]/g, '\\$&');
};
/**
 * Парсим текст с активными ссылками в тегах
 *
 * @param {string} text Исходный текст
 * @param {string} classNames Содержимое атрибута class у ссылок
 * @param {boolean} escapeText Экранировать теги html, превратив их в символьный текст
 * @param {number|boolean} maxlength Максимальная длина ссылки
 * @returns {string} Текст с активными ссылками
 */
/**
 *
 * @param root0
 * @param root0.text
 * @param root0.classNames
 * @param root0.escapeText
 * @param root0.maxlength
 * @param root0.callback
 */
export function parseTextWithUrl(_a) {
    var e_8, _b, e_9, _c;
    var text = _a.text, _d = _a.classNames, classNames = _d === void 0 ? '' : _d, _e = _a.maxlength, maxlength = _e === void 0 ? 53 : _e, _f = _a.escapeText, escapeText = _f === void 0 ? true : _f;
    var innerText = text;
    if (escapeText) {
        innerText = escapeHTML(text || '');
    }
    var document = $.stringToDom(innerText);
    var textNodes = $.findTextNodes(document.body);
    try {
        for (var textNodes_1 = __values(textNodes), textNodes_1_1 = textNodes_1.next(); !textNodes_1_1.done; textNodes_1_1 = textNodes_1.next()) {
            var node = textNodes_1_1.value;
            var match = void 0;
            var remainingText = node.textContent;
            var fragment = document.createDocumentFragment();
            while (remainingText.match(regularUrl) !== null) {
                match = remainingText.match(regularUrl);
                var linkText = match[0];
                var matchIndex = match.index;
                if (matchIndex > 0) {
                    var textBefore = remainingText.slice(0, matchIndex);
                    fragment.append(document.createTextNode(textBefore));
                }
                var linkNode = document.createElement('a');
                linkNode.href = linkText;
                linkNode.textContent = maxlength !== false && linkText.length > maxlength ? linkText.slice(0, maxlength) + '..' : linkText;
                linkNode.className = classNames;
                fragment.append(linkNode);
                remainingText = remainingText.slice(matchIndex + linkText.length);
            }
            if (remainingText.length > 0) {
                fragment.append(document.createTextNode(remainingText));
            }
            node.replaceWith(fragment);
        }
    }
    catch (e_8_1) { e_8 = { error: e_8_1 }; }
    finally {
        try {
            if (textNodes_1_1 && !textNodes_1_1.done && (_b = textNodes_1.return)) _b.call(textNodes_1);
        }
        finally { if (e_8) throw e_8.error; }
    }
    var linkNodes = document.querySelectorAll('a');
    var currentOrigin = window.location.origin;
    var isMobileApp = isMobile();
    try {
        for (var linkNodes_1 = __values(linkNodes), linkNodes_1_1 = linkNodes_1.next(); !linkNodes_1_1.done; linkNodes_1_1 = linkNodes_1.next()) {
            var node = linkNodes_1_1.value;
            if (!node.href || !/^https?:\/\//.test(node.href)) {
                continue;
            }
            var linkUrl = void 0;
            try {
                linkUrl = new URL(node.href);
            }
            catch (_g) {
                continue;
            }
            if (linkUrl.origin === currentOrigin && isMobileApp) {
                node.removeAttribute('target');
            }
        }
    }
    catch (e_9_1) { e_9 = { error: e_9_1 }; }
    finally {
        try {
            if (linkNodes_1_1 && !linkNodes_1_1.done && (_c = linkNodes_1.return)) _c.call(linkNodes_1);
        }
        finally { if (e_9) throw e_9.error; }
    }
    return $.domToString(document);
}
/**
 * Получаем из хоста корневой домен
 *
 * @param {string} hostname Оригинальное имя хоста
 * @param {number} domainLevel Уровень домена до которого стоит обрезать
 * @returns {string} Корневой домен из хоста
 */
export function getDomainFromHostname(hostname, domainLevel) {
    if (domainLevel === void 0) { domainLevel = 2; }
    return hostname.split('.').reverse().splice(0, domainLevel).reverse().join('.');
}
/**
 * Удаляем из текста ключи с упоминанием skillspace, например для онбординга
 *
 * @param {string} text Текст для обработки
 * @param {boolean} whiteLabel Включен ли whitelabel
 * @example "На платформе {{no-wl|skillspace|данной}}." При whitelabel будет "На платформе данной", иначе
 * "На платформе skillspace"
 * @returns {string}
 */
export function noWhiteLabelMarkdown(text, whiteLabel) {
    return text.replace(/{{no-wl\|(.*?)(|.*?)}}/g, function (match) {
        var _a;
        var tag = match.split(/{{|\||}}/).filter(Boolean);
        var originalText = tag[1];
        var whiteLabeledText = (_a = tag[2]) !== null && _a !== void 0 ? _a : '';
        return whiteLabel ? whiteLabeledText : originalText;
    });
}
/**
 * Заменяем токены в строке на значения
 *
 * @param {string} string Исходная строка
 * @param {object} tokens Объект, где ключи - токены, значения - на что их заменить
 * @param {Function} tokenModificator Функция, модифицирующая токен перед заменой (например в объекте может быть
 * url, а в тексте #url#, функция должна модифицировать ключ,чтобы он соответствовал тому, что в тексте
 * @param {Function} valueModificator Функция, модифицирующая значение токена перед заменой
 * @returns {string} Строка с замененными токенами на их значения
 */
export function replaceTemplateTokens(string, tokens, tokenModificator, valueModificator) {
    var e_10, _a;
    if (tokenModificator === void 0) { tokenModificator = function (token) { return token; }; }
    if (valueModificator === void 0) { valueModificator = function (value) { return value; }; }
    var tokensKeys = Object.keys(tokens);
    try {
        for (var tokensKeys_1 = __values(tokensKeys), tokensKeys_1_1 = tokensKeys_1.next(); !tokensKeys_1_1.done; tokensKeys_1_1 = tokensKeys_1.next()) {
            var token = tokensKeys_1_1.value;
            string = string.replaceAll(tokenModificator(token), valueModificator(tokens[token]));
        }
    }
    catch (e_10_1) { e_10 = { error: e_10_1 }; }
    finally {
        try {
            if (tokensKeys_1_1 && !tokensKeys_1_1.done && (_a = tokensKeys_1.return)) _a.call(tokensKeys_1);
        }
        finally { if (e_10) throw e_10.error; }
    }
    return string;
}
/* istanbul ignore next */
export var trim = function (string) {
    return (string || '').replace(/^\s+|\s+$/g, '');
};
/* istanbul ignore next */
export var camelCase = function (name) {
    return name.replace(regularSpecialChars, function (_, separator, letter, offset) {
        return offset ? letter.toUpperCase() : letter;
    }).replace(regularMozHack, 'Moz$1');
};
/**
 * @param str
 */
export function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    return str.charAt(0).toUpperCase() + str.slice(1);
}
