var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { apiErrorsHandle } from '@common/utils/Helpers/Helpers';
import { createResolvedPromise } from '@common/utils/Promise/Promise';
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import Api from '@student/modules/api/api';
import { studentLandingApi } from '@student/modules/api/landing/landing.api';
import { AuthModalStore, CourseStore } from '@student/modules/store';
import { getLessonStatus } from '@common/utils/GetLessonStatus';
import Message from '@common/ui/EMessage/Message';
export var useCourseStore = defineStore('courseStudent', {
    state: function () { return ({
        // Открытый курс
        // @sync - нужно синхронить с одинаковыми id
        course: {},
        stepsLoading: false,
        // Значения кастомных полей
        userFieldsValues: [],
        // Состояние загрузки курса
        courseLoading: false,
        // Лендинг курса
        landing: {},
        // Состояние загрузки оплаты
        landingPurchaseLoading: false,
        // Этапы курса
        // @sync - если одинаковый course
        steps: [],
        // Занятия курса
        // @sync - если одинаковый course
        lessons: [],
        // Группа в которой состоит ученик в данном курсе
        // @sync - если одинаковый course
        group: {},
        // Тарифные планы доступные на данном курсе
        // @sync - если одинаковый course
        plans: [],
        // Открытое занятие
        // @sync - нужно синхронить с одинаковыми id
        lesson: {},
        // Состояние загрузки занятия
        lessonLoading: false,
        // Тест открытого занятия
        // @sync - нужно синхронить с одинаковыми lesson
        test: {},
        // Домашнее задание открытого занятия
        // @sync - нужно синхронить с одинаковыми lesson
        homeworkUser: {},
        // Модуль в котором находится текущее занятие
        // @sync - нужно синхронить с одинаковыми id
        step: {},
        // Отображать форму заказа
        landingOrderFormVisibility: false,
        // Тарифный план отображаемый в форме заказа
        landingOrderFormPlan: null,
        // Окно смены тарифного плана
        changePlanVisible: false,
        // Выделить тарифный план в окне смены тарифного плана
        changePlanOutlined: null,
        // Запретить сброс данных в текущем сторедже
        clearStoreStateDisabled: false,
        finishRules: {},
        certificateRules: {},
        finishAvailable: false,
        certificateAvailable: false,
        enableCertificate: false,
        displayStudent: false,
        studentStatus: 'active',
        studentFinishDate: null
    }); },
    getters: {
        /**
         * Включена ли ссылка на внешний лендинг
         *
         * @param store
         * @returns {boolean}
         */
        useLandingExternalUrl: function (store) {
            return store.course.useLandingExternalUrl;
        },
        /**
         * cсылка на внешний лендинг
         *
         * @param store
         * @returns {string}
         */
        landingExternalUrl: function (store) {
            return store.course.landingExternalUrl;
        },
        /**
         * Обработанный список занятий, везде где нужна проверка доступа нужно
         * использовать именно его
         *
         * @param store
         * @returns {object}
         */
        preparedLessons: function (store) {
            var lessons = store.lessons;
            var group = store.group;
            // Каждому занятию привязываем свойство с предыдущим и следующим занятием
            // Это позволит на фронте смотреть заблокировано ли следующее/предыдущее занятие и тп
            lessons = lessons.map(function (lesson, lessonIndex) {
                lesson.lessonPrev = lessons[lessonIndex - 1];
                lesson.lessonNext = lessons[lessonIndex + 1];
                return lesson;
            });
            /**
             * Прописываем каждому занятию условия для доступа к нему.
             * Логика для проверки при настройке "строгая последовательность" простая:
             * в каждом занятии мы смотрим, есть ли в списке перед ним где-либо *доступное* (не отключенное
             * настройками группы или личными настройками) занятие для ученика, которое оне еще *не прошел*.
             *
             * Тут есть исключения для отдельных типов занятий, например настройка hardSequenceTest === OPTIONAL
             * позволяет при поиске не пройденного занятия игнорировать все тесты. Соответственно у ученика могут
             * быть не пройден тест, но следующее занятия за ним будет открыто.
             */
            lessons = lessons.map(function (lesson, lessonIndex) {
                var progressStatus = getLessonStatus(lesson, lessonIndex, lessons, group);
                lesson.progressStatus = progressStatus;
                lesson.accessByUser = group.access.lessons === null || group.access.lessons.length === 0 || group.access.lessons.includes(lesson.id);
                // Если порядок выключен или занятие уже пройдено, не блокируем
                if (group.hardSequence === false || lesson.completed) {
                    lesson.blocked = false;
                    return lesson;
                }
                // Если занятие первое - даем доступ
                if (lessonIndex === 0) {
                    lesson.blocked = false;
                    return lesson;
                }
                if (lesson.type === 'webinar' && Date.now() > lesson.webinarFlow.startAt + lesson.webinarFlow.duration) {
                    lesson.blocked = false;
                    return lesson;
                }
                var previousLessonToComplete;
                // Ищем индекс последнего по порядку пройденного урока, либо берем 0 индекс
                var lessonSearcherIndex = Math.max(lessons.findLastIndex(function (l) { return l.completed; }), 0);
                // Ищем ближайший предыдущий урок, который нужно было пройти, начиная с последнего пройденного
                while (lessonSearcherIndex < lessonIndex && !previousLessonToComplete) {
                    var pLesson = lessons[lessonSearcherIndex];
                    if (!pLesson)
                        break;
                    // К уроку обязательн должен быть доступ и он должен быть не завершен
                    if (pLesson.access && !pLesson.completed) {
                        if (pLesson.type === 'homework'
                            // Если домашки заблокированы, то не проверяем их
                            && !store.isHomeworkBlock
                            && (group.hardSequenceHomework === 'APPROVMENT_REQUIRED'
                                || (group.hardSequenceHomework === 'SEND_REQUIRED'
                                    && (!pLesson.homeworkUser
                                        || pLesson.homeworkUser.answers.length <= 0)))) {
                            previousLessonToComplete = pLesson;
                            break;
                        }
                        else if (pLesson.type === 'test' &&
                            (group.hardSequenceTest === 'PASS_REQUIRED'
                                || (group.hardSequenceTest === 'PASS_REQUIRED_ONCE' && !pLesson.tried))) {
                            previousLessonToComplete = pLesson;
                            break;
                        }
                        else if (pLesson.type === 'webinar' || pLesson.type === 'default') {
                            previousLessonToComplete = pLesson;
                            break;
                        }
                    }
                    lessonSearcherIndex++;
                }
                // Если перед занятием был урок, который нужно пройти - блокируем текущее занятие
                lesson.blocked = !!previousLessonToComplete;
                return lesson;
            });
            return lessons;
        },
        /**
         * Модули курса
         *
         * @param store
         * @returns {object}
         */
        preparedSteps: function (store) {
            var steps = store.steps.map(function (step) {
                var e_1, _a;
                var _b, _c, _d;
                var importantStepStatus = {
                    pulse: false,
                    check: false,
                    rework: false
                };
                // Занятия текущего модуля
                var lessons = store.lessons.filter(function (l) {
                    return l.step.id === step.id;
                });
                try {
                    for (var lessons_1 = __values(lessons), lessons_1_1 = lessons_1.next(); !lessons_1_1.done; lessons_1_1 = lessons_1.next()) {
                        var lesson = lessons_1_1.value;
                        if (lesson.webinarFlow) {
                            var wbStart = dayjs(lesson.webinarFlow.startAt);
                            var wbFinish = wbStart.add(lesson.webinarFlow.duration, 'minutes');
                            if (wbStart.unix() < dayjs().unix() && dayjs().unix() < wbFinish.unix()) {
                                importantStepStatus.pulse = true;
                            }
                        }
                        if ((_b = lesson === null || lesson === void 0 ? void 0 : lesson.homeworkUser) === null || _b === void 0 ? void 0 : _b.status) {
                            switch (lesson.homeworkUser.status) {
                                case 'student_sent':
                                case 'autoaccept_pending':
                                    importantStepStatus.check = true;
                                    break;
                                case 'teacher_sent':
                                    importantStepStatus.rework = true;
                                    break;
                            }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (lessons_1_1 && !lessons_1_1.done && (_a = lessons_1.return)) _a.call(lessons_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                // На основании пройденных занятий учеником в данном модуле
                // устанавливаем статус прохождения модуля в целом
                if (lessons.length > 0 && lessons.every(function (lesson) { return lesson.completed; })) {
                    // Все занятия модуля пройдены
                    step.completeStatus = 'completed';
                    step.completed = true;
                }
                else if (lessons.some(function (lesson) { return lesson.completed; })) {
                    // Хотябы одно занятие в модуле пройдено
                    step.completeStatus = 'inprocess';
                    step.completed = false;
                }
                else {
                    // Модуль еще не начали проходить
                    step.completeStatus = 'notstarted';
                    step.completed = false;
                }
                step.importantStepStatus = importantStepStatus;
                var preparedLessons = (_d = (_c = CourseStore.preparedLessons) === null || _c === void 0 ? void 0 : _c.filter(function (lesson) { return lesson.step.id === step.id; })) !== null && _d !== void 0 ? _d : [];
                var needToFinishCourseLessons = preparedLessons.filter(function (l) { return l.needToFinishCourse; });
                step.allFinishCourse = needToFinishCourseLessons.length === needToFinishCourseLessons.filter(function (l) { return l.completed; }).length;
                var needToReceivedCertificateLessons = preparedLessons.filter(function (l) { return l.needToReceivedCertificate; });
                step.allFinishReceivedCertificate = needToReceivedCertificateLessons.length === needToReceivedCertificateLessons.filter(function (l) { return l.completed; }).length;
                step.lessons = lessons;
                // Вычисляем процент завершения уроков в модуле
                var completedLessons = lessons.filter(function (l) { return l.completed; }).length;
                var totalLessons = lessons.length;
                step.progress = totalLessons > 0 ? (completedLessons / totalLessons) * 100 : 0;
                return step;
            });
            return steps ? steps.filter(function (s) { var _a; return (((_a = CourseStore.preparedLessons) === null || _a === void 0 ? void 0 : _a.filter(function (lesson) { return lesson.step.id === s.id; })) || []).length > 0; }) : [];
        },
        /**
         * Следующее, еще не пройденное занятие
         *
         * @param store
         * @returns {object}
         */
        nextUncompletedLesson: function (store) {
            return store.lessons.find(function (lesson) { return !lesson.completed; });
        },
        /**
         * Первый модуль в котором есть непройденные занятия
         *
         * @param store
         * @returns {object}
         */
        currentStep: function (store) {
            var lastestUncompletedLesson = store.lessons.find(function (lesson) { return !lesson.completed; });
            return lastestUncompletedLesson ? lastestUncompletedLesson.step : null;
        },
        /**
         * Занятия открытого модуля
         *
         * @param store
         * @returns {Array<object>}
         */
        stepLessons: function (store) {
            return store.preparedLessons.filter(function (lesson) { return lesson.step.id === store.step.id; });
        },
        /**
         * Предыдущее, от открытого, занятие
         *
         * @param store
         * @returns {object}
         */
        prevLesson: function (store) {
            if (!store.lesson)
                return;
            var lessons = store.preparedLessons.filter(function (e) { return e.access; });
            var lessonIndex = lessons.findIndex(function (lesson) { return lesson.id === store.lesson.id; });
            return lessons[lessonIndex - 1];
        },
        /**
         * Следующее, за открытым, занятие
         *
         * @param store
         * @returns {object}
         */
        nextLesson: function (store) {
            if (!store.lesson)
                return;
            var lessons = store.preparedLessons.filter(function (e) { return e.access; });
            var lessonIndex = lessons.findIndex(function (lesson) { return lesson.id === store.lesson.id; });
            return lessons[lessonIndex + 1];
        },
        /**
         * Студенту запрещено отправлять решение на домашние задания
         *
         * @param store
         * @returns {Array<object>}
         */
        isHomeworkBlock: function (store) {
            return store.group ? store.group.homeworkBlock : false;
        },
        /**
         * Тарифный план курса у студента
         *
         * @param store
         * @returns {object}
         */
        currentPlan: function (store) {
            return store.plans.find(function (p) { return p.group.id === store.group.id; });
        }
    },
    actions: {
        /**
         * Получить курс
         *
         * @param {number | string} field
         * @param {'id' | 'slug'} by
         *
         * @param isForceUpdate
         * @returns {Promise}
         */
        // eslint-disable-next-line complexity
        getCourse: function (field_1) {
            return __awaiter(this, arguments, void 0, function (field, by, isForceUpdate) {
                /**
                 * Обработать данные внутри курса для фронтенда
                 *
                 * @param {object} data объект данных с бэкенда
                 * @param {object} data.course курс
                 * @param {object} data.course.landing публичный лендинг курса
                 * @param {object} data.plans тарифные планы, доступне на курсе
                 * @returns {object}
                 */
                function processCourse(data) {
                    var _a, _b, _c;
                    var lessons = [];
                    store.course = data.course;
                    store.plans = (_a = data.plans) !== null && _a !== void 0 ? _a : [];
                    store.userFieldsValues = data.userFieldsValues || [];
                    store.landing = (_c = (_b = data.course) === null || _b === void 0 ? void 0 : _b.landing) !== null && _c !== void 0 ? _c : {};
                    store.certificateRules = data.certificateRules || {};
                    store.finishRules = data.finishRules || {};
                    store.studentStatus = data.studentStatus;
                    store.studentFinishDate = data.studentFinishDate;
                    store.enableCertificate = data.enableCertificate || false;
                    store.displayStudent = data.displayStudent || false;
                    if (data.course.allowed) {
                        store.steps = data.steps.map(function (step) {
                            step.opened = !!(data.course.allowed
                                && !data.course.uncompletedLesson || (data.course.uncompletedLesson.step
                                && step.id === data.course.uncompletedLesson.step.id));
                            var stepLessons = step.lessons.map(function (lesson) {
                                lesson.step = step;
                                return lesson;
                            });
                            lessons.push.apply(lessons, __spreadArray([], __read(stepLessons), false));
                            delete step.lessons;
                            return step;
                        });
                        store.group = data.flow;
                        store.lessons = lessons;
                    }
                    store.courseLoading = false;
                    return data;
                }
                var firstLoad, forceUpdate, store, preloadingCourse, res_1, err_1;
                var _this = this;
                var _a;
                if (by === void 0) { by = 'id'; }
                if (isForceUpdate === void 0) { isForceUpdate = false; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            firstLoad = this.course[by] !== field;
                            forceUpdate = isForceUpdate;
                            if (!(!firstLoad && this.course.allowed)) return [3 /*break*/, 2];
                            return [4 /*yield*/, Api.checkCourseInfo(this.course.uuid)
                                    .then(function (res) {
                                    var data = res.data;
                                    if (data.certificate !== _this.certificateAvailable || data.finish !== _this.finishAvailable) {
                                        forceUpdate = true;
                                    }
                                    _this.certificateAvailable = data.certificate;
                                    _this.finishAvailable = data.finish;
                                })];
                        case 1:
                            _b.sent();
                            _b.label = 2;
                        case 2:
                            if (((_a = this.course) === null || _a === void 0 ? void 0 : _a[by]) === field && !forceUpdate) {
                                return [2 /*return*/, createResolvedPromise(this.course)];
                            }
                            store = this;
                            preloadingCourse = window.__passdata.preloading.course;
                            if (preloadingCourse && preloadingCourse[by] === field) {
                                // Сразу удаляем из прелоадинга, чтобы при повторном заходе на страницу данные загружались с сервера
                                delete window.__passdata.preloading.course;
                                return [2 /*return*/, createResolvedPromise(processCourse({ course: preloadingCourse }))];
                            }
                            if (this.courseLoading)
                                return [2 /*return*/];
                            this.courseLoading = true;
                            this.course = {};
                            this.landing = {};
                            _b.label = 3;
                        case 3:
                            _b.trys.push([3, 5, , 6]);
                            return [4 /*yield*/, (by === 'id' ? Api.getCourse(field) : studentLandingApi.getCourse(field))];
                        case 4:
                            res_1 = _b.sent();
                            processCourse(res_1.data);
                            if (firstLoad) {
                                this.finishAvailable = Object.keys(res_1.data.finishRules || {}).every(function (e) { return res_1.data.finishRules[e] === 'success'; });
                                this.certificateAvailable = Object.keys(res_1.data.certificateRules || {}).every(function (e) { return res_1.data.certificateRules[e] === 'success'; });
                            }
                            return [2 /*return*/, this.course];
                        case 5:
                            err_1 = _b.sent();
                            this.courseLoading = false;
                            this.course = {};
                            this.landing = {};
                            apiErrorsHandle(err_1, {
                                LANDING_NOT_FOUND: function () {
                                    Message.error({ message: 'Страница курса еще не была опубликована' });
                                    var token = new URLSearchParams(window.location.search).get('registerToken') || null;
                                    if (!token) {
                                        AuthModalStore.showLogin();
                                    }
                                },
                            });
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        // Получить занятие
        getLesson: function (lessonId) {
            return __awaiter(this, void 0, void 0, function () {
                var lesson, data, lesson_1, homeworkUser, step, test, err_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            lesson = this.lessons.find(function (l) { return l.id === lessonId; });
                            this.lessonLoading = true;
                            if (lesson && lesson.step) {
                                this.step = lesson.step;
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, Api.getLesson(lessonId)];
                        case 2:
                            data = (_a.sent()).data;
                            lesson_1 = data.lesson, homeworkUser = data.homeworkUser, step = data.step, test = data.test;
                            lesson_1.homeworkUser = homeworkUser;
                            this.lesson = lesson_1;
                            this.lessons = this.lessons.map(function (l) {
                                if (l.id === lesson_1.id) {
                                    lesson_1.step = l.step;
                                    return lesson_1;
                                }
                                return l;
                            });
                            this.homeworkUser = homeworkUser;
                            this.test = test;
                            this.step = step;
                            this.lessonLoading = false;
                            return [3 /*break*/, 4];
                        case 3:
                            err_2 = _a.sent();
                            apiErrorsHandle(err_2, {
                                LESSON_FAIL_CHECK_ACCESS: 'У вас нет доступа к данному занятию'
                            });
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Показать окно смены тарифного плана курса
         *
         * @param {object} outlinedPlan тарифный план, который нужно выделить после показа окна
         * @returns {Promise}
         */
        showChangePlan: function (outlinedPlan) {
            if (outlinedPlan === void 0) { outlinedPlan = {}; }
            this.changePlanVisible = true;
            this.changePlanOutlined = outlinedPlan;
        },
        /**
         * Скрыть окно смены тарифного плана курса
         *
         * @returns {Promise}
         */
        hideChangePlan: function () {
            this.changePlanVisible = false;
            this.changePlanOutlined = null;
        },
        /**
         * Очистка стореджа от всех данных и кэша
         *
         * @returns {Promise}
         */
        clearStoreState: function () {
            if (this.clearStoreStateDisabled) {
                return;
            }
            this.$reset();
        },
        /**
         * Разрешить очистку всех стейтов стореджа
         *
         * @returns {Promise}
         */
        enableStoreStateClear: function () {
            this.clearStoreStateDisabled = false;
        },
        /**
         * Запретить очистку всех стейтов стореджа
         *
         * @returns {Promise}
         */
        disableStoreStateClear: function () {
            this.clearStoreStateDisabled = true;
        }
    },
});
