var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Mask } from './mask';
import { parseInput } from './parser';
var MaskInput = /** @class */ (function () {
    function MaskInput(target, options) {
        if (options === void 0) { options = {}; }
        var _this = this;
        this.options = options;
        this.items = new Map();
        this.beforeinputEvent = function (e) {
            var input = e.target;
            var mask = _this.items.get(input);
            // delete first character in eager mask when it's the only left
            if (mask.isEager() &&
                'inputType' in e &&
                e.inputType.startsWith('delete') &&
                mask.unmasked(input.value).length <= 1) {
                _this.setMaskedValue(input, '');
            }
        };
        this.inputEvent = function (e) {
            if (e instanceof CustomEvent &&
                e.type === 'input' &&
                e.detail != null &&
                typeof e.detail === 'object' &&
                'masked' in e.detail) {
                return;
            }
            var input = e.target;
            var mask = _this.items.get(input);
            var valueOld = input.value;
            var ss = input.selectionStart;
            var se = input.selectionEnd;
            var value = valueOld;
            if (!input.dataset.maska) {
                return;
            }
            if (mask.isEager()) {
                var masked = mask.masked(valueOld);
                var unmasked = mask.unmasked(valueOld);
                if (unmasked === '' && 'data' in e && e.data != null) {
                    // empty state and something like `space` pressed
                    value = e.data;
                }
                else if (unmasked !== mask.unmasked(masked)) {
                    value = unmasked;
                }
            }
            _this.setMaskedValue(input, value);
            // set caret position
            if ('inputType' in e && (e.inputType.startsWith('delete') ||
                (ss != null && ss < valueOld.length))) {
                try {
                    // see https://github.com/beholdr/maska/issues/118
                    input.setSelectionRange(ss, se);
                }
                catch (_a) { }
            }
        };
        if (typeof target === 'string') {
            this.init(__spreadArray([], __read(document.querySelectorAll(target)), false), this.getMaskOpts(options));
        }
        else {
            this.init('length' in target ? __spreadArray([], __read(target), false) : [target], this.getMaskOpts(options));
        }
    }
    MaskInput.prototype.destroy = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.items.keys()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var input = _c.value;
                input.removeEventListener('input', this.inputEvent);
                input.removeEventListener('beforeinput', this.beforeinputEvent);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.items.clear();
    };
    MaskInput.prototype.needUpdateOptions = function (input, opts) {
        var mask = this.items.get(input);
        var maskNew = new Mask(parseInput(input, this.getMaskOpts(opts)));
        return JSON.stringify(mask.opts) !== JSON.stringify(maskNew.opts);
    };
    MaskInput.prototype.needUpdateValue = function (input) {
        var value = input.dataset.maskaValue;
        return ((value == null && input.value !== '') ||
            (value != null && value !== input.value));
    };
    MaskInput.prototype.getMaskOpts = function (options) {
        var onMaska = options.onMaska, preProcess = options.preProcess, postProcess = options.postProcess, opts = __rest(options, ["onMaska", "preProcess", "postProcess"]);
        return opts;
    };
    MaskInput.prototype.init = function (inputs, defaults) {
        var e_2, _a;
        try {
            for (var inputs_1 = __values(inputs), inputs_1_1 = inputs_1.next(); !inputs_1_1.done; inputs_1_1 = inputs_1.next()) {
                var input = inputs_1_1.value;
                var mask = new Mask(parseInput(input, defaults));
                this.items.set(input, mask);
                if (input.value !== '') {
                    this.setMaskedValue(input, input.value);
                }
                input.addEventListener('input', this.inputEvent);
                input.addEventListener('beforeinput', this.beforeinputEvent);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (inputs_1_1 && !inputs_1_1.done && (_a = inputs_1.return)) _a.call(inputs_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    MaskInput.prototype.setMaskedValue = function (input, value) {
        var e_3, _a;
        var mask = this.items.get(input);
        if (this.options.preProcess != null) {
            value = this.options.preProcess(value);
        }
        var masked = mask.masked(value);
        var unmasked = mask.unmasked(mask.isEager() ? masked : value);
        var completed = mask.completed(value);
        var detail = {
            masked: masked,
            unmasked: unmasked,
            completed: completed
        };
        value = masked;
        if (this.options.postProcess != null) {
            value = this.options.postProcess(value);
        }
        input.value = value;
        input.dataset.maskaValue = value;
        if (this.options.onMaska != null) {
            if (Array.isArray(this.options.onMaska)) {
                try {
                    for (var _b = __values(this.options.onMaska), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var f = _c.value;
                        f(detail);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
            else {
                this.options.onMaska(detail);
            }
        }
        input.dispatchEvent(new CustomEvent('maska', { detail: detail }));
        input.dispatchEvent(new CustomEvent('input', { detail: detail }));
    };
    return MaskInput;
}());
export { MaskInput };
