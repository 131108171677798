/**
 * Защита от копирования
 */
export var copyProtected = {
    mounted: function (el, _a) {
        var value = _a.value;
        if (value) {
            el.classList.add('unselectable');
        }
    },
    updated: function (el, _a) {
        var value = _a.value;
        if (value) {
            el.classList.add('unselectable');
        }
    }
};
