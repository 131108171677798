import Dom from '@common/utils/Dom';
import './styles/styles.css';
var maskClassName = 'v-loading-mask';
var cloneBorderRadius = function (source, target) {
    var computedStyle = window.getComputedStyle(source);
    target.style.borderRadius = computedStyle.getPropertyValue('border-radius');
};
var appendLoadingMask = function (el) {
    if (!el) {
        return;
    }
    // Если маска уже присутствует не добавляем лишнюю
    if (el.querySelector(".".concat(maskClassName))) {
        return;
    }
    // Создаем маску
    var mask = Dom.make('div', maskClassName);
    // Добавляем в маску спиннер
    mask.innerHTML = '<div class="v-loading-spinner"><svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg></div>';
    // Копируем стили скругления чтобы не бросалось в глаза
    cloneBorderRadius(el, mask);
    // Добавляем в родителя
    el.append(mask);
    el.classList.add('v-loading-parent');
};
var removeLoadingMask = function (el) {
    if (!el) {
        return;
    }
    el.classList.remove('v-loading-parent');
    var mask = el.querySelector(".".concat(maskClassName));
    if (mask) {
        // Удаляем из родителя если маска существует
        mask.remove();
    }
};
export var loading = {
    mounted: function (el, binding) {
        if (binding.value || typeof binding.value === 'undefined') {
            appendLoadingMask(el);
        }
        else {
            removeLoadingMask(el);
        }
    },
    updated: function (el, binding) {
        if (binding.value) {
            appendLoadingMask(el);
        }
        else {
            removeLoadingMask(el);
        }
    },
    unmounted: function (el) {
        removeLoadingMask(el);
    },
};
