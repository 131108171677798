var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import cloneDeep from 'clone-deep';
/**
 * Глубокое клонирование (любого типа данных)
 *
 * @param {any} source данные для клонирования
 * @returns {any}
 */
export function clone(source) {
    return cloneDeep(source);
}
/**
 * Конвертировать любые данные в объект formData
 *
 * @param {any} data Любые данные
 * @param {FormData} formData ужа созданный объект FormData
 * @param {string} keyPrefix Ключ для данных
 * @returns {FormData}
 */
export function convertToFormData(data, formData, keyPrefix) {
    var e_1, _a;
    var _b;
    if (formData === void 0) { formData = null; }
    if (keyPrefix === void 0) { keyPrefix = ''; }
    if (!data) {
        return null;
    }
    var currentFormData = formData || new FormData();
    try {
        for (var _c = __values(Object.entries(data)), _d = _c.next(); !_d.done; _d = _c.next()) {
            var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
            var fullKey = !keyPrefix ? key : keyPrefix + "[".concat(key, "]");
            if (value === undefined) { }
            else if (value === null) {
                currentFormData === null || currentFormData === void 0 ? void 0 : currentFormData.append(fullKey, '');
            }
            else if (value.constructor === File) {
                currentFormData === null || currentFormData === void 0 ? void 0 : currentFormData.append(fullKey, value);
            }
            else if (value.constructor === Blob) {
                currentFormData === null || currentFormData === void 0 ? void 0 : currentFormData.append(fullKey, value, (_b = value.name) !== null && _b !== void 0 ? _b : '');
            }
            else if (Array.isArray(value)) {
                currentFormData = convertToFormData(value, currentFormData, "".concat(fullKey));
            }
            else if (value === Object(value)) {
                currentFormData = convertToFormData(value, currentFormData, "".concat(fullKey));
            }
            else {
                currentFormData === null || currentFormData === void 0 ? void 0 : currentFormData.append(fullKey, value);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return currentFormData;
}
/**
 * Конвертировать дынне в query строку (one=1&two=2&three=3&...)
 *
 * @param {object} data объект с данными
 * @returns {string}
 */
export function convertToQueryString(data) {
    return (new URLSearchParams(convertToFormData(data))).toString();
}
/**
 * Динамическая сортировка объектов в массиве по значению заданного ключа
 *
 * @param {string|number} property свойство по которому вести сортировку
 * @returns {Array}
 */
export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.slice(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        // eslint-disable-next-line no-nested-ternary
        var result = (a[property] < b[property]) ? -1 : ((a[property] > b[property]) ? 1 : 0);
        return result * sortOrder;
    };
}
/**
 * Установить текстовый указатель в конец элемента
 *
 * @param {Node} el элемент (инпут) внутри которого установить указатель
 */
export function placeCaretAtEnd(el) {
    el.focus();
    if (typeof window.getSelection != 'undefined'
        && typeof document.createRange != 'undefined') {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel === null || sel === void 0 ? void 0 : sel.removeAllRanges();
        sel === null || sel === void 0 ? void 0 : sel.addRange(range);
        // @ts-ignore
    }
    else if (typeof document.body.createTextRange != 'undefined') {
        // @ts-ignore
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
}
/**
 *
 */
export function lockBodyScroll() {
    document.body.style.overflow = 'hidden';
}
/**
 *
 */
export function unlockBodyScroll() {
    document.body.style.overflow = '';
}
/**
 * Временная зона в формате GMT±N
 *
 * @returns {string} GMT±N
 */
export function getCurrentTimezoneAbbr() {
    return new Date().toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[1];
}
/**
 * Обработчик ошибок для ответоа API
 *
 * @param {object} error
 * @param {object | null} errorsDescriptor
 * @param options
 * @param options.notifyDuration
 */
export function apiErrorsHandle(error_1) {
    return __awaiter(this, arguments, void 0, function (error, errorsDescriptor, options) {
        var Message, errors, _loop_1, errorKey, state_1;
        var _a;
        if (errorsDescriptor === void 0) { errorsDescriptor = {}; }
        if (options === void 0) { options = {}; }
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    // Если не ошибка запроса бьем ошибку и не выводим окно
                    if (!error.response) {
                        console.error(error);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, import('@common/ui/EMessage/Message')];
                case 1:
                    Message = (_b.sent()).default;
                    // Если ответ не в json или статус ошибки 500 - бьем ошибку и не выводим окно подробностей
                    if (((_a = error.response.headers['content-type']) === null || _a === void 0 ? void 0 : _a.includes('text/html')) || [500, 405].includes(error.response.status)) {
                        Message.error({
                            message: 'Неизвестная ошибка, попробуйте обновить страницу',
                            duration: 5000,
                        });
                        return [2 /*return*/];
                    }
                    errors = error.response.data;
                    if (errors) {
                        _loop_1 = function (errorKey) {
                            if (!errors.hasOwnProperty(errorKey)) {
                                return { value: void 0 };
                            }
                            var error_2 = errors[errorKey];
                            setTimeout(function () {
                                if (errorsDescriptor.hasOwnProperty(errorKey)) {
                                    if (typeof errorsDescriptor[errorKey] === 'function') {
                                        errorsDescriptor[errorKey]();
                                    }
                                    else {
                                        Message.error({
                                            message: errorsDescriptor[errorKey],
                                            duration: 6000,
                                        });
                                    }
                                }
                                else {
                                    Message.error({
                                        message: error_2 ? error_2 : errorKey,
                                        duration: options.notifyDuration ? options.notifyDuration : 10000,
                                    });
                                }
                            }, 10);
                        };
                        for (errorKey in errors) {
                            state_1 = _loop_1(errorKey);
                            if (typeof state_1 === "object")
                                return [2 /*return*/, state_1.value];
                        }
                    }
                    return [2 /*return*/];
            }
        });
    });
}
/*
 convert a Number to a two character hex string
 must round, or we will end up with more digits than expected (2)
 note: can also result in single digit, which will need to be padded with a 0 to the left
 @param: num         => the number to convert to hex
 @returns: string    => the hex representation of the provided number
 */
/**
 * @param num
 */
export function intToHex(num) {
    var hex = Math.round(num).toString(16);
    if (hex.length === 1) {
        hex = '0' + hex;
    }
    return hex;
}
export var escapeRegexpString = function (value) {
    if (value === void 0) { value = ''; }
    return String(value).replace(/[$()*+.?[\\\]^{|}]/g, '\\$&');
};
/**
 * @param text
 */
export function isKorean(text) {
    var reg = /([()|\u3130-\u318F\uAC00-\uD7AF])+/gi;
    return reg.test(text);
}
export var isEmail = function (email) {
    return email.match(/^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/);
};
export var valueEquals = function (a, b) {
    // see: https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
    if (a === b)
        return true;
    if (!(Array.isArray(a)))
        return false;
    if (!(Array.isArray(b)))
        return false;
    if (a.length !== b.length)
        return false;
    for (var i = 0; i !== a.length; ++i) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
};
export var generateId = function () {
    return Math.floor(Math.random() * 10000);
};
export var generateUuid = function () {
    var d = Date.now(); //Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; //Time in
    // microseconds since page-load or 0 if unsupported
    return ('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) { //Use timestamp until depleted
            r = Math.trunc((d + r) % 16);
            d = Math.floor(d / 16);
        }
        else { //Use microseconds since page-load if supported
            r = Math.trunc((d2 + r) % 16);
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    }));
};
export var getLessonIcon = function (lessonType) {
    return ({
        default: 'graduation-cap-bold',
        homework: 'task-bold',
        test: 'test-bold',
        webinar: 'webinar',
    })[lessonType];
};
/**
 * Проверяем есть ли доступ к занятию у ученика на основе lessonAccess массивов (проверяет именно включено ли
 * занятие в принципе, не проверяет ограничение по времени/датам)
 *
 * @param {number} lessonId id занятия
 * @param {Array<number>|null} lessonAccessArrays lessonAccess массивы, приоритет отдается первому не-null
 * массиву. Порядок
 * user/coursePlan/group/...
 * @returns {boolean}
 */
export var checkLessonAccess = function (lessonId) {
    var e_2, _a;
    var lessonAccessArrays = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        lessonAccessArrays[_i - 1] = arguments[_i];
    }
    try {
        for (var lessonAccessArrays_1 = __values(lessonAccessArrays), lessonAccessArrays_1_1 = lessonAccessArrays_1.next(); !lessonAccessArrays_1_1.done; lessonAccessArrays_1_1 = lessonAccessArrays_1.next()) {
            var lessonAccess = lessonAccessArrays_1_1.value;
            // Если null - значит ограничения отключены
            // и смотрим следующий уровень ограничений
            if (lessonAccess === null || lessonAccess === undefined) {
                continue;
            }
            if (lessonAccess.length === 0) {
                // Если массив пуст - значит доступ ко всем занятиям
                return true;
            }
            if (lessonAccess.length > 0 && !lessonAccess.includes(lessonId)) {
                // Если массив не пуст и там нет нашего занятия - запрещаем
                return false;
            }
            if (lessonAccess.length > 0 && lessonAccess.includes(lessonId)) {
                // Если массив не пуст и там есть наше занятие - разрешаем
                return true;
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (lessonAccessArrays_1_1 && !lessonAccessArrays_1_1.done && (_a = lessonAccessArrays_1.return)) _a.call(lessonAccessArrays_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return true;
};
/**
 * Конвертируем массив занятий в массив из id занятий
 *
 * @param {object[]|number[]|null} lessons Массив занятий
 * @returns {number[]}
 */
export function convertLessonsArrayToIds(lessons) {
    var accessedLessons;
    if (!lessons || lessons[0] === undefined) {
        // Занятий не передали - возвращаем пустой массив
        accessedLessons = [];
    }
    else if (typeof lessons[0] === 'object') {
        // Если занятия - объекты - вычленяем id
        var typeLessons = lessons;
        accessedLessons = __spreadArray([], __read(typeLessons.map(function (l) { return l.id; })), false);
    }
    else if (typeof lessons[0] === 'number') {
        // Если занятия - id, так и кладем
        accessedLessons = __spreadArray([], __read(lessons), false);
    }
    return accessedLessons;
}
/**
 * Функция для упрощения работы с массивом доступов к занятиям. Конвертирует данные с бэкенда в нужный
 * для вывода вид
 *
 * На входе может быть:
 *  - null (не задано)
 *  - [0] (все закрыто)
 *  - [0,1,2,3...] (некоторые занятия доступны)
 *  - [] (все занятия доступны
 *
 * На выход пойдет всегда только массив id занятий которые доступны
 *
 *
 * @param {null|number[]} field Данные в формате сервера
 * @param {object[]|number[]} allLessons Список всех занятий или список id всех занятий
 * @returns {number[]} Массив доступных занятий
 */
export function convertAccessLessonsToFrontend(field, allLessons) {
    var accessedLessons = [];
    if (field === null || (field === null || field === void 0 ? void 0 : field.length) === 0) {
        // null или пустой массив значит, что ограничений нет и доступ ко всем занятиям
        accessedLessons = convertLessonsArrayToIds(allLessons);
    }
    else if (field.length === 1 && field[0] === 0) {
        // Массив с одним нулем в качестве значения - значит доступ закрыт ко всем занятиям без исключений
        accessedLessons = [];
    }
    else {
        // Иначе просто берем массив состоящий из id занятий
        accessedLessons = field;
    }
    return accessedLessons;
}
/**
 * Функция для упрощения работы с массивом доступов к занятиям. Конвертирует данные с фронтенда в нужный
 * для бэкенда вид
 *
 * На входе может быть:
 *  - [0,1,2,3...] (некоторые или все занятия доступны)
 *  - [] (ни одно занятие не доступно)
 *
 * На выход пойдут данные формата:
 *  - 'noone' (ни к одному занятию)
 *  - 'all' (ко всем занятиям)
 *  - 'inherit' (наследовать)
 *  - [0,1,2,3] (доступ к конкретным занятиям)
 *
 * @param {number[]} selectedLessons Выбранные пользователем занятия
 * @param {object[]|number[]} allLessons Список всех занятий или список id всех занятий
 * @param {boolean} inherit Если задано true - всегда вернем значение 'inherit'
 * @returns {'noone'|'all'|'inherit'|number[]} Массив доступных занятий
 */
export function convertAccessLessonsToBackend(selectedLessons, allLessons, inherit) {
    if (inherit === void 0) { inherit = false; }
    if (inherit || selectedLessons === null) {
        return 'inherit';
    }
    if (!Array.isArray(allLessons)) {
        throw new TypeError('[Helpers.js] convertAccessLessonsToBackend: allLessons должен быть массивом');
    }
    // Конвертируем в формат занятия
    if (selectedLessons.length === 0) {
        // Пустой массив - нет доступа к занятиям
        return 'noone';
    }
    else if (selectedLessons.length === allLessons.length) {
        // Выбраны все занятия
        return 'all';
    }
    else {
        // Иначе возвращаем массив из id выбранных занятий
        return convertLessonsArrayToIds(selectedLessons);
    }
}
/**
 * Проверяет, является ли указанная ссылка нашей публичной иконкой
 *
 * @param {string} link Ссылка
 * @returns {boolean|*}
 */
export function isLinkIcon(link) {
    if (!link)
        return false;
    return link.startsWith('icons-public');
}
/**
 * Открываем ссылку в новой вкладке. Необходимо только из-за бага в IOS
 *
 * @param {string} link ссылка
 * @param isBlank
 */
export function openLink(link, isBlank) {
    if (isBlank === void 0) { isBlank = true; }
    // Фикс для IOS приложения, т.к. через window.open не работает
    var externalLink = document.createElement('a');
    externalLink.href = link;
    if (isBlank) {
        externalLink.target = '_blank';
    }
    externalLink.click();
    externalLink.remove();
}
/**
 * Return string representation of the object type
 *
 * @param {*} object - object to get type
 * @returns {string}
 */
export function typeOf(object) {
    // @ts-ignore
    return Object.prototype.toString.call(object).match(/\s([A-Za-z]+)/)[1].toLowerCase();
}
/**
 * Check if passed variable is a function
 *
 * @param {*} fn - function to check
 * @returns {boolean}
 */
export function isFunction(fn) {
    return typeOf(fn) === 'function' || typeOf(fn) === 'asyncfunction';
}
/**
 * Checks if passed argument is an object
 *
 * @param {*} v - object to check
 * @returns {boolean}
 */
export function isObject(v) {
    return typeOf(v) === 'object';
}
/**
 * Checks if passed argument is a string
 *
 * @param {*} v - variable to check
 * @returns {boolean}
 */
export function isString(v) {
    return typeOf(v) === 'string';
}
/**
 * Checks if passed argument is boolean
 *
 * @param {*} v - variable to check
 * @returns {boolean}
 */
export function isBoolean(v) {
    return typeOf(v) === 'boolean';
}
/**
 * Checks if passed argument is number
 *
 * @param {*} v - variable to check
 * @returns {boolean}
 */
export function isNumber(v) {
    return typeOf(v) === 'number';
}
/**
 * Checks if passed argument is undefined
 *
 * @param {*} v - variable to check
 * @returns {boolean}
 */
export function isUndefined(v) {
    return typeOf(v) === 'undefined';
}
/**
 * Check if passed function is a class
 *
 * @param {Function} fn - function to check
 * @returns {boolean}
 */
export function isClass(fn) {
    return isFunction(fn) && /^\s*class\s+/.test(fn.toString());
}
/**
 * Checks if object is empty
 *
 * @param {object} object - object to check
 * @returns {boolean}
 */
export function isEmpty(object) {
    if (!object) {
        return true;
    }
    return Object.keys(object).length === 0 && object.constructor === Object;
}
/**
 * Check if passed object is a Promise
 *
 * @param  {*}  object - object to check
 * @returns {boolean}
 */
export function isPromise(object) {
    return Promise.resolve(object) === object;
}
/**
 *
 */
export function isMobileBrowser() {
    var check = false;
    // @ts-ignore
    (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.slice(0, 4)))
        check = true; })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}
export var validatePassword = function (value) {
    // Проверка наличия пароля
    if (value === '') {
        return 'Пожалуйста, введите пароль';
    }
    // Проверка длины пароля
    if (value.length < 8 || value.length > 32) {
        return 'Пароль должен содержать от 8 до 32 символов';
    }
    // Проверка наличия цифр
    if (!/\d/.test(value)) {
        return 'Пароль должен содержать хотя бы одну цифру';
    }
    // Проверка наличия прописных букв
    if (!/\p{Lu}/u.test(value)) {
        return 'Пароль должен содержать хотя бы одну прописную букву';
    }
    // Проверка наличия строчных букв
    if (!/\p{Ll}/u.test(value)) {
        return 'Пароль должен содержать хотя бы одну строчную букву';
    }
    // Проверка наличия специальных символов
    if (!/[!#$%&()*+,./:;<=>?@[\\\]^_{|}~-]/.test(value)) {
        return 'Пароль должен содержать хотя бы один из следующих символов: !#$%&()*+,.\\/:;<=>?@[]^_{|}~-';
    }
    return true;
};
export var loadScript = function (FILE_URL, isAsync, type) {
    if (isAsync === void 0) { isAsync = true; }
    if (type === void 0) { type = 'text/javascript'; }
    return new Promise(function (resolve, reject) {
        try {
            var scriptEle = document.createElement('script');
            scriptEle.type = type;
            scriptEle.async = isAsync;
            scriptEle.src = FILE_URL;
            scriptEle.addEventListener('load', function (ev) {
                resolve({ status: true });
            });
            scriptEle.addEventListener('error', function (ev) {
                reject({
                    status: false,
                    message: "Failed to load the script \uFF04{FILE_URL}"
                });
            });
            document.head.append(scriptEle);
        }
        catch (error) {
            reject(error);
        }
    });
};
export var sleep = function (ms) { return new Promise(function (r) { return setTimeout(r, ms); }); };
export var getParagraphContent = function (p) {
    return p.split('<p>').join('').split('</p>').join('');
};
export var isEmptyParagraph = function (p) {
    return getParagraphContent(p).trim().length === 0;
};
export var millisToMinutesAndSeconds = function (millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = Number(((millis % 60000) / 1000).toFixed(0));
    return "".concat((minutes < 10 ? '0' : '')).concat(minutes, ":").concat((seconds < 10 ? '0' : '')).concat(seconds);
};
