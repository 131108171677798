var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { sha256 } from 'js-sha256';
import axios from 'axios';
import { storageSession } from '@common/utils/Storage/Storage';
var ChunkUpload = /** @class */ (function () {
    function ChunkUpload(_chunkBlob, position, _isUpload) {
        if (_isUpload === void 0) { _isUpload = false; }
        this._chunkBlob = _chunkBlob;
        this._isUpload = _isUpload;
        this._uploadTimestamp = new Date();
        this._progress = 0;
        this._cancelToken = axios.CancelToken.source();
        this._catalystUpload = null;
        this.setPosition(position);
        if (_isUpload) {
            this._progress = 100;
        }
    }
    ChunkUpload.CreateChunkNameByBlob = function (blob) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = sha256;
                        return [4 /*yield*/, blob.slice(0, 12).text()];
                    case 1: return [2 /*return*/, _a.apply(void 0, [(_b.sent()) + blob.type + blob.size])];
                }
            });
        });
    };
    ChunkUpload.prototype.setCatalystUpload = function (catalystUpload) {
        this._catalystUpload = catalystUpload;
    };
    ChunkUpload.prototype.setPosition = function (position) {
        if (position < 1) {
            throw new Error('Позиция чанка не может быть меньше 1');
        }
        this._position = position;
    };
    ChunkUpload.prototype.getPosition = function () {
        return this._position;
    };
    ChunkUpload.prototype.getIsUpload = function () {
        return this._isUpload;
    };
    ChunkUpload.prototype.getFingerprint = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ChunkUpload.CreateChunkNameByBlob(this._chunkBlob)];
            });
        });
    };
    ChunkUpload.prototype.getBlob = function () {
        return this._chunkBlob;
    };
    ChunkUpload.prototype.getCancelToken = function () {
        return this._cancelToken.token;
    };
    ChunkUpload.prototype.declineUpload = function () {
        this._cancelToken.cancel('Abort ChunkUpload');
    };
    ChunkUpload.prototype.saveToCache = function () {
        return __awaiter(this, void 0, void 0, function () {
            var id, uploadedChunksCache, _a, _b, _c, _d, _e, _f;
            var _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        if (!this._catalystUpload) {
                            throw new Error('Не передан CatalystUpload; Используй setCatalystUpload для передачи');
                        }
                        id = this._catalystUpload.getFileCatalyst().getIdentifyId();
                        uploadedChunksCache = storageSession.get('uploadedChunks');
                        _b = (_a = storageSession).set;
                        _c = ['uploadedChunks'];
                        _d = [__assign({}, uploadedChunksCache)];
                        _g = {};
                        _e = id;
                        _f = [__assign({}, uploadedChunksCache === null || uploadedChunksCache === void 0 ? void 0 : uploadedChunksCache[id])];
                        _h = {};
                        return [4 /*yield*/, this.getFingerprint()];
                    case 1:
                        _b.apply(_a, _c.concat([__assign.apply(void 0, _d.concat([(_g[_e] = __assign.apply(void 0, _f.concat([(_h[_j.sent()] = {
                                        uploadTimestamp: this._uploadTimestamp,
                                        position: this._position
                                    }, _h)])), _g)]))]));
                        return [2 /*return*/];
                }
            });
        });
    };
    ChunkUpload.prototype.setUpload = function () {
        this._uploadTimestamp = new Date();
        this._isUpload = true;
        void this.saveToCache();
    };
    ChunkUpload.prototype.setProgress = function (progress) {
        if (progress < 0 || progress > 100) {
            throw new Error("\u041D\u0435\u043A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u044B\u0439 \u043F\u0440\u043E\u0433\u0440\u0435\u0441\u0441 - ".concat(progress));
        }
        this._progress = progress;
        if (!this._catalystUpload) {
            throw new Error('Не передан CatalystUpload; Используй setCatalystUpload для передачи');
        }
        this._catalystUpload.updateProgress();
        if (progress === 100) {
            this.setUpload();
        }
    };
    ChunkUpload.prototype.getProgress = function () {
        return this._progress;
    };
    return ChunkUpload;
}());
export { ChunkUpload };
