import dayjs from 'dayjs';
// Dayjs tools
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/kk';
import 'dayjs/locale/uz';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
// Подключаем плагины dayjs
import dayjsTimezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekDay from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { watch } from 'vue';
dayjs.extend(dayjsTimezone);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(weekDay);
dayjs.extend(weekOfYear);
// Функция синхронизации локали
export function syncDayjsLocale(i18n) {
    dayjs.locale(i18n.global.locale.value); // Устанавливаем начальную локаль
    watch(function () { return i18n.global.locale.value; }, function (newLocale) {
        dayjs.locale(newLocale);
    });
}
// Устанавливаем русскую локаль по умолчанию
dayjs.locale('ru');
