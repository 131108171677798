var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApiBase } from '@common/api/apiBase';
import { httpClientApiSchool } from '@common/utils/HttpClient';
import { convertToFormData } from '@common/utils/Helpers/Helpers';
import { FileUploadApi, FileUploadNamespace } from '@common/api/infrastructure/fileUpload';
var HomeworkApi = /** @class */ (function (_super) {
    __extends(HomeworkApi, _super);
    function HomeworkApi() {
        var _this = _super.call(this) || this;
        _this._fileUploadApi = new FileUploadApi(FileUploadNamespace.HOMEWORK);
        return _this;
    }
    // Грузим файл на s3
    HomeworkApi.prototype.createFile = function (uuid, data) {
        return this._fileUploadApi.createFile(uuid, data);
    };
    // Удаляем файл
    HomeworkApi.prototype.removeFile = function (uuid) {
        return this._fileUploadApi.removeFile(uuid);
    };
    // Получить список ответов на домашки
    HomeworkApi.prototype.getHomeworkThreads = function (requestParamsDto) {
        var params = __assign(__assign({}, requestParamsDto), { filters: JSON.stringify(requestParamsDto.filters) });
        return httpClientApiSchool.get("/homework-user/list", { params: params });
    };
    // Отправить ответ в домашку
    HomeworkApi.prototype.sendAnswer = function (data) {
        var request = convertToFormData(data);
        return httpClientApiSchool.post("/homework-user/answer", request);
    };
    // Изменит ответ домашки
    HomeworkApi.prototype.changeAnswer = function (answerId, data) {
        var request = convertToFormData(data);
        return httpClientApiSchool.post("/homework-user/answer/".concat(answerId), request);
    };
    // Получить информацию по ответам домашки
    HomeworkApi.prototype.getHomeworkInfo = function () {
        return httpClientApiSchool.get('/homework-user/info');
    };
    return HomeworkApi;
}(ApiBase));
export var homeworkApi = new HomeworkApi();
