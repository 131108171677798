import passData from '@common/utils/PassData';
/**
 * Check feature access
 *
 * @param feature
 * @returns boolean
 */
export function canUseFeature(feature) {
    return passData.schoolFeaturesAccess.includes(feature);
}
