export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])}
        }
      },
      "ru": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["из"])}
        }
      },
      "es": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])}
        }
      },
      "kk": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бет"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ден"])}
        }
      },
      "uz": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahifa"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dan"])}
        }
      },
      "de": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])}
        }
      },
      "fr": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])}
        }
      },
      "it": {
        "pdfViewer": {
          "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina"])},
          "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])}
        }
      }
    }
  })
}
