import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import CurrentI18n from '@common/utils/CurrentI18n';
import { translations } from './Format.i18n';
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
/**
 * Склоняем по числам
 *
 * @param {number} number число
 * @param {Array<string>} titles принимает массив слов: ['совпадение', 'совпадения', 'совпадений']
 * Одно "совпадение"
 * Два "совпадения"
 * Пять "совпадений"
 * @returns {string} подобранное слово
 */
export function numeralize(number, titles) {
    var locale = CurrentI18n.getInstance().global.locale;
    var lang = locale.value;
    number = typeof number === 'string' ? parseInt(number) : number;
    if (lang === 'ru') {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20)
            ? 2
            : cases[(number % 10 < 5) ? number % 10 : 5]];
    }
    else {
        return number === 1 ? titles[0] : titles[1];
    }
}
/**
 * Формитаривание цены
 */
// @ts-ignore
var decimalFormatter = new Intl.NumberFormat({ style: 'decimal', });
/**
 * Форматируем цену
 *
 * @param {number} value Value
 * @returns {string} value
 */
export function formatPrice(value) {
    if (Number.isFinite(value) && !isNaN(Number.isFinite(value))) {
        return decimalFormatter.format(value);
    }
}
/**
 * Форматируем дату
 *
 * @param {string|number|Date} value date value
 * @param {string} format format
 * @returns {string} formatted date
 */
export function formatDate(value, format) {
    if (format === void 0) { format = 'DD.MM.YYYY HH:mm'; }
    if (value) {
        var date = new Date(value);
        return dayjs(date).format(format);
    }
}
/**
 * Форматируем дату с учетом дня (сегодня/вчера)
 *
 * @param {string|number|Date} value date value
 * @param {string} format format
 * @returns {string} formatted date
 */
export function formatDateDay(value, format) {
    if (format === void 0) { format = 'DD.MM.YYYY HH:mm'; }
    if (!value)
        return '';
    var date = dayjs(value);
    var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
    var lang = locale.value;
    if (date.isToday()) {
        return t(translations[lang].today_at) + "".concat(date.format('HH:mm'));
    }
    else if (date.isYesterday()) {
        return t(translations[lang].yesterday_at) + "".concat(date.format('HH:mm'));
    }
    else {
        return date.format(format);
    }
}
