var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { loadScript } from '@common/utils/Helpers/Helpers';
var loadGoogleAnalytics = function (code) { return __awaiter(void 0, void 0, Promise, function () {
    function gtag() {
        window.dataLayer.push(arguments);
    }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, loadScript("https://www.googletagmanager.com/gtag/js?id=".concat(code))];
            case 1:
                _a.sent();
                window.dataLayer = window.dataLayer || [];
                gtag('js', new Date());
                gtag('config', code, { 'debug_mode': true });
                return [2 /*return*/, gtag];
        }
    });
}); };
// const COUNTER_ID = envVars.GOOGLE_COUNTER_ID
var COUNTER_ID = 'G-0PZWR7RLGR';
export var GAEventsEnum;
(function (GAEventsEnum) {
    GAEventsEnum["REGISTER"] = "sign_up";
})(GAEventsEnum || (GAEventsEnum = {}));
var eventMapsData = {
    register: { 'sign_up': 'Регистрация на платформе' },
};
var GoogleAnalytics = /** @class */ (function () {
    function GoogleAnalytics() {
    }
    GoogleAnalytics.setGtagPlatform = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = GoogleAnalytics;
                        return [4 /*yield*/, loadGoogleAnalytics(COUNTER_ID)];
                    case 1:
                        _a.gtagPlatform = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GoogleAnalytics.getGtagPlatform = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!GoogleAnalytics.gtagPlatform) return [3 /*break*/, 2];
                        return [4 /*yield*/, GoogleAnalytics.setGtagPlatform()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, GoogleAnalytics.gtagPlatform];
                }
            });
        });
    };
    GoogleAnalytics.prototype.sendPlatformEvent = function (event, data) {
        return __awaiter(this, void 0, void 0, function () {
            var gtag, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, GoogleAnalytics.getGtagPlatform()];
                    case 1:
                        gtag = _a.sent();
                        gtag('event', event, data !== null && data !== void 0 ? data : eventMapsData[event]);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error('sendPlatformEvent GM', e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GoogleAnalytics.prototype.sendEvent = function (code, event, data) {
        return __awaiter(this, void 0, void 0, function () {
            var gtag, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, loadGoogleAnalytics(code)];
                    case 1:
                        gtag = _a.sent();
                        gtag('event', event, data !== null && data !== void 0 ? data : eventMapsData[event]);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error('sendEvent GM', e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return GoogleAnalytics;
}());
export var googleAnalytics = new GoogleAnalytics();
