var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { httpClient } from '@common/utils/HttpClient';
import { generateUuid } from '@common/utils/Helpers/Helpers';
/**
 * Скачать файл в браузере по указанной ссылке
 *
 * @param {url} url Ссылка для скачивания
 * @param {string} name Название файла
 */
export function downloadFileByURL(url, name) {
    if (name === void 0) { name = ''; }
    var link = document.createElement('a');
    link.setAttribute('download', name);
    link.setAttribute('target', '_blank');
    link.href = url;
    document.body.append(link);
    link.click();
    link.remove();
}
/**
 * Конвертирует объект File в base64 строку
 *
 * @param {File} file Файл для конвертации
 * @returns {Promise<string>}
 */
export var fileToBase64 = function (file) { return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', function () { return resolve(reader.result); });
    reader.addEventListener('error', function (error) { return reject(error); });
}); };
/**
 * Base64 или blob строку в File объект
 *
 * @param {string} dataUrl Дата-строка base64 или blob: строка
 * @param {string} filename Название файла
 * @param {string} fileExt Расширение файла
 */
export function base64ToFile(dataUrl, filename, fileExt) {
    return __awaiter(this, void 0, void 0, function () {
        var response, file;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(dataUrl)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.blob()];
                case 2:
                    file = _a.sent();
                    // Если названия нет - генерируем
                    if (!filename) {
                        filename = generateUuid();
                    }
                    // Если расширение файла не указано - пытаемся его получить из mime типа
                    if (!fileExt) {
                        fileExt = mimeToExtension(file.type);
                    }
                    // Если есть расширение файла - добавляем к названию
                    if (fileExt) {
                        filename = filename.replace(/\.[^./]+$/, '') + ".".concat(fileExt);
                    }
                    file.name = filename;
                    file.lastModifiedDate = new Date();
                    return [2 /*return*/, file];
            }
        });
    });
}
/**
 *
 * @param base64String
 * @param contentType
 */
export function base64ToBlobV2(base64String, contentType) {
    if (contentType === void 0) { contentType = ''; }
    var byteCharacters = atob(base64String);
    var byteArrays = [];
    for (var i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }
    var byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
}
export var downloadBase64 = function (base64String, fileName) {
    var a = document.createElement('a'); //Create <a>
    a.href = base64String; //Image Base64 Goes here
    a.download = fileName || 'Файл'; //File name Here
    a.click(); //Downloaded file
    a.remove();
};
/**
 * Скачать объект File
 *
 * @param {File} file Файл для скачивания
 * @returns {any}
 */
export function downloadFile(file) {
    // @ts-ignore
    if (file !== null && navigator.msSaveBlob)
        // @ts-ignore
        return navigator.msSaveBlob(file, file.name);
    var a = document.createElement('a');
    var url = window.URL.createObjectURL(file);
    a.href = url;
    a.download = file.name;
    document.body.append(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
/**
 * Скачать объект File
 *
 * @param {File} file Файл для скачивания
 * @param base64Data
 * @param mimeType
 * @param fileName
 * @returns {any}
 */
export function openFileInNewTabSave(base64Data, mimeType, fileName) {
    var linkSource = "data:".concat(mimeType, ";base64,").concat(base64Data.split(',')[1]);
    var downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    document.body.append(downloadLink);
    downloadLink.click();
    downloadLink.remove();
}
/**
 * Выбрать файлы для загрузки куда-либо
 *
 * @param {boolean} multiple Выбор нескольких файлов
 * @param {string} accept Строка для фильтра типов файлов при выборе
 * @returns {Promise<File[]>}
 */
export function selectSystemFile(multiple, accept) {
    if (multiple === void 0) { multiple = false; }
    if (accept === void 0) { accept = ''; }
    return new Promise(function (resolve, reject) {
        var file = document.createElement('input');
        file.type = 'file';
        if (multiple) {
            file.multiple = true;
        }
        if (accept) {
            file.accept = accept;
        }
        file.addEventListener('change', function (e) {
            var target = e.target;
            var files = target === null || target === void 0 ? void 0 : target.files;
            if (files && files.length > 0) {
                // @ts-ignore
                resolve(multiple ? __spreadArray([], __read(files), false) : files[0]);
            }
            else {
                resolve(null);
            }
            file.remove();
        });
        document.body.append(file);
        file.click();
    });
}
/**
 *
 * @param base64
 * @param mimeType
 */
export var base64ToBlob = function (base64, mimeType) {
    var byteString = atob(base64.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
};
/**
 * Format bytes as human-readable text.
 *
 * @param {number} bytes Number of bytes.
 * @param {number} dp Number of decimal places to display.
 * @returns {string} Formatted string.
 */
export function getHumanFileSize(bytes, dp) {
    if (dp === void 0) { dp = 1; }
    var thresh = 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = ['Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Еб', 'Зб', 'Йб'];
    var u = -1;
    var r = Math.pow(10, dp);
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + ' ' + units[u];
}
export var readUploadedFileAsArrayBuffer = function (blob) {
    var temporaryFileReader = new FileReader();
    return new Promise(function (resolve, reject) {
        temporaryFileReader.addEventListener('error', function () {
            temporaryFileReader.abort();
            reject(new DOMException('Ошибка преобразования файла в ArrayBuffer'));
        });
        temporaryFileReader.addEventListener('loadend', function () {
            resolve(temporaryFileReader.result);
        });
        temporaryFileReader.readAsArrayBuffer(blob);
    });
};
export var urlToBlob = function (url) { return __awaiter(void 0, void 0, Promise, function () {
    var res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, httpClient.get(url, {
                    baseURL: '/api',
                    responseType: 'blob',
                })];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.data];
        }
    });
}); };
export var urlToPng = function (url_1, maxWidth_1, maxHeight_1) {
    var args_1 = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        args_1[_i - 3] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([url_1, maxWidth_1, maxHeight_1], __read(args_1), false), Promise, function (url, maxWidth, maxHeight, quality) {
        if (quality === void 0) { quality = 1; }
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var image = new Image();
                    image.crossOrigin = 'anonymous';
                    image.addEventListener('load', function () {
                        var width = image.width;
                        var height = image.height;
                        if (width > maxWidth || height > maxHeight) {
                            var aspectRatio = width / height;
                            if (aspectRatio > 1) {
                                width = maxWidth;
                                height = maxWidth / aspectRatio;
                            }
                            else {
                                height = maxHeight;
                                width = maxHeight * aspectRatio;
                            }
                        }
                        var canvas = document.createElement('canvas');
                        var context = canvas.getContext('2d');
                        if (!context) {
                            reject(new Error('Canvas context is not supported'));
                            return;
                        }
                        canvas.width = width;
                        canvas.height = height;
                        context.drawImage(image, 0, 0, width, height);
                        try {
                            var dataURL = canvas.toDataURL('image/png', quality);
                            resolve(dataURL);
                        }
                        catch (error) {
                            reject(error);
                        }
                    });
                    image.addEventListener('error', function () {
                        reject(new Error('Failed to load the image'));
                    });
                    image.src = url;
                })];
        });
    });
};
export var mergeImages = function (firstUrl, secondUrl) {
    return new Promise(function (resolve, reject) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var image1 = new Image();
        image1.src = firstUrl;
        image1.addEventListener('load', function () {
            var image2 = new Image();
            image2.src = secondUrl;
            image2.addEventListener('load', function () {
                var percentHeight = image2.height / 100;
                var percentWidth = image2.width / 100;
                var offsetPercent = 0;
                if (image2.height > image1.height) {
                    offsetPercent = Math.floor((image2.height - image1.height) / percentHeight);
                }
                var scaleWidth = image1.width + (offsetPercent ? percentWidth * offsetPercent : 0);
                var x = (scaleWidth - image2.width);
                canvas.width = image2.width;
                canvas.height = image2.height;
                context === null || context === void 0 ? void 0 : context.drawImage(image1, -(x / 2), 0, scaleWidth, image2.height);
                context === null || context === void 0 ? void 0 : context.drawImage(image2, 0, 0);
                // Получение содержимого совмещенных картинок в формате PNG
                var dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            });
        });
    });
};
export var urlToFile = function (url_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([url_1], __read(args_1), false), Promise, function (url, name) {
        var blob;
        if (name === void 0) { name = 'file'; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, urlToBlob(url)];
                case 1:
                    blob = _a.sent();
                    return [2 /*return*/, new File([blob], name, { type: blob.type })];
            }
        });
    });
};
export var cloneBlob = function (b) { return new Promise(function (resolve, reject) {
    var r = new FileReader();
    r.readAsArrayBuffer(b);
    r.addEventListener('load', function (_) {
        resolve(new Blob([r.result], { type: b.type }));
    });
    r.addEventListener('error', function (_) {
        reject();
    });
}); };
/**
 * Получить расширение файла из mime type
 *
 * @param mimeType
 * @function Object() { [native code] }
 */
export var mimeToExtension = function (mimeType) {
    return {
        'audio/x-mpeg': 'mpega',
        'application/postscript': 'ps',
        'audio/x-aiff': 'aiff',
        'application/x-aim': 'aim',
        'image/x-jg': 'art',
        'video/x-ms-asf': 'asx',
        'audio/basic': 'ulw',
        'video/x-msvideo': 'avi',
        'video/x-rad-screenplay': 'avx',
        'application/x-bcpio': 'bcpio',
        'application/octet-stream': 'exe',
        'image/bmp': 'dib',
        'text/html': 'html',
        'application/x-cdf': 'cdf',
        'application/pkix-cert': 'cer',
        'application/java': 'class',
        'application/x-cpio': 'cpio',
        'application/x-csh': 'csh',
        'text/css': 'css',
        'application/msword': 'doc',
        'application/xml-dtd': 'dtd',
        'video/x-dv': 'dv',
        'application/x-dvi': 'dvi',
        'application/vnd.ms-fontobject': 'eot',
        'text/x-setext': 'etx',
        'image/gif': 'gif',
        'application/x-gtar': 'gtar',
        'application/x-gzip': 'gz',
        'application/x-hdf': 'hdf',
        'application/mac-binhex40': 'hqx',
        'text/x-component': 'htc',
        'image/ief': 'ief',
        'text/vnd.sun.j2me.app-descriptor': 'jad',
        'application/java-archive': 'jar',
        'text/x-java-source': 'java',
        'application/x-java-jnlp-file': 'jnlp',
        'image/jpeg': 'jpg',
        'application/javascript': 'js',
        'text/plain': 'txt',
        'application/json': 'json',
        'audio/midi': 'midi',
        'application/x-latex': 'latex',
        'audio/x-mpegurl': 'm3u',
        'image/x-macpaint': 'pnt',
        'text/troff': 'tr',
        'application/mathml+xml': 'mathml',
        'application/x-mif': 'mif',
        'video/quicktime': 'qt',
        'video/x-sgi-movie': 'movie',
        'audio/mpeg': 'mpa',
        'video/mp4': 'mp4',
        'video/mpeg': 'mpg',
        'video/mpeg2': 'mpv2',
        'application/x-wais-source': 'src',
        'application/x-netcdf': 'nc',
        'application/oda': 'oda',
        'application/vnd.oasis.opendocument.database': 'odb',
        'application/vnd.oasis.opendocument.chart': 'odc',
        'application/vnd.oasis.opendocument.formula': 'odf',
        'application/vnd.oasis.opendocument.graphics': 'odg',
        'application/vnd.oasis.opendocument.image': 'odi',
        'application/vnd.oasis.opendocument.text-master': 'odm',
        'application/vnd.oasis.opendocument.presentation': 'odp',
        'application/vnd.oasis.opendocument.spreadsheet': 'ods',
        'application/vnd.oasis.opendocument.text': 'odt',
        'application/vnd.oasis.opendocument.graphics-template': 'otg',
        'application/vnd.oasis.opendocument.text-web': 'oth',
        'application/vnd.oasis.opendocument.presentation-template': 'otp',
        'application/vnd.oasis.opendocument.spreadsheet-template': 'ots',
        'application/vnd.oasis.opendocument.text-template': 'ott',
        'application/ogg': 'ogx',
        'video/ogg': 'ogv',
        'audio/ogg': 'spx',
        'application/x-font-opentype': 'otf',
        'audio/flac': 'flac',
        'application/annodex': 'anx',
        'audio/annodex': 'axa',
        'video/annodex': 'axv',
        'application/xspf+xml': 'xspf',
        'image/x-portable-bitmap': 'pbm',
        'image/pict': 'pict',
        'application/pdf': 'pdf',
        'image/x-portable-graymap': 'pgm',
        'audio/x-scpls': 'pls',
        'image/png': 'png',
        'image/x-portable-anymap': 'pnm',
        'image/x-portable-pixmap': 'ppm',
        'application/vnd.ms-powerpoint': 'pps',
        'image/vnd.adobe.photoshop': 'psd',
        'image/x-quicktime': 'qtif',
        'image/x-cmu-raster': 'ras',
        'application/rdf+xml': 'rdf',
        'image/x-rgb': 'rgb',
        'application/vnd.rn-realmedia': 'rm',
        'application/rtf': 'rtf',
        'text/richtext': 'rtx',
        'application/font-sfnt': 'sfnt',
        'application/x-sh': 'sh',
        'application/x-shar': 'shar',
        'application/x-stuffit': 'sit',
        'application/x-sv4cpio': 'sv4cpio',
        'application/x-sv4crc': 'sv4crc',
        'image/svg+xml': 'svgz',
        'application/x-shockwave-flash': 'swf',
        'application/x-tar': 'tar',
        'application/x-tcl': 'tcl',
        'application/x-tex': 'tex',
        'application/x-texinfo': 'texinfo',
        'image/tiff': 'tiff',
        'text/tab-separated-values': 'tsv',
        'application/x-font-ttf': 'ttf',
        'application/x-ustar': 'ustar',
        'application/voicexml+xml': 'vxml',
        'image/x-xbitmap': 'xbm',
        'application/xhtml+xml': 'xhtml',
        'application/vnd.ms-excel': 'xls',
        'application/xml': 'xsl',
        'image/x-xpixmap': 'xpm',
        'application/xslt+xml': 'xslt',
        'application/vnd.mozilla.xul+xml': 'xul',
        'image/x-xwindowdump': 'xwd',
        'application/vnd.visio': 'vsd',
        'audio/x-wav': 'wav',
        'image/vnd.wap.wbmp': 'wbmp',
        'text/vnd.wap.wml': 'wml',
        'application/vnd.wap.wmlc': 'wmlc',
        'text/vnd.wap.wmlsc': 'wmls',
        'application/vnd.wap.wmlscriptc': 'wmlscriptc',
        'video/x-ms-wmv': 'wmv',
        'application/font-woff': 'woff',
        'application/font-woff2': 'woff2',
        'model/vrml': 'wrl',
        'application/wspolicy+xml': 'wspolicy',
        'application/x-compress': 'z',
        'application/zip': 'zip'
    }[mimeType];
};
export var getImageOrientation = function (file) {
    return new Promise(function (resolve) {
        var img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.addEventListener('load', function () {
            var height = img.height;
            var width = img.width;
            var a = height >= width ? 'vertical' : 'horizontal';
            resolve(a);
        });
    });
};
/**
 * Получение размеров загруженной картинки
 * @param file
 */
export var getImageDimensions = function (file) {
    return new Promise(function (resolve) {
        var img = new Image();
        var url = window.URL.createObjectURL(file);
        img.src = url;
        img.addEventListener('load', function () {
            var height = img.height;
            var width = img.width;
            window.URL.revokeObjectURL(url);
            resolve({ width: width, height: height });
        });
    });
};
