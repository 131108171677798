var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { httpClientApiSchool } from '@common/utils/HttpClient';
var RolesApi = /** @class */ (function (_super) {
    __extends(RolesApi, _super);
    function RolesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RolesApi.prototype.getActions = function () {
        return httpClientApiSchool.get("/school/employee/role/action-map");
    };
    RolesApi.prototype.getRoles = function () {
        return httpClientApiSchool.get("/school/employee/role/list");
    };
    RolesApi.prototype.createRole = function (data) {
        return httpClientApiSchool.post("/school/employee/role/create", data);
    };
    RolesApi.prototype.updateRole = function (uuid, data) {
        return httpClientApiSchool.post("/school/employee/role/".concat(uuid, "/update"), data);
    };
    RolesApi.prototype.removeRole = function (uuid) {
        return httpClientApiSchool.delete("/school/employee/role/".concat(uuid, "/remove"));
    };
    return RolesApi;
}(ApiBase));
export default new RolesApi();
