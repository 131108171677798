var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { convertToFormData, convertToQueryString, } from '@common/utils/Helpers/Helpers';
import { httpClient } from '@common/utils/HttpClient';
import { ApiBase } from '@common/api/apiBase';
import Auth from '@common/modules/auth';
var ApiCommonClass = /** @class */ (function (_super) {
    __extends(ApiCommonClass, _super);
    function ApiCommonClass() {
        return _super.call(this) || this;
    }
    /**
     * USER
     */
    /**
     * Обновление данных пользователя
     *
     * @param {object} data Данные для обновления
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.updateUser = function (data) {
        data = convertToFormData(data);
        return httpClient.post('/user/update', data, { baseURL: '/api' });
    };
    /**
     * Обновление email пользователя
     *
     * @param {string} email почта
     * @param {string} password пароль для подтверждения
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.changeEmail = function (email, password) {
        var data = convertToFormData({
            email: email,
            password: password,
        });
        return httpClient.post('/user/change-email-initialization', data, { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Обновление пароля
     *
     * @param {object} data Данные для смены
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.changePassword = function (data) {
        data = convertToFormData(data);
        return httpClient.post('/user/change-password', data, { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Зарегистрировать пользователя в школе
     *
     * @param {object} data Данные пользователя
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.registerUser = function (data) {
        data = convertToFormData(__assign(__assign({}, data), { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
        return httpClient.post('/user/register', data, { baseURL: '/api' })
            .then(function (res) {
            var data = res.data;
            Auth.setUser(data.user);
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Восстановить пароль
     *
     * @param {string} email Почта
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.recoverPassword = function (email) {
        var data = convertToFormData({ email: email });
        return httpClient.post('/user/password-recovery', data, { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * AFFILIATE (Partner/партнерка)
     */
    /**
     * Список событий партнерки
     *
     * @param {number} page Страница
     * @param {Array<'register'|'trial'|'payment'>} event Событие
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.getAffiliateEvents = function (page, event) {
        var query = convertToQueryString({
            page: page,
            event: event,
        });
        return httpClient.get("/affiliate/event/list?".concat(query), { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Список уровней
     *
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.getAffiliateLevels = function () {
        return httpClient.get('/affiliate/level/list', { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Информация по партнерке текущего пользователя
     *
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.getPartnerCurrent = function () {
        return httpClient.get('/affiliate/partner/current', { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Регистрация партнера
     *
     * @param fields
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.registerPartner = function (fields) {
        fields = convertToFormData(fields);
        return httpClient.post('/affiliate/partner/register', fields, { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Создание новых реквизитов партнера
     *
     * @param data
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.createPartnerRequisites = function (data) {
        data = convertToFormData(data);
        return httpClient.post('/affiliate/requisites/create', data, { baseURL: '/api' });
    };
    /**
     * Создание запроса на выплату средств с партнерки
     *
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.createPartnerWithdraw = function () {
        return httpClient.post('/affiliate/withdraw/create', {}, { baseURL: '/api' });
    };
    /**
     * Список запросов на выплаты
     *
     * @param {number} page Страница
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.getPartnerWithdraws = function (page) {
        return httpClient.get("/affiliate/withdraw/list?page=".concat(page), { baseURL: '/api' });
    };
    /**
     * Получение списка чатов для ученика
     *
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.getChats = function () {
        return httpClient.get("/chat/list", { baseURL: '/api' });
    };
    /**
     * Редактирование сообщения
     *
     * @param {number} chatId ID чата
     * @param {number} messageId ID сообщения
     * @param {object} data Даные для изменения
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.editChatMessage = function (chatId, messageId, data) {
        data = convertToFormData(data);
        return httpClient.post("/chat/".concat(chatId, "/message/").concat(messageId, "/update"), data, { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Удаление сообщений
     *
     * @param {number} chatId ID чата
     * @param {Array<number>} messageIds Массив из id сообщений
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.deleteChatMessages = function (chatId, messageIds) {
        var data = convertToFormData({ messageIds: messageIds });
        return httpClient.post("/chat/".concat(chatId, "/message/remove"), data, { baseURL: '/api' })
            .then(function (res) {
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    /**
     * Пометить сообщение прочитанным
     *
     * @param {number} chatId ID чата
     * @returns {Promise<object>}
     */
    ApiCommonClass.prototype.markChatRead = function (chatId) {
        return httpClient.post("/chat/".concat(chatId, "/mark-read"), {}, { baseURL: '/api' });
    };
    ApiCommonClass.prototype.getEmployee = function (uuid) {
        return httpClient.get("/api/student/school/employee/".concat(uuid));
    };
    ApiCommonClass.prototype.sendAmoUserEmail = function (userEmail) {
        var data = convertToFormData({ email: userEmail });
        return httpClient.post('/api/school/register-amo-email', data);
    };
    return ApiCommonClass;
}(ApiBase));
export { ApiCommonClass };
export default new ApiCommonClass();
