export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Recovery"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Recovery Email"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow the link in the email"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with instructions to reset your password has been sent to your email"])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user with this email does not exist"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email entered"])}
          }
        }
      },
      "ru": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш Email"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш email"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить письмо для восстановления"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейдите по ссылке из письма"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На вашу почту отправлено письмо с инструкциями для восстановления пароля"])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в почту"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятно"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите свой email"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите корректный email"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь с таким email не существует"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введён некорректный email"])}
          }
        }
      },
      "es": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperación de contraseña"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar correo de recuperación"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accede al enlace enviado por correo"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha enviado un correo a tu dirección con instrucciones para recuperar la contraseña"])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al correo"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendido"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce tu correo electrónico"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce un correo electrónico válido"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe ningún usuario con ese correo electrónico"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico inválido"])}
          }
        }
      },
      "kk": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль қалпына келтіру"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің электрондық поштаңыз"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің электрондық поштаңыз"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру хатын жіберу"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаттағы сілтемеге өтіңіз"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парольді қалпына келтіру бойынша нұсқаулар бар хат сіздің поштаңызға жіберілді"])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поштаға өту"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсіндім"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінемін, электрондық поштаңызды енгізіңіз"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінемін, дұрыс электрондық пошта енгізіңіз"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұндай электрондық поштаға ие пайдаланушы жоқ"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате электрондық пошта енгізілді"])}
          }
        }
      },
      "uz": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolni tiklash"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailingiz"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailingiz"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiklash uchun xat yuborish"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orqaga"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xatdagi havolaga o'ting"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailingizga parolni tiklash bo'yicha ko'rsatmalar bilan xat yuborildi"])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailga o'tish"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tushunarli"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, elektron pochtangizni kiriting"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, to'g'ri elektron pochta manzilini kiriting"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunday elektron pochta manziliga ega foydalanuvchi mavjud emas"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiritilgan elektron pochta manzili noto'g'ri"])}
          }
        }
      },
      "de": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort Wiederherstellung"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellungsemail senden"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgen Sie dem Link in der E-Mail"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine E-Mail mit Anweisungen zur Wiederherstellung Ihres Passworts wurde an Ihre E-Mail-Adresse gesendet"])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur E-Mail wechseln"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstanden"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail-Adresse ein"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine gültige E-Mail-Adresse ein"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Benutzer mit dieser E-Mail existiert nicht"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail-Adresse eingegeben"])}
          }
        }
      },
      "fr": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un email de réinitialisation"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le lien dans l'email"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email avec les instructions pour réinitialiser votre mot de passe a été envoyé à votre adresse"])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la messagerie"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compris"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre email"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un email valide"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur avec cet email n'existe"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email invalide"])}
          }
        }
      },
      "it": {
        "passwordRecovery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero della password"])},
          "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo indirizzo email"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo indirizzo email"])},
          "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia email per il recupero"])},
          "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
          "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segui il link nell'email"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un'email con le istruzioni per il recupero della password è stata inviata al tuo indirizzo email."])},
          "goToMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla tua email"])},
          "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho capito"])},
          "validation": {
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la tua email"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci un'email valida"])}
          },
          "errors": {
            "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non esiste un utente con questa email"])},
            "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email non valida inserita"])}
          }
        }
      }
    }
  })
}
