var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var methodMap = [
    [
        'requestFullscreen',
        'exitFullscreen',
        'fullscreenElement',
        'fullscreenEnabled',
        'fullscreenchange',
        'fullscreenerror',
    ],
    // New WebKit
    [
        'webkitRequestFullscreen',
        'webkitExitFullscreen',
        'webkitFullscreenElement',
        'webkitFullscreenEnabled',
        'webkitfullscreenchange',
        'webkitfullscreenerror',
    ],
    // Old WebKit
    [
        'webkitRequestFullScreen',
        'webkitCancelFullScreen',
        'webkitCurrentFullScreenElement',
        'webkitCancelFullScreen',
        'webkitfullscreenchange',
        'webkitfullscreenerror',
    ],
    [
        'mozRequestFullScreen',
        'mozCancelFullScreen',
        'mozFullScreenElement',
        'mozFullScreenEnabled',
        'mozfullscreenchange',
        'mozfullscreenerror',
    ],
    [
        'msRequestFullscreen',
        'msExitFullscreen',
        'msFullscreenElement',
        'msFullscreenEnabled',
        'MSFullscreenChange',
        'MSFullscreenError',
    ],
];
var nativeAPI = (function () {
    var e_1, _a;
    if (typeof document === 'undefined') {
        return false;
    }
    var unprefixedMethods = methodMap[0];
    var returnValue = {};
    try {
        for (var methodMap_1 = __values(methodMap), methodMap_1_1 = methodMap_1.next(); !methodMap_1_1.done; methodMap_1_1 = methodMap_1.next()) {
            var methodList = methodMap_1_1.value;
            var exitFullscreenMethod = methodList === null || methodList === void 0 ? void 0 : methodList[1];
            if (exitFullscreenMethod in document) {
                for (var methodIndex in methodList) {
                    returnValue[unprefixedMethods[methodIndex]] = methodList[methodIndex];
                }
                return returnValue;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (methodMap_1_1 && !methodMap_1_1.done && (_a = methodMap_1.return)) _a.call(methodMap_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
})();
var eventNameMap = {
    // @ts-ignore
    change: nativeAPI.fullscreenchange,
    // @ts-ignore
    error: nativeAPI.fullscreenerror,
};
var fullscreen = {
    get isFullscreen() {
        // @ts-ignore
        return Boolean(document[nativeAPI.fullscreenElement]);
    },
    get element() {
        var _a;
        // @ts-ignore
        return (_a = document[nativeAPI.fullscreenElement]) !== null && _a !== void 0 ? _a : null;
    },
    get isEnabled() {
        // @ts-ignore
        return Boolean(document[nativeAPI.fullscreenEnabled]);
    },
    // eslint-disable-next-line default-param-last
    request: function (element, options) {
        if (element === void 0) { element = document.documentElement; }
        return new Promise(function (resolve, reject) {
            var onFullScreenEntered = function () {
                fullscreen.off('change', onFullScreenEntered);
                resolve();
            };
            fullscreen.on('change', onFullScreenEntered);
            // @ts-ignore
            var returnPromise = element[nativeAPI.requestFullscreen](options);
            if (returnPromise instanceof Promise) {
                returnPromise.then(onFullScreenEntered).catch(reject);
            }
        });
    },
    exit: function () {
        return new Promise(function (resolve, reject) {
            // @ts-ignore
            if (!fullscreen.isFullscreen) {
                resolve();
                return;
            }
            var onFullScreenExit = function () {
                fullscreen.off('change', onFullScreenExit);
                resolve();
            };
            fullscreen.on('change', onFullScreenExit);
            // @ts-ignore
            var returnPromise = document[nativeAPI.exitFullscreen]();
            if (returnPromise instanceof Promise) {
                returnPromise.then(onFullScreenExit).catch(reject);
            }
        });
    },
    toggle: function (element, options) {
        if (options === void 0) { options = {}; }
        // @ts-ignore
        return fullscreen.isFullscreen ? fullscreen.exit() : fullscreen.request(element, options);
    },
    onChange: function (callback) {
        fullscreen.on('change', callback);
    },
    onError: function (callback) {
        fullscreen.on('error', callback);
    },
    on: function (event, callback, element) {
        if (element === void 0) { element = document; }
        var eventName = eventNameMap[event];
        if (eventName) {
            element.addEventListener(eventName, callback);
        }
    },
    off: function (event, callback, element) {
        if (element === void 0) { element = document; }
        var eventName = eventNameMap[event];
        if (eventName) {
            element.removeEventListener(eventName, callback);
        }
    },
    raw: nativeAPI,
};
if (!nativeAPI) {
    // @ts-ignore
    fullscreen = { isEnabled: false };
}
export default fullscreen;
