export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No chats found with the applied filters =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No chats found for \"", _interpolate(_named("search")), "\" =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any chats yet."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add school-wide chats in the message settings and individual chats in each group’s settings."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All course chats"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialogues"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School chats"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By last message date"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By name"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Editor"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Editor"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace Support"])}
        }
      },
      "ru": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По данным фильтрам ни один чат не найден =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поиск по \"", _interpolate(_named("search")), "\" не нашёл ни одного чата =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас пока нет ни одного чата."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете добавить общешкольные чаты в настройках сообщений и отдельные чаты в настройках каждой группы учеников."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все чаты курса"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диалоги"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Школьные чаты"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По дате последнего сообщения"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По названию"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактор школы"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преподаватель"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактор курсов"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка Skillspace"])}
        }
      },
      "es": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún chat con estos filtros =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se encontró ningún chat para \"", _interpolate(_named("search")), "\" =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes ningún chat aún."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes agregar chats escolares generales en la configuración de mensajes y chats individuales en la configuración de cada grupo de estudiantes."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los chats del curso"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diálogos"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats escolares"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por la fecha del último mensaje"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por nombre"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor de la escuela"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor de cursos"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte de Skillspace"])}
        }
      },
      "kk": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осы сүзгілер бойынша ешқандай чат табылған жоқ =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("search")), "\" бойынша іздеу ешқандай чат табылмады =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізде әлі ешқандай чат жоқ."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз хабарламалар баптауларында мектептегі жалпы чаттарды және әрбір оқушы тобының баптауларында жеке чаттарды қоса аласыз."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсқа арналған барлық чаттар"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстар"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диалогтар"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мектеп чаттары"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы хабарламаның күні бойынша"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атау бойынша"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мектеп редакторы"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұғалім"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстар редакторы"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкімші"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иесі"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace қолдауы"])}
        }
      },
      "uz": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қидириш"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бу фильтрларга мувофиқ чат топилмади =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("search")), "\" бўйича қидиришда чат топилмади =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ҳалича сизда чатлар мавжуд эмас."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиз хабарлар созламаларида умумий мактаб чатларини ва ҳар бир талабалар гуруҳининг созламаларида алоҳида чатларини қўшишингиз мумкин."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курснинг барча чатлари"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстар"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диалоглар"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мактаб чатлари"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сўнгги хабарнинг санаси бўйича"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номи бўйича"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мактaб таҳрирловчиси"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ўқитувчи"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс таҳрирловчиси"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эгаси"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace қўллаб-қувватлови"])}
        }
      },
      "de": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach diesen Filtern wurde kein Chat gefunden =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Suche nach \"", _interpolate(_named("search")), "\" hat keinen Chat gefunden =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keinen Chat."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können allgemeine Schulchats in den Nachrichteneinstellungen und einzelne Chats in den Einstellungen jeder Schülergruppe hinzufügen."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kurschats"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurse"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialoge"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulchats"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Datum der letzten Nachricht"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schul-Editor"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs-Editor"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skillspace-Support"])}
        }
      },
      "fr": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun chat trouvé avec ces filtres =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La recherche de \"", _interpolate(_named("search")), "\" n'a trouvé aucun chat =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de chat."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter des chats scolaires généraux dans les paramètres des messages et des chats individuels dans les paramètres de chaque groupe d'élèves."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les chats du cours"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialogues"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats scolaires"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par date du dernier message"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par nom"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de l'école"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignant"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de cours"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Skillspace"])}
        }
      },
      "it": {
        "commonChats": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
          "noChatsWithFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna chat trovata per i filtri selezionati =("])},
          "noChatsWithSearch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La ricerca per \"", _interpolate(_named("search")), "\" non ha trovato nessuna chat =("])},
          "noChatsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non hai alcuna chat."])},
          "noChatsDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi aggiungere chat scolastiche comuni nelle impostazioni dei messaggi e chat separate nelle impostazioni di ogni gruppo di studenti."])},
          "allCourseChats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le chat del corso"])},
          "filter": {
            "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corsi"])},
            "dialogues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialoghi"])},
            "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat scolastiche"])}
          },
          "sort": {
            "lastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per data dell'ultimo messaggio"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per nome"])}
          }
        },
        "roles": {
          "schoolEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore della scuola"])},
          "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insegnante"])},
          "courseEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore del corso"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietario"])},
          "skillspaceSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto Skillspace"])}
        }
      }
    }
  })
}
