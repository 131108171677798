import fingerprintJS from '@fingerprintjs/fingerprintjs';
import { storage } from '@common/utils/Storage/Storage';
import { createResolvedPromise } from '@common/utils/Promise/Promise';
var fingerprintStorageKey = 'browserFingerprint';
// Initialize an agent at application startup.
var fpPromise = fingerprintJS.load({ monitoring: false })
    .then(function (fp) {
    return fp.get();
});
/**
 * Get the visitor identifier when you need it.
 *
 * @returns {Promise<string>}
 */
export default function getClientFingerprint() {
    // Если в локалсторедже есть фингерпринт - используем его в первую очередь,
    // т.к. отпечаток может поменяться, а ключ в хранилище поможет избежать лишних проверок
    if (storage.has(fingerprintStorageKey)) {
        return createResolvedPromise(storage.get(fingerprintStorageKey));
    }
    return fpPromise.then(function (fp) {
        storage.set(fingerprintStorageKey, fp.visitorId);
        return fp.visitorId;
    });
}
