import { defineStore } from 'pinia';
import { nextZIndex } from '@common/hooks';
/**
 * Сторедж лайтбокса
 *
 * Сторедж используется в нескольких фронтендах
 */
export var useLightboxStore = defineStore('lightbox', {
    state: function () { return ({
        // Показывать модалку лайтбокса
        singleLightboxHidden: true,
        // Ссылка на картинку в лайтбоксе
        singleLightboxImage: '',
        // zIndex модалки лайтбокса
        zIndex: 100,
    }); },
    actions: {
        singleLightboxShow: function (url) {
            this.singleLightboxHidden = false;
            this.singleLightboxImage = url;
            this.zIndex = nextZIndex();
        },
        singleLightboxHide: function () {
            this.singleLightboxHidden = true;
        },
    },
});
