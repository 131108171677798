import { generateId, generateUuid } from '@common/utils/Helpers/Helpers';
import { defaultTestAnswers } from '@school/utils/DefaultTestAnswers';
export var defaultTestQuestions = function () { return ({
    select: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'select',
        title: 'Выберите правильный вариант',
        text: '',
        order: 0,
        answers: [
            defaultTestAnswers().select
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: [],
    },
    select_image: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'select_image',
        title: 'Выберите правильный вариант',
        text: '',
        order: 0,
        answers: [
            defaultTestAnswers().select_image
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: []
    },
    text: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'text',
        title: 'Введите правильный ответ',
        text: '',
        order: 100,
        answers: [
            defaultTestAnswers().text
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: [],
        correctAll: false,
        singleLine: false,
        caseSensitive: false
    },
    digit: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'digit',
        title: 'Введите правильный ответ',
        text: '',
        order: 100,
        answers: [
            defaultTestAnswers().digit
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: [],
        integerOnly: false,
        range: false
    },
    fill_words: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'fill_words',
        title: 'Заполните пропуски в тексте',
        text: '',
        order: 100,
        answers: [
            defaultTestAnswers().fill_words
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: [],
        textWithSkips: ''
    },
    arrange_words: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'arrange_words',
        title: 'Расставьте слова в тексте',
        text: '',
        order: 100,
        answers: [
            defaultTestAnswers().arrange_words
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: [],
        textWithSkips: '',
        fakeWords: []
    },
    relation: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'relation',
        title: 'Сопоставьте варианты ответов',
        text: '',
        order: 100,
        answers: [
            defaultTestAnswers().relation
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: []
    },
    formula: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'formula',
        title: 'Выберите правильную формулу',
        text: '',
        order: 100,
        answers: [
            defaultTestAnswers().formula
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: []
    },
    audio: {
        id: generateId(),
        uuid: generateUuid(),
        type: 'audio',
        title: 'Выберите правильный ответ',
        text: '',
        order: 100,
        answers: [
            defaultTestAnswers().audio
        ],
        score: 0,
        useTimer: false,
        timer: null,
        successComment: null,
        failComment: null,
        isRemoved: false,
        audio: null,
        video: null,
        image: null,
        imageOrientation: 'vertical',
        formula: '',
        embed: '',
        successCommentFiles: [],
        failCommentFiles: []
    }
}); };
