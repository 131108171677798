var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { httpClient } from '@common/utils/HttpClient';
import { convertToFormData } from '@common/utils/Helpers/Helpers';
import { FileUploadApi, FileUploadNamespace } from '@common/api/infrastructure/fileUpload';
var ChatApi = /** @class */ (function (_super) {
    __extends(ChatApi, _super);
    function ChatApi() {
        var _this = _super.call(this) || this;
        _this._fileUploadApi = new FileUploadApi(FileUploadNamespace.CHAT_FILE);
        return _this;
    }
    // создать файл через s3
    ChatApi.prototype.createFile = function (uuid, data) {
        return this._fileUploadApi.createFile(uuid, data);
    };
    //  Получение чата
    ChatApi.prototype.getChat = function (chatId) {
        return httpClient.get("/chat/".concat(chatId), { baseURL: '/api' });
    };
    //  Получение чата с пользователем
    ChatApi.prototype.getDialogue = function (userId) {
        return httpClient.get("/chat/dialogue/".concat(userId), { baseURL: '/api' });
    };
    //  Получение всех чатов
    ChatApi.prototype.getList = function () {
        return httpClient.get("/chat/list", { baseURL: '/api' });
    };
    //  Получение сообщений в чате
    ChatApi.prototype.getOffsetMessages = function (chatId, offset) {
        return httpClient.get("/chat/".concat(chatId, "/messages?offset=").concat(offset), { baseURL: '/api' });
    };
    // Отправить сообщение
    ChatApi.prototype.sendMessage = function (chatId, data) {
        var requestData = convertToFormData(data);
        return httpClient.post("/chat/".concat(chatId, "/message/send"), requestData, { baseURL: '/api' });
    };
    return ChatApi;
}(ApiBase));
export { ChatApi };
