var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getCachedChunkById } from '@common/utils/GetCachedChunkById';
import { ChunkUpload } from '@common/utils/Catalyst/upload/ChunkUpload';
var CatalystUpload = /** @class */ (function () {
    function CatalystUpload(_chunkUploads, _fileCatalyst) {
        var e_1, _a;
        if (_chunkUploads === void 0) { _chunkUploads = []; }
        this._chunkUploads = _chunkUploads;
        this._fileCatalyst = _fileCatalyst;
        try {
            for (var _chunkUploads_1 = __values(_chunkUploads), _chunkUploads_1_1 = _chunkUploads_1.next(); !_chunkUploads_1_1.done; _chunkUploads_1_1 = _chunkUploads_1.next()) {
                var chunkUpload = _chunkUploads_1_1.value;
                chunkUpload.setCatalystUpload(this);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_chunkUploads_1_1 && !_chunkUploads_1_1.done && (_a = _chunkUploads_1.return)) _a.call(_chunkUploads_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    CatalystUpload.Factory = function (fileCatalyst, chunkSize) {
        return __awaiter(this, void 0, void 0, function () {
            var standardFile, chunkUploads, cachedChunks, chunksCount, chunkTime, _a, _b, _c, _d, chunkNumber, _, start, end, blob, currentFingerprint, hasCachedChunk, e_2_1;
            var _e, e_2, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        standardFile = fileCatalyst.getFile();
                        if (!standardFile) {
                            throw new Error('Файл не является Blob классом, возможно, файл был сделан с помощью new CatalystUrl');
                        }
                        chunkUploads = [];
                        cachedChunks = getCachedChunkById(fileCatalyst.getIdentifyId());
                        if (!chunkSize) return [3 /*break*/, 14];
                        chunksCount = Math.ceil(standardFile.size / chunkSize);
                        chunkTime = Array(chunksCount).fill(null);
                        _h.label = 1;
                    case 1:
                        _h.trys.push([1, 7, 8, 13]);
                        _a = true, _b = __asyncValues(chunkTime.entries());
                        _h.label = 2;
                    case 2: return [4 /*yield*/, _b.next()];
                    case 3:
                        if (!(_c = _h.sent(), _e = _c.done, !_e)) return [3 /*break*/, 6];
                        _g = _c.value;
                        _a = false;
                        _d = __read(_g, 2), chunkNumber = _d[0], _ = _d[1];
                        start = chunkNumber * chunkSize;
                        end = Math.min(start + chunkSize, standardFile.size);
                        blob = standardFile.slice(start, end);
                        return [4 /*yield*/, ChunkUpload.CreateChunkNameByBlob(blob)];
                    case 4:
                        currentFingerprint = _h.sent();
                        hasCachedChunk = !!(cachedChunks && cachedChunks[currentFingerprint]);
                        chunkUploads.push(new ChunkUpload(blob, chunkNumber + 1, hasCachedChunk));
                        _h.label = 5;
                    case 5:
                        _a = true;
                        return [3 /*break*/, 2];
                    case 6: return [3 /*break*/, 13];
                    case 7:
                        e_2_1 = _h.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 13];
                    case 8:
                        _h.trys.push([8, , 11, 12]);
                        if (!(!_a && !_e && (_f = _b.return))) return [3 /*break*/, 10];
                        return [4 /*yield*/, _f.call(_b)];
                    case 9:
                        _h.sent();
                        _h.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        if (e_2) throw e_2.error;
                        return [7 /*endfinally*/];
                    case 12: return [7 /*endfinally*/];
                    case 13: return [3 /*break*/, 15];
                    case 14:
                        chunkUploads.push(new ChunkUpload(standardFile, 1, false));
                        _h.label = 15;
                    case 15: return [2 /*return*/, new CatalystUpload(chunkUploads, fileCatalyst)];
                }
            });
        });
    };
    CatalystUpload.prototype.getFileCatalyst = function () {
        return this._fileCatalyst;
    };
    CatalystUpload.prototype.getCountChunksOfFile = function () {
        return this._chunkUploads.length;
    };
    CatalystUpload.prototype.stopUpload = function () {
        var e_3, _a;
        try {
            for (var _b = __values(this._chunkUploads), _c = _b.next(); !_c.done; _c = _b.next()) {
                var chunk = _c.value;
                chunk.declineUpload();
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.clearUpload();
    };
    CatalystUpload.prototype.clearUpload = function () {
        this._chunkUploads = [];
    };
    CatalystUpload.prototype.getChunks = function () {
        return this._chunkUploads;
    };
    CatalystUpload.prototype.updateProgress = function () {
        this._fileCatalyst.setProgress(this._chunkUploads
            .reduce(function (previousValue, currentValue) { return previousValue + currentValue.getProgress(); }, 0)
            / this._chunkUploads.length);
    };
    return CatalystUpload;
}());
export { CatalystUpload };
