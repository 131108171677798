var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { defineStore } from 'pinia';
import { generateUuid } from '@common/utils/Helpers/Helpers';
import { AlignmentEnum, EntityStatusEnum, EntityTypeEnum, OrientationEnum, TemplateBackgroundsEnum, TemplateNamesEnum, TextTypeEnum, VariableTypeEnum } from '@common/enums';
import cloneDeep from 'clone-deep';
import { CoreStore, CourseStore } from '@school/modules/store';
import { fileToBase64, urlToFile } from '@common/utils/File/File';
import { defaultTemplateEntities, } from '@common/utils/StandardCertificateTemplate';
import { TemplateBaseName } from '@common/enums/templateBaseName.enum';
import { planFeatures } from '@common/utils/Plans';
import CertificateApi from '@school/modules/api/entities/certificate/certificate.api';
import { getPrimaryColor } from '@common/utils/GetPrimaryColor';
var DEFAULT_WIDTH = 200;
var DEFAULT_HEIGHT = 100;
export var useCertificateConstructorStore = defineStore('certificateConstructor', {
    state: function () {
        var _a;
        return ({
            size: 1,
            orientation: OrientationEnum.LANDSCAPE,
            isCustomPrimaryColor: false,
            primaryColor: getPrimaryColor(),
            loading: false,
            readMode: true,
            published: true,
            variablesSelect: [
                {
                    name: 'Текст',
                    id: VariableTypeEnum.TEXT,
                    multiple: true,
                    checkPlan: false,
                    hidden: false,
                },
                {
                    name: 'Имя ученика\n(подставляется автоматически)',
                    id: VariableTypeEnum.NAME,
                    multiple: false,
                    checkPlan: false,
                    hidden: false,
                },
                {
                    name: 'Курс\n(подставляется автоматически)',
                    id: VariableTypeEnum.COURSE,
                    multiple: false,
                    checkPlan: false,
                    hidden: false,
                },
                {
                    name: 'Школа\n(подставляется автоматически)',
                    id: VariableTypeEnum.SCHOOL,
                    multiple: false,
                    checkPlan: false,
                    hidden: false,
                },
                {
                    name: 'Логотип',
                    id: VariableTypeEnum.LOGO,
                    multiple: false,
                    checkPlan: false,
                    hidden: false,
                },
                {
                    name: 'QR',
                    id: VariableTypeEnum.QR,
                    multiple: false,
                    checkPlan: true,
                    hidden: false,
                },
                {
                    name: 'Домен',
                    id: VariableTypeEnum.DOMAIN,
                    multiple: false,
                    checkPlan: false,
                    hidden: false,
                },
                {
                    name: 'Номер сертификата',
                    id: VariableTypeEnum.NUMBER_AND_DATE,
                    multiple: false,
                    checkPlan: false,
                    hidden: false,
                },
                {
                    name: 'Лейбл skillspace',
                    id: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    multiple: false,
                    checkPlan: true,
                    hidden: true,
                },
            ],
            templatesSelect: [
                {
                    name: TemplateBaseName.WITHOUT_TEMPLATE,
                    id: TemplateNamesEnum.WITHOUT_TEMPLATE,
                    uuid: '',
                    hasChange: false,
                    active: true,
                },
                {
                    name: TemplateBaseName.STANDARD_FIRST,
                    id: TemplateNamesEnum.STANDARD_FIRST,
                    uuid: '',
                    hasChange: false,
                    active: false,
                },
                {
                    name: TemplateBaseName.STANDARD_SECOND,
                    id: TemplateNamesEnum.STANDARD_SECOND,
                    uuid: '',
                    hasChange: false,
                    active: false
                },
            ],
            localEntitiesState: {
                statusesByUuid: {},
                editableUuids: {},
                movedUuids: {},
            },
            commonVariablesValue: (_a = {},
                _a[VariableTypeEnum.COURSE] = '',
                _a[VariableTypeEnum.NAME] = '',
                _a[VariableTypeEnum.SCHOOL] = '',
                _a[VariableTypeEnum.NUMBER_AND_DATE] = '',
                _a[VariableTypeEnum.DOMAIN] = '',
                _a),
            assignTemplateUuid: '',
            entitiesCurrentCords: defaultTemplateEntities(),
            defaultEntitiesCurrentCords: defaultTemplateEntities()
        });
    },
    getters: {
        isWhiteLabelAllowed: function () {
            return CoreStore.canUseFeature(planFeatures.WHITE_LABEL_FEATURE_CODE);
        },
        activeTemplate: function () {
            return this.templatesSelect.find(function (e) { return e.active; }) || this.templatesSelect.find(function (e) { return e.id === TemplateNamesEnum.WITHOUT_TEMPLATE; });
        },
        entitiesCordsActual: function () {
            var _this = this;
            var isPlanVarTypes = function (varType, type) {
                return [
                    VariableTypeEnum.QR,
                    VariableTypeEnum.DOMAIN,
                    VariableTypeEnum.NUMBER_AND_DATE,
                    VariableTypeEnum.ISSUED_AUTOMATICALLY,
                ].includes(varType) && [EntityTypeEnum.QR, EntityTypeEnum.TEXT].includes(type);
            };
            return this.currentEntities
                .map(function (e) {
                var status = _this.localEntitiesState.statusesByUuid[e.uuid];
                var isEditable = _this.localEntitiesState.editableUuids[e.uuid];
                var hasVar = _this.commonVariablesValue[e.varType] !== undefined;
                return __assign(__assign({}, e), { xy: [
                        e.xy[0] * _this.size,
                        e.xy[1] * _this.size,
                    ], isResizable: isPlanVarTypes(e.varType, e.type) ? _this.isWhiteLabelAllowed : e.isResizable, isDraggable: isPlanVarTypes(e.varType, e.type) ? _this.isWhiteLabelAllowed : e.isDraggable, status: status, isEditable: isEditable, value: hasVar ? _this.commonVariablesValue[e.varType] : e.value });
            });
        },
        entitiesWithLocalState: function () {
            var _this = this;
            return this.currentEntities
                .map(function (e) {
                var status = _this.localEntitiesState.statusesByUuid[e.uuid];
                var isEditable = _this.localEntitiesState.editableUuids[e.uuid];
                var isMoved = _this.localEntitiesState.movedUuids[e.uuid];
                return __assign(__assign({}, e), { xy: [
                        e.xy[0] * _this.size,
                        e.xy[1] * _this.size,
                    ], status: status, isEditable: isEditable, isMoved: isMoved });
            });
        },
        currentTemplateId: function () {
            return this.activeTemplate.id;
        },
        currentTemplate: function () {
            return this.entitiesCurrentCords[this.currentTemplateId];
        },
        currentTemplateUuid: function () {
            return this.currentTemplate.uuid;
        },
        currentOrientationTemplate: function () {
            // @ts-ignore
            return this.currentTemplate[this.orientation];
        },
        currentEntities: function () {
            return this.currentOrientationTemplate.entities;
        },
        currentBackground: function () {
            return this.currentOrientationTemplate.background;
        },
        currentBackgroundFile: function () {
            return this.currentOrientationTemplate.backgroundFile;
        },
        currentOrientationIsStandardBackground: function () {
            return [TemplateBackgroundsEnum.STANDARD_BG_FIRST, TemplateBackgroundsEnum.STANDARD_BG_SECOND].includes(this.currentBackground);
        }
    },
    actions: {
        serializeTemplate: function (template, defaultLandscapeEntities, defaultPortraitEntities) {
            var _a;
            return _a = {
                    uuid: template.uuid,
                    lastEditTemplate: template.lastEditTemplate,
                    primaryColor: template.primaryColor
                },
                _a[OrientationEnum.LANDSCAPE] = this.serializeTemplateOrientation(template.editable, template.drawTemplate.LANDSCAPE.background, template.drawLandscapeBackground, template.drawLandscapeLogo, template.drawTemplate.LANDSCAPE.entities, defaultLandscapeEntities),
                _a[OrientationEnum.PORTRAIT] = this.serializeTemplateOrientation(template.editable, template.drawTemplate.PORTRAIT.background, template.drawPortraitBackground, template.drawPortraitLogo, template.drawTemplate.PORTRAIT.entities, defaultPortraitEntities),
                _a;
        },
        serializeTemplateOrientation: function (editable, background, landscapeBackground, landscapeLogo, entities, defaultEntities) {
            return {
                editable: editable,
                background: background,
                backgroundFile: landscapeBackground,
                entities: entities ? entities.map(function (e) {
                    var _a, _b, _c, _d, _e;
                    if (EntityTypeEnum.IMAGE === e.type) {
                        return __assign(__assign({}, e), { value: landscapeLogo || '', height: +e.height, width: +e.width, xy: [+e.xy[0], +e.xy[1]], isDraggable: e.isDraggable !== 'false', isResizable: e.isResizable !== 'false', options: null });
                    }
                    if (EntityTypeEnum.STANDARD_IMAGE === e.type) {
                        return __assign(__assign({}, e), { height: +e.height, width: +e.width, xy: [+e.xy[0], +e.xy[1]], isDraggable: e.isDraggable !== 'false', isResizable: e.isResizable !== 'false', options: null });
                    }
                    return __assign(__assign({}, e), { height: +e.height, width: +e.width, xy: [+e.xy[0], +e.xy[1]], isDraggable: e.isDraggable !== 'false', isResizable: e.isResizable !== 'false', options: e.options ? __assign(__assign({}, e.options), { inlineTools: (_b = (_a = e.options) === null || _a === void 0 ? void 0 : _a.inlineTools) !== null && _b !== void 0 ? _b : [], highlight: e.options.highlight || {
                                color: null,
                                background: null
                            }, style: {
                                cornerRadius: (_e = (_d = (_c = e.options) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.cornerRadius) !== null && _e !== void 0 ? _e : [6, 6, 6, 6]
                            } }) : null });
                }) : defaultEntities
            };
        },
        loadCurrentCertificatesEntities: function () {
            return __awaiter(this, arguments, void 0, function (isFirstLoad) {
                var courseUuid, defaultEntities, mappedTemplates, newTemplateSelect, templates, mergedTemplates, mergedTemplates_1, mergedTemplates_1_1, template;
                var e_1, _a, _b, _c;
                var _this = this;
                var _d;
                if (isFirstLoad === void 0) { isFirstLoad = false; }
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            courseUuid = CourseStore.course.uuid;
                            defaultEntities = defaultTemplateEntities();
                            mappedTemplates = {};
                            newTemplateSelect = [];
                            return [4 /*yield*/, CertificateApi.getTemplates(courseUuid)];
                        case 1:
                            templates = (_e.sent()).data;
                            this.assignTemplateUuid = templates.course.courseDrawTemplate.uuid;
                            this.published = ((_d = templates.course.courseDrawTemplate) === null || _d === void 0 ? void 0 : _d.status) === 'published';
                            mergedTemplates = __spreadArray([], __read(templates.template), false);
                            // Если есть шаблон курса - подмешиваем
                            if (templates.course.courseTemplate) {
                                mergedTemplates.unshift(templates.course.courseTemplate);
                            }
                            try {
                                for (mergedTemplates_1 = __values(mergedTemplates), mergedTemplates_1_1 = mergedTemplates_1.next(); !mergedTemplates_1_1.done; mergedTemplates_1_1 = mergedTemplates_1.next()) {
                                    template = mergedTemplates_1_1.value;
                                    if (template.uuid === this.assignTemplateUuid) {
                                        this.orientation = template.drawOrientation.toUpperCase();
                                    }
                                    if (template.name === '') {
                                        continue;
                                    }
                                    switch (template.name) {
                                        case TemplateBaseName.WITHOUT_TEMPLATE: {
                                            newTemplateSelect.push({
                                                name: TemplateBaseName.WITHOUT_TEMPLATE,
                                                id: TemplateNamesEnum.WITHOUT_TEMPLATE,
                                                hasChange: false,
                                                uuid: template.uuid,
                                                active: false,
                                            });
                                            mappedTemplates[TemplateNamesEnum.WITHOUT_TEMPLATE] = this.serializeTemplate(template, defaultEntities.WITHOUT_TEMPLATE.LANDSCAPE.entities, defaultEntities.WITHOUT_TEMPLATE.PORTRAIT.entities);
                                            break;
                                        }
                                        case TemplateBaseName.STANDARD_FIRST: {
                                            newTemplateSelect.push({
                                                name: TemplateBaseName.STANDARD_FIRST,
                                                id: TemplateNamesEnum.STANDARD_FIRST,
                                                hasChange: false,
                                                uuid: template.uuid,
                                                active: true
                                            });
                                            mappedTemplates[TemplateNamesEnum.STANDARD_FIRST] = (_b = {
                                                    uuid: template.uuid,
                                                    lastEditTemplate: template.lastEditTemplate,
                                                    primaryColor: template.primaryColor
                                                },
                                                _b[OrientationEnum.LANDSCAPE] = defaultEntities[TemplateNamesEnum.STANDARD_FIRST].LANDSCAPE,
                                                _b[OrientationEnum.PORTRAIT] = defaultEntities[TemplateNamesEnum.STANDARD_FIRST].PORTRAIT,
                                                _b);
                                            break;
                                        }
                                        case TemplateBaseName.STANDARD_SECOND: {
                                            newTemplateSelect.push({
                                                name: TemplateBaseName.STANDARD_SECOND,
                                                id: TemplateNamesEnum.STANDARD_SECOND,
                                                hasChange: false,
                                                uuid: template.uuid,
                                                active: false
                                            });
                                            mappedTemplates[TemplateNamesEnum.STANDARD_SECOND] = (_c = {
                                                    uuid: template.uuid,
                                                    lastEditTemplate: template.lastEditTemplate,
                                                    primaryColor: template.primaryColor
                                                },
                                                _c[OrientationEnum.LANDSCAPE] = defaultEntities[TemplateNamesEnum.STANDARD_SECOND].LANDSCAPE,
                                                _c[OrientationEnum.PORTRAIT] = defaultEntities[TemplateNamesEnum.STANDARD_SECOND].PORTRAIT,
                                                _c);
                                            break;
                                        }
                                        default: {
                                            mappedTemplates[template.uuid] = this.serializeTemplate(template, [], []);
                                            newTemplateSelect.push({
                                                name: template.name,
                                                id: template.uuid,
                                                uuid: template.uuid,
                                                hasChange: false,
                                                active: false
                                            });
                                        }
                                    }
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (mergedTemplates_1_1 && !mergedTemplates_1_1.done && (_a = mergedTemplates_1.return)) _a.call(mergedTemplates_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                            this.templatesSelect = newTemplateSelect.map(function (e) { return (__assign(__assign({}, e), { active: isFirstLoad ? e.uuid === _this.assignTemplateUuid : _this.activeTemplate.id === e.id, hasChange: false })); });
                            this.entitiesCurrentCords = cloneDeep(mappedTemplates);
                            this.defaultEntitiesCurrentCords = cloneDeep(mappedTemplates);
                            this.setDefaultLocalState();
                            this.primaryColor = this.entitiesCurrentCords[this.activeTemplate.id].primaryColor || getPrimaryColor();
                            this.isCustomPrimaryColor = !!this.entitiesCurrentCords[this.activeTemplate.id].primaryColor;
                            this.loading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        setCommonVariablesValue: function (variableType, value) {
            this.commonVariablesValue[variableType] = value;
        },
        setLocalState: function (uuid, _a, reset) {
            var status = _a.status, isEditable = _a.isEditable, isMoved = _a.isMoved;
            if (reset === void 0) { reset = false; }
            var prevIsMoved = this.localEntitiesState.movedUuids[uuid];
            var prevIsEditable = this.localEntitiesState.editableUuids[uuid];
            var prevStatus = this.localEntitiesState.statusesByUuid[uuid];
            if (reset) {
                this.setDefaultLocalState();
            }
            this.localEntitiesState.movedUuids[uuid] = isMoved !== undefined ? isMoved : prevIsMoved;
            this.localEntitiesState.editableUuids[uuid] = isEditable !== undefined ? isEditable : prevIsEditable;
            this.localEntitiesState.statusesByUuid[uuid] = status !== undefined ? status : prevStatus;
        },
        setDefaultLocalState: function () {
            var e_2, _a;
            var statusesByUuid = {};
            var editableUuids = {};
            var movedUuids = {};
            try {
                for (var _b = __values(this.currentEntities), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var entityCords = _c.value;
                    statusesByUuid[entityCords.uuid] = EntityStatusEnum.ATTACHED;
                    editableUuids[entityCords.uuid] = false;
                    movedUuids[entityCords.uuid] = false;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            this.localEntitiesState = {
                statusesByUuid: statusesByUuid,
                editableUuids: editableUuids,
                movedUuids: movedUuids
            };
        },
        changeActiveTemplate: function (id, byId) {
            if (byId === void 0) { byId = true; }
            var currentTemplateId = this.currentTemplateId;
            var defaultTemplate = cloneDeep(this.defaultEntitiesCurrentCords[currentTemplateId]);
            // если переключается на WITHOUT_TEMPLATE, то берем все свойства от предыдущего шаблона
            if (id === TemplateNamesEnum.WITHOUT_TEMPLATE) {
                if (this.entitiesCurrentCords[id].LANDSCAPE.background === TemplateBackgroundsEnum.EMPTY_BG) {
                    this.entitiesCurrentCords[id].LANDSCAPE = this.entitiesCurrentCords[currentTemplateId].LANDSCAPE;
                }
                if (this.entitiesCurrentCords[id].PORTRAIT.background === TemplateBackgroundsEnum.EMPTY_BG) {
                    this.entitiesCurrentCords[id].PORTRAIT = this.entitiesCurrentCords[currentTemplateId].PORTRAIT;
                }
            }
            // сбрасываем на стандарт
            this.entitiesCurrentCords[currentTemplateId].LANDSCAPE = defaultTemplate.LANDSCAPE;
            this.entitiesCurrentCords[currentTemplateId].PORTRAIT = defaultTemplate.PORTRAIT;
            this.templatesSelect = this.templatesSelect.map(function (e) {
                if (byId) {
                    if (e.id !== id)
                        return __assign(__assign({}, e), { active: false, hasChange: false });
                }
                else {
                    if (e.uuid !== id)
                        return __assign(__assign({}, e), { active: false, hasChange: false });
                }
                return __assign(__assign({}, e), { active: true });
            });
            this.primaryColor = this.currentTemplate.primaryColor || getPrimaryColor();
            this.isCustomPrimaryColor = !!this.currentTemplate.primaryColor;
        },
        changeOrientation: function () {
            this.orientation = this.orientation === OrientationEnum.LANDSCAPE ? OrientationEnum.PORTRAIT : OrientationEnum.LANDSCAPE;
        },
        setEntityPosition: function (xy, entityCords) {
            var _this = this;
            this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = this.currentEntities.map(function (e) {
                if (e.uuid !== entityCords.uuid) {
                    return e;
                }
                return __assign(__assign({}, e), { xy: [
                        xy[0] / _this.size,
                        xy[1] / _this.size,
                    ] });
            });
            this.editTemplate();
        },
        // eslint-disable-next-line complexity
        setNewEntity: function (_a, variable, type) {
            var _b = __read(_a, 2), x = _b[0], y = _b[1];
            var newUuid = generateUuid();
            if ([VariableTypeEnum.TEXT, VariableTypeEnum.COURSE, VariableTypeEnum.SCHOOL, VariableTypeEnum.NAME].includes(variable.id)) {
                var regularEntity = {
                    uuid: newUuid,
                    type: type,
                    width: DEFAULT_WIDTH,
                    height: DEFAULT_HEIGHT,
                    xy: [
                        x / this.size,
                        y / this.size,
                    ],
                    isResizable: true,
                    isDraggable: true,
                    varType: variable.id,
                    value: variable.id === VariableTypeEnum.TEXT ? 'Текст' : '',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '16',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                };
                this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = __spreadArray(__spreadArray([], __read((!variable.multiple ? this.currentEntities.filter(function (e) { return e.varType !== variable.id; }) : this.currentEntities)), false), [
                    regularEntity
                ], false);
            }
            if (variable.id === VariableTypeEnum.LOGO) {
                var imageEntity = {
                    uuid: newUuid,
                    type: EntityTypeEnum.STANDARD_IMAGE,
                    value: '/img/school/certificates/paste-your-image.svg',
                    width: 136,
                    height: 38,
                    isResizable: true,
                    isDraggable: true,
                    xy: [
                        x / this.size,
                        y / this.size,
                    ],
                    varType: variable.id,
                    options: null
                };
                this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = __spreadArray(__spreadArray([], __read((!variable.multiple ? this.currentEntities.filter(function (e) { return e.varType !== variable.id; }) : this.currentEntities)), false), [
                    imageEntity
                ], false);
            }
            if (variable.id === VariableTypeEnum.QR) {
                var qrEntity = {
                    uuid: newUuid,
                    type: EntityTypeEnum.QR,
                    value: '',
                    width: 50,
                    height: 50,
                    isResizable: false,
                    isDraggable: false,
                    xy: [
                        x / this.size,
                        y / this.size,
                    ],
                    varType: variable.id,
                    options: null
                };
                this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = __spreadArray(__spreadArray([], __read((!variable.multiple ? this.currentEntities.filter(function (e) { return e.varType !== variable.id; }) : this.currentEntities)), false), [
                    qrEntity
                ], false);
            }
            if (variable.id === VariableTypeEnum.ISSUED_AUTOMATICALLY) {
                var qrEntity = {
                    uuid: newUuid,
                    type: EntityTypeEnum.TEXT,
                    varType: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    value: 'Выдано автоматически на skillspace.ru',
                    width: 220,
                    height: 86,
                    isResizable: false,
                    isDraggable: false,
                    xy: [
                        x / this.size,
                        y / this.size,
                    ],
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: 'PRIMARY',
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                };
                this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = __spreadArray(__spreadArray([], __read((!variable.multiple ? this.currentEntities.filter(function (e) { return e.varType !== variable.id; }) : this.currentEntities)), false), [
                    qrEntity
                ], false);
            }
            if (variable.id === VariableTypeEnum.NUMBER_AND_DATE) {
                var qrEntity = {
                    uuid: newUuid,
                    type: EntityTypeEnum.TEXT,
                    varType: VariableTypeEnum.NUMBER_AND_DATE,
                    value: '',
                    width: 220,
                    height: 86,
                    isResizable: false,
                    isDraggable: false,
                    xy: [
                        x / this.size,
                        y / this.size,
                    ],
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                };
                this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = __spreadArray(__spreadArray([], __read((!variable.multiple ? this.currentEntities.filter(function (e) { return e.varType !== variable.id; }) : this.currentEntities)), false), [
                    qrEntity
                ], false);
            }
            if (variable.id === VariableTypeEnum.DOMAIN) {
                var qrEntity = {
                    uuid: newUuid,
                    type: EntityTypeEnum.TEXT,
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    width: 220,
                    height: 86,
                    isResizable: false,
                    isDraggable: false,
                    xy: [
                        x / this.size,
                        y / this.size,
                    ],
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                };
                this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = __spreadArray(__spreadArray([], __read((!variable.multiple ? this.currentEntities.filter(function (e) { return e.varType !== variable.id; }) : this.currentEntities)), false), [
                    qrEntity
                ], false);
            }
            this.setLocalState(newUuid, { status: EntityStatusEnum.HELD }, true);
            this.editTemplate();
        },
        setEntitySize: function (sizes, entityCords) {
            this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = this.currentEntities.map(function (e) {
                if (e.uuid !== entityCords.uuid) {
                    return e;
                }
                return __assign(__assign({}, e), { width: sizes.width, height: sizes.height });
            });
            this.editTemplate();
        },
        setText: function (uuid, value) {
            var index = this.currentEntities.findIndex(function (e) { return e.uuid === uuid; });
            this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities[index].value = value;
            this.editTemplate();
        },
        deleteEntity: function (uuid) {
            this.entitiesCurrentCords[this.currentTemplateId][this.orientation].entities = this.currentEntities.filter(function (e) { return e.uuid !== uuid; });
            delete this.localEntitiesState.statusesByUuid[uuid];
            delete this.localEntitiesState.editableUuids[uuid];
            delete this.localEntitiesState.movedUuids[uuid];
            this.editTemplate();
        },
        editTemplate: function () {
            if (!this.currentTemplate[this.orientation].editable) {
                this.editWithoutTemplate();
            }
            else if (this.currentTemplateId !== TemplateNamesEnum.WITHOUT_TEMPLATE) {
                this.editCustomTemplate();
            }
        },
        editCustomTemplate: function () {
            var _this = this;
            var currentTemplateSelect = this.templatesSelect.find(function (e) { return e.id === _this.currentTemplateId; });
            if (currentTemplateSelect && !currentTemplateSelect.hasChange) {
                this.templatesSelect = this.templatesSelect.map(function (e) {
                    if (e.id === _this.currentTemplateId)
                        return __assign(__assign({}, e), { hasChange: true });
                    return __assign(__assign({}, e), { hasChange: false });
                });
            }
        },
        editWithoutTemplate: function () {
            var currentBg = this.currentBackground;
            var currentEntities = this.currentEntities;
            var currentTemplate = this.currentTemplate;
            var currentBgFile = this.currentBackgroundFile;
            var anotherOrientation = this.orientation === OrientationEnum.LANDSCAPE ? OrientationEnum.PORTRAIT : OrientationEnum.LANDSCAPE;
            var primary = this.primaryColor;
            this.changeActiveTemplate(TemplateNamesEnum.WITHOUT_TEMPLATE);
            this.entitiesCurrentCords[TemplateNamesEnum.WITHOUT_TEMPLATE][this.orientation] = {
                editable: true,
                entities: currentEntities,
                background: currentBg,
                backgroundFile: currentBgFile,
            };
            this.primaryColor = primary;
            this.entitiesCurrentCords[TemplateNamesEnum.WITHOUT_TEMPLATE].primaryColor = primary;
            this.entitiesCurrentCords[TemplateNamesEnum.WITHOUT_TEMPLATE][anotherOrientation] = currentTemplate[anotherOrientation];
        },
        getFileOrNull: function (image) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (image instanceof File)
                        return [2 /*return*/, image];
                    if (typeof image === 'string')
                        return [2 /*return*/, urlToFile(image)];
                    return [2 /*return*/, null];
                });
            });
        },
        createTemplate: function (name, onlyCourse) {
            return __awaiter(this, void 0, void 0, function () {
                var courseUuid, landscapeLogo, portraitLogo, landscapeBackground, portraitBackground, res, _a, _b, _c;
                var _d, _e;
                var _f, _g;
                return __generator(this, function (_h) {
                    switch (_h.label) {
                        case 0:
                            this.loading = true;
                            courseUuid = CourseStore.course.uuid;
                            landscapeLogo = (_f = this.currentTemplate.LANDSCAPE.entities.find(function (e) { return e.type === EntityTypeEnum.IMAGE; })) === null || _f === void 0 ? void 0 : _f.value;
                            portraitLogo = (_g = this.currentTemplate.PORTRAIT.entities.find(function (e) { return e.type === EntityTypeEnum.IMAGE; })) === null || _g === void 0 ? void 0 : _g.value;
                            landscapeBackground = this.currentTemplate.LANDSCAPE.backgroundFile;
                            portraitBackground = this.currentTemplate.PORTRAIT.backgroundFile;
                            _b = (_a = CertificateApi).createTemplate;
                            _c = [courseUuid];
                            _d = {
                                name: onlyCourse ? '' : name,
                                onlyCourse: onlyCourse
                            };
                            return [4 /*yield*/, this.getFileOrNull(landscapeBackground)];
                        case 1:
                            _d.landscapeBackground = _h.sent();
                            return [4 /*yield*/, this.getFileOrNull(portraitBackground)];
                        case 2:
                            _d.portraitBackground = _h.sent();
                            return [4 /*yield*/, this.getFileOrNull(landscapeLogo)];
                        case 3:
                            _d.landscapeLogo = _h.sent();
                            return [4 /*yield*/, this.getFileOrNull(portraitLogo)];
                        case 4: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.portraitLogo = _h.sent(),
                                    _d.orientation = this.orientation,
                                    _d.primaryColor = this.isCustomPrimaryColor ? this.primaryColor : undefined,
                                    _d.fields = (_e = {},
                                        _e[OrientationEnum.LANDSCAPE] = {
                                            background: this.currentTemplate.LANDSCAPE.background,
                                            entities: this.currentTemplate.LANDSCAPE.entities.map(function (e) {
                                                if (e.type === EntityTypeEnum.IMAGE)
                                                    return __assign(__assign({}, e), { value: '' });
                                                return e;
                                            })
                                        },
                                        _e[OrientationEnum.PORTRAIT] = {
                                            background: this.currentTemplate.LANDSCAPE.background,
                                            entities: this.currentTemplate.PORTRAIT.entities.map(function (e) {
                                                if (e.type === EntityTypeEnum.IMAGE)
                                                    return __assign(__assign({}, e), { value: '' });
                                                return e;
                                            })
                                        },
                                        _e),
                                    _d)]))];
                        case 5:
                            res = (_h.sent()).data;
                            return [4 /*yield*/, CertificateApi.assignTemplate(courseUuid, this.currentTemplate.uuid)];
                        case 6:
                            _h.sent();
                            return [4 /*yield*/, this.loadCurrentCertificatesEntities()];
                        case 7:
                            _h.sent();
                            if (onlyCourse) {
                                this.changeActiveTemplate(TemplateNamesEnum.WITHOUT_TEMPLATE);
                            }
                            else {
                                this.changeActiveTemplate(res.uuid, false);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateStandardTemplate: function () {
            return __awaiter(this, void 0, void 0, function () {
                var courseUuid;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loading = true;
                            courseUuid = CourseStore.course.uuid;
                            return [4 /*yield*/, CertificateApi.updateTemplate(this.currentTemplateUuid, {
                                    orientation: this.orientation,
                                    fields: null,
                                    primaryColor: this.isCustomPrimaryColor ? this.primaryColor : null
                                })];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, CertificateApi.assignTemplate(courseUuid, this.currentTemplate.uuid)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.loadCurrentCertificatesEntities()];
                        case 3:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateTemplate: function (onlyCourse) {
            return __awaiter(this, void 0, void 0, function () {
                var courseUuid, neededUpdate, landscapeLogo, portraitLogo, landscapeBackground, portraitBackground, _a;
                var _b;
                var _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            this.loading = true;
                            courseUuid = CourseStore.course.uuid;
                            neededUpdate = this.currentTemplateId === TemplateNamesEnum.WITHOUT_TEMPLATE || this.activeTemplate.hasChange;
                            _e.label = 1;
                        case 1:
                            _e.trys.push([1, 10, , 11]);
                            if (!neededUpdate) return [3 /*break*/, 7];
                            return [4 /*yield*/, this.getFileOrNull((_c = this.currentTemplate.LANDSCAPE.entities.find(function (e) { return e.type === EntityTypeEnum.IMAGE; })) === null || _c === void 0 ? void 0 : _c.value)];
                        case 2:
                            landscapeLogo = _e.sent();
                            return [4 /*yield*/, this.getFileOrNull((_d = this.currentTemplate.PORTRAIT.entities.find(function (e) { return e.type === EntityTypeEnum.IMAGE; })) === null || _d === void 0 ? void 0 : _d.value)];
                        case 3:
                            portraitLogo = _e.sent();
                            return [4 /*yield*/, this.getFileOrNull(this.currentTemplate.LANDSCAPE.backgroundFile)];
                        case 4:
                            landscapeBackground = _e.sent();
                            return [4 /*yield*/, this.getFileOrNull(this.currentTemplate.PORTRAIT.backgroundFile)];
                        case 5:
                            portraitBackground = _e.sent();
                            return [4 /*yield*/, CertificateApi.updateTemplate(this.currentTemplateUuid, {
                                    removePortraitLogo: portraitLogo === null ? true : null,
                                    removeLandscapeLogo: landscapeLogo === null ? true : null,
                                    removeLandscapeBackground: landscapeBackground === null ? true : null,
                                    removePortraitBackground: portraitBackground === null ? true : null,
                                    onlyCourse: onlyCourse,
                                    course: onlyCourse ? courseUuid : '',
                                    landscapeBackground: landscapeBackground,
                                    portraitBackground: portraitBackground,
                                    landscapeLogo: landscapeLogo,
                                    portraitLogo: portraitLogo,
                                    orientation: this.orientation,
                                    primaryColor: this.isCustomPrimaryColor ? this.primaryColor : null,
                                    fields: (_b = {},
                                        _b[OrientationEnum.LANDSCAPE] = {
                                            background: this.currentTemplate.LANDSCAPE.background,
                                            entities: this.currentTemplate.LANDSCAPE.entities.map(function (e) {
                                                if (e.type === EntityTypeEnum.IMAGE)
                                                    return __assign(__assign({}, e), { value: '' });
                                                return e;
                                            })
                                        },
                                        _b[OrientationEnum.PORTRAIT] = {
                                            background: this.currentTemplate.PORTRAIT.background,
                                            entities: this.currentTemplate.PORTRAIT.entities.map(function (e) {
                                                if (e.type === EntityTypeEnum.IMAGE)
                                                    return __assign(__assign({}, e), { value: '' });
                                                return e;
                                            })
                                        },
                                        _b)
                                })];
                        case 6:
                            _e.sent();
                            _e.label = 7;
                        case 7: return [4 /*yield*/, CertificateApi.assignTemplate(courseUuid, this.currentTemplate.uuid)];
                        case 8:
                            _e.sent();
                            return [4 /*yield*/, this.loadCurrentCertificatesEntities()];
                        case 9:
                            _e.sent();
                            if (onlyCourse) {
                                this.changeActiveTemplate(TemplateNamesEnum.WITHOUT_TEMPLATE);
                            }
                            return [3 /*break*/, 11];
                        case 10:
                            _a = _e.sent();
                            this.loading = false;
                            return [3 /*break*/, 11];
                        case 11: return [2 /*return*/];
                    }
                });
            });
        },
        setLogo: function (file) {
            return __awaiter(this, void 0, void 0, function () {
                var base64, image;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fileToBase64(file)];
                        case 1:
                            base64 = _a.sent();
                            image = new Image();
                            image.src = base64;
                            image.addEventListener('load', function () {
                                var width = image.width;
                                var height = image.height;
                                _this.entitiesCurrentCords[_this.currentTemplateId][_this.orientation].entities = _this.entitiesCurrentCords[_this.currentTemplateId][_this.orientation].entities.map(function (e) {
                                    if ([EntityTypeEnum.IMAGE, EntityTypeEnum.STANDARD_IMAGE].includes(e.type)) {
                                        var ratio = width / DEFAULT_WIDTH;
                                        return __assign(__assign({}, e), { type: EntityTypeEnum.IMAGE, value: file, width: width / ratio, height: height / ratio });
                                    }
                                    return e;
                                });
                                _this.editTemplate();
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        setBackgroundFile: function (file) {
            this.entitiesCurrentCords[this.currentTemplateId][this.orientation].background = TemplateBackgroundsEnum.CUSTOM_BG;
            this.entitiesCurrentCords[this.currentTemplateId][this.orientation].backgroundFile = file;
            this.editTemplate();
        },
        deleteTemplate: function (uuid) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loading = true;
                            return [4 /*yield*/, CertificateApi.deleteTemplate(uuid)];
                        case 1:
                            _a.sent();
                            this.changeActiveTemplate(TemplateNamesEnum.STANDARD_FIRST);
                            return [4 /*yield*/, this.loadCurrentCertificatesEntities()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        setDefaultTemplates: function () {
            this.entitiesCurrentCords = cloneDeep(this.defaultEntitiesCurrentCords);
            this.templatesSelect = this.templatesSelect.map(function (e) { return (__assign(__assign({}, e), { hasChange: false })); });
            this.changeActiveTemplate(this.assignTemplateUuid, false);
            this.setDefaultLocalState();
        },
        setPrimaryColor: function (color) {
            this.primaryColor = color;
            this.isCustomPrimaryColor = true;
            this.editTemplate();
        }
    }
});
