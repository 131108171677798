export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a conversation to start communication"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, you can communicate through private messages, group chats, and channels."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, you can communicate with students through private messages, group chats, and channels. You can create a shared channel and group chat in the School Settings section. Separate chats for student groups can be created in group settings."])}
        }
      },
      "ru": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите диалог, чтобы начать общение"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом разделе Вы сможете общаться в личных сообщениях, групповых чатах и каналах."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом разделе Вы сможете общаться с учениками в личных сообщениях, групповых чатах и каналах. Создать общий канал и групповой чат можно в разделе Настройки школы. Отдельные чаты для групп учеников можно создать в настройках групп."])}
        }
      },
      "es": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un diálogo para iniciar la comunicación"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta sección podrá comunicarse en mensajes privados, chats grupales y canales."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta sección podrá comunicarse con los alumnos en mensajes privados, chats grupales y canales. Puede crear un canal general y un chat grupal en la sección Configuración de la escuela. Puede crear chats separados para grupos de alumnos en la configuración de los grupos."])}
        }
      },
      "kk": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әңгімелесуді бастау үшін диалогты таңдаңыз"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл бөлімде сіз жеке хабарламаларда, топтық чаттарда және арналарда сөйлесе аласыз."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл бөлімде сіз оқушылармен жеке хабарламаларда, топтық чаттарда және арналарда сөйлесе аласыз. Жалпы арнаны және топтық чатты мектеп параметрлері бөлімінде жасауға болады. Оқушылар топтары үшін жеке чаттарды топ параметрлерінде жасауға болады."])}
        }
      },
      "uz": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloqa boshlash uchun dialogni tanlang"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bo‘limda siz shaxsan xabarlarda, guruh chatlarida va kanallarda muloqot qila olasiz."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bo‘limda siz oʻquvchilar bilan shaxsan xabarlarda, guruh chatlarida va kanallarda muloqot qila olasiz. Jami kanal va guruh chatini Maktab sozlamalari bo‘limida yaratish mumkin. Oʻquvchilar guruhlari uchun alohida chatlarni guruh sozlamalarida yaratish mumkin."])}
        }
      },
      "de": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Dialog aus, um die Kommunikation zu starten"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Abschnitt können Sie in persönlichen Nachrichten, Gruppenchats und Kanälen kommunizieren."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Abschnitt können Sie mit Schülern in persönlichen Nachrichten, Gruppenchats und Kanälen kommunizieren. Einen gemeinsamen Kanal und Gruppenchats können Sie im Abschnitt Schuleinstellungen erstellen. Separate Chats für Schülergruppen können Sie in den Gruppeneinstellungen erstellen."])}
        }
      },
      "fr": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un dialogue pour commencer à communiquer"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette section, vous pourrez communiquer via des messages privés, des discussions de groupe et des canaux."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette section, vous pourrez communiquer avec les élèves via des messages privés, des discussions de groupe et des canaux. Vous pouvez créer un canal commun et une discussion de groupe dans la section Paramètres de l'école. Des discussions séparées pour les groupes d'élèves peuvent être créées dans les paramètres des groupes."])}
        }
      },
      "it": {
        "messages": {
          "selectDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un dialogo per iniziare a comunicare"])},
          "studentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione potrai comunicare tramite messaggi personali, chat di gruppo e canali."])},
          "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione potrai comunicare con gli studenti tramite messaggi personali, chat di gruppo e canali. È possibile creare un canale comune e una chat di gruppo nella sezione Impostazioni della scuola. È possibile creare chat separate per i gruppi di studenti nelle impostazioni dei gruppi."])}
        }
      }
    }
  })
}
