export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach files"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size limit exceeded for upload"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid file type"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid file type"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File cannot be larger than ", _interpolate(_named("size")), " MB"])}
        }
      },
      "ru": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепить файлы"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышен лимит размера файла для загрузки"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный тип файла"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный тип файла"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Файл не может весить больше ", _interpolate(_named("size")), " мб"])}
        }
      },
      "es": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar archivos"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha excedido el límite de tamaño de archivo para subir"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de archivo inválido"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de archivo inválido"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El archivo no puede pesar más de ", _interpolate(_named("size")), " MB"])}
        }
      },
      "kk": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдарды тіркеу"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеу үшін файл өлшемі шегінен асып кетті"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл түрі дұрыс емес"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл түрі дұрыс емес"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Файлдың салмағы ", _interpolate(_named("size")), " МБ-дан артық болмауы керек"])}
        }
      },
      "uz": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлларни қўшиш"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юклаб бориш учун файл ўлчами чегарадан ташқари"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нотўғри файл тури"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нотўғри файл кенгайиши"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Файл ", _interpolate(_named("size")), " МБдан катта бўлиши мумкин эмас"])}
        }
      },
      "de": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien anhängen"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateigrößenlimit für den Upload überschritten"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Dateityp"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Dateityp"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Datei darf nicht größer als ", _interpolate(_named("size")), " MB sein"])}
        }
      },
      "fr": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre des fichiers"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de taille de fichier dépassée pour le téléchargement"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier incorrect"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension de fichier incorrecte"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le fichier ne peut pas dépasser ", _interpolate(_named("size")), " Mo"])}
        }
      },
      "it": {
        "fileUpload": {
          "attachFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega file"])},
          "limitSizeErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il limite di dimensione del file per il caricamento è stato superato"])},
          "limitTypesErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di file non valido"])},
          "limitExtensionsErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di file non valido"])},
          "sizeLimitError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il file non può superare i ", _interpolate(_named("size")), " MB"])}
        }
      }
    }
  })
}
