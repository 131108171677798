import { useCourseStore } from './stores/courseStore';
import { useSchoolStore } from './stores/schoolStore';
import { usePlanContentStore } from './stores/planContentStore';
import { useOnboardingStore } from './stores/onboardingStore';
import { useSchoolGatewayStore } from './stores/schoolGatewaysStore';
import { useStudentsStore } from '@school/modules/stores/studentsStore';
import { useCoursesStore } from '@school/modules/stores/coursesStore';
import { useLessonStore } from '@school/modules/stores/lessonStore';
import { usePromoCodeStore } from '@school/modules/stores/promoCodeStore';
import CurrentPinia from '@common/utils/CurrentPinia';
export { CoreStore } from '@common/stores';
var piniaInstance = CurrentPinia.getInstance();
export var SchoolStore = useSchoolStore(piniaInstance);
export var CourseStore = useCourseStore(piniaInstance);
export var LessonStore = useLessonStore(piniaInstance);
export var CoursesStore = useCoursesStore(piniaInstance);
export var PlanContentStore = usePlanContentStore(piniaInstance);
export var OnboardingStore = useOnboardingStore(piniaInstance);
export var SchoolGatewaysStore = useSchoolGatewayStore(piniaInstance);
export var StudentsStore = useStudentsStore(piniaInstance);
export var PromoCodeStore = usePromoCodeStore(piniaInstance);
