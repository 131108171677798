export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Catalog"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Chat"])}
        }
      },
      "ru": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каталог курсов"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Библиотека"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-чат"])}
        }
      },
      "es": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catálogo de cursos"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat en línea"])}
        }
      },
      "kk": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстар каталогі"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кітапхана"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-чат"])}
        }
      },
      "uz": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurslar katalogi"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutubxona"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlayn chat"])}
        }
      },
      "de": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurskatalog"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online-Chat"])}
        }
      },
      "fr": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue des cours"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat en ligne"])}
        }
      },
      "it": {
        "sidebar": {
          "courseCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogo dei corsi"])},
          "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat online"])}
        }
      }
    }
  })
}
