var FileIdentify = /** @class */ (function () {
    function FileIdentify(uuid, id) {
        this.setUuid(uuid);
        this.setId(id);
    }
    FileIdentify.prototype.setUuid = function (uuid) {
        this._uuid = uuid;
    };
    FileIdentify.prototype.setId = function (id) {
        this._id = id;
    };
    FileIdentify.prototype.getUuid = function () {
        return this._uuid;
    };
    FileIdentify.prototype.getId = function () {
        return this._id;
    };
    return FileIdentify;
}());
export { FileIdentify };
