/* eslint-disable no-unused-vars */
export var RuleTypeEnum;
(function (RuleTypeEnum) {
    /**
     * Набрать необходимое кол-во баллов на курсе
     */
    RuleTypeEnum["TOTAL_SCORE"] = "total-score";
    /**
     * Успешно пройти выбранные тестирования
     */
    RuleTypeEnum["FINISH_SELECT_TEST"] = "finish-select-test";
    /**
     * Успешно пройти все тестирования
     */
    RuleTypeEnum["FINISH_ALL_TEST"] = "finish-all-test";
    /**
     * Завершить курс за отведенное время
     */
    RuleTypeEnum["FINISH_COURSE_TIME"] = "finish-course-time";
    /**
     * Завершить курс до выбранной даты
     */
    RuleTypeEnum["FINISH_COURSE_BEFORE_DATE"] = "finish-course-before-date";
    /**
     * Успешно сдать все домашние задания
     */
    RuleTypeEnum["FINISH_ALL_HOMEWORK"] = "finish-all-homework";
    /**
     * Успешно сдать выбранные домашние задания
     */
    RuleTypeEnum["FINISH_SELECT_HOMEWORK"] = "finish-select-homework";
    /**
     * Завершить все занятия
     */
    RuleTypeEnum["FINISH_ALL_LESSONS_COMPATIBILITY"] = "finish-all-lessons-compatibility";
    /**
     * Завершить курс
     */
    RuleTypeEnum["FINISH_COURSE"] = "finish-course";
    /**
     * Завершить выбранные уроки (лекции)
     */
    RuleTypeEnum["FINISH_SELECT_DEFAULT_LESSON"] = "finish-select-default-lesson";
    /**
     * Завершить все уроки (лекции)
     */
    RuleTypeEnum["FINISH_ALL_DEFAULT_LESSON"] = "finish-all-default-lesson";
    /**
     * Завершить выбранные вебинары
     */
    RuleTypeEnum["FINISH_SELECT_WEBINAR"] = "finish-select-webinar";
    /**
     * Завершить все вебинары
     */
    RuleTypeEnum["FINISH_ALL_WEBINAR"] = "finish-all-webinar";
})(RuleTypeEnum || (RuleTypeEnum = {}));
/* eslint-enable no-unused-vars */
