var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import passData from '../utils/PassData';
import { convertToFormData } from '../utils/Helpers/Helpers';
import getClientFingerprint from '../utils/ClientFingerprint';
import { numberColorHash } from '@common/utils/Colors/Colors';
import { isMobileApp, setMobileAppSchool } from '@common/utils/MobileApp';
import { httpClient } from '@common/utils/HttpClient';
import { storageSession } from '@common/utils/Storage/Storage';
import { AuthRoles } from '@common/interfaces/utils';
import { GrantedActionsEnum } from '@common/enums/grantedActions.enum';
import { useEventBus } from '@vueuse/core';
import { openWsConnection, closeWsConnection } from '@common/api/ws/websocketInstance';
export var AuthEvents = {
    AUTH_SIGNED_IN: 'AUTH_SIGNED_IN',
    AUTH_SIGNED_OUT: 'AUTH_SIGNED_OUT',
    AUTH_CANCELLED: 'AUTH_CANCELLED',
};
var busSignIn = useEventBus(AuthEvents.AUTH_SIGNED_IN);
var busSignOut = useEventBus(AuthEvents.AUTH_SIGNED_OUT);
var Auth = /** @class */ (function () {
    function Auth() {
        this.schoolUserRole = null;
        this.schoolStudentName = null;
    }
    Auth.prototype.setOptions = function (_a) {
        var _b;
        var store = _a.store, router = _a.router;
        this.router = router;
        this.store = store;
        this.schoolUserRole = passData.schoolUserRole;
        this.schoolStudentName = (_b = passData.student) === null || _b === void 0 ? void 0 : _b.schoolStudentName;
        var user = passData.user;
        if (user) {
            this.setUser(user);
        }
        else if (!user && (passData === null || passData === void 0 ? void 0 : passData.authTokenExpired)) {
            this.refreshToken()
                .then(function () {
                document.location.reload();
            });
        }
    };
    Auth.prototype.getUser = function () {
        var _a;
        return (_a = this.store) === null || _a === void 0 ? void 0 : _a.user;
    };
    Auth.prototype.getUserEmployee = function () {
        return passData.employee;
    };
    Auth.prototype.setUser = function (user) {
        var _a, _b;
        if (user && user.id) {
            user.role = (_a = user.role) !== null && _a !== void 0 ? _a : this.schoolUserRole;
            user.color = numberColorHash(user.id);
            user.schoolStudentName = (_b = user.schoolStudentName) !== null && _b !== void 0 ? _b : this.schoolStudentName;
        }
        if (this.store) {
            this.store.user = user;
        }
    };
    Auth.prototype.cleanUser = function () {
        this.setUser({});
    };
    Auth.prototype.isGuest = function () {
        return !this.getUser() || !this.getUser().id;
    };
    Auth.prototype.isStudentUser = function () {
        return this.schoolUserRole === AuthRoles.ROLE_STUDENT;
    };
    Auth.prototype.isSchoolEmployee = function () {
        return this.schoolUserRole === AuthRoles.ROLE_EMPLOYEE;
    };
    Auth.prototype.isSchoolAdmin = function () {
        return this.schoolUserRole === AuthRoles.ROLE_SCHOOL_ADMIN;
    };
    Auth.prototype.isSchoolOwner = function () {
        if (this.isGuest()) {
            return false;
        }
        return passData.isSchoolUserOwner;
    };
    Auth.prototype.isSchoolTeacher = function () {
        return !this.isGuest();
    };
    Auth.prototype.hasActions = function (action, mode) {
        if (mode === void 0) { mode = 'some'; }
        if (!passData.employeeRoleActions)
            return false;
        if (mode === 'some') {
            return action.some(function (a) { return passData.employeeRoleActions.includes(a); });
        }
        return action.every(function (a) { return passData.employeeRoleActions.includes(a); });
    };
    // Доступ к разделу курсы
    Auth.prototype.hasAccessCourse = function () {
        return this.hasActions([GrantedActionsEnum.ACTION_COURSE_VIEW]);
    };
    // Доступ к разделу с тарифами и оплатой
    Auth.prototype.hasAccessTariffAndPayment = function () {
        return this.hasActions([GrantedActionsEnum.ACTION_SCHOOL_TARIFF_AND_PAYMENT]);
    };
    // Доступ к разделу Ученики и аналитика
    Auth.prototype.hasAccessStudentsAndAnalytics = function () {
        return this.hasActions([GrantedActionsEnum.ACTION_STUDENT_LIST]);
    };
    // Доступ к разделу Проверка домашних работ
    Auth.prototype.hasAccessHomework = function () {
        return this.hasActions([GrantedActionsEnum.ACTION_COURSE_HOMEWORK_USER_LIST]);
    };
    // Доступ к Онлайн чату
    Auth.prototype.hasAccessChat = function () {
        return this.hasActions([GrantedActionsEnum.ACTION_SCHOOL_CHAT_ACCESS]);
    };
    // Доступ к разделу Баланс и вывод средств
    Auth.prototype.hasAccessBalance = function () {
        return this.hasActions([GrantedActionsEnum.ACTION_SCHOOL_BALANCE]);
    };
    // Доступ к разделу Настройки школы
    Auth.prototype.hasAccessSettings = function () {
        return this.hasActions([GrantedActionsEnum.ACTION_SCHOOL_SETTINGS]);
    };
    Auth.prototype.firstAccessedSection = function () {
        var _a, e_1, _b;
        var sections = (_a = {},
            _a[GrantedActionsEnum.ACTION_COURSE_VIEW] = this.hasAccessCourse(),
            _a[GrantedActionsEnum.ACTION_SCHOOL_TARIFF_AND_PAYMENT] = this.hasAccessTariffAndPayment(),
            _a[GrantedActionsEnum.ACTION_STUDENT_LIST] = this.hasAccessStudentsAndAnalytics(),
            _a[GrantedActionsEnum.ACTION_COURSE_HOMEWORK_USER_LIST] = this.hasAccessHomework(),
            _a[GrantedActionsEnum.ACTION_SCHOOL_BALANCE] = this.hasAccessBalance(),
            _a[GrantedActionsEnum.ACTION_SCHOOL_SETTINGS] = this.hasAccessSettings(),
            _a);
        try {
            for (var _c = __values(Object.entries(sections)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
                if (value)
                    return key;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    };
    Auth.prototype.login = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var fingerprint;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getClientFingerprint()];
                    case 1:
                        fingerprint = _a.sent();
                        data = convertToFormData(__assign(__assign({}, data), { fingerprint: fingerprint }));
                        return [2 /*return*/, httpClient.post('/user/auth', data, { baseURL: '/api' })
                                .then(function (res) {
                                openWsConnection();
                                var result = res.data;
                                if (result.schools[0].role === 'ROLE_STUDENT' && result.student) {
                                    var user = result.user, student = result.student;
                                    var schoolStudentName = student.schoolStudentName;
                                    var loginUser = __assign(__assign({}, user), { schoolStudentName: schoolStudentName });
                                    _this.schoolUserRole = user.role;
                                    _this.schoolStudentName = student.studentName;
                                    _this.setUser(loginUser);
                                    busSignIn.emit(AuthEvents.AUTH_SIGNED_IN, loginUser);
                                }
                                else {
                                    _this.schoolUserRole = result.user.role;
                                    _this.setUser(result.user);
                                    // Уведомляем о том, что авторизация успешно прошла
                                    busSignIn.emit(AuthEvents.AUTH_SIGNED_IN, result.user);
                                }
                                return res;
                            }).catch(function (err) {
                                throw err;
                            })];
                }
            });
        });
    };
    Auth.prototype.register = function (data) {
        var _this = this;
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        data = convertToFormData(data);
        return httpClient.post('/user/register', data, { baseURL: '/api' })
            .then(function (res) {
            var result = res.data;
            _this.setUser(result.user);
            _this.schoolUserRole = result.user.role;
            // Уведомляем о том, что авторизация успешно прошла
            busSignIn.emit(AuthEvents.AUTH_SIGNED_IN, result.user);
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Auth.prototype.registerByToken = function (data) {
        var _this = this;
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        data = convertToFormData(data);
        return httpClient.post('/user/change-register-password', data, { baseURL: '/api' })
            .then(function (res) {
            var data = res.data;
            _this.setUser(data.user);
            // Уведомляем о том, что авторизация успешно прошла
            busSignIn.emit(AuthEvents.AUTH_SIGNED_IN, data.user);
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Auth.prototype.logout = function () {
        var _this = this;
        // Стираем в приложении сохраненную школу
        setMobileAppSchool('');
        closeWsConnection();
        return httpClient.post('/user/logout', {}, { baseURL: '/api' })
            .then(function (res) {
            var _a;
            var domain = document.location.host.split('.').slice(-2).join('.');
            if (isMobileApp() && domain === 'skillspace.ru') {
                document.location.href = "https://".concat(domain, "/auth/login");
            }
            else if (_this.isStudentUser()) {
                (_a = _this.router) === null || _a === void 0 ? void 0 : _a.push({ name: 'schoolCourses', }).catch(function () { });
            }
            else {
                document.location.href = '/';
            }
            _this.cleanUser();
            _this.schoolUserRole = null;
            _this.schoolStudentName = null;
            busSignOut.emit();
            return res;
        }).catch(function (err) {
            throw err;
        });
    };
    Auth.prototype.refreshToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var fingerprint, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getClientFingerprint()];
                    case 1:
                        fingerprint = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, httpClient.post('/user/refresh', convertToFormData({ fingerprint: fingerprint }), { baseURL: '/api' })];
                    case 3:
                        response = _a.sent();
                        // Если код ответа не 200, выбрасываем ошибку
                        if (response.status !== 200) {
                            throw new Error("\u041E\u0448\u0438\u0431\u043A\u0430 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F \u0442\u043E\u043A\u0435\u043D\u0430: \u0441\u0442\u0430\u0442\u0443\u0441 ".concat(response.status));
                        }
                        return [2 /*return*/, response];
                    case 4:
                        error_1 = _a.sent();
                        throw error_1;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Сохраняем текущую локацию, чтобы перейти к ней после завершения регистрации/авторизации
     *
     * @param route
     */
    Auth.prototype.saveCurrentLocation = function (route) {
        storageSession.set('routeToRedirectAfterAuth', route);
    };
    /**
     * Открываем сохраненную локацию после авторизации, либо переходим в домашнюю директорию
     *
     * @param reloadPage
     * @param fallBackUrl
     * @param isStudentUser
     */
    Auth.prototype.restoreSavedLocation = function (reloadPage, fallBackUrl, isStudentUser) {
        var _a;
        var savedRoute = storageSession.get('routeToRedirectAfterAuth');
        var defaultLibraryUrl = '/library/courses';
        var redirectLink = isStudentUser && !savedRoute
            ? defaultLibraryUrl
            : (savedRoute
                ? savedRoute.fullPath
                : fallBackUrl !== null && fallBackUrl !== void 0 ? fallBackUrl : document.location.href.split('#')[0]);
        if (reloadPage) {
            document.location = redirectLink;
        }
        else {
            (_a = this.router) === null || _a === void 0 ? void 0 : _a.push(redirectLink).catch(function () { });
        }
    };
    return Auth;
}());
export default new Auth();
