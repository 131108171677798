var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { convertToFormData } from '@common/utils/Helpers/Helpers';
import { httpClientApiSchool } from '@common/utils/HttpClient';
var CertificateApi = /** @class */ (function (_super) {
    __extends(CertificateApi, _super);
    function CertificateApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CertificateApi.prototype.updateCertificate = function (courseUuid, data) {
        return httpClientApiSchool.post("course/".concat(courseUuid, "/certificate/update"), data);
    };
    CertificateApi.prototype.getCertificate = function (courseUuid) {
        return httpClientApiSchool.get("course/".concat(courseUuid, "/certificate/get"));
    };
    CertificateApi.prototype.publishCertificateRules = function (courseUuid, data) {
        return httpClientApiSchool.post("course/".concat(courseUuid, "/certificate/publish"), data);
    };
    CertificateApi.prototype.deleteGroupSettings = function (courseUuid, groupUuid) {
        return httpClientApiSchool.delete("course/".concat(courseUuid, "/certificate/remove/").concat(groupUuid));
    };
    CertificateApi.prototype.createTemplate = function (courseUuid, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("course/".concat(courseUuid, "/certificate/template/create"), data);
    };
    CertificateApi.prototype.getTemplates = function (courseUuid) {
        return httpClientApiSchool.get("course/".concat(courseUuid, "/certificate/template/list"));
    };
    CertificateApi.prototype.updateTemplate = function (templateUuid, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("course/certificate/template/".concat(templateUuid, "/update"), data);
    };
    CertificateApi.prototype.assignTemplate = function (courseUuid, templateUuid) {
        return httpClientApiSchool.post("course/".concat(courseUuid, "/certificate/template/").concat(templateUuid, "/assign"));
    };
    CertificateApi.prototype.deleteTemplate = function (templateUuid) {
        return httpClientApiSchool.delete("course/certificate/template/".concat(templateUuid, "/remove"));
    };
    CertificateApi.prototype.publishCertificate = function (courseUuid) {
        return httpClientApiSchool.post("/course/".concat(courseUuid, "/certificate/template/publish"));
    };
    return CertificateApi;
}(ApiBase));
export default new CertificateApi();
