var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import EMessage from './EMessage.vue';
import { nextZIndex } from '@common/hooks';
import { componentMount } from "@common/utils/ComponentMount";
var messagesQueue = [];
var messageIndex = 0;
var Message = /** @class */ (function () {
    function Message(_a) {
        var type = _a.type, message = _a.message, duration = _a.duration, onClose = _a.onClose;
        this.id = "message-".concat(messageIndex++);
        this.type = type;
        this.type = type;
        this.message = message;
        this.duration = duration;
        this.onClose = onClose;
    }
    Message.prototype.mountComponent = function () {
        var _this = this;
        var _a, _b;
        this.vueApp = componentMount(EMessage, {
            props: {
                type: this.type,
                message: this.message,
                zIndex: nextZIndex(),
                duration: this.duration,
                // Подсчитанное значение должно быть
                startedVerticalOffset: this.getVerticalOffset(),
                onAfterClose: function () {
                    // Таймер на всякий случай, чтобы все анимации точно завершились
                    // При закрытии компонента изнутри — закрываем и message
                    _this.close();
                    updateAllOffsets();
                },
                onClose: function () {
                    _this.onClose && _this.onClose();
                }
            },
        });
        this.container = this.vueApp.el;
        document.body.append(this.container);
        (_b = (_a = this.vueApp) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.open();
    };
    Message.prototype.open = function () {
        // eslint-disable-next-line unicorn/no-this-assignment
        this.mountComponent();
    };
    Message.prototype.close = function () {
        var _this = this;
        // Открепляем и удаляем инстанс компонента и контейнер
        this.vueApp.destroy();
        this.vueApp = null;
        this.container.remove();
        // Удаляем из очереди сообщение
        messagesQueue = messagesQueue.filter(function (m) {
            return m !== _this;
        });
    };
    Message.prototype.getVerticalOffset = function () {
        var e_1, _a;
        var _b, _c;
        var offset = 60;
        try {
            for (var messagesQueue_1 = __values(messagesQueue), messagesQueue_1_1 = messagesQueue_1.next(); !messagesQueue_1_1.done; messagesQueue_1_1 = messagesQueue_1.next()) {
                var message = messagesQueue_1_1.value;
                if (message === this) {
                    break;
                }
                offset += ((_c = (_b = message.vueApp) === null || _b === void 0 ? void 0 : _b.methods) === null || _c === void 0 ? void 0 : _c.getHeight()) + 20; // 20 is gap
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (messagesQueue_1_1 && !messagesQueue_1_1.done && (_a = messagesQueue_1.return)) _a.call(messagesQueue_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return offset;
    };
    Message.prototype.updateVerticalOffset = function () {
        var _a, _b;
        var offset = this.getVerticalOffset();
        (_b = (_a = this.vueApp) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.updateOffset(offset);
    };
    return Message;
}());
// Добавляем сообщение в очередь и показываем, если в очереди не было сообщений до него
var addToQueue = function (message) {
    messagesQueue.push(message);
    message.open();
};
var updateAllOffsets = function () {
    var e_2, _a;
    try {
        for (var messagesQueue_2 = __values(messagesQueue), messagesQueue_2_1 = messagesQueue_2.next(); !messagesQueue_2_1.done; messagesQueue_2_1 = messagesQueue_2.next()) {
            var message = messagesQueue_2_1.value;
            message.updateVerticalOffset();
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (messagesQueue_2_1 && !messagesQueue_2_1.done && (_a = messagesQueue_2.return)) _a.call(messagesQueue_2);
        }
        finally { if (e_2) throw e_2.error; }
    }
};
export var MessagePush = function (type, options) { return __awaiter(void 0, void 0, void 0, function () {
    var message;
    return __generator(this, function (_a) {
        // Если опций вовсе не передали - создаем пустой объект
        options = options !== null && options !== void 0 ? options : {};
        // Если передали строку - конвертим в объект
        if (typeof options === 'string') {
            options = {
                message: options,
            };
        }
        message = new Message(__assign({ type: type }, options));
        addToQueue(message);
        return [2 /*return*/];
    });
}); };
export default {
    success: function (options) {
        MessagePush('success', options);
    },
    warning: function (options) {
        MessagePush('warning', options);
    },
    info: function (options) {
        MessagePush('info', options);
    },
    error: function (options) {
        MessagePush('error', options);
    },
};
