export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to courses has been blocked by the administrator"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore access, please contact the administrator of the educational project"])}
        }
      },
      "ru": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к курсам заблокирован администратором"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для восстановления доступа обратитесь к администратору образовательного проекта"])}
        }
      },
      "es": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El acceso a los cursos está bloqueado por el administrador"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restaurar el acceso, contacte con el administrador del proyecto educativo"])}
        }
      },
      "kk": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстарға қолжеткізу әкімші тарапынан бұғатталды"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолжеткізуді қалпына келтіру үшін білім беру жобасының әкімшіге хабарласыңыз"])}
        }
      },
      "uz": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurslarga kirish administrator tomonidan bloklangan"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirish huquqini tiklash uchun ta'lim loyihasi administratoriga murojaat qiling"])}
        }
      },
      "de": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff auf die Kurse wurde vom Administrator gesperrt"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Zugriff wiederherzustellen, wenden Sie sich bitte an den Administrator des Bildungsprojekts"])}
        }
      },
      "fr": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accès aux cours est bloqué par l'administrateur"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rétablir l'accès, veuillez contacter l'administrateur du projet éducatif"])}
        }
      },
      "it": {
        "subscription": {
          "accessBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accesso ai corsi è stato bloccato dall'amministratore"])},
          "contactAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ripristinare l'accesso, contatta l'amministratore del progetto educativo"])}
        }
      }
    }
  })
}
