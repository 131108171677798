import { createPinia } from 'pinia';
var CurrentPinia = /** @class */ (function () {
    function CurrentPinia() {
    }
    CurrentPinia.getInstance = function () {
        return this.instance;
    };
    CurrentPinia.instance = createPinia();
    return CurrentPinia;
}());
export default CurrentPinia;
