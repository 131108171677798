export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To record a message, please press and hold the microphone button."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microphone access has been blocked."])}
        }
      },
      "ru": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для записи сообщения удерживайте, пожалуйста, кнопку микрофона нажатой."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас заблокирован доступ к микрофону"])}
        }
      },
      "es": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para grabar un mensaje, por favor mantén pulsado el botón del micrófono."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu acceso al micrófono está bloqueado."])}
        }
      },
      "kk": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарлама жазу үшін микрофон батырмасын басып ұстаңыз, өтінемін."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің микрофонға қатынауыңыз бұғатталды"])}
        }
      },
      "uz": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabar yozish uchun, iltimos, mikrofon tugmasini bosib turing."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizda mikrofonga kirishga olib qo'yilgan."])}
        }
      },
      "de": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte halten Sie die Mikrofontaste gedrückt, um eine Nachricht aufzuzeichnen."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff auf Ihr Mikrofon ist blockiert."])}
        }
      },
      "fr": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour enregistrer un message, veuillez maintenir le bouton du microphone enfoncé."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accès au microphone est bloqué."])}
        }
      },
      "it": {
        "message": {
          "recordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per registrare un messaggio, tieni premuto il pulsante del microfono, per favore."])},
          "microphoneBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai bloccato l'accesso al microfono"])}
        }
      }
    }
  })
}
