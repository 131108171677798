export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or username"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email or username"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with mos.ru"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot contain spaces"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user with this email is already registered"])}
        }
      },
      "ru": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уже зарегистрированы?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email или логин"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш email или логин"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я согласен с"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политикой обработки персональных данных"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договором оферты"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через mos.ru"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите свой email"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите корректный email"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите пароль"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен состоять минимум из 8 символов"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не может содержать пробел"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это обязательный пункт"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь с таким email уже зарегистрирован"])}
        }
      },
      "es": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya estás registrado?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico o usuario"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico o usuario"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy de acuerdo con"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la Política de tratamiento de datos personales"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el contrato de oferta"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión a través de mos.ru"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce tu correo electrónico"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce un correo electrónico válido"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce una contraseña"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe tener al menos 8 caracteres"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña no puede contener espacios"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es un campo obligatorio"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe un usuario con ese correo electrónico"])}
        }
      },
      "kk": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелу"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазір тіркелгенсіз бе?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық пошта немесе логин"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің электрондық поштаңыз немесе логиніңіз"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мен келесілермен келісемін"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке деректерді өңдеу саясаты"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["және"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс келісімшарты"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт құру"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mos.ru арқылы кіру"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінеміз, өзіңіздің электрондық поштаңызды енгізіңіз"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінеміз, дұрыс электрондық пошта енгізіңіз"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтінеміз, құпия сөзді енгізіңіз"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз кем дегенде 8 таңбадан тұруы керек"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз бос орындарды қамти алмайды"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл міндетті тармақ"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұндай электрондық поштамен пайдаланушы бұрыннан тіркелген"])}
        }
      },
      "uz": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ro'yxatdan o'tish"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allaqachon ro'yxatdan o'tganmisiz?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirish"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email yoki login"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailingiz yoki loginingiz"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men roziman"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shaxsiy ma'lumotlarni qayta ishlash siyosati"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["va"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taklif shartnomasi"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hisob yaratish"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mos.ru orqali kirish"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, emailingizni kiriting"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, to'g'ri emailni kiriting"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltimos, parolni kiriting"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol kamida 8 belgidan iborat bo'lishi kerak"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolda bo'shliqlar bo'lishi mumkin emas"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu majburiy band"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunday email bilan foydalanuvchi allaqachon ro'yxatdan o'tgan"])}
        }
      },
      "de": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits registriert?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail oder Benutzername"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse oder Benutzername"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme zu"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["der Datenschutzerklärung"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dem Angebotsvertrag"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über mos.ru anmelden"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail-Adresse ein"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine gültige E-Mail-Adresse ein"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihr Passwort ein"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen lang sein"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort darf keine Leerzeichen enthalten"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Pflichtfeld"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Benutzer mit dieser E-Mail-Adresse ist bereits registriert"])}
        }
      },
      "fr": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà inscrit ?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou identifiant"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email ou identifiant"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politiques de traitement des données personnelles"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le contrat d'offre"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter via mos.ru"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre email"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un email valide"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un mot de passe"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne peut pas contenir d'espaces"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette option est obligatoire"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utilisateur avec cet email est déjà enregistré"])}
        }
      },
      "it": {
        "registration": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
          "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Già registrato?"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
          "emailOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email o nome utente"])},
          "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo email o nome utente"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "agreeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acconsento a"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica di trattamento dei dati personali"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
          "offerAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratto d'offerta"])},
          "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account"])},
          "loginWithMosru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi tramite mos.ru"])}
        },
        "validation": {
          "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo email"])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci un email valido"])},
          "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la password"])},
          "passwordMinLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve contenere almeno 8 caratteri"])},
          "passwordNoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non può contenere spazi"])},
          "policyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo è un campo obbligatorio"])}
        },
        "errors": {
          "emailExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utente con questo email è già registrato"])}
        }
      }
    }
  })
}
