var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { convertToFormData } from '@common/utils/Helpers/Helpers';
import { httpClientApiSchool } from '@common/utils/HttpClient';
var EmployeesApi = /** @class */ (function (_super) {
    __extends(EmployeesApi, _super);
    function EmployeesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Добавить сотрудника в школу
     *
     * @param {any} data Информация
     * @returns {Promise<any>}
     */
    EmployeesApi.prototype.addEmployee = function (data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/school/employee/append", data);
    };
    /**
     * Обновить пользователя школы
     *
     * @param uuid
     * @param data
     */
    EmployeesApi.prototype.updateEmployee = function (uuid, data) {
        data = convertToFormData(data);
        return httpClientApiSchool.post("/school/employee/".concat(uuid, "/update"), data);
    };
    /**
     * Удалить сотрудника из школы
     *
     * @param {GetRequest<RemoveEmployeeDto>} data
     * @returns {Promise<AxiosResponse<GetResponse<RemoveEmployeeDto>>>}
     */
    EmployeesApi.prototype.removeEmployee = function (data) {
        return httpClientApiSchool.post("/school/employee/remove", data);
    };
    EmployeesApi.prototype.getEmployees = function () {
        return httpClientApiSchool.get("/school/employee/list");
    };
    return EmployeesApi;
}(ApiBase));
export default new EmployeesApi();
