import { createWebHistory } from 'vue-router';
import { createRouter } from 'vue-router';
import { useCoreStore } from '@student/modules/stores/coreStore';
import { useAuthModalStore } from '@student/modules/stores/authModalStore';
import Auth from '@common/modules/auth';
import Message from '@common/ui/EMessage/Message';
import { useMeta } from '@common/hooks/useMeta';
import CurrentI18n from '@common/utils/CurrentI18n';
import { translations } from '@student/modules/router.i18n';
var router = function () {
    var getPageTitle = function (RouteTitle) {
        var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
        var lang = locale.value;
        return t(translations[lang][RouteTitle]);
    };
    var routes = [
        {
            path: '/',
            name: 'home',
            redirect: { name: 'schoolCatalog' },
            meta: {
                auth: {},
                title: '',
            },
        },
        {
            path: '/school',
            name: 'schoolCatalog',
            redirect: { name: 'schoolCourses' },
            component: function () { return import('@student/pages/SchoolCatalog/SchoolCatalog.vue'); },
            meta: {
                auth: {},
                title: getPageTitle('schoolCatalog'),
            },
            children: [
                {
                    path: 'courses',
                    name: 'schoolCourses',
                    component: function () { return import('@student/pages/SchoolCatalog/SchoolCourses/SchoolCourses.vue'); },
                    meta: {
                        auth: {},
                        title: getPageTitle('schoolCourses'),
                    }
                },
                {
                    path: 'webinars',
                    name: 'schoolWebinars',
                    component: function () { return import('@student/pages/SchoolCatalog/SchoolWebinars/SchoolWebinars.vue'); },
                    meta: {
                        auth: {},
                        title: getPageTitle('schoolWebinars'),
                    },
                }
            ],
        },
        {
            path: '/library',
            name: 'library',
            redirect: { name: 'libraryCourses' },
            component: function () { return import('../pages/Library/LibraryPage.vue'); },
            meta: {
                auth: {
                    userOnly: true
                },
                title: getPageTitle('library'),
                parentRoute: 'schoolCourses' // Родитель для библиотеки — это schoolCourses
            },
            children: [
                {
                    path: 'courses',
                    name: 'libraryCourses',
                    component: function () { return import('@student/pages/Library/CoursesLibrary/CoursesLibrary.vue'); },
                    meta: {
                        auth: {},
                        title: getPageTitle('libraryCourses'),
                    }
                },
                {
                    path: 'webinars',
                    name: 'libraryWebinars',
                    component: function () { return import('@student/pages/Library/WebinarsLibrary/WebinarsLibrary.vue'); },
                    meta: {
                        auth: {},
                        title: getPageTitle('libraryWebinars'),
                    },
                }
            ],
        },
        // Course
        {
            path: '/course/:courseId',
            name: 'courseLearn',
            component: function () { return import('../pages/Course/CourseLearn/CourseLearn.vue'); },
            meta: {
                auth: { userOnly: true, },
                title: '',
                parentRoute: 'library' // Родитель для courseLearn — это список курсов
            },
        },
        // Course Landing Old
        {
            path: '/course/:courseId/about',
            name: 'courseAboutOld',
            component: function () { return import('../pages/Course/CourseAbout/CourseAbout.vue'); },
            meta: {
                auth: {},
                title: '',
                parentRoute: 'courseLearn' // От/about поднимаемся к courseLearn
            },
        },
        // Course Landing New
        {
            path: '/l/:slug',
            name: 'courseAbout',
            component: function () { return import('../pages/Course/CourseAbout/CourseAbout.vue'); },
            meta: {
                auth: {},
                title: '',
                parentRoute: 'schoolCourses' // Или, например, к schoolCourses, если нужно
            },
        },
        {
            path: '/course/:courseId/completion',
            name: 'courseCompletion',
            component: function () { return import('../pages/Course/CourseCompletion/CourseCompletion.vue'); },
            meta: {
                auth: { userOnly: true, },
                title: getPageTitle('courseCompletion'),
                parentRoute: 'courseLearn'
            },
        },
        {
            path: '/course/:courseId/certificate',
            name: 'courseCertificate',
            component: function () { return import('../pages/Course/CourseCertificate/CourseCertificate.vue'); },
            meta: {
                auth: { userOnly: true, },
                title: getPageTitle('courseCertificate'),
                parentRoute: 'courseLearn'
            },
        },
        // Course
        {
            path: '/course/:courseId',
            name: 'courseStep',
            redirect: function (to) {
                return {
                    name: 'courseLearn',
                    params: { courseId: to.params.courseId, },
                };
            },
            meta: {
                auth: { userOnly: true, },
                title: '',
                parentRoute: 'library'
            },
        },
        // Course lesson
        {
            path: '/course/:courseId/:lessonId',
            name: 'courseLesson',
            component: function () { return import('../pages/Course/CourseLesson/CourseLesson.vue'); },
            meta: {
                auth: { userOnly: true, },
                title: '',
                parentRoute: 'courseLearn' // урок → курс
            },
        },
        // Webinar
        {
            path: '/stream/:streamId',
            name: 'WebinarTranslation',
            component: function () { return import('../pages/WebinarTranslation/WebinarTranslation.vue'); },
            meta: {
                auth: {
                    actionAccess: []
                },
                title: getPageTitle('webinarTranslation'),
            },
        },
        // Profile
        {
            path: '/',
            name: 'profile',
            component: function () { return import('../pages/Profile/Profile.vue'); },
            redirect: { name: 'profileSettings' },
            meta: {
                auth: { userOnly: true, },
                title: getPageTitle('profile'),
                parentRoute: 'schoolCourses' // Можно указать родителя. Например, профиль не имеет прямого родителя, но пусть будет schoolCourses
            },
            children: [
                {
                    path: 'settings',
                    name: 'profileSettings',
                    component: function () { return import('../pages/Profile/ProfileSettings/ProfileSettings.vue'); },
                    meta: {
                        auth: { userOnly: true, },
                        title: getPageTitle('profileSettings'),
                    },
                },
                {
                    path: '/notification-settings',
                    name: 'profileNotifications',
                    component: function () { return import('../pages/Profile/NotificationSettings/NottificationSettings.vue'); },
                    meta: {
                        auth: { userOnly: true, },
                        title: getPageTitle('profileNotifications'),
                    },
                },
                {
                    path: '/partner',
                    name: 'profilePartner',
                    component: function () { return import('@common/components/PartnerProgram/PartnerProgram.vue'); },
                    meta: {
                        auth: { userOnly: true, },
                        title: getPageTitle('profilePartner'),
                    },
                },
            ],
        },
        // Not found
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            redirect: { name: 'home' },
            meta: { title: getPageTitle('notFound'), },
        },
    ];
    var router = createRouter({
        history: createWebHistory(),
        routes: routes,
    });
    var setTitle = useMeta().setTitle;
    router.beforeEach(function (to, from, next) {
        var _a, _b, _c;
        var CoreStore = useCoreStore();
        var authModalStore = useAuthModalStore();
        setTitle((_a = to.meta) === null || _a === void 0 ? void 0 : _a.title);
        if (typeof to.name === 'string') {
            document.body.setAttribute('page', to.name);
        }
        var userHasNoAccess = ((_c = (_b = to === null || to === void 0 ? void 0 : to.meta) === null || _b === void 0 ? void 0 : _b.auth) === null || _c === void 0 ? void 0 : _c.userOnly) && Auth.isGuest();
        // Если это гость и нет доступа к странице - просим авторизоваться
        if (userHasNoAccess && to.name !== 'courseLearn') {
            Auth.saveCurrentLocation(to);
            authModalStore.showLogin();
            setTimeout(function () {
                Message.warning('Данная страница доступна только зарегистрированным пользователям');
            }, 100);
            next({ name: 'schoolCourses' });
        }
        if (CoreStore.schoolSubscriptionIsOverdue && to.path.includes('/course/')) {
            next({ name: 'schoolCourses' });
        }
        else {
            next();
        }
    });
    return router;
};
export default router;
