import { CacheFactory } from '@common/utils/Cache/Cache';
import { defineStore } from 'pinia';
import Api from '@school/modules/api/api';
// Чтобы кэш не пересекался с другими модулями
var cache = new CacheFactory();
export var useSchoolGatewayStore = defineStore('schoolGateway', {
    state: function () { return ({
        // Платежная система
        // @sync с одинаковыми id
        gateway: {},
        // Отображать попап с настройкой платежной системы
        gatewayEditVisible: false,
        // Список платежных систем
        // @sync
        gateways: [],
        // Состояние загрузки списка платежных систем
        gatewaysLoading: false,
    }); },
    actions: {
        /**
         * Открыть окно редактирования платежной системы
         *
         * @param {object} gateway платежная система
         * @returns {Promise}
         */
        showGatewayEdit: function (gateway) {
            this.gateway = gateway;
            this.gatewayEditVisible = true;
        },
        /**
         * Скрыть окно ограничения на тарифе
         *
         * @returns {Promise}
         */
        hideGatewayEdit: function () {
            this.gatewayEditVisible = false;
            this.gateway = {};
        },
        /**
         * Получить список подключенных платежных систем
         *
         * @returns {Promise}
         */
        getGateways: function () {
            var _this = this;
            this.gatewaysLoading = true;
            return Api.getSchoolGateways()
                .then(function (res) {
                var data = res.data, result = data;
                _this.gateways = result;
                cache.set("gateways", result);
                _this.gatewaysLoading = false;
            });
        },
    },
});
