var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { countries, countriesIso2 } from '@common/utils/Location';
import { sortArray } from '@common/utils/Array/Array';
import { clone } from '@common/utils/Helpers/Helpers';
/**
 * Простая проверка на минимально и максимально возможную длину номера
 *
 * @param {string} phoneStr
 * @returns boolean
 */
export function isValidPhoneNumber(phoneStr) {
    var trimmedPhone = phoneStr ? phoneStr.replace(/\s/g, '') : '';
    return trimmedPhone.length > 8 && trimmedPhone.length <= 16 && trimmedPhone[0] === '+';
}
/**
 *
 * @param phoneStr
 */
export function isValidPhoneNumberV2(phoneStr) {
    var trimmedPhone = (phoneStr ? phoneStr : '')
        .replace(/\s/g, '')
        .replace(/(?!^-)-/g, '');
    return /^(\+\d{1,3}[ -]?)?\d{10}$/.test(trimmedPhone);
}
/**
 * Форматирует номер в формат E164. Прим: +79876543210
 *
 * @param {string} string
 * @returns string
 */
export function formatToE164(string) {
    if (!string)
        return '';
    var newString = string.match(/\d{0,14}/g);
    if (newString === null) {
        return '';
    }
    newString = newString.join('');
    newString = newString === '' ? newString : '+' + newString;
    // Limit length to 15 characters
    newString = newString.slice(0, 15);
    return newString;
}
export var isCountryAvailable = function (locale) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, countriesIso2.includes(locale)];
    });
}); };
/**
 * Находим страну по номеру телефона
 *
 * @param phone
 */
export var getCountryByPhone = function (phone) {
    var e_1, _a;
    phone = phone.replace(/\s|\+/g, '');
    var suitableCountries = [];
    try {
        // Собираем все страны у кого начало номера похоже на нужное
        for (var countries_1 = __values(countries), countries_1_1 = countries_1.next(); !countries_1_1.done; countries_1_1 = countries_1.next()) {
            var country = countries_1_1.value;
            if (typeof country.phoneCode === 'number' && phone.startsWith('' + country.phoneCode)) {
                suitableCountries.push(country);
            }
            else if (Array.isArray(country.phoneCode) && country.phoneCode.some(function (code) { return phone.startsWith('' + code); })) {
                // Берем подходящие коды, если их несколько
                var suitableCodes = country.phoneCode.filter(function (code) { return phone.startsWith('' + code); });
                // Сортируем коды по длинне
                suitableCodes = sortArray(suitableCodes, function (c) { return ('' + c).length; }, 'desc');
                // Оставлем в объекте только самый длинный подходящий код
                var tempCountry = clone(country);
                // Оставляем только самый длинный из подходящих кодов
                tempCountry.phoneCode = suitableCodes[0];
                // В phoneCode может быть массив кодов, берем самый длинный из подходящих
                suitableCountries.push(tempCountry);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (countries_1_1 && !countries_1_1.done && (_a = countries_1.return)) _a.call(countries_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    // Выбираем из них наиболее точное совпадение (по длине строки совпадения)
    suitableCountries = sortArray(suitableCountries, 'priority');
    suitableCountries = sortArray(suitableCountries, function (c) { return ('' + c.phoneCode).length; }, 'desc');
    return suitableCountries[0];
};
/**
 * @param val
 */
export function getPhoneMask(val) {
    var e_2, _a;
    // Маски номеров в зависимости от кода страны
    var codes = {
        '247': '###-####',
        '376': '###-###-###',
        '971': '###-##-###-####',
        '93': '##-##-###-####',
        '1': '# ### ###-####',
        '355': '### ### ###-###',
        '374': '###-##-###-###',
        '599': '###-###-####',
        '244': '### ### ###-###',
        '672': '###-###-###',
        '54': '## ### ###-####',
        '43': '## ### ###-####',
        '61': '##-#-####-####',
        '297': '###-###-####',
        '994': '###-##-###-##-##',
        '387': '###-##-#####',
        '880': '###-##-###-###',
        '32': '## ### ###-###',
        '226': '###-##-##-####',
        '359': '### ### ###-###',
        '973': '###-####-####',
        '257': '###-##-##-####',
        '229': '###-##-##-####',
        '673': '###-###-####',
        '591': '###-#-###-####',
        '55': '## ## ####-####',
        '975': '###-##-###-###',
        '267': '###-##-###-###',
        '375': '### ## ###-##-##',
        '501': '###-###-####',
        '243': '### ### ###-###',
        '236': '###-##-##-####',
        '242': '###-##-###-####',
        '41': '##-##-###-####',
        '225': '###-##-###-###',
        '682': '###-##-###',
        '56': '##-#-####-####',
        '237': '###-####-####',
        '86': '## ### ####-####',
        '57': '## ### ###-####',
        '506': '###-####-####',
        '53': '##-#-###-####',
        '238': '### ### ##-##',
        '357': '###-##-###-###',
        '420': '### ### ###-###',
        '49': '## #### ###-####',
        '253': '###-##-##-##-##',
        '45': '##-##-##-##-##',
        '213': '###-##-###-####',
        '593': '###-##-###-####',
        '372': '###-####-####',
        '20': '## ### ###-####',
        '291': '###-#-###-###',
        '34': '## ### ###-###',
        '251': '###-##-###-####',
        '358': '### ### ###-##-##',
        '679': '###-##-#####',
        '500': '###-#####',
        '691': '###-###-####',
        '298': '###-###-###',
        '262': '###-#####-####',
        '33': '## ### ###-###',
        '508': '###-##-####',
        '590': '### ### ###-###',
        '241': '###-#-##-##-##',
        '995': '### ### ###-###',
        '594': '###-#####-####',
        '233': '### ### ###-###',
        '350': '###-###-#####',
        '299': '###-##-##-##',
        '220': '### ### ##-##',
        '224': '###-##-###-###',
        '240': '###-##-###-####',
        '30': '## ### ###-####',
        '502': '###-#-###-####',
        '245': '###-#-######',
        '592': '###-###-####',
        '852': '###-####-####',
        '504': '###-####-####',
        '385': '###-##-###-###',
        '509': '###-##-##-####',
        '36': '## ### ###-###',
        '62': '## ### ###-#####',
        '353': '### ### ###-###',
        '972': '###-##-###-####',
        '91': '## #### ###-###',
        '246': '###-###-####',
        '964': '### ### ###-####',
        '98': '## ### ###-####',
        '354': '###-###-####',
        '39': '## ### ####-###',
        '962': '###-#-####-####',
        '81': '##-##-####-####',
        '254': '###-###-######',
        '996': '### ### ###-###',
        '855': '###-##-###-###',
        '686': '###-##-###',
        '269': '###-##-#####',
        '850': '###-###-###-####',
        '82': '##-##-####-####',
        '965': '###-####-####',
        '7': '# ### ###-##-##',
        '856': '### #### ###-###',
        '961': '###-##-###-###',
        '423': '### ### ###-####',
        '94': '##-##-###-####',
        '231': '###-##-###-###',
        '266': '###-#-###-####',
        '370': '### ### ##-###',
        '352': '### ### ###-###',
        '371': '###-##-###-###',
        '218': '###-##-###-###',
        '212': '###-##-####-###',
        '377': '### ### ###-###',
        '373': '###-####-####',
        '382': '###-##-###-###',
        '261': '###-##-##-#####',
        '692': '###-###-####',
        '389': '###-##-###-###',
        '223': '###-##-##-####',
        '95': '##-##-###-###',
        '976': '###-##-##-####',
        '853': '###-####-####',
        '596': '### ### ##-##-##',
        '222': '###-##-##-####',
        '356': '###-####-####',
        '230': '###-###-####',
        '960': '###-###-####',
        '265': '###-#-###-###',
        '52': '## ### ###-####',
        '60': '##-##-###-####',
        '258': '###-##-###-###',
        '264': '###-##-###-####',
        '687': '###-##-####',
        '227': '###-##-##-####',
        '234': '### ### ###-####',
        '505': '###-####-####',
        '31': '##-##-###-####',
        '47': '## ### ##-###',
        '977': '###-##-###-###',
        '674': '###-###-####',
        '683': '###-####',
        '64': '## ### ###-###',
        '968': '###-##-###-###',
        '507': '###-###-####',
        '51': '## ### ###-###',
        '689': '###-##-##-##',
        '675': '### ### ##-###',
        '63': '## ### ###-####',
        '92': '## ### ###-####',
        '48': '## ### ###-###',
        '970': '###-##-###-####',
        '351': '###-##-###-####',
        '680': '###-###-####',
        '595': '### ### ###-###',
        '974': '###-####-####',
        '40': '##-##-###-####',
        '381': '###-##-###-####',
        '250': '### ### ###-###',
        '966': '###-#-####-####',
        '677': '###-###-####',
        '248': '###-#-###-###',
        '249': '###-##-###-####',
        '46': '##-##-###-####',
        '65': '##-####-####',
        '290': '###-####',
        '386': '###-##-###-###',
        '421': '### ### ###-###',
        '232': '###-##-######',
        '378': '###-####-######',
        '221': '###-##-###-####',
        '252': '###-##-###-###',
        '597': '###-###-####',
        '211': '###-##-###-####',
        '239': '###-##-#####',
        '503': '###-##-##-####',
        '963': '###-##-####-###',
        '268': '###-##-##-####',
        '235': '###-##-##-##-##',
        '228': '###-##-###-###',
        '66': '##-##-###-####',
        '992': '###-##-###-####',
        '690': '###-####',
        '670': '###-###-####',
        '993': '###-#-###-####',
        '216': '###-##-###-###',
        '676': '###-#####',
        '90': '## ### ###-####',
        '688': '###-######',
        '886': '###-#-####-####',
        '255': '###-##-###-####',
        '380': '### ## ###-##-##',
        '256': '### ### ###-###',
        '44': '##-##-####-####',
        '598': '###-#-###-##-##',
        '998': '###-##-###-####',
        '58': '## ### ###-####',
        '84': '##-##-####-###',
        '678': '###-##-#####',
        '681': '###-##-####',
        '685': '###-##-####',
        '967': '###-###-###-###',
        '27': '##-##-###-####',
        '260': '###-##-###-####',
        '263': '###-#-######',
    };
    // Значение из поля ввода но без лишних символов (только цифры)
    var phone = val.replace(/\D{0,17}/g, '');
    var mask;
    try {
        // Находим маску по коду страны в начале номера
        for (var _b = __values(Object.keys(codes)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var codeKey = _c.value;
            if (phone.indexOf(codeKey) === 0) {
                mask = codes[codeKey];
                break;
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return mask ? "+".concat(mask) : null;
}
/**
 * Эту функцию использовать для вставки значения в input плохая идея, нужно как-то запоминать положение курсора
 * до изменений, потом высчитывать где он должен находиться после изменений и ставить его вручную в нужное место
 *
 * @param {string} val
 */
export function phoneStringFormat(val) {
    var mask = getPhoneMask(val);
    var phone = val.replace(/\D{0,17}/g, '');
    // Если маска не найдена, вставляем универсальную
    if (!mask) {
        mask = '+####################';
    }
    var phoneArr = __spreadArray([], __read(phone), false);
    var maskedPhone = mask
        .replaceAll('#', function () {
        return phoneArr.shift() || '#';
    })
        .replace(/\D+$/, '');
    var normalizedPhone = phone ? '+' + phone : '';
    // Значение этой переменной должно записываться уже в input и курсор устанавливаться в конец
    return maskedPhone || normalizedPhone;
}
