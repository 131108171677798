var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import ResizeObserver from 'resize-observer-polyfill';
var isServer = typeof window === 'undefined';
var resizeHandler = function (entries) {
    var e_1, _a, e_2, _b;
    try {
        for (var entries_1 = __values(entries), entries_1_1 = entries_1.next(); !entries_1_1.done; entries_1_1 = entries_1.next()) {
            var entry = entries_1_1.value;
            var listeners = resizeListenersMap.get(entry.target) || [];
            if (listeners.length > 0) {
                try {
                    for (var listeners_1 = (e_2 = void 0, __values(listeners)), listeners_1_1 = listeners_1.next(); !listeners_1_1.done; listeners_1_1 = listeners_1.next()) {
                        var fn = listeners_1_1.value;
                        fn(entry);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (listeners_1_1 && !listeners_1_1.done && (_b = listeners_1.return)) _b.call(listeners_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (entries_1_1 && !entries_1_1.done && (_a = entries_1.return)) _a.call(entries_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
var resizeObserversMap = new WeakMap();
var resizeListenersMap = new WeakMap();
export var addResizeListener = function (element, fn) {
    if (isServer)
        return;
    if (!resizeListenersMap.has(element)) {
        resizeListenersMap.set(element, []);
        var ro = new ResizeObserver(resizeHandler);
        resizeObserversMap.set(element, ro);
        ro.observe(element);
    }
    resizeListenersMap.get(element).push(fn);
};
export var removeResizeListener = function (element, fn) {
    if (!resizeListenersMap.has(element))
        return;
    var listeners = resizeListenersMap.get(element);
    var index = listeners.indexOf(fn);
    if (index !== -1) {
        listeners.splice(index, 1);
        if (listeners.length === 0 && resizeObserversMap.has(element)) {
            resizeObserversMap.get(element).disconnect();
            resizeObserversMap.delete(element);
            resizeListenersMap.delete(element);
        }
    }
};
