var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { httpClientApiStudent } from '@common/utils/HttpClient';
var LandingApi = /** @class */ (function (_super) {
    __extends(LandingApi, _super);
    function LandingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Получение курса по слагу лендинга
     *
     * @param {string} slug
     * @returns {Promise<AxiosResponse<GetResponse<GetCourseDto>>>}
     */
    LandingApi.prototype.getCourse = function (slug) {
        return httpClientApiStudent.get("/landing/".concat(slug));
    };
    /**
     * Проверка промокода
     *
     * @param {string} slug
     * @param {string} discount
     * @returns {Promise<AxiosResponse<GetResponse<CheckDiscountDto>>>}
     */
    LandingApi.prototype.checkDiscount = function (slug, discount) {
        return httpClientApiStudent.get("/payment/landing/".concat(slug, "/check-discount/").concat(encodeURI(discount)));
    };
    return LandingApi;
}(ApiBase));
export var studentLandingApi = new LandingApi();
