import CurrentApp from '@common/utils/CurrentApp';
window.__buildName = 'appStudent';
import * as Directives from '@common/directives';
/**
 * IMPORT
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// Styles
import '@commonStyles/app.css';
import 'plyr/dist/plyr.css';
// Libraries
import 'es6-promise/auto';
import '@common/utils/libs/DayjsExtended';
import '@common/utils/ZodLocale';
// Vue modules
import { createApp, h, provide } from 'vue';
// Layout
import Index from '@student/Index.vue';
// Modules
import * as Stores from '@student/modules/store';
import router from './modules/router';
import Auth from '@common/modules/auth';
// Vue Directives
import VueHotkey from 'v-hotkey';
import EIcon from '@common/ui/EIcon.vue';
import avatar from '@common/ui/EAvatar/EAvatar.vue';
import EButton from '@common/ui/EButton.vue';
import EDialog from '@common/ui/EDialog.vue';
import ETabs from '@common/ui/ETabs/ETabs.vue';
import ELink from '@common/ui/ELink.vue';
import EInput from '@common/ui/EInput.vue';
import ERangeSlider from '@common/ui/ERangeSlider/ERangeSlider.vue';
import ECheckbox from '@common/ui/ECheckbox/ECheckbox.vue';
import ESwitch from '@common/ui/ESwitch.vue';
import { copyProtected } from '@common/directives/copyProtected';
import Vue3TouchEvents from 'vue3-touch-events';
import VueKatex from '@hsorby/vue3-katex';
import CurrentPinia from '@common/utils/CurrentPinia';
import apolloClient from '../../apolloClient';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createI18n } from 'vue-i18n';
// Импортируем dayjs с синхронизацией локали
import { syncDayjsLocale } from '@common/utils/libs/DayjsExtended';
import { syncZodLocale } from '@common/utils/ZodLocale';
import CurrentI18n from '@common/utils/CurrentI18n';
import { customRule } from '@common/utils/pluralizationRules';
var app = createApp({
    name: 'AppStudent',
    setup: function () {
        provide(DefaultApolloClient, apolloClient);
    },
    render: function () { return h(Index); },
});
var pinia = CurrentPinia.getInstance();
CurrentApp.setInstance(app);
var savedLocale = localStorage.getItem('locale');
var supportedLocalesMap = ['en', 'ru', 'es', 'kk', 'uz', 'de', 'fr', 'it'];
var browserLang = navigator.language.split('-')[0];
var userLanguage = supportedLocalesMap.includes(browserLang) ? browserLang : 'en';
var locale = savedLocale || userLanguage;
localStorage.setItem('locale', locale);
export var i18n = createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'ru',
    pluralRules: {
        ru: customRule
    },
});
CurrentI18n.setInstance(i18n);
// Подключаем синхронизацию локали dayjs и Zod с i18n
syncDayjsLocale(i18n);
syncZodLocale(i18n);
app.use(pinia);
app.use(i18n);
var routerInst = router();
app.use(routerInst);
app.directive('copy-protected', copyProtected);
app.use(VueKatex, {
    globalOptions: {
        throwOnError: false
    }
});
app.use(Vue3TouchEvents, {
    touchHoldTolerance: 200,
});
app.directive('loading', Directives.loading);
Auth.setOptions({
    store: Stores.CoreStore,
    router: routerInst
});
// Set VueHotkey
app.use(VueHotkey);
// Custom global components
app
    .component('EIcon', EIcon)
    .component('Avatar', avatar)
    .component('EButton', EButton)
    .component('EDialog', EDialog)
    .component('ETabs', ETabs)
    .component('EInput', EInput)
    .component('ERangeSlider', ERangeSlider)
    .component('ECheckbox', ECheckbox)
    .component('ELink', ELink)
    .component('ESwitch', ESwitch);
// Это все необходимо для точной проверки, что скрипт загружен
if (document.readyState !== 'loading') {
    window.setTimeout(init);
}
else {
    // Use the handy event callback
    document.addEventListener('DOMContentLoaded', init);
    // A fallback to window.onload, that will always work
    window.addEventListener('load', init);
}
/**
 *
 */
function init() {
    document.removeEventListener('DOMContentLoaded', init);
    window.removeEventListener('load', init);
    if (document.querySelector('#app')) {
        app.mount('#app');
    }
}
