import { FileMeta } from '@common/utils/Catalyst/FileMeta';
var CatalystURL = /** @class */ (function () {
    function CatalystURL(url, name, size, mime, lastModified) {
        this._type = 'url';
        this._link = url;
        this._params = new FileMeta(name, size, mime, lastModified);
    }
    CatalystURL.prototype.getExtension = function () {
        return this._params.getExtension();
    };
    CatalystURL.prototype.getName = function () {
        return this._params.getName();
    };
    CatalystURL.prototype.getSize = function () {
        return this._params.getSize();
    };
    CatalystURL.prototype.getType = function () {
        return this._type;
    };
    CatalystURL.prototype.getLink = function () {
        return this._link;
    };
    CatalystURL.prototype.getFile = function () {
        return null;
    };
    CatalystURL.prototype.getMime = function () {
        return this._params.getMime();
    };
    return CatalystURL;
}());
export { CatalystURL };
