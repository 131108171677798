export var FileStatusEnum;
(function (FileStatusEnum) {
    FileStatusEnum["WAITING"] = "waiting";
    FileStatusEnum["LOADING"] = "loading";
    FileStatusEnum["PAUSED"] = "paused";
    FileStatusEnum["FINISHED"] = "finished";
    FileStatusEnum["FAILED"] = "failed";
    FileStatusEnum["CANCELLED"] = "cancelled";
    FileStatusEnum["DELETING"] = "deleting"; // удаляется
})(FileStatusEnum || (FileStatusEnum = {}));
