var CurrentApp = /** @class */ (function () {
    function CurrentApp() {
    }
    CurrentApp.setInstance = function (instance) {
        this.instance = instance;
    };
    CurrentApp.getInstance = function () {
        return this.instance;
    };
    return CurrentApp;
}());
export default CurrentApp;
