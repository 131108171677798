import { ref } from 'vue';
import { lockBodyScroll, unlockBodyScroll } from "@common/utils/Helpers/Helpers";
// Должен быть глобальным, чтобы разные useDialog не пересекались с одинаковым индексом
var globalZIndex = 2000;
// Открытые модалки
// @ts-ignore
var openedDialogs = [];
// Индекс открытой модалки
var openedIndex = 0;
export var nextZIndex = function () {
    globalZIndex += 1;
    return globalZIndex;
};
export var useDialog = function (_a) {
    var _b = _a.closeByUser, closeByUser = _b === void 0 ? true : _b, onOpen = _a.onOpen, onClose = _a.onClose;
    var id = "dialog-".concat(openedIndex++);
    var zIndex = ref(nextZIndex());
    var open = function () {
        openedDialogs.push(exposed);
        zIndex.value = nextZIndex();
        lockBodyScroll();
        // Недостаточно просто переключить visible, нужно сообщить о событии наверх
        if (onOpen) {
            onOpen();
        }
    };
    var close = function () {
        // Удаляем из списка открытых модалок
        openedDialogs = openedDialogs.filter(function (d) { return d.id !== id; });
        // Если в списке открытых модалок больше нет - разрешаем скролл
        if (openedDialogs.length === 0) {
            unlockBodyScroll();
        }
        // Недостаточно просто переключить visible, нужно сообщить о событии наверх
        if (onClose) {
            onClose();
        }
    };
    var isOpened = function () {
        return openedDialogs.find(function (d) { return d.id === id; });
    };
    var exposed = {
        id: id,
        zIndex: zIndex,
        closeByUser: closeByUser,
        open: open,
        close: close,
        isOpened: isOpened
    };
    return exposed;
};
var closeTopDialog = function () {
    var lastOpenedDialog = openedDialogs.at(-1);
    if (lastOpenedDialog && lastOpenedDialog.closeByUser) {
        lastOpenedDialog.close(true);
    }
};
window.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
        closeTopDialog();
    }
});
