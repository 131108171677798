var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { httpClient } from '@common/utils/HttpClient';
import { FileStatusEnum } from '@common/enums/fileStatus.enum';
import { useCacheSession } from '@common/hooks/useCacheStorage/useCacheSession';
import { FileUploadApi } from '@common/api/infrastructure/fileUpload';
import { sleep } from "@common/utils/Helpers/Helpers";
var ONE_MB = 1000000;
var CHUNK_SIZE = ONE_MB * 4;
var PARALLEL_UPLOADS = 5;
export var useChunkedUpload = function (type) {
    var uploadedChunksCache = useCacheSession('uploadedChunks', {});
    var fileUploadApi = new FileUploadApi(type);
    var uploadPart = function (chunk, preSignedUrl, partNo) { return __awaiter(void 0, void 0, Promise, function () {
        var uploadResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpClient.put(preSignedUrl, chunk.getBlob(), {
                        cancelToken: chunk.getCancelToken(),
                        onUploadProgress: function (e) {
                            var progress = (e.loaded * 100) / e.total;
                            chunk.setProgress(progress);
                        }
                    })];
                case 1:
                    uploadResponse = _a.sent();
                    return [2 /*return*/, { eTag: uploadResponse.headers['etag'], partNumber: partNo }];
            }
        });
    }); };
    // //* грузит файл на с3
    // const uploadFileToS3 = async (
    //     fileUpload: CatalystUpload,
    //     uuid: FileUuidType,
    // ) => {
    //     const numberOfParts = fileUpload.getCountChunksOfFile();
    //     const preSignedParts = (await createPreSignedUrls(uuid, numberOfParts)).uploadParts
    //     void uploadPartsToS3(fileUpload, uuid, preSignedParts)
    // }
    //* грузит части файла на с3
    var uploadPartsToS3 = function (fileUpload, uuid, parts) { return __awaiter(void 0, void 0, void 0, function () {
        var file, standardFile, uploadsArray, chunksNotUpload_1, chunkIterator_1, startUpload_1, i, uploadedPartsNested, uploadedParts, uploadedPartsNested_1, uploadedPartsNested_1_1, uploadedPartsNestedElement, finish, error_1;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    file = fileUpload.getFileCatalyst();
                    standardFile = file.getFile();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    if (!standardFile) {
                        throw new Error('Blob отсутствует в FileCatalyst, возможно использовался CatalystUrl');
                    }
                    uploadsArray = [];
                    file.setStatus(FileStatusEnum.LOADING);
                    chunksNotUpload_1 = fileUpload.getChunks().filter(function (e) { return !e.getIsUpload(); });
                    chunkIterator_1 = 0;
                    startUpload_1 = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var chunk, _a, url, partNumber, attemptsLeft, part, tryUploadPart, parts_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    chunk = chunksNotUpload_1[chunkIterator_1];
                                    _a = parts.find(function (p) { return p.partNumber === chunk.getPosition(); }), url = _a.url, partNumber = _a.partNumber;
                                    chunkIterator_1 += 1;
                                    attemptsLeft = 3;
                                    tryUploadPart = function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var e_2;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    _a.trys.push([0, 2, , 5]);
                                                    console.info('Загрузка чанка, осталось попыток - ', attemptsLeft);
                                                    attemptsLeft -= 1;
                                                    return [4 /*yield*/, uploadPart(chunk, url, partNumber)];
                                                case 1:
                                                    part = _a.sent();
                                                    return [3 /*break*/, 5];
                                                case 2:
                                                    e_2 = _a.sent();
                                                    if (attemptsLeft === 0) {
                                                        throw e_2;
                                                    }
                                                    return [4 /*yield*/, sleep(1000 - Math.pow(10, attemptsLeft))];
                                                case 3:
                                                    _a.sent();
                                                    return [4 /*yield*/, tryUploadPart()];
                                                case 4:
                                                    _a.sent();
                                                    return [3 /*break*/, 5];
                                                case 5: return [2 /*return*/];
                                            }
                                        });
                                    }); };
                                    return [4 /*yield*/, tryUploadPart()];
                                case 1:
                                    _b.sent();
                                    if (!chunksNotUpload_1[chunkIterator_1]) return [3 /*break*/, 3];
                                    return [4 /*yield*/, startUpload_1()];
                                case 2:
                                    parts_1 = _b.sent();
                                    return [2 /*return*/, __spreadArray([
                                            part
                                        ], __read(parts_1), false)];
                                case 3: return [2 /*return*/, [part]];
                            }
                        });
                    }); };
                    for (i = 0; i < PARALLEL_UPLOADS; i++) {
                        uploadsArray.push(startUpload_1());
                        if (chunkIterator_1 === chunksNotUpload_1.length) {
                            break;
                        }
                    }
                    return [4 /*yield*/, Promise.all(uploadsArray)];
                case 2:
                    uploadedPartsNested = _b.sent();
                    uploadedChunksCache.value = (function () {
                        var uploadedChunks = __assign({}, uploadedChunksCache.value);
                        delete uploadedChunks[file.getIdentifyId()];
                        return uploadedChunks;
                    })();
                    uploadedParts = [];
                    try {
                        for (uploadedPartsNested_1 = __values(uploadedPartsNested), uploadedPartsNested_1_1 = uploadedPartsNested_1.next(); !uploadedPartsNested_1_1.done; uploadedPartsNested_1_1 = uploadedPartsNested_1.next()) {
                            uploadedPartsNestedElement = uploadedPartsNested_1_1.value;
                            uploadedParts = __spreadArray(__spreadArray([], __read(uploadedParts), false), __read(uploadedPartsNestedElement), false);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (uploadedPartsNested_1_1 && !uploadedPartsNested_1_1.done && (_a = uploadedPartsNested_1.return)) _a.call(uploadedPartsNested_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    return [4 /*yield*/, finishFileUpload(uuid, uploadedParts)];
                case 3:
                    finish = _b.sent();
                    file.setStatus(FileStatusEnum.FINISHED);
                    return [2 /*return*/, finish];
                case 4:
                    error_1 = _b.sent();
                    if (error_1 instanceof Error) {
                        file.setStatus(FileStatusEnum.FAILED);
                        file.setError(error_1.message);
                        fileUpload.stopUpload();
                        throw new Error(error_1.message);
                    }
                    throw new Error(error_1);
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // const createPreSignedUrls = async (uuid: FileUuidType, chunkSize: number) => {
    //     return (await chunkedUploadApi.createPreSignedUrls(uuid, chunkSize)).data
    // }
    var finishFileUpload = function (uuid, uploadedParts) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fileUploadApi.completeUpload(uuid, uploadedParts)];
                case 1: return [2 /*return*/, (_a.sent()).data];
            }
        });
    }); };
    return {
        // uploadFileToS3,
        uploadPartsToS3: uploadPartsToS3,
        CHUNK_SIZE: CHUNK_SIZE,
    };
};
