import { generateId, generateUuid } from '@common/utils/Helpers/Helpers';
import { FileStatusEnum } from '@common/enums/fileStatus.enum';
import { FileIdentify } from '@common/utils/Catalyst/FileIdentify';
import { CatalystBlob } from '@common/utils/Catalyst/CatalystBlob';
import { CatalystURL } from '@common/utils/Catalyst/CatalystURL';
var FileCatalyst = /** @class */ (function () {
    function FileCatalyst(unityFile, identify) {
        if (identify === void 0) { identify = new FileIdentify(generateUuid(), generateId()); }
        this._error = null;
        this._status = FileStatusEnum.WAITING;
        this._progress = 0;
        if (!unityFile ||
            !((unityFile instanceof CatalystBlob) ||
                (unityFile instanceof CatalystURL))) {
            throw new Error('UnityFileInterface не передан в FileCatalyst');
        }
        this.setIdentify(identify);
        this.setUnityFile(unityFile);
        this._id = this._identify.getId();
        this._uniqHash = (this.getName() + this.getMime() + this.getSize());
    }
    FileCatalyst.prototype.getUniqHash = function () {
        return this._uniqHash;
    };
    FileCatalyst.prototype.getFileId = function () {
        return this._id;
    };
    FileCatalyst.prototype.getLink = function () {
        return this._unityFile.getLink();
    };
    FileCatalyst.prototype.getFile = function () {
        return this._unityFile.getFile();
    };
    FileCatalyst.prototype.getType = function () {
        return this._unityFile.getType();
    };
    FileCatalyst.prototype.getExtension = function () {
        return this._unityFile.getExtension();
    };
    FileCatalyst.prototype.getIdentifyUuid = function () {
        return this._identify.getUuid();
    };
    FileCatalyst.prototype.getIdentifyId = function () {
        return this._identify.getId();
    };
    FileCatalyst.prototype.getMime = function () {
        return this._unityFile.getMime();
    };
    FileCatalyst.prototype.getName = function () {
        return this._unityFile.getName();
    };
    FileCatalyst.prototype.getSize = function () {
        return this._unityFile.getSize();
    };
    FileCatalyst.prototype.getProgress = function () {
        return this._progress;
    };
    FileCatalyst.prototype.hasStatus = function (status) {
        return this._status === status;
    };
    FileCatalyst.prototype.getStatus = function () {
        return this._status;
    };
    FileCatalyst.prototype.getErrorMessage = function () {
        return this._error;
    };
    FileCatalyst.prototype.setProgress = function (progress) {
        this._progress = Math.round(progress);
    };
    FileCatalyst.prototype.setStatus = function (status) {
        this._status = status;
    };
    FileCatalyst.prototype.setError = function (errorMessage) {
        this._error = errorMessage;
    };
    FileCatalyst.prototype.setLink = function (url) {
        if (this._unityFile instanceof CatalystURL) {
            throw new TypeError("'\u041D\u0435\u043B\u044C\u0437\u044F \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0441\u0441\u044B\u043B\u043A\u0443 \u0434\u043B\u044F \u043A\u043B\u0430\u0441\u0441\u0430 CatalystURL'");
        }
        else {
            this.setUnityFile(new CatalystBlob(this.getFile(), url));
        }
    };
    FileCatalyst.prototype.setUnityFile = function (unityFile) {
        this._unityFile = unityFile;
        if (unityFile instanceof CatalystURL) {
            this._status = FileStatusEnum.FINISHED;
        }
        else {
            // ?
        }
    };
    FileCatalyst.prototype.setIdentify = function (identify) {
        this._identify = identify;
    };
    return FileCatalyst;
}());
export { FileCatalyst };
