import { AlignmentEnum, EntityTypeEnum, OrientationEnum, TemplateBackgroundsEnum, TemplateNamesEnum, TextTypeEnum, VariableTypeEnum } from '@common/enums';
import { generateUuid } from '@common/utils/Helpers/Helpers';
export var withoutTemplate = function () {
    var _a;
    return (_a = {
            lastEditTemplate: Date.now(),
            uuid: '',
            primaryColor: null
        },
        _a[OrientationEnum.LANDSCAPE] = {
            editable: true,
            entities: [
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 250,
                    height: 86,
                    xy: [
                        1.5,
                        15.7
                    ],
                    varType: VariableTypeEnum.NUMBER_AND_DATE,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 280,
                    height: 86,
                    xy: [
                        1.5,
                        16.7
                    ],
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 200,
                    height: 86,
                    xy: [
                        1.7,
                        18
                    ],
                    varType: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    value: 'Выдано автоматически на skillspace.ru',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: 'PRIMARY',
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.QR,
                    width: 50,
                    height: 50,
                    xy: [
                        24.9,
                        16.2
                    ],
                    varType: VariableTypeEnum.QR,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: null
                }
            ],
            background: TemplateBackgroundsEnum.EMPTY_BG,
            backgroundFile: null
        },
        _a[OrientationEnum.PORTRAIT] = {
            editable: true,
            entities: [
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 250,
                    height: 86,
                    xy: [
                        1.5,
                        24.9
                    ],
                    varType: VariableTypeEnum.NUMBER_AND_DATE,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 280,
                    height: 86,
                    xy: [
                        1.5,
                        25.8
                    ],
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 220,
                    height: 86,
                    xy: [
                        1.7,
                        27
                    ],
                    varType: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    value: 'Выдано автоматически на skillspace.ru',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: 'PRIMARY',
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.QR,
                    width: 50,
                    height: 50,
                    xy: [
                        17,
                        25.4
                    ],
                    varType: VariableTypeEnum.QR,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: null
                }
            ],
            background: TemplateBackgroundsEnum.EMPTY_BG,
            backgroundFile: null
        },
        _a);
};
export var standardFirstTemplate = function (_primaryColor) {
    var _a;
    return (_a = {
            lastEditTemplate: Date.now(),
            uuid: '',
            primaryColor: null
        },
        _a[OrientationEnum.LANDSCAPE] = {
            editable: false,
            background: TemplateBackgroundsEnum.STANDARD_BG_FIRST,
            backgroundFile: null,
            entities: [
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 290,
                    height: 86,
                    xy: [
                        1.5,
                        5.5
                    ],
                    varType: VariableTypeEnum.TEXT,
                    value: 'Настоящим сертификатом подтверждается, что',
                    isDraggable: true,
                    isResizable: true,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 290,
                    height: 86,
                    xy: [
                        1.5,
                        7
                    ],
                    varType: VariableTypeEnum.NAME,
                    value: '',
                    isDraggable: true,
                    isResizable: true,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '24',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 250,
                    height: 68,
                    xy: [
                        16.3,
                        7
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.COURSE,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '24',
                        highlight: {
                            color: '#FFFFFF',
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 260,
                    height: 68,
                    xy: [
                        16,
                        2
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.SCHOOL,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '16',
                        highlight: {
                            color: '#FFFFFF',
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 260,
                    height: 16,
                    xy: [
                        16,
                        5.5
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'Успешно завершил обучение по курсу:',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: '#FFFFFF',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.STANDARD_IMAGE,
                    varType: VariableTypeEnum.LOGO,
                    isDraggable: true,
                    isResizable: true,
                    value: '/img/school/certificates/paste-your-image.svg',
                    width: 136,
                    height: 38,
                    xy: [1.8, 2],
                    options: null,
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 160,
                    height: 86,
                    xy: [
                        16.8,
                        16.5
                    ],
                    varType: VariableTypeEnum.TEXT,
                    value: 'Проверка оригинальности:',
                    isResizable: true,
                    isDraggable: true,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: '#fff',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 160,
                    height: 86,
                    xy: [
                        16.8,
                        17.7
                    ],
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    isResizable: true,
                    isDraggable: true,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: '#fff',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 250,
                    height: 86,
                    xy: [
                        1.5,
                        15.7
                    ],
                    varType: VariableTypeEnum.NUMBER_AND_DATE,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 280,
                    height: 86,
                    xy: [
                        1.5,
                        16.7
                    ],
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 200,
                    height: 86,
                    xy: [
                        1.7,
                        18
                    ],
                    varType: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    value: 'Выдано автоматически на skillspace.ru',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: 'PRIMARY',
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.QR,
                    width: 50,
                    height: 50,
                    xy: [
                        24.9,
                        16.2
                    ],
                    varType: VariableTypeEnum.QR,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: null
                }
            ],
        },
        _a[OrientationEnum.PORTRAIT] = {
            editable: false,
            background: TemplateBackgroundsEnum.STANDARD_BG_FIRST,
            backgroundFile: null,
            entities: [
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.STANDARD_IMAGE,
                    varType: VariableTypeEnum.LOGO,
                    value: '/img/school/certificates/paste-your-image.svg',
                    isDraggable: true,
                    isResizable: true,
                    width: 136,
                    height: 38,
                    xy: [7.5, 1.4],
                    options: null,
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 292,
                    height: 86,
                    xy: [
                        4,
                        5.2
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.NAME,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '24',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 462,
                    height: 16,
                    xy: [
                        0,
                        3.7
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'Настоящим сертификатом подтверждается, что',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '12',
                        highlight: {
                            color: 'PRIMARY',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 380,
                    height: 92,
                    xy: [
                        2,
                        13
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.COURSE,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '24',
                        highlight: {
                            color: '#FFFFFF',
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 462,
                    height: 16,
                    xy: [
                        0,
                        11.8
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'Успешно завершил обучение по курсу:',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '12',
                        highlight: {
                            color: '#FFFFFF',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 380,
                    height: 92,
                    xy: [
                        2.1,
                        20
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.SCHOOL,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '14',
                        highlight: {
                            color: '#FFFFFF',
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 250,
                    height: 86,
                    xy: [
                        1.5,
                        24.9
                    ],
                    varType: VariableTypeEnum.NUMBER_AND_DATE,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 280,
                    height: 86,
                    xy: [
                        1.5,
                        25.8
                    ],
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 220,
                    height: 86,
                    xy: [
                        1.7,
                        27
                    ],
                    varType: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    value: 'Выдано автоматически на skillspace.ru',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: 'PRIMARY',
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.QR,
                    width: 50,
                    height: 50,
                    xy: [
                        17,
                        25.4
                    ],
                    varType: VariableTypeEnum.QR,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: null
                }
            ]
        },
        _a);
};
export var standardSecondTemplate = function (_primaryColor) {
    var _a;
    return (_a = {
            lastEditTemplate: Date.now(),
            uuid: '',
            primaryColor: null
        },
        _a[OrientationEnum.LANDSCAPE] = {
            editable: false,
            background: TemplateBackgroundsEnum.STANDARD_BG_SECOND,
            backgroundFile: null,
            entities: [
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 390,
                    height: 64,
                    xy: [
                        1.5,
                        8
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.NAME,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '22',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 300,
                    height: 64,
                    xy: [
                        1.5,
                        4.7
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'СЕРТИФИКАТ',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '36',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 300,
                    height: 64,
                    xy: [
                        1.5,
                        7
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'Настоящим сертификатом подтверждается, что',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 300,
                    height: 16,
                    xy: [
                        1.5,
                        11.4
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'Успешно завершил обучение по курсу:',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: '#838A94',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 390,
                    height: 42,
                    xy: [
                        1.5,
                        12.4
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.COURSE,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '20',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.STANDARD_IMAGE,
                    varType: VariableTypeEnum.LOGO,
                    isDraggable: true,
                    isResizable: true,
                    value: '/img/school/certificates/paste-your-image.svg',
                    width: 136,
                    height: 38,
                    xy: [1.8, 2],
                    options: null,
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 250,
                    height: 86,
                    xy: [
                        1.5,
                        15.7
                    ],
                    varType: VariableTypeEnum.NUMBER_AND_DATE,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 280,
                    height: 86,
                    xy: [
                        1.5,
                        16.7
                    ],
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 200,
                    height: 86,
                    xy: [
                        1.7,
                        18
                    ],
                    varType: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    value: 'Выдано автоматически на skillspace.ru',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: 'PRIMARY',
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.QR,
                    width: 50,
                    height: 50,
                    xy: [
                        24.9,
                        16.2
                    ],
                    varType: VariableTypeEnum.QR,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: null
                }
            ]
        },
        _a[OrientationEnum.PORTRAIT] = {
            editable: false,
            background: TemplateBackgroundsEnum.STANDARD_BG_SECOND,
            backgroundFile: null,
            entities: [
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.STANDARD_IMAGE,
                    varType: VariableTypeEnum.LOGO,
                    value: '/img/school/certificates/paste-your-image.svg',
                    isDraggable: true,
                    isResizable: true,
                    width: 136,
                    height: 38,
                    xy: [
                        1.3,
                        1.4
                    ],
                    options: null,
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 300,
                    height: 64,
                    xy: [
                        1,
                        5.2
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'СЕРТИФИКАТ',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '36',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 300,
                    height: 64,
                    xy: [
                        1,
                        8
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'Настоящим сертификатом подтверждается, что',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 390,
                    height: 64,
                    xy: [
                        1,
                        9
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.NAME,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '22',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.EDITABLE_TEXT,
                    width: 300,
                    height: 64,
                    xy: [
                        1,
                        13.5
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.TEXT,
                    value: 'Успешно завершил обучение по курсу:',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: '#838A94',
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 390,
                    height: 42,
                    xy: [
                        1,
                        14.5
                    ],
                    isDraggable: true,
                    isResizable: true,
                    varType: VariableTypeEnum.COURSE,
                    value: '',
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '20',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    },
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 250,
                    height: 86,
                    xy: [
                        1.5,
                        24.9
                    ],
                    varType: VariableTypeEnum.NUMBER_AND_DATE,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [
                            TextTypeEnum.BOLD
                        ],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 280,
                    height: 86,
                    xy: [
                        1.5,
                        25.8
                    ],
                    varType: VariableTypeEnum.DOMAIN,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.LEFT,
                        textConversions: '10',
                        highlight: {
                            color: null,
                            background: null,
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.TEXT,
                    width: 220,
                    height: 86,
                    xy: [
                        1.7,
                        27
                    ],
                    varType: VariableTypeEnum.ISSUED_AUTOMATICALLY,
                    value: 'Выдано автоматически на skillspace.ru',
                    isResizable: false,
                    isDraggable: false,
                    options: {
                        alignment: AlignmentEnum.CENTER,
                        textConversions: '10',
                        highlight: {
                            color: 'PRIMARY',
                            background: 'PRIMARY',
                        },
                        inlineTools: [],
                        style: {
                            cornerRadius: [6, 6, 6, 6]
                        }
                    }
                },
                {
                    uuid: generateUuid(),
                    type: EntityTypeEnum.QR,
                    width: 50,
                    height: 50,
                    xy: [
                        17,
                        25.4
                    ],
                    varType: VariableTypeEnum.QR,
                    value: '',
                    isResizable: false,
                    isDraggable: false,
                    options: null
                }
            ]
        },
        _a);
};
export var defaultTemplateEntities = function (primaryColor) {
    var _a;
    return (_a = {},
        _a[TemplateNamesEnum.WITHOUT_TEMPLATE] = withoutTemplate(),
        _a[TemplateNamesEnum.STANDARD_FIRST] = standardFirstTemplate(primaryColor),
        _a[TemplateNamesEnum.STANDARD_SECOND] = standardSecondTemplate(primaryColor),
        _a);
};
