var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defineStore } from 'pinia';
import { ref } from 'vue';
export var usePopperRelationStore = defineStore('popperRelationStore', function () {
    var poppersRelation = ref([]);
    var setRelation = function (parentUuid, uuid, ref) {
        poppersRelation.value.push({
            ref: ref,
            parentUuid: parentUuid,
            uuid: uuid,
        });
    };
    var getChildren = function (uuid) {
        var currentRel = poppersRelation.value.find(function (rel) { return rel.uuid === uuid; });
        if (!currentRel) {
            return [];
        }
        var childRel = poppersRelation.value.find(function (c) { return c.parentUuid === currentRel.uuid; });
        if (!childRel) {
            return [];
        }
        return __spreadArray([
            childRel
        ], __read(getChildren(childRel.uuid)), false);
    };
    var getChildrenRef = function (uuid) {
        return getChildren(uuid).map(function (e) { return e.ref; });
    };
    var deleteRelation = function (uuid) {
        var childrenUuid = getChildren(uuid).map(function (e) { return e.uuid; });
        var relationGraph = __spreadArray(__spreadArray([], __read(childrenUuid), false), [
            uuid
        ], false);
        poppersRelation.value = poppersRelation.value
            .filter(function (rel) {
            return !relationGraph.includes(rel.uuid);
        });
    };
    return {
        poppersRelation: poppersRelation,
        setRelation: setRelation,
        getChildren: getChildren,
        getChildrenRef: getChildrenRef,
        deleteRelation: deleteRelation,
    };
});
