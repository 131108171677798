/**
 * Создать успешно разрешенный промис с данными
 *
 * @param {any} data какие данные вернуть в then
 * @returns {any}
 */
export function createResolvedPromise(data) {
    return Promise.resolve(data);
}
/**
 * Создать промис, вернувший catch
 *
 * @param {any} data какие данные вернуть в catch
 *
 * @returns {Promise}
 */
export function createCatchedPromise(data) {
    return Promise.reject(data);
}
/**
 * Конвертирует промис в булевое
 *
 * @param {any} promise Промис
 * @returns {Promise<boolean>}
 */
export var promiseToBool = function (promise) {
    return promise.then(function () { return true; }).catch(function () { return false; });
};
/**
 * Является ли объект промисом
 *
 * @param {any} object
 * @returns {boolean}
 */
export default function isPromise(object) {
    return typeof (object === null || object === void 0 ? void 0 : object.then) === 'function';
}
