import { CacheFactory } from '@common/utils/Cache/Cache';
import { createResolvedPromise } from '@common/utils/Promise/Promise';
import { defineStore } from 'pinia';
import Api from '@school/modules/api/api';
// Чтобы кэш не пересекался с другими модулями
var cache = new CacheFactory();
/**
 * Сторедж раздела "курсы"
 */
export var useCoursesStore = defineStore('courses', {
    state: function () { return ({
        // Список курсов
        // @sync
        courses: [],
        // Состояние загрузки курсов
        coursesLoading: false,
        // Список категорий курсов
        // @sync
        categories: [],
        // Состояние загрузки списка категорий курсов
        categoriesLoading: false,
        // Режим редактирования страницы курсов
        editMode: false,
        // Режим редактирования обложки курса
        editModeSchoolCover: false,
    }); },
    actions: {
        /**
         * Получить список курсов
         *
         * @returns {Promise}
         */
        getCourses: function () {
            // const cacheCodeCourses = `courses`;
            //
            // if (cache.exists(cacheCodeCourses) && !forceUpdate) {
            //     const cachedData = cache.get(cacheCodeCourses);
            //     this[cacheCodeCourses] = cachedData;
            //     return createResolvedPromise(cachedData);
            // }
            var _this = this;
            this.coursesLoading = true;
            return Api.getCourses()
                .then(function (res) {
                var data = res.data;
                _this.courses = data;
                // cache.set(cacheCodeCourses, data);
                _this.coursesLoading = false;
            });
        },
        /**
         * Получить список категорий
         *
         * @param {boolean} forceUpdate игнорировать кэш
         * @returns {Promise}
         */
        getCategories: function (forceUpdate) {
            var _this = this;
            if (forceUpdate === void 0) { forceUpdate = false; }
            var cacheCodeCategories = "courseCategories";
            if (cache.exists(cacheCodeCategories) && !forceUpdate) {
                var cachedDataCourse = cache.get(cacheCodeCategories);
                // @ts-ignore TODO а че это за course??? !проверить потом!
                this.course = cachedDataCourse;
                return createResolvedPromise(cachedDataCourse);
            }
            this.categoriesLoading = true;
            return Api.getCourseCategories()
                .then(function (res) {
                var data = res.data;
                _this.categories = data;
                cache.set("categories", cacheCodeCategories);
                _this.categoriesLoading = false;
                return data;
            });
        },
        /**
         * Включить режим редактирования страницы курсов
         *
         * @returns {Promise}
         */
        turnOnEditMode: function () {
            this.editMode = true;
        },
        /**
         * Выключить режим редактирования страницы курсов
         *
         * @returns {Promise}
         */
        turnOffEditMode: function () {
            this.editMode = false;
        },
        /**
         * Включить режим редактирования страницы курсов
         *
         * @returns {Promise}
         */
        turnOnSchoolCover: function () {
            this.editModeSchoolCover = true;
        },
        /**
         * Выключить режим редактирования страницы курсов
         *
         * @returns {Promise}
         */
        turnOffSchoolCover: function () {
            this.editModeSchoolCover = false;
        },
    },
});
