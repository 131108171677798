var Storage = /** @class */ (function () {
    function Storage(type) {
        try {
            this.storage = type === 'local' ? window.localStorage : window.sessionStorage;
        }
        catch (error) {
            console.error(error);
        }
    }
    Object.defineProperty(Storage.prototype, "length", {
        /**
         * Получение длины storage.
         */
        get: function () {
            if (!this.storage)
                return null;
            return this.storage.length;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Получение элемента storage (getItem).
     */
    Storage.prototype.get = function (key) {
        if (!this.storage)
            return;
        try {
            var value = this.storage.getItem(key);
            if (value === null) {
                return;
            }
            return JSON.parse(value);
        }
        catch (error) {
            console.error(error);
        }
    };
    /**
     * Добавление элемента в storage (setItem).
     */
    Storage.prototype.set = function (key, value) {
        if (!this.storage)
            return;
        try {
            var stringValue = JSON.stringify(value);
            this.storage.setItem(key, stringValue);
        }
        catch (error) {
            console.error(error);
        }
    };
    /**
     * Добавление элемента в storage, если он еще не был определен.
     */
    Storage.prototype.define = function (key, value) {
        if (!this.storage || this.has(key))
            return;
        try {
            var stringValue = JSON.stringify(value);
            this.storage.setItem(key, stringValue);
        }
        catch (error) {
            console.error(error);
        }
    };
    /**
     * Проверяем наличие элемента в storage
     */
    Storage.prototype.has = function (key) {
        return this.storage.getItem(key) !== null;
    };
    /**
     * Удаление элемента из storage (removeItem).
     */
    Storage.prototype.remove = function (key) {
        if (!this.storage)
            return;
        this.storage.removeItem(key);
    };
    /**
     * Очистка storage (clear).
     */
    Storage.prototype.clear = function () {
        if (!this.storage)
            return;
        this.storage.clear();
    };
    return Storage;
}());
export var storage = new Storage('local');
export var storageSession = new Storage('session');
