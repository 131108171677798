var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { httpClientApiSchool } from '@common/utils/HttpClient';
var CompletionApi = /** @class */ (function (_super) {
    __extends(CompletionApi, _super);
    function CompletionApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Обновить условия для прохождения курса и вид шаблона
     *
     * @param {string} courseUuid uuid курса
     * @param {GetRequest<UpdateFinishDto>} data обновленные данные
     * @returns {Promise<AxiosResponse<GetResponse<UpdateFinishDto>>>}
     */
    CompletionApi.prototype.updateFinish = function (courseUuid, data) {
        return httpClientApiSchool.post("course/".concat(courseUuid, "/finish/update"), data);
    };
    /**
     * Получить данные по прохождению
     *
     * @param {string} courseUuid uuid курса
     * @returns {Promise<AxiosResponse<GetResponse<GetFinishDto>>>} данные по завершению
     */
    CompletionApi.prototype.getFinish = function (courseUuid) {
        return httpClientApiSchool.get("course/".concat(courseUuid, "/finish/get"));
    };
    /**
     * Опубликовать изменения
     *
     * @param {string} courseUuid uuid курса
     * @param {GetRequest<PublishFinishDto>} data настройки
     * @returns {Promise<AxiosResponse<GetResponse<PublishFinishDto>>>} данные по завершению
     */
    CompletionApi.prototype.publishFinish = function (courseUuid, data) {
        return httpClientApiSchool.post("course/".concat(courseUuid, "/finish/publish"), data);
    };
    /**
     * Удалить группу из настроек
     *
     * @param {string} courseUuid uuid курса
     * @param {string} groupUuid uuid группы
     * @returns {Promise<AxiosResponse<GetResponse<DeleteGroupSettingsDto>>>}
     */
    CompletionApi.prototype.deleteGroupSettings = function (courseUuid, groupUuid) {
        return httpClientApiSchool.delete("course/".concat(courseUuid, "/finish/remove/").concat(groupUuid));
    };
    return CompletionApi;
}(ApiBase));
export default new CompletionApi();
