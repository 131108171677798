var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { httpClientApiSchool } from '@common/utils/HttpClient';
import { convertToFormData } from '@common/utils/Helpers/Helpers';
var TestApi = /** @class */ (function (_super) {
    __extends(TestApi, _super);
    function TestApi() {
        return _super.call(this) || this;
    }
    /**
     * Создание вопроса к тесту
     *
     * @param testUuid
     * @param fields
     */
    TestApi.prototype.createQuestion = function (testUuid, fields) {
        fields = convertToFormData(fields);
        return httpClientApiSchool.post("/test/".concat(testUuid, "/question/create"), fields);
    };
    /**
     * Изменение вопроса
     *
     * @param uuid
     * @param fields
     * @param config
     */
    TestApi.prototype.updateQuestion = function (uuid, fields, config) {
        fields = convertToFormData(fields);
        return httpClientApiSchool.post("/test/question/".concat(uuid, "/update"), fields, config);
    };
    /**
     * Клонирование вопроса
     *
     * @param uuid
     */
    TestApi.prototype.cloneQuestion = function (uuid) {
        return httpClientApiSchool.post("/test/question/".concat(uuid, "/clone"));
    };
    /**
     * Удаление вопроса
     *
     * @param uuid
     */
    TestApi.prototype.deleteQuestion = function (uuid) {
        return httpClientApiSchool.delete("/test/question/".concat(uuid, "/remove"));
    };
    /**
     * Сортировка вопросов в тесте
     *
     * @param testUuid
     * @param order
     */
    TestApi.prototype.sortQuestions = function (testUuid, order) {
        var data = convertToFormData({
            order: order
        });
        return httpClientApiSchool.post("/test/".concat(testUuid, "/questions-sort"), data);
    };
    /**
     * Создание вопроса к тесту
     *
     * @param questionUuid
     * @param fields
     */
    TestApi.prototype.createAnswer = function (questionUuid, fields) {
        if (fields === void 0) { fields = {}; }
        fields = convertToFormData(fields);
        return httpClientApiSchool.post("/test/question/".concat(questionUuid, "/answer/create"), fields);
    };
    /**
     * Изменение вопроса
     *
     * @param uuid
     * @param fields
     * @param config
     */
    TestApi.prototype.updateAnswer = function (uuid, fields, config) {
        fields = convertToFormData(fields);
        return httpClientApiSchool.post("/test/question/answer/".concat(uuid, "/update"), fields, config);
    };
    /**
     * Удаление вопроса
     *
     * @param uuid
     */
    TestApi.prototype.deleteAnswer = function (uuid) {
        return httpClientApiSchool.delete("/test/question/answer/".concat(uuid, "/remove"));
    };
    /**
     * Сортировка вопросов в тесте
     *
     * @param questionUuid
     * @param order
     */
    TestApi.prototype.sortAnswers = function (questionUuid, order) {
        var data = convertToFormData({
            order: order
        });
        return httpClientApiSchool.post("/test/question/".concat(questionUuid, "/answers-sort"), data);
    };
    return TestApi;
}(ApiBase));
export default new TestApi();
