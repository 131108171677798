export var PublishStatusEnum;
(function (PublishStatusEnum) {
    /**
     * Опубликован
     */
    PublishStatusEnum["PUBLISHED"] = "published";
    /**
     * Черновик
     */
    PublishStatusEnum["DRAW"] = "draw";
    /**
     * Есть неопубликованные изменения
     */
    PublishStatusEnum["PUBLISHED_DRAW"] = "published-draw";
    /**
     * Снят с публикации
     */
    PublishStatusEnum["NONE_PUBLISHED"] = "none-published";
})(PublishStatusEnum || (PublishStatusEnum = {}));
