var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { convertToFormData } from '@common/utils/Helpers/Helpers';
import { httpClient } from '@common/utils/HttpClient';
import { ApiBase } from '@common/api/apiBase';
var FileUploadApi = /** @class */ (function (_super) {
    __extends(FileUploadApi, _super);
    function FileUploadApi(_namespace) {
        var _this = _super.call(this) || this;
        _this._namespace = _namespace;
        return _this;
    }
    // Создаем файл и получаем ссылки на s3, чтобы грузить туда чанки файла
    FileUploadApi.prototype.createFile = function (uuid, data) {
        var request = convertToFormData(data);
        return httpClient.post("/api/upload/file/".concat(this._namespace, "/").concat(uuid, "/create"), request);
    };
    // Если загрузили файл, то кидаем на бэк что закончили
    FileUploadApi.prototype.completeUpload = function (uuid, uploadedParts) {
        var data = convertToFormData({
            parts: uploadedParts.toSorted(function (a, b) { return a.partNumber - b.partNumber; })
        });
        return httpClient.post("/api/upload/file/".concat(this._namespace, "/").concat(uuid, "/complete"), data);
    };
    // Удаление файла
    FileUploadApi.prototype.removeFile = function (uuid) {
        return httpClient.delete("/api/upload/file/".concat(this._namespace, "/").concat(uuid, "/remove"));
    };
    return FileUploadApi;
}(ApiBase));
export { FileUploadApi };
