export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat List"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last online ", _interpolate(_named("time"))])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 participants"]), _normalize([_interpolate(_named("n")), " participant"]), _normalize([_interpolate(_named("n")), " participants"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To cancel, swipe left"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel recording"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to send messages in this chat"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot send an empty message"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])}
        }
      },
      "ru": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список чатов"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Был(а) онлайн ", _interpolate(_named("time"))])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 участников"]), _normalize([_interpolate(_named("n")), " участник"]), _normalize([_interpolate(_named("n")), " участника"]), _normalize([_interpolate(_named("n")), " участников"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картинка"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите сообщение..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для отмены потяните влево"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить запись"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет прав для отправки сообщения в данный чат"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя отправить пустое сообщение"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сегодня"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчера"])}
        }
      },
      "es": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de chats"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Estuvo en línea ", _interpolate(_named("time"))])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 participantes"]), _normalize([_interpolate(_named("n")), " participante"]), _normalize([_interpolate(_named("n")), " participantes"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe un mensaje..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desliza a la izquierda para cancelar"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar grabación"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes permisos para enviar mensajes a este chat"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede enviar un mensaje vacío"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoy"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayer"])}
        }
      },
      "kk": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чаттар тізімі"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " уақытында онлайн болды"])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 қатысушы"]), _normalize([_interpolate(_named("n")), " қатысушы"]), _normalize([_interpolate(_named("n")), " қатысушы"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзгерту"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурет"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарлама жазыңыз..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болдырмау үшін солға сүйреңіз"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жазбаны болдырмау"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізде осы чатқа хабарлама жіберуге құқық жоқ"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бос хабарлама жіберуге болмайды"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүгін"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кеше"])}
        }
      },
      "uz": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чатлар рўйхати"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " да онлайн эди"])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ishtirokchi"]), _normalize([_interpolate(_named("n")), " ishtirokchi"]), _normalize([_interpolate(_named("n")), " ishtirokchilar"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таҳрирлаш"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ўчириш"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расм"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабар ёзинг..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бекор қилиш учун солинг"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ёзувни бекор қилиш"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сизда бу чатда хабар юбориш ҳуқуқи йўқ"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бўш хабар юбориб бўлмайди"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бугун"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кеча"])}
        }
      },
      "de": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatliste"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zuletzt online um ", _interpolate(_named("time"))])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 Teilnehmer"]), _normalize([_interpolate(_named("n")), " Teilnehmer"]), _normalize([_interpolate(_named("n")), " Teilnehmer"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie eine Nachricht..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Abbrechen nach links wischen"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufnahme abbrechen"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, eine Nachricht in diesen Chat zu senden"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine leere Nachricht kann nicht gesendet werden"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestern"])}
        }
      },
      "fr": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des discussions"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière activité il y a ", _interpolate(_named("time"))])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 participants"]), _normalize([_interpolate(_named("n")), " participant"]), _normalize([_interpolate(_named("n")), " participants"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez un message..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour annuler, glissez vers la gauche"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'enregistrement"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas le droit d'envoyer un message dans ce chat"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'envoyer un message vide"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aujourd'hui"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])}
        }
      },
      "it": {
        "chat": {
          "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco chat"])},
          "lastOnline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultimo accesso ", _interpolate(_named("time"))])},
          "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 partecipanti"]), _normalize([_interpolate(_named("n")), " partecipante"]), _normalize([_interpolate(_named("n")), " partecipanti"])])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
          "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine"])},
          "writeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi un messaggio..."])},
          "cancelHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per annullare, scorri a sinistra"])},
          "cancelRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla registrazione"])}
        },
        "error": {
          "noRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i diritti per inviare un messaggio in questa chat"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non puoi inviare un messaggio vuoto"])}
        },
        "date": {
          "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oggi"])},
          "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieri"])}
        }
      }
    }
  })
}
