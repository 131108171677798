var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CacheFactory } from '@common/utils/Cache/Cache';
import { createResolvedPromise } from '@common/utils/Promise/Promise';
import { apiErrorsHandle } from '@common/utils/Helpers/Helpers';
import { defineStore } from 'pinia';
import { fillObject } from '@common/utils/Object/Object';
import WebhookApi from '@school/modules/api/entities/webhook/webhook.api';
import Api from '@school/modules/api/api';
import EmployeesApi from '@school/modules/api/entities/employees/employees.api';
import RolesApi from '@school/modules/api/entities/roles/roles.api';
import Message from '@common/ui/EMessage/Message';
import { homeworkApi } from "@school/modules/api/entities/homework";
// Чтобы кэш не пересекался с другими модулями
var cache = new CacheFactory();
/**
 * Стор школы
 */
export var useSchoolStore = defineStore('school', {
    state: function () { return ({
        // Список домашних заданий
        // Не синхроним из-за фильтров и возможности потеряться в списке,
        // будет актуально только при полном live режиме
        homeworkThreads: [],
        // Список непроверенных домашних заданий
        // @sync
        uncheckedHomeworks: 0,
        // Состояние загрузки списка домашних заданий
        homeworkThreadsLoading: false,
        // Список api ключей
        // @sync
        publicApiTokens: [],
        // Состояние загрузки списка api ключей
        publicApiTokensLoading: false,
        // Список сотрудников
        // @sync
        employees: [],
        // Список ролей
        roles: [],
        rolesActions: [],
        deadline: {},
        // Состояние загрузки списка сотрудников
        employeesLoading: false,
        rolesLoading: false,
        paymentCard: null,
        paymentCustomer: null,
        webhooks: [],
        // Отображать окно подтверждения телефона для школы
        schoolConfirmationVisible: false,
    }); },
    actions: {
        // * Получить кол-во непроверенных домашек
        getUncheckedHomeworksCount: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Api.getUncheckedHomeworksCount()];
                        case 1:
                            res = (_a.sent()).data;
                            this.uncheckedHomeworks = res.uncheckedHomeworks;
                            return [2 /*return*/, res];
                    }
                });
            });
        },
        // * Получить список домашек
        getHomeworkThreads: function (paramsDto) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.homeworkThreadsLoading = true;
                            return [4 /*yield*/, homeworkApi.getHomeworkThreads(paramsDto)];
                        case 1:
                            res = (_a.sent()).data;
                            this.homeworkThreads = res.data;
                            this.homeworkThreadsLoading = false;
                            return [2 /*return*/, res];
                    }
                });
            });
        },
        getRolesActions: function () {
            return __awaiter(this, arguments, void 0, function (forceUpdate) {
                var _a, err_1;
                if (forceUpdate === void 0) { forceUpdate = false; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            _a = this;
                            return [4 /*yield*/, RolesApi.getActions()];
                        case 1:
                            _a.rolesActions = (_b.sent()).data;
                            return [3 /*break*/, 3];
                        case 2:
                            err_1 = _b.sent();
                            apiErrorsHandle(err_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Получить список сотрудников
         *
         * @param {boolean} forceUpdate игнорировать кэш
         * @returns {Promise}
         */
        getEmployees: function () {
            return __awaiter(this, arguments, Promise, function (forceUpdate) {
                var data;
                if (forceUpdate === void 0) { forceUpdate = false; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // const cacheCode = `employees`;
                            //
                            // if (cache.exists(cacheCode) && !forceUpdate) {
                            //     this.employees = cache.get<GetResponse<GetEmployeesDto>>(cacheCode);
                            //     return;
                            // }
                            this.employeesLoading = true;
                            return [4 /*yield*/, EmployeesApi.getEmployees()];
                        case 1:
                            data = (_a.sent()).data;
                            this.employees = data;
                            // cache.set(cacheCode, data);
                            this.employeesLoading = false;
                            return [2 /*return*/, data];
                    }
                });
            });
        },
        addEmployee: function (data) {
            return __awaiter(this, void 0, void 0, function () {
                var employee, err_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, EmployeesApi.addEmployee(data)];
                        case 1:
                            employee = (_a.sent()).data;
                            this.employees.push(employee);
                            Message.success('Сотрудник создан');
                            return [3 /*break*/, 3];
                        case 2:
                            err_2 = _a.sent();
                            apiErrorsHandle(err_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        updateEmployee: function (uuid, data) {
            return __awaiter(this, void 0, void 0, function () {
                var employee_1, err_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, EmployeesApi.updateEmployee(uuid, data)];
                        case 1:
                            employee_1 = (_a.sent()).data;
                            this.employees = this.employees.map(function (e) {
                                if (e.id === employee_1.id) {
                                    return employee_1;
                                }
                                return e;
                            });
                            Message.success('Сотрудник обновлен');
                            return [3 /*break*/, 3];
                        case 2:
                            err_3 = _a.sent();
                            apiErrorsHandle(err_3);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        removeEmployee: function (data) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, , 2, 3]);
                            this.employeesLoading = true;
                            return [4 /*yield*/, EmployeesApi.removeEmployee(data)];
                        case 1:
                            _a.sent();
                            this.employees = this.employees.filter(function (e) { return e.user.email !== data.email; });
                            return [3 /*break*/, 3];
                        case 2:
                            this.employeesLoading = false;
                            return [7 /*endfinally*/];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        getRoles: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.rolesLoading = true;
                            _a = this;
                            return [4 /*yield*/, RolesApi.getRoles()];
                        case 1:
                            _a.roles = (_b.sent()).data;
                            this.rolesLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        createRole: function (data) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, RolesApi.createRole({
                                name: data.name,
                                icon: data.icon,
                                actions: data.actions,
                                role: data.role
                            })];
                        case 1:
                            res = (_a.sent()).data;
                            this.roles.push(res);
                            return [2 /*return*/, res];
                    }
                });
            });
        },
        updateRole: function (uuid, data) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, RolesApi.updateRole(uuid, {
                                name: data.name,
                                icon: data.icon,
                                actions: data.actions,
                                role: data.role
                            })];
                        case 1:
                            res = (_a.sent()).data;
                            this.roles = this.roles.map(function (e) {
                                if (e.uuid === res.uuid) {
                                    return res;
                                }
                                return e;
                            });
                            return [2 /*return*/, res];
                    }
                });
            });
        },
        removeRole: function (uuid) {
            return __awaiter(this, void 0, void 0, function () {
                var err_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, 3, 4]);
                            this.rolesLoading = true;
                            return [4 /*yield*/, RolesApi.removeRole(uuid)];
                        case 1:
                            _a.sent();
                            this.roles = this.roles.filter(function (e) { return e.uuid !== uuid; });
                            return [3 /*break*/, 4];
                        case 2:
                            err_4 = _a.sent();
                            apiErrorsHandle(err_4, {
                                SCHOOL_EMPLOYEE_ROLE_USAGE_BY_EMPLOYEE: function () {
                                    Message.error('Невозможно удалить роль. Сначала удалите или поменяйте настройки сотрудников в этой роли');
                                }
                            });
                            return [3 /*break*/, 4];
                        case 3:
                            this.rolesLoading = false;
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Получить список api-ключей
         *
         * @param {boolean} forceUpdate игнорировать кэш
         * @returns {Promise}
         */
        getPublicApiTokens: function () {
            return __awaiter(this, arguments, void 0, function (forceUpdate) {
                var cacheCode, cachedData, result;
                if (forceUpdate === void 0) { forceUpdate = false; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            cacheCode = "publicApiTokens";
                            if (cache.exists(cacheCode) && !forceUpdate) {
                                cachedData = cache.get(cacheCode);
                                this.publicApiTokens = cachedData;
                                return [2 /*return*/, createResolvedPromise(cachedData)];
                            }
                            this.publicApiTokensLoading = true;
                            return [4 /*yield*/, Api.getPublicApiTokens()];
                        case 1:
                            result = (_a.sent()).data;
                            this.publicApiTokens = result;
                            cache.set(cacheCode, result);
                            this.publicApiTokensLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Получить данные привязанных карт
         *
         * @returns {Promise}
         */
        getPaymentCards: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, err_5;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, Api.getPaymentCards()];
                        case 1:
                            res = _a.sent();
                            this.paymentCard = res.data.cards[0];
                            this.paymentCustomer = res.data.customer;
                            return [2 /*return*/, res];
                        case 2:
                            err_5 = _a.sent();
                            apiErrorsHandle(err_5, {
                                // Ignore this error
                                'PAYMENT_RECURRENT_CUSTOMER_NOT_FOUND': function () { },
                            });
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        getWebhooks: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, WebhookApi.getWebhooks()];
                        case 1:
                            _a.webhooks = (_b.sent()).data;
                            return [2 /*return*/, this.webhooks];
                    }
                });
            });
        },
        createWebhook: function (fields) {
            return __awaiter(this, void 0, void 0, function () {
                var webhook, err_6;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, WebhookApi.createWebhook(fields)];
                        case 1:
                            webhook = (_a.sent()).data;
                            this.webhooks.push(webhook);
                            return [2 /*return*/, webhook];
                        case 2:
                            err_6 = _a.sent();
                            apiErrorsHandle(err_6, {
                                SCHOOL_WEBHOOK_EVENT_NOT_CONFIGURATION: 'Указаны неверные события'
                            });
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        editWebhook: function (uuid, fields) {
            return __awaiter(this, void 0, void 0, function () {
                var webhook, err_7;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, WebhookApi.editWebhook(uuid, fields)];
                        case 1:
                            webhook = (_a.sent()).data;
                            // Наполняем объект в списке новыми данными
                            fillObject(this.webhooks.find(function (w) { return w.uuid === uuid; }), webhook);
                            return [2 /*return*/, webhook];
                        case 2:
                            err_7 = _a.sent();
                            apiErrorsHandle(err_7);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        deleteWebhook: function (uuid) {
            return __awaiter(this, void 0, void 0, function () {
                var err_8;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, WebhookApi.deleteWebhook(uuid)];
                        case 1:
                            _a.sent();
                            this.webhooks = this.webhooks.filter(function (w) { return w.uuid !== uuid; });
                            return [3 /*break*/, 3];
                        case 2:
                            err_8 = _a.sent();
                            apiErrorsHandle(err_8);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        showSchoolConfirmation: function () {
            this.schoolConfirmationVisible = true;
        },
        hideSchoolConfirmation: function () {
            this.schoolConfirmationVisible = false;
        }
    },
});
