var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { ref } from 'vue';
import { urlToBlob } from '@common/utils/File/File';
export var useAudioHandler = function (props) {
    var barsCount = ref(0);
    var averageFrequency = ref(props.averageFrequency || []);
    var barsWithPercentage = ref([]);
    var audioBlob = ref(null);
    var _calculateBarsCount = function (clientWidth, barWidth, barOffset) {
        barsCount.value = (clientWidth / (barWidth + barOffset)) - barOffset;
    };
    var renderPlayer = function (element, barWidth, barOffset) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(averageFrequency.value.length === 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchBlobIfNotExists()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    countBarsWithPercentage(element, barWidth, barOffset);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchBlobIfNotExists = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (audioBlob.value) {
                        return [2 /*return*/];
                    }
                    if (props.audio instanceof Blob) {
                        audioBlob.value = props.audio;
                        return [2 /*return*/];
                    }
                    _a = audioBlob;
                    return [4 /*yield*/, urlToBlob(props.audio)];
                case 1:
                    _a.value = _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var countBarsWithPercentage = function (element, barWidth, barOffset) {
        var tempFreqPercent = __spreadArray([], __read(averageFrequency.value), false);
        _calculateBarsCount(element.clientWidth, barWidth, barOffset);
        var stepFloor = Math.floor(tempFreqPercent.length / barsCount.value);
        var step = stepFloor === 0 ? 1 : stepFloor;
        var stepFreq = [];
        // из-за того, что шаг приходить с плавающей точкой не даем stepAverage выйти за пределы barsCount
        while (tempFreqPercent.length > 0 && barsCount.value > stepFreq.length) {
            stepFreq.push(tempFreqPercent.splice(0, step).reduce(function (partialSum, a) { return Number(partialSum) + Number(a); }, 0));
        }
        // если меньше, то накидываем пустые полосы в дорожку
        while (barsCount.value > stepFreq.length) {
            stepFreq.unshift(0);
        }
        var stepFreqSort = __spreadArray([], __read(stepFreq), false).sort(function (a, b) { return a - b; });
        var median = stepFreqSort.at(Math.ceil(stepFreqSort.length / 2)) || 1;
        var onePercent = median / 100;
        barsWithPercentage.value = stepFreq.map(function (p) { return ({
            percent: p / onePercent, isHeard: false
        }); });
    };
    var clearProgress = function () {
        var e_1, _a;
        try {
            for (var _b = __values(barsWithPercentage.value.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 1), index = _d[0];
                barsWithPercentage.value[index].isHeard = false;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return {
        averageFrequency: averageFrequency,
        barsWithPercentage: barsWithPercentage,
        barsCount: barsCount,
        audioBlob: audioBlob,
        renderPlayer: renderPlayer,
        fetchBlobIfNotExists: fetchBlobIfNotExists,
        countBarsWithPercentage: countBarsWithPercentage,
        clearProgress: clearProgress,
    };
};
