import i18next from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import { watch } from 'vue';
// Импорты переводов Zod
import ruTranslation from 'zod-i18n-map/locales/ru/zod.json';
import enTranslation from 'zod-i18n-map/locales/en/zod.json';
import esTranslation from 'zod-i18n-map/locales/es/zod.json';
import kkTranslation from 'zod-i18n-map/locales/en/zod.json';
import uzTranslation from 'zod-i18n-map/locales/uz/zod.json';
import deTranslation from 'zod-i18n-map/locales/de/zod.json';
import frTranslation from 'zod-i18n-map/locales/fr/zod.json';
import itTranslation from 'zod-i18n-map/locales/it/zod.json';
export function syncZodLocale(i18n) {
    var currentLocale = i18n.global.locale.value;
    i18next.init({
        lng: currentLocale,
        resources: {
            ru: { zod: ruTranslation },
            en: { zod: enTranslation },
            es: { zod: esTranslation },
            kk: { zod: kkTranslation },
            uz: { zod: uzTranslation },
            de: { zod: deTranslation },
            fr: { zod: frTranslation },
            it: { zod: itTranslation },
        },
    });
    z.setErrorMap(zodI18nMap);
    watch(function () { return String(i18n.global.locale.value); }, function (newLocale) {
        i18next.changeLanguage(newLocale);
    });
}
