import { createVNode, render } from 'vue';
import CurrentApp from '@common/utils/CurrentApp';
export var componentMount = function (component, options) {
    var _a, _b;
    if (options === void 0) { options = {}; }
    var props = options.props, children = options.children, element = options.element;
    var el = element;
    var app = (_a = options.app) !== null && _a !== void 0 ? _a : CurrentApp.getInstance();
    var vNode = createVNode(component, props, children);
    if (app && app._context)
        vNode.appContext = app._context;
    if (!el && typeof document !== 'undefined') {
        el = document.createElement('div');
    }
    render(vNode, el);
    var destroy = function () {
        if (el)
            render(null, el);
        el = null;
        vNode = null;
    };
    return {
        vNode: vNode,
        destroy: destroy,
        el: el,
        methods: (_b = vNode.component) === null || _b === void 0 ? void 0 : _b.exposed
    };
};
