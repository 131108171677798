var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import { translations } from './datatime.i18n';
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
import { fromMs, toMs } from '../libs/Ms';
import { numeralize } from '@common/utils/Format/Format';
import CurrentI18n from '@common/utils/CurrentI18n';
var TIME_UNITS = [
    'second',
    'minute',
    'hour',
    'day',
    'month',
    'year'
];
/**
 * Возвращает время последнего онлайна в нужном формате
 *
 * @param {number} timestamp Дата последнего онлайна
 * @returns {string} Отформатированная дата
 */
export function formatOnline(timestamp) {
    if (!timestamp) {
        return '';
    }
    var lastOnlineTime = dayjs(timestamp).startOf('day');
    return lastOnlineTime.format('DD.MM.YYYY');
}
/**
 * Возвращает время нужном формате
 *
 * @param {number} timestamp Дата стрима
 * @returns {string} Отформатированная дата
 */
export function formatOnlineMinuteMonth(timestamp) {
    if (!timestamp) {
        return '';
    }
    var time = dayjs(timestamp);
    return time.format('DD MMMM YYYY, HH:mm');
}
/**
 * Возвращает время окончания выполнения урока
 * убирает одну минуту если время окончания 00:00
 *
 * @param {number} timestamp Дата завершения выполнения урока
 * @returns {string} Отформатированная дата
 */
export function formatOnlineMinute(timestamp) {
    if (!timestamp) {
        return '';
    }
    var endTime = dayjs(timestamp);
    if (endTime.format('HH:mm') === '00:00') {
        // если срок истекает в 00:00, то убираем одну минуту для понятности юзера 23:59
        endTime = endTime.subtract(1, 'minute');
    }
    return endTime.format('DD.MM.YYYY, HH:mm');
}
/**
 * Возвращает дату вебинара
 *
 * @param {number} timestamp Дата вебинара
 * @returns {string} Отформатированная дата
 */
export var formatDateWebinarTranslationTag = function (timestamp) {
    var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
    var lang = locale.value;
    if (!timestamp)
        return '';
    var date = dayjs(timestamp);
    var now = dayjs();
    if (date.isToday()) {
        return now.isBefore(date)
            ? t(translations[lang].today_at) + " ".concat(date.format('HH:mm'))
            : t(translations[lang].today_at2) + " ".concat(date.format('HH:mm'));
    }
    else if (date.isTomorrow()) {
        return t(translations[lang].tomorrow_at) + " ".concat(date.format('HH:mm'));
    }
    else if (date.isYesterday()) {
        return t(translations[lang].yesterday_at2) + " ".concat(date.format('HH:mm'));
    }
    return "".concat(date.format('DD.MM.YY'), " ").concat(t(translations[lang].at), " ").concat(date.format('HH:mm'));
};
/**
 * Возвращает дату вебинара
 *
 * @param {number} timestamp Дата Дата вебинара
 * @returns {string} Отформатированная дата
 */
export function formatDateWebinarCardTag(timestamp) {
    var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
    var lang = locale.value;
    if (!timestamp) {
        return '';
    }
    if (lang === 'ru') {
        var customLocale = __assign(__assign({}, dayjs.Ls.ru), { monthsShort: [
                'янв', 'фев', 'мар', 'апр', 'май', 'июн',
                'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
            ] });
        dayjs.locale(customLocale, null, true);
    }
    var date = dayjs(timestamp);
    if (date.isToday()) {
        return t(translations[lang].today_at) + " ".concat(date.format('HH:mm'));
    }
    else if (date.isTomorrow()) {
        return t(translations[lang].tomorrow_at) + " ".concat(date.format('HH:mm'));
    }
    else if (date.isYesterday()) {
        return t(translations[lang].yesterday_at) + " ".concat(date.format('HH:mm'));
    }
    return "".concat(date.format('DD MMM'), " ").concat(t(translations[lang].at), " ").concat(date.format('HH:mm'));
}
/**
 * Возвращает дату вебинара
 *
 * @param {number} timestamp Дата Дата вебинара
 * @returns {string} Отформатированная дата
 */
export function formatDateWebinarCardStudentTag(timestamp) {
    if (!timestamp) {
        return '';
    }
    var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
    var lang = locale.value;
    var date = dayjs(timestamp);
    if (date.isToday()) {
        return t(translations[lang].today_at) + " ".concat(date.format('HH:mm'));
    }
    else if (date.isTomorrow()) {
        return t(translations[lang].tomorrow_at) + " ".concat(date.format('HH:mm'));
    }
    else if (date.isYesterday()) {
        return t(translations[lang].yesterday_at) + " ".concat(date.format('HH:mm'));
    }
    return "".concat(date.format('DD MMM'), " ").concat(t(translations[lang].at), " ").concat(date.format('HH:mm'));
}
/**
 * Возвращает время просмотра
 *
 * @param {number} viewingTime время просмотра в милисекундах
 * @returns {string} Отформатированная дата
 */
export function formattedViewingTime(viewingTime) {
    var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
    var lang = locale.value;
    if (!viewingTime)
        return '';
    var hours = Math.floor(viewingTime / 3600000);
    var minutes = Math.floor((viewingTime % 3600000) / 60000);
    return "".concat(hours, " ").concat(t(translations[lang].hours), " ").concat(minutes, " ").concat(t(translations[lang].minutes));
}
/**
 * Возвращает количество дней, оставшихся до даты завершения задания
 *
 * @param {number} endTimestamp Дата завершения выполнения в миллисекундах
 * @returns {number} Количество оставшихся дней
 */
export function getDaysToCompletion(endTimestamp) {
    // if (!endTimestamp) {
    //     return null;
    // }
    var now = dayjs(); // Текущее время
    var endTime = dayjs(endTimestamp);
    // Вычисляем разницу в днях
    var daysToCompletion = endTime.diff(now, 'day');
    return daysToCompletion;
}
/**
 * Возвращает количество часов, оставшихся до даты завершения задания
 *
 * @param {number} endTimestamp Дата завершения выполнения в миллисекундах
 * @returns {number} Количество оставшихся дней
 */
export function getHoursToCompletion(endTimestamp) {
    var now = dayjs(); // Current time
    var endTime = dayjs(endTimestamp);
    // Calculate the difference in hours
    var hoursToCompletion = endTime.diff(now, 'hour');
    return hoursToCompletion;
}
/**
 * Возвращает булевое значение в зависимости от наличия просрочки по дедлайну задачи
 *
 * @param {number} endTimestamp Дата завершения выполнения в миллисекундах
 * @returns {boolean} Количество оставшихся дней
 */
export function isOverdueDeadline(endTimestamp) {
    if (!endTimestamp) {
        return false;
    }
    var nowTimestamp = Date.now(); // Текущее время
    var timeUntilToEnd = nowTimestamp - endTimestamp;
    return timeUntilToEnd > 0;
}
/**
 * Соответствует ли текущая дата одному из дней недели
 *
 * @param {Date} date Дата
 * @param {number[]} weekdays Номера дней недели (с 0 - воскресенье до 6 - суббота) одному из которых должна
 * соответствовать дата
 * @returns {boolean}
 */
export function isDateInWeekdays(date, weekdays) {
    return weekdays.includes(dayjs(date).weekday());
}
/**
 * Конвертер единиц времени
 *
 * @param {number} value Исходное значение
 * @param {string} fromUnit Исходная единица времени
 * @param {string} toUnit Необходимая единица времени
 * @returns {number} Значение в необходимой единице времени
 */
export function convertTimeUnit(value, fromUnit, toUnit) {
    // Конвертируем значение в миллисекунды
    var valueInMs = toMs(value, fromUnit);
    // Конвертируем миллисекунды в нужную единицу
    return parseFloat(fromMs(valueInMs, toUnit).toFixed(2));
}
/**
 * @param timestamp
 */
export function daysLeftToDate(timestamp) {
    var eventdate = dayjs(new Date(timestamp)), todaysdate = dayjs();
    return Math.round(eventdate.diff(todaysdate, 'hours') / 24);
}
/**
 * @param timestamp
 */
export function timeLeftToDate(timestamp) {
    var startDate = Date.now();
    var endDate = timestamp;
    var delta = Math.abs(endDate - startDate) / 1000;
    var isNegative = startDate > endDate ? 0 : 1;
    var res = [
        ['days', 24 * 60 * 60],
        ['hours', 60 * 60],
        ['minutes', 60],
    ].reduce(function (acc, _a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return (acc[key] = Math.round(delta / +value) * isNegative, delta -= acc[key] * isNegative * +value, acc);
    }, {});
    // @ts-ignore
    if (res.days > 0) { // @ts-ignore
        return res.days + ' ' + numeralize(res.days, ['день', 'дня', 'дней']);
    }
    // @ts-ignore
    if (res.hours > 0) { // @ts-ignore
        return res.hours + ' ' + numeralize(res.hours, ['час', 'часа', 'часов']);
    }
    // @ts-ignore
    if (res.minutes > 0) { // @ts-ignore
        return res.minutes + ' ' + numeralize(res.minutes, ['минута', 'минуты', 'минут']);
    }
    return '0 минут';
}
/**
 * Получить оставшееся время между датами
 *
 * @param {Date|timestamp} dateFirst Начальная дата
 * @param {Date|timestamp} dateSecond Конечная дата
 * @param {string[]} units Единицы измерения по которым производится поиск разницы
 * @returns {{unit: string, diff: number}} Разница и подходящая единица измерения между датами
 */
export function leftToDate(dateFirst, dateSecond, units) {
    var e_1, _a;
    if (units === void 0) { units = ['hour', 'day', 'month', 'year']; }
    // Единицы измерения в порядке от большей к меньшей и из выборки аргумента units
    var unitsSorted = TIME_UNITS.filter(function (u) { return units.includes(u); }).reverse();
    // Даты в dayjs формате
    dateFirst = dayjs(dateFirst);
    dateSecond = dayjs(dateSecond);
    var lastCheckedUnit;
    try {
        for (var unitsSorted_1 = __values(unitsSorted), unitsSorted_1_1 = unitsSorted_1.next(); !unitsSorted_1_1.done; unitsSorted_1_1 = unitsSorted_1.next()) {
            var unit = unitsSorted_1_1.value;
            var diff = Math.round(dateSecond.diff(dateFirst, unit, true));
            if (diff >= 1) {
                return {
                    diff: diff,
                    unit: unit
                };
            }
            lastCheckedUnit = unit;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (unitsSorted_1_1 && !unitsSorted_1_1.done && (_a = unitsSorted_1.return)) _a.call(unitsSorted_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return {
        diff: 0, unit: lastCheckedUnit
    };
}
/**
 * Получить единицу изменения в правильном падеже
 *
 * @param {number} value Значение
 * @param {string} unit Единица измерения
 * @param {boolean} unitOnly Возвращать только единицу времени
 * @example (11, 'hour) => часов
 * @returns {string} (часов)
 */
export function numeralizeTimeUnit(value, unit, unitOnly) {
    if (unitOnly === void 0) { unitOnly = false; }
    var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
    var lang = locale.value;
    var TIME_UNITS_NUMERALIZE = {
        'second': [t(translations[lang].time.second), t(translations[lang].time.seconds), t(translations[lang].time.seconds_plural)],
        'minute': [t(translations[lang].time.minute), t(translations[lang].time.minutes), t(translations[lang].time.minutes_plural)],
        'hour': [t(translations[lang].time.hour), t(translations[lang].time.hours), t(translations[lang].time.hours_plural)],
        'day': [t(translations[lang].time.day), t(translations[lang].time.days), t(translations[lang].time.days_plural)],
        'month': [t(translations[lang].time.month), t(translations[lang].time.months), t(translations[lang].time.months_plural)],
        'year': [t(translations[lang].time.year), t(translations[lang].time.years), t(translations[lang].time.years_plural)]
    };
    return (!unitOnly ? value + ' ' : '') + numeralize(value, TIME_UNITS_NUMERALIZE[unit]);
}
/**
 * Конвертирует секунды в формат "часы (если есть):минуты:секунды"
 *
 * @param {number} e секунды
 * @param {boolean | undefined} useHours
 * @returns {string}
 */
// @ts-ignore
export function secondsToTime(e, useHours) {
    var removeNegative = function (num) { return num < 0 ? 0 : num; };
    var h = removeNegative(Math.floor(e / 3600)).toString().padStart(2, '0');
    var m = removeNegative(Math.floor(e % 3600 / 60)).toString().padStart(2, '0');
    var s = removeNegative(Math.floor(e % 60)).toString().padStart(2, '0');
    if (useHours === true) {
        return h + ':' + m + ':' + s;
    }
    if (useHours === false) {
        return m + ':' + s;
    }
    if (useHours === undefined) {
        return h === '00' ? m + ':' + s : h + ':' + m + ':' + s;
    }
}
/**
 * Конвертирует секунды в формат "часы (если есть):минуты:секунды(если есть)"
 *
 * @param {number} e секунды
 * @param {boolean | undefined} useHours
 * @param {boolean | undefined} useSecond
 * @returns {string}
 */
export function secondsToTimePicker(e, useHours, useSecond) {
    var removeNegative = function (num) { return num < 0 ? 0 : num; };
    var h = removeNegative(Math.floor(e / 3600)).toString().padStart(2, '0');
    var m = removeNegative(Math.floor(e % 3600 / 60)).toString().padStart(2, '0');
    var s = removeNegative(Math.floor(e % 60)).toString().padStart(2, '0');
    if (useHours === true && useSecond === true) {
        return "".concat(h, ":").concat(m, ":").concat(s);
    }
    if (useHours === true && useSecond === false) {
        return "".concat(h, ":").concat(m);
    }
    if (useHours === false && useSecond === true) {
        return "".concat(m, ":").concat(s);
    }
    if (useHours === false && useSecond === false) {
        return "".concat(m);
    }
    // Если ни часы, ни секунды не переданы
    return h === '00' ? "".concat(m, ":").concat(s) : "".concat(h, ":").concat(m, ":").concat(s);
}
/**
 * Конвертирует формат "часы:минуты:секунды" в секунды
 *
 * @param {string} time
 * @param useSecond
 * @returns {number}
 */
export function timeToSeconds(time, useSecond) {
    // Если секунды не используются, добавляем ":00" в конец
    if (!useSecond) {
        time += ':00';
    }
    var parts = time.split(':');
    var seconds = 0;
    if (parts.length === 1) {
        // Формат: "00" (считаем секунды)
        seconds = parseInt(parts[0]);
    }
    else if (parts.length === 2) {
        // Формат: "00:00" (считаем минуты и секунды)
        var minutes = parseInt(parts[0]);
        var secs = isNaN(parseInt(parts[1])) ? 0 : parseInt(parts[1]);
        seconds = minutes * 60 + secs;
    }
    else if (parts.length === 3) {
        // Формат: "00:00:00" (считаем часы, минуты и секунды)
        var hours = parseInt(parts[0]);
        var minutes = isNaN(parseInt(parts[1])) ? 0 : parseInt(parts[1]);
        var secs = isNaN(parseInt(parts[2])) ? 0 : parseInt(parts[2]);
        seconds = hours * 3600 + minutes * 60 + secs;
    }
    return seconds;
}
export var validateAndPadTime = function (time, includeHours, includeSeconds) {
    if (includeHours === void 0) { includeHours = false; }
    if (includeSeconds === void 0) { includeSeconds = false; }
    var parts = time.split(':');
    var numComponents = includeHours ? (includeSeconds ? 3 : 2) : (includeSeconds ? 2 : 1);
    while (parts.length < numComponents) {
        parts.unshift('00');
    }
    var hours = includeHours ? parseInt(parts[0]) : 0;
    var mins = parseInt(parts[includeHours ? 1 : 0]);
    var secs = includeSeconds ? parseInt(parts[includeHours ? 2 : 1]) : 0;
    var validHours = Math.max(0, Math.min(hours, 23));
    var validMins = Math.max(0, Math.min(mins, 59));
    var validSecs = Math.max(0, Math.min(secs, 59));
    // Если не нужно выводить секунды
    if (!includeSeconds) {
        return includeHours
            ? "".concat(padNumber(validHours), ":").concat(padNumber(validMins))
            : "".concat(padNumber(validMins));
    }
    return includeHours
        ? "".concat(padNumber(validHours), ":").concat(padNumber(validMins), ":").concat(padNumber(validSecs))
        : "".concat(padNumber(validMins), ":").concat(padNumber(validSecs));
};
var padNumber = function (num) {
    return num.toString().padStart(2, '0');
};
/**
 * обратный точный таймер с компенсацией
 *
 * @param {Function} callback
 * @param {number} interval
 * @returns {Function} clearInterval
 */
export function intervalTimer(callback, interval) {
    if (interval === void 0) { interval = 1000; }
    var counter = 1;
    var timeoutId;
    var startTime = Date.now();
    /**
     *
     */
    function main() {
        var nowTime = Date.now();
        var nextTime = startTime + counter * interval;
        timeoutId = setTimeout(main, interval - (nowTime - nextTime));
        counter += 1;
        callback();
    }
    timeoutId = setTimeout(main, interval);
    return function () {
        clearTimeout(timeoutId);
    };
}
/**
 * Позднее ли первая дата второй (currentDate > afterDate)
 *
 * @param {number|timestamp|Date|dayjs} currentDate Дата для сравнения
 * @param {number|timestamp|Date|dayjs} afterDate Дата, которая должна быть раньше первой
 * @param {'millisecond'|'second'|'minute'|'hour'|'day'|'date'|'week'|'month'|'quarter'|'year'} unit Минимальная
 * единица сравнения дат
 * @returns {boolean}
 */
export function isDateAfter(currentDate, afterDate, unit) {
    if (unit === void 0) { unit = 'millisecond'; }
    currentDate = dayjs(currentDate);
    afterDate = dayjs(afterDate);
    return currentDate.isAfter(afterDate, unit);
}
/**
 * Позднее ли первая дата второй или одинаковые (currentDate >= afterDate)
 *
 * @param {number|timestamp|Date|dayjs} currentDate Дата для сравнения
 * @param {number|timestamp|Date|dayjs} afterDate Дата, которая должна быть раньше или равна первой
 * @param {'millisecond'|'second'|'minute'|'hour'|'day'|'date'|'week'|'month'|'quarter'|'year'} unit Минимальная
 * единица сравнения дат
 * @returns {boolean}
 */
export function isDateAfterOrSame(currentDate, afterDate, unit) {
    if (unit === void 0) { unit = 'millisecond'; }
    currentDate = dayjs(currentDate);
    afterDate = dayjs(afterDate);
    return currentDate.isSameOrAfter(afterDate, unit);
}
/**
 * Ранее ли первая дата второй (currentDate > beforeDate)
 *
 * @param {number|timestamp|Date|dayjs} currentDate Дата для сравнения
 * @param {number|timestamp|Date|dayjs} beforeDate Дата, которая должна быть позднее первой
 * @param {'millisecond'|'second'|'minute'|'hour'|'day'|'date'|'week'|'month'|'quarter'|'year'} unit Минимальная
 * единица сравнения дат
 * @returns {boolean}
 */
export function isDateBefore(currentDate, beforeDate, unit) {
    if (unit === void 0) { unit = 'millisecond'; }
    currentDate = dayjs(currentDate);
    beforeDate = dayjs(beforeDate);
    return currentDate.isBefore(beforeDate, unit);
}
/**
 * Ранее ли первая дата второй (currentDate > beforeDate)
 *
 * @param {number|timestamp|Date|dayjs} currentDate Дата для сравнения
 * @param {number|timestamp|Date|dayjs} beforeDate Дата, которая должна быть позднее или равна первой
 * @param {'millisecond'|'second'|'minute'|'hour'|'day'|'date'|'week'|'month'|'quarter'|'year'} unit Минимальная
 * единица сравнения дат
 * @returns {boolean}
 */
export function isDateBeforeOrSame(currentDate, beforeDate, unit) {
    if (unit === void 0) { unit = 'millisecond'; }
    currentDate = dayjs(currentDate);
    beforeDate = dayjs(beforeDate);
    return currentDate.isSameOrBefore(beforeDate, unit);
}
/**
 * Равна ли первая дата второй (firstDate === secondDate)
 *
 * @param {number|timestamp|Date|dayjs} firstDate Дата для сравнения
 * @param {number|timestamp|Date|dayjs} secondDate Дата, которая должна равна
 * @param {'millisecond'|'second'|'minute'|'hour'|'day'|'date'|'week'|'month'|'quarter'|'year'} unit Минимальная
 * единица сравнения дат
 * @returns {boolean}
 */
export function isDateSame(firstDate, secondDate, unit) {
    if (unit === void 0) { unit = 'millisecond'; }
    firstDate = dayjs(firstDate);
    secondDate = dayjs(secondDate);
    return firstDate.isSame(secondDate, unit);
}
/**
 * Находится ли дата между двух других (либо равен одной из дат)
 *
 * @param {number|timestamp|Date|dayjs} currentDate Дата для сравнения
 * @param {number|timestamp|Date|dayjs} firstDate Первая дата диапазона
 * @param {number|timestamp|Date|dayjs} secondDate Вторая дата диапазона
 * @param {'millisecond'|'second'|'minute'|'hour'|'day'|'date'|'week'|'month'|'quarter'|'year'} unit Минимальная
 * единица сравнения дат
 * @returns {boolean}
 */
export function isDateBetween(currentDate, firstDate, secondDate, unit) {
    if (unit === void 0) { unit = 'millisecond'; }
    currentDate = dayjs(currentDate);
    firstDate = dayjs(firstDate);
    secondDate = dayjs(secondDate);
    return currentDate.isBetween(firstDate, secondDate, unit, '[]');
}
/**
 * Получить дату с форматированием в "сегодня"|"вчера"
 *
 * @param date
 * @param format
 */
export function getRelatedDate(date, format) {
    if (format === void 0) { format = 'DD MMMM'; }
    var _a = CurrentI18n.getInstance().global, t = _a.t, locale = _a.locale;
    var lang = locale.value;
    date = dayjs(date);
    var now = dayjs(Date.now()).startOf('day');
    var day = date.startOf('day');
    var differentDays = now.diff(day, 'days');
    if (differentDays === 0) {
        return t(translations[lang].today);
    }
    else if (differentDays < 2) {
        return t(translations[lang].yesterday);
    }
    else {
        return date.format(format);
    }
}
