var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { apiErrorsHandle, generateUuid } from '@common/utils/Helpers/Helpers';
import Api from '@school/modules/api/api';
import { isCancel } from 'axios';
import { CoreStore, CourseStore } from '@school/modules/store';
import analytics from '@common/utils/Analytics/Analytics';
import TestApi from '@school/modules/api/entities/test/test.api';
import Message from '@common/ui/EMessage/Message';
import { getOrderArray } from '@common/utils/Array/Array';
import { getImageOrientation } from '@common/utils/File/File';
import { defineStore } from 'pinia';
import { PublishStatusEnum } from '@common/enums';
import { defaultTestQuestions } from '@school/utils/DefaultTestQuestions';
import { defaultTestAnswers } from '@school/utils/DefaultTestAnswers';
/**
 * Стор школы
 */
export var useLessonStore = defineStore('lesson', {
    state: function () { return ({
        // Открытое занятие
        // @sync - синхроним с одинаковыми id
        lesson: {},
        // Состояние загрузки занятия
        lessonLoading: false,
        // Отображение модалки настроек занятия
        lessonSettingsVisible: false,
        // Список функций-флушей для дебоунс методов занятия
        lessonContentFlushes: [],
        // История версий занятия
        lessonHistory: [],
        // Отображение истории занятия
        lessonHistoryVisible: false,
        // Статус загрузки истории занятия
        lessonHistoryLoading: false,
        // Открытая версия из истории занятия
        lessonVersion: null,
        // Загрузка открытой версии из истории занятия
        lessonVersionLoading: false,
    }); },
    actions: {
        /**
         * Получить занятие
         */
        getLesson: function (lessonId_1) {
            return __awaiter(this, arguments, void 0, function (lessonId, hasLoading) {
                var res, data_1, err_1;
                if (hasLoading === void 0) { hasLoading = true; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (hasLoading) {
                                this.lessonLoading = true;
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            Api.executeAbort('getLesson');
                            return [4 /*yield*/, Api.getLesson(lessonId)];
                        case 2:
                            res = _a.sent();
                            data_1 = res.data;
                            this.lesson = data_1;
                            // Обновляем инфу по занятию в списке
                            if (data_1.uuid) {
                                CourseStore.lessons = CourseStore.lessons.map(function (l) {
                                    if (l.uuid === data_1.uuid) {
                                        data_1.step = l.step;
                                        return data_1;
                                    }
                                    return l;
                                });
                            }
                            this.lessonLoading = false;
                            return [2 /*return*/, data_1];
                        case 3:
                            err_1 = _a.sent();
                            if (isCancel(err_1)) {
                                console.warn(err_1.message);
                            }
                            else {
                                this.lessonLoading = false;
                                throw err_1;
                            }
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Отобразить или скрыть историю версий занятия
         *
         * @param lesson
         */
        toggleLessonHistory: function (lesson) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (!this.lessonHistoryVisible ? this.showLessonHistory(lesson) : this.hideLessonHistory())];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Отобразить историю версий занятия
         *
         * @param lesson
         */
        showLessonHistory: function (lesson) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.flushLessonChanges()];
                        case 1:
                            _a.sent();
                            this.lessonHistoryVisible = true;
                            return [4 /*yield*/, this.getLessonHistory(lesson.uuid)];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Скрыть историю версий занятия
         */
        hideLessonHistory: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.lessonHistoryVisible = false;
                    this.lessonHistory = [];
                    this.lessonVersion = null;
                    return [2 /*return*/];
                });
            });
        },
        /**
         * Получить историю версий занятия
         *
         * @param lessonUuid
         */
        getLessonHistory: function (lessonUuid) {
            return __awaiter(this, void 0, Promise, function () {
                var response, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.lessonHistoryLoading = true;
                            return [4 /*yield*/, Api.getLessonHistory(lessonUuid)];
                        case 1:
                            response = _a.sent();
                            data = response.data;
                            this.lessonHistory = data;
                            this.lessonHistoryLoading = false;
                            return [2 /*return*/, data];
                    }
                });
            });
        },
        /**
         * Открыть версию занятия
         *
         * @param versionUuid
         */
        openLessonVersion: function (versionUuid) {
            return __awaiter(this, void 0, void 0, function () {
                var response, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.lessonVersionLoading = true;
                            return [4 /*yield*/, Api.getLessonVersion(versionUuid)];
                        case 1:
                            response = _a.sent();
                            data = response.data;
                            this.lessonVersion = data;
                            this.lessonVersionLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        showLessonSettings: function () {
            this.lessonSettingsVisible = true;
        },
        hideLessonSettings: function () {
            this.lessonSettingsVisible = false;
        },
        /**
         * Флушим на сервер все отложенные сохранения изменений
         */
        flushLessonChanges: function () {
            return __awaiter(this, void 0, void 0, function () {
                var flushPromises, _a, _b, flush;
                var e_1, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            flushPromises = [];
                            try {
                                for (_a = __values(this.lessonContentFlushes), _b = _a.next(); !_b.done; _b = _a.next()) {
                                    flush = _b.value;
                                    flushPromises.push(flush());
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                            return [4 /*yield*/, Promise.all(flushPromises)];
                        case 1:
                            _d.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Очищаем список флушеров при смене занятия
         */
        clearLessonFlushes: function () {
            this.lessonContentFlushes = [];
        },
        /**
         * Создать вопрос к тесту
         *
         * @param testUuid
         * @param questionData
         * @param tempUuid
         */
        createQuestion: function (testUuid, questionData, tempUuid) {
            return __awaiter(this, void 0, void 0, function () {
                var question, questions, _a, _b, testQuestion;
                var e_2, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, TestApi.createQuestion(testUuid, questionData)];
                        case 1:
                            question = (_d.sent()).data;
                            questions = [];
                            try {
                                for (_a = __values(this.lesson.test.questions), _b = _a.next(); !_b.done; _b = _a.next()) {
                                    testQuestion = _b.value;
                                    if (testQuestion.uuid !== tempUuid) {
                                        questions.push(testQuestion);
                                    }
                                    else {
                                        questions.push(__assign(__assign({}, question), { _createdNow: true }));
                                    }
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            this.lesson.test.questions = questions;
                            analytics.trackDetail({
                                'Раздел': 'Конструктор',
                                'Событие': 'Тест',
                                'Подсобытие': 'Добавил новый вопрос',
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        createTempQuestion: function (questionData) {
            var tempUuid = "".concat(generateUuid(), "_TEMP");
            this.lesson.test.questions.push(__assign(__assign({}, defaultTestQuestions()[questionData.type]), { uuid: tempUuid, order: questionData.order, _createdNow: true, _tempUuid: true }));
            return tempUuid;
        },
        /**
         * Удалить вопрос теста
         *
         * @param uuid
         */
        cloneQuestion: function (uuid) {
            var _this = this;
            TestApi.cloneQuestion(uuid)
                .then(function (res) {
                var data = res.data;
                var question = data;
                question._createdNow = true;
                _this.lesson.test.questions.push(question);
                analytics.trackDetail({
                    'Раздел': 'Конструктор',
                    'Событие': 'Тест',
                    'Подсобытие': 'Копировал вопрос',
                });
            })
                .catch(function (err) { return apiErrorsHandle(err, {}); });
        },
        updateQuestion: function (uuid_1, fields_1) {
            return __awaiter(this, arguments, void 0, function (uuid, fields, notify) {
                var _a, updatedQuestion, err_2;
                if (notify === void 0) { notify = true; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!(fields.image instanceof File)) return [3 /*break*/, 2];
                            _a = fields;
                            return [4 /*yield*/, getImageOrientation(fields.image)];
                        case 1:
                            _a.imageOrientation = _b.sent();
                            _b.label = 2;
                        case 2:
                            if (fields.text) {
                                fields.text = fields.text.trim() === '<p></p>' ? '' : fields.text.trim();
                            }
                            _b.label = 3;
                        case 3:
                            _b.trys.push([3, 5, , 6]);
                            return [4 /*yield*/, TestApi.updateQuestion(uuid, fields)];
                        case 4:
                            updatedQuestion = _b.sent();
                            if (notify) {
                                Message.success('Вопрос сохранен');
                            }
                            analytics.trackDetail({
                                'Раздел': 'Конструктор',
                                'Событие': 'Тест',
                                'Подсобытие': 'Обновил контент вопроса',
                            });
                            return [2 /*return*/, updatedQuestion.data];
                        case 5:
                            err_2 = _b.sent();
                            apiErrorsHandle(err_2, {
                                LIMIT_SCHOOL_STORAGE: function () {
                                    CoreStore.showPlanLimit('storage');
                                },
                            });
                            throw err_2;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Удалить вопрос теста
         *
         * @param uuid
         */
        deleteQuestion: function (uuid) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, TestApi.deleteQuestion(uuid)
                                .then(function (res) {
                                _this.lesson.test.questions = _this.lesson.test.questions.filter(function (q) {
                                    return q.uuid !== uuid;
                                });
                                analytics.trackDetail({
                                    'Раздел': 'Конструктор',
                                    'Событие': 'Тест',
                                    'Подсобытие': 'Удалил вопрос',
                                });
                            })
                                .catch(function (err) { return apiErrorsHandle(err, {}); })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateChangeStatus: function () {
            if ([PublishStatusEnum.NONE_PUBLISHED, PublishStatusEnum.DRAW].includes(this.lesson.status))
                return;
            this.lesson.status = PublishStatusEnum.PUBLISHED_DRAW;
        },
        /**
         * Обновить порядок вопросов
         *
         * @param handledByUser
         */
        sortQuestions: function () {
            return __awaiter(this, arguments, void 0, function (handledByUser) {
                var _this = this;
                if (handledByUser === void 0) { handledByUser = false; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.lesson.test.questions = this.lesson.test.questions.map(function (q, i) {
                                q.order = 100 * (i + 1);
                                return q;
                            });
                            if (this.lesson.test.questions.length === 0) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, TestApi.sortQuestions(this.lesson.test.uuid, getOrderArray(this.lesson.test.questions))
                                    .then(function (_res) {
                                    _this.updateChangeStatus();
                                    Message.success("\u041F\u043E\u0440\u044F\u0434\u043E\u043A \u0432\u043E\u043F\u0440\u043E\u0441\u043E\u0432 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D");
                                    if (handledByUser) {
                                        analytics.trackDetail({
                                            'Раздел': 'Конструктор',
                                            'Событие': 'Тест',
                                            'Подсобытие': 'Поменял вопросы местами',
                                        });
                                    }
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Создать вопрос к тесту
         *
         * @param questionUuid
         * @param tempUuid
         * @param answerData
         */
        createAnswer: function (questionUuid, tempUuid, answerData) {
            return __awaiter(this, void 0, void 0, function () {
                var questionAnswers, order, answerDataWithOrder, data, question, newQuestionAnswers, questionAnswers_1, questionAnswers_1_1, answer, err_3;
                var e_3, _a;
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 2, , 3]);
                            questionAnswers = (_b = this.lesson.test.questions.find(function (q) { return q.uuid === questionUuid; })) === null || _b === void 0 ? void 0 : _b.answers;
                            if (!questionAnswers) {
                                return [2 /*return*/];
                            }
                            order = questionAnswers.length * 100;
                            answerDataWithOrder = __assign(__assign({}, answerData), { order: order });
                            return [4 /*yield*/, TestApi.createAnswer(questionUuid, answerDataWithOrder)];
                        case 1:
                            data = (_c.sent()).data;
                            question = this.lesson.test.questions.find(function (q) { return q.uuid === questionUuid; });
                            newQuestionAnswers = [];
                            if (questionAnswers && question) {
                                try {
                                    for (questionAnswers_1 = __values(questionAnswers), questionAnswers_1_1 = questionAnswers_1.next(); !questionAnswers_1_1.done; questionAnswers_1_1 = questionAnswers_1.next()) {
                                        answer = questionAnswers_1_1.value;
                                        if (answer.uuid !== tempUuid) {
                                            newQuestionAnswers.push(answer);
                                        }
                                        else {
                                            newQuestionAnswers.push(__assign(__assign({}, answer), { uuid: data.uuid, _tempUuid: undefined, order: data.order }));
                                        }
                                    }
                                }
                                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                finally {
                                    try {
                                        if (questionAnswers_1_1 && !questionAnswers_1_1.done && (_a = questionAnswers_1.return)) _a.call(questionAnswers_1);
                                    }
                                    finally { if (e_3) throw e_3.error; }
                                }
                                this.lesson.test.questions.find(function (q) { return q.uuid === questionUuid; }).answers = newQuestionAnswers;
                            }
                            analytics.trackDetail({
                                'Раздел': 'Конструктор',
                                'Событие': 'Тест',
                                'Подсобытие': 'Добавил новый ответ',
                            });
                            return [2 /*return*/, data];
                        case 2:
                            err_3 = _c.sent();
                            apiErrorsHandle(err_3);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        createTempAnswer: function (questionUuid, type, answerData) {
            var _a, _b;
            var tempUuid = "".concat(generateUuid(), "_TEMP");
            var answerLocal = defaultTestAnswers()[type];
            (_b = (_a = this.lesson.test.questions.find(function (q) { return q.uuid === questionUuid; })) === null || _a === void 0 ? void 0 : _a.answers) === null || _b === void 0 ? void 0 : _b.push(__assign(__assign(__assign({}, answerLocal), answerData), { uuid: tempUuid, _tempUuid: true }));
            return tempUuid;
        },
        updateAnswer: function (uuid_1, fields_1) {
            return __awaiter(this, arguments, void 0, function (uuid, fields, notify) {
                var updatedAnswer, err_4;
                if (notify === void 0) { notify = true; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (fields.text) {
                                fields.text = fields.text.trim() === '<p></p>' ? '' : fields.text.trim();
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, TestApi.updateAnswer(uuid, fields)];
                        case 2:
                            updatedAnswer = _a.sent();
                            if (notify) {
                                Message.success('Ответ сохранен');
                            }
                            analytics.trackDetail({
                                'Раздел': 'Конструктор',
                                'Событие': 'Тест',
                                'Подсобытие': 'Обновил контент ответа',
                            });
                            return [2 /*return*/, updatedAnswer.data];
                        case 3:
                            err_4 = _a.sent();
                            apiErrorsHandle(err_4, {
                                LIMIT_SCHOOL_STORAGE: function () {
                                    CoreStore.showPlanLimit('storage');
                                },
                            });
                            throw err_4;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Удалить ответ вопроса
         *
         * @param uuid
         */
        deleteAnswer: function (uuid) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, TestApi.deleteAnswer(uuid)
                                .then(function (res) {
                                analytics.trackDetail({
                                    'Раздел': 'Конструктор',
                                    'Событие': 'Тест',
                                    'Подсобытие': 'Удалил ответ',
                                });
                            })
                                .catch(function (err) { return apiErrorsHandle(err, {}); })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        /**
         * Обновить порядок ответов
         *
         * @param questionUuid
         */
        sortAnswers: function (questionUuid) {
            return __awaiter(this, void 0, void 0, function () {
                var question;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            question = this.lesson.test.questions.find(function (q) { return q.uuid === questionUuid; });
                            if (!question) {
                                return [2 /*return*/];
                            }
                            question.answers = (_a = question.answers) === null || _a === void 0 ? void 0 : _a.map(function (a, i) {
                                a.order = 100 * (i + 1);
                                return a;
                            });
                            return [4 /*yield*/, TestApi.sortAnswers(questionUuid, getOrderArray(question.answers))
                                    .then(function (res) {
                                    Message.success("\u041F\u043E\u0440\u044F\u0434\u043E\u043A \u043E\u0442\u0432\u0435\u0442\u043E\u0432 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D");
                                    analytics.trackDetail({
                                        'Раздел': 'Конструктор',
                                        'Событие': 'Тест',
                                        'Подсобытие': 'Поменял ответы местами',
                                    });
                                })];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
