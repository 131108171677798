var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var ApiBase = /** @class */ (function () {
    function ApiBase() {
        this.cancelTokens = {};
    }
    // TODO мейби затащить новые декораторы и в них проворачивать это
    ApiBase.prototype.setRequestAbort = function (methodName, cancelToken, uuid) {
        if (!this.cancelTokens[methodName]) {
            this.cancelTokens[methodName] = [];
        }
        this.cancelTokens[methodName].push({
            token: cancelToken,
            uuid: uuid
        });
    };
    ApiBase.prototype.deleteRequestAbort = function (methodName, uuid) {
        if (this.cancelTokens[methodName]) {
            this.cancelTokens[methodName] = this.cancelTokens[methodName].filter(function (t) { return t.uuid !== uuid; });
        }
    };
    ApiBase.prototype.executeAbort = function (methodName) {
        var e_1, _a;
        if (this.cancelTokens[methodName]) {
            try {
                for (var _b = __values(this.cancelTokens[methodName]), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var abort = _c.value;
                    abort.token.cancel("Abort method - ".concat(methodName));
                    this.deleteRequestAbort(methodName, abort.uuid);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    return ApiBase;
}());
export { ApiBase };
