var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { CacheFactory } from '@common/utils/Cache/Cache';
import { defineStore } from 'pinia';
import { clone, lockBodyScroll, unlockBodyScroll } from '@common/utils/Helpers/Helpers';
import { createResolvedPromise } from '@common/utils/Promise/Promise';
import { AudioContextWrapper } from '@common/utils/AudioContextWrapper';
import Auth from '@common/modules/auth';
import { useEventBus } from '@vueuse/core';
import { toggleCarrotquestMessenger } from '@common/utils/ToggleCarrotquestMessenger';
import { ChatApi } from '@common/api/entities/chat';
import CurrentI18n from '@common/utils/CurrentI18n';
import { translations } from '@common/stores/chatStore.i18n';
var cache = new CacheFactory();
var chatApi = new ChatApi();
var busChatUnread = useEventBus('chatUnreadMessageAdded');
export var useChatStore = defineStore('chat', {
    state: function () { return ({
        // Отображение модалки сообщений (false === виден)
        hidden: true,
        // Список преподавателей,
        // с которыми можно связаться через чат
        teachers: [],
        // Список чатов пользователя
        // @sync
        chats: [],
        // Состояние загрузки чатов
        chatsLoading: false,
        // Настройки чата для школы
        // @sync
        chatSettings: {},
        // Состояние загрузки настроек чата
        chatSettingsLoading: false,
        // Открытый раздел сообщений
        // (empty - пусто, chat - чат, settings - настройки, chat-recipients - участники чата)
        contentSection: 'empty',
        // Открытый уровень списка чатов (common - все чаты, course - чаты курса)
        schoolSection: 'common',
        // Открытый чат
        // @sync - нужно синхронить с одинаковыми id
        contentChat: {},
        // Загружается чат
        contentChatLoading: false,
        // Отображение списка или контента для мобилок
        // (list - список чатов, content - контент)
        mainSection: 'list',
        // Курс для вывода чатов по нему
        course: {},
    }); },
    getters: {
        /**
         * Количество непрочитанных сообщений
         * @param store
         */
        totalUnreadMessages: function (store) {
            var e_1, _a;
            var count = 0;
            try {
                for (var _b = __values(store.chats), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var c = _c.value;
                    if (c.unreadMessages) {
                        count += c.unreadMessages;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return count;
        },
    },
    actions: {
        /**
         * Показать окно сообщений
         *
         * @returns {Promise}
         */
        show: function () {
            this.hidden = false;
            lockBodyScroll();
            toggleCarrotquestMessenger('hide');
        },
        /**
         * Скрыть окно сообщений
         *
         * @returns {Promise}
         */
        hide: function () {
            this.hidden = true;
            AudioContextWrapper.stopActiveSource();
            unlockBodyScroll();
            toggleCarrotquestMessenger('show');
        },
        /**
         * Показать/скрыть окно сообщений
         *
         * @returns {Promise}
         */
        toggle: function () {
            if (!this.hidden) {
                this.hidden = true;
                unlockBodyScroll();
                toggleCarrotquestMessenger('show');
            }
            else {
                this.hidden = false;
                lockBodyScroll();
                toggleCarrotquestMessenger('hide');
            }
        },
        // Получить список чатов пользователя
        getChats: function (forceUpdate) {
            return __awaiter(this, void 0, void 0, function () {
                var cacheCode, cachedData, res, chats, teachers;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            cacheCode = "chats";
                            if (cache.exists(cacheCode) && !forceUpdate) {
                                cachedData = cache.get(cacheCode);
                                this.chats = cachedData;
                                cache.set("chats", this.chats);
                                return [2 /*return*/, createResolvedPromise(cachedData)];
                            }
                            this.chatsLoading = true;
                            return [4 /*yield*/, chatApi.getList()];
                        case 1:
                            res = (_a.sent()).data;
                            chats = res.chats;
                            teachers = res.teachers;
                            this.chats = chats.map(function (c) { return prepareChat(c); });
                            this.chatsLoading = false;
                            if (teachers) {
                                this.teachers = teachers;
                            }
                            cache.set("chats", this.chats);
                            return [2 /*return*/];
                    }
                });
            });
        },
        // * Добавить сообщение в чат
        appendMessage: function (chatId, message) {
            var chat = this.contentChat;
            if (this.chats.some(function (c) { return c.id === chatId; })) {
                this.updateChatLastMessage(chatId, message);
                if (chatId === chat.id) {
                    chat.messages.push(message);
                    this.contentChat = chat;
                }
            }
            else {
                this.getChats(true);
            }
            busChatUnread.emit();
        },
        // * Обновить измененное сообщение в чате
        updateEditedMessage: function (chatId, message) {
            var chat = this.contentChat;
            if (chatId === chat.id) {
                chat.messages = chat.messages.map(function (m) {
                    return m.id === message.id ? message : m;
                });
                this.contentChat = chat;
            }
            this.chats = this.chats.map(function (c) {
                if (c.id === chatId
                    && c.lastMessage.id === message.id) {
                    c.lastMessage = message;
                }
                return c;
            });
            cache.set("chats", this.chats);
        },
        /**
         * Обновить информацию по удаленному сообщению в чате
         *
         * @param {number} chatId ID чата
         * @param {object} chatLastMessage последнее сообщение в чате
         * @param {Array} messages сообщение
         * @returns {Promise}
         */
        updateDeletedMessages: function (chatId, chatLastMessage, messages) {
            var chat = this.contentChat;
            var messageIds = messages.map(function (m) { return Number(m); });
            if (chatId === chat.id) {
                chat.messages = chat.messages.filter(function (m) {
                    return !messageIds.includes(m.id);
                });
                this.contentChat = chat;
            }
            this.chats = this.chats.map(function (c) {
                if (c.id === chatId) {
                    c.lastMessage = chatLastMessage ? chatLastMessage : {};
                }
                return c;
            });
            cache.set("chats", this.chats);
        },
        // Обновить "последнее сообщение" чата
        updateChatLastMessage: function (chatId, message) {
            var user = Auth.getUser();
            this.chats = this.chats.map(function (chat) {
                if (chat.id === chatId) {
                    chat.lastMessage = message;
                    if (message.user.id !== user.id) {
                        chat.unreadMessages += 1;
                        if (!chat.unreadSince) {
                            chat.unreadSince = message.createdAt;
                        }
                    }
                }
                return chat;
            });
            cache.set("chats", this.chats);
        },
        // Добавить текущий открытый чат в список чатов (если он отсутствует)
        addChatToList: function () {
            var _this = this;
            var chats = this.chats;
            if (!chats.some(function (c) { return c.id === _this.contentChat.id; })) {
                chats.push(this.contentChat);
                this.chats = chats;
                cache.set("chats", this.chats);
            }
        },
        // Открыто пустой раздел
        openEmptyPage: function () {
            this.clearContentChat();
            this.contentSection = 'empty';
        },
        // Открыть раздел "чат"
        openChatPage: function (chat) {
            return __awaiter(this, void 0, void 0, function () {
                var chatList, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.contentChat && !chat) {
                                this.contentSection = 'chat';
                                this.mainSection = 'content';
                            }
                            else if (!chat) {
                                return [2 /*return*/];
                            }
                            if (!chat)
                                return [2 /*return*/];
                            this.contentChatLoading = true;
                            this.mainSection = 'content';
                            return [4 /*yield*/, chatApi.getChat(chat.id)];
                        case 1:
                            chatList = (_a.sent()).data;
                            result = prepareChat(chatList);
                            this.chats = clone(this.chats).map(function (c) {
                                if (c.id === chat.id) {
                                    return result;
                                }
                                return c;
                            });
                            this.contentChat = result;
                            this.contentSection = 'chat';
                            this.contentChatLoading = false;
                            cache.set("chats", this.chats);
                            return [2 /*return*/, result];
                    }
                });
            });
        },
        // Открыть раздел "настройка сообщений"
        openSettingsPage: function () {
            this.clearContentChat();
            this.contentSection = 'settings';
            this.mainSection = 'content';
        },
        // Открыть раздел "список участников чата"
        openChatRecipientsPage: function () {
            this.contentSection = 'chat-recipients';
        },
        // Открыть диалог с пользователем
        openDialogue: function (userId) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.contentChatLoading = true;
                            this.mainSection = 'content';
                            return [4 /*yield*/, chatApi.getDialogue(userId)];
                        case 1:
                            data = (_a.sent()).data;
                            this.contentChat = prepareChat(data);
                            this.contentSection = 'chat';
                            this.contentChatLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        // Открыть раздел "список чатов"
        openChatList: function () {
            this.mainSection = 'list';
        },
        // Открыть список с чатами курса
        openCourse: function (course) {
            this.course = course;
        },
        // Стереть локальные данные последнего открытого чата
        clearContentChat: function () {
            this.contentChat = {};
        },
        // Очистка стореджа от всех данных и кэша
        clearStoreState: function () {
            this.$reset();
            cache.clear();
        },
    },
});
/**
 * Обработка объекта чата
 *
 * @param {object} chat Объект чата
 * @returns {object} chat
 */
function prepareChat(chat) {
    var e_2, _a;
    if (chat.school) {
        var _b = CurrentI18n.getInstance().global, t = _b.t, locale = _b.locale;
        var lang = locale.value;
        chat.name = chat.type === 'group'
            ? t(translations[lang].generalChat)
            : (chat.type === 'channel'
                ? t(translations[lang].generalChannel)
                : '');
        chat.avatar = chat.school.avatar;
    }
    if (chat.course) {
        chat.name = chat.flow.name;
        chat.course.cover = chat.course.cover ? chat.course.cover : '/img/content/courseBgSmall.jpg';
        chat.avatar = chat.course.cover ? chat.course.cover : '/img/content/courseBgSmall.jpg';
    }
    if (chat.messages) {
        chat.messages.reverse();
        if (chat.recipients) {
            var _loop_1 = function (message) {
                var recipient = chat.recipients.find(function (recipient) { return recipient.id === message.user.id; });
                if (recipient && recipient.studentProfileName) {
                    message.user.name = recipient.studentProfileName;
                }
            };
            try {
                for (var _c = __values(chat.messages), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var message = _d.value;
                    _loop_1(message);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    if (chat.recipient) {
        chat.name = chat.recipient.studentProfileName ? chat.recipient.studentProfileName : chat.recipient.name;
        chat.avatar = chat.recipient.avatar;
    }
    return chat;
}
