import { intToHex } from '@common/utils/Helpers/Helpers';
/**
 * @param number
 */
export function numberColorHash(number) {
    var colors = [
        '#FFECD0',
        '#FFE2D8',
        '#F9DCDC',
        '#D0F1E7',
        '#E2F6F3',
        '#D3F2F8',
        '#F1E3F4',
        '#E7E1FF',
        '#DAEBFF',
    ];
    return colors[number % colors.length];
}
/**
 * @param id
 */
export function getAvatarColors(id) {
    var colors = [
        [
            '#FFECD0',
            '#D0B48B',
        ],
        [
            '#FFE2D8',
            '#EEA78D',
        ],
        [
            '#F9DCDC',
            '#D26B6B',
        ],
        [
            '#D0F1E7',
            '#7CC6AE',
        ],
        [
            '#E2F6F3',
            '#72C5B7',
        ],
        [
            '#D3F2F8',
            '#72B0BC',
        ],
        [
            '#F1E3F4',
            '#C07FCD',
        ],
        [
            '#E7E1FF',
            '#AAA1CE',
        ],
        [
            '#DAEBFF',
            '#8AABD1',
        ],
    ];
    return colors[id % colors.length];
}
export var defaultCourseCategoryColors = [
    '#17B198',
    '#60D18D',
    '#8166F2',
    '#3B9DF8',
    '#7CE7FF',
    '#FF7575',
    '#FFAD32',
    '#FFD977',
];
/**
 * @param hex
 * @param text
 */
export function hexToRgb(hex, text) {
    if (text === void 0) { text = false; }
    var result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
    if (result === null) {
        return null;
    }
    var rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
    return text === false ? rgb : "".concat(rgb.r, ", ").concat(rgb.g, ", ").concat(rgb.b);
}
/*
 blend two colors to create the color that is at the percentage away from the first color
 this is a 5 step process
 1: validate input
 2: convert input to 6 char hex
 3: convert hex to rgb
 4: take the percentage to create a ratio between the two colors
 5: convert blend to hex
 @param: color1      => the first color, hex (ie: #000000)
 @param: color2      => the second color, hex (ie: #ffffff)
 @param: percentage  => the distance from the first color, as a decimal between 0 and 1 (ie: 0.5)
 @returns: string    => the third color, hex, represenatation of the blend between color1 and color2 at the given percentage
 */
/**
 * @param color1
 * @param color2
 * @param percentage
 */
export function blendColors(color1, color2, percentage) {
    if (color1 === void 0) { color1 = '#000'; }
    if (color2 === void 0) { color2 = '#fff'; }
    if (percentage === void 0) { percentage = 0.5; }
    // 1: validate input, make sure we have provided a valid hex
    if (color1.length !== 4 && color1.length !== 7) {
        throw new Error('colors must be provided as hexes');
    }
    if (color2.length !== 4 && color2.length !== 7) {
        throw new Error('colors must be provided as hexes');
    }
    if (percentage > 1 || percentage < 0) {
        throw new Error('percentage must be between 0 and 1');
    }
    // output to canvas for proof
    var cvs = document.createElement('canvas');
    var ctx = cvs.getContext('2d');
    cvs.width = 90;
    cvs.height = 25;
    // color1 on the left
    ctx.fillStyle = color1;
    ctx.fillRect(0, 0, 30, 25);
    // color2 on the right
    ctx.fillStyle = color2;
    ctx.fillRect(60, 0, 30, 25);
    // 2: check to see if we need to convert 3 char hex to 6 char hex, else slice off hash
    //      the three character hex is just a representation of the 6 hex where each character is repeated
    //      ie: #060 => #006600 (green)
    color1 = color1.length === 4 ? color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3] : color1.slice(1);
    color2 = color2.length === 4 ? color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3] : color2.slice(1);
    // 3: we have valid input, convert colors to rgb
    color1 = [
        parseInt(color1[0] + color1[1], 16),
        parseInt(color1[2] + color1[3], 16),
        parseInt(color1[4] + color1[5], 16),
    ];
    color2 = [
        parseInt(color2[0] + color2[1], 16),
        parseInt(color2[2] + color2[3], 16),
        parseInt(color2[4] + color2[5], 16),
    ];
    // 4: blend
    var color3 = [
        (1 - percentage) * color1[0] + percentage * color2[0],
        (1 - percentage) * color1[1] + percentage * color2[1],
        (1 - percentage) * color1[2] + percentage * color2[2],
    ];
    // 5: convert to hex
    color3 = '#' + intToHex(color3[0]) + intToHex(color3[1]) + intToHex(color3[2]);
    // color3 in the middle
    ctx.fillStyle = color3;
    ctx.fillRect(30, 0, 30, 25);
    // return hex
    return color3;
}
/**
 * Установка цвета primary. Возвращаемые css-переменные присваеваем в нужном месте к DOM элементу для переопределения
 * основного цвета
 *
 * @param {string} primaryColor
 */
export var primaryStyles = function (primaryColor) {
    if (primaryColor === void 0) { primaryColor = '#17b198'; }
    var primaryColorRGB = hexToRgb(primaryColor, true);
    return [
        "--color-primary: ".concat(primaryColor, ";"),
        "--color-primary-rgb: ".concat(primaryColorRGB, ";"),
        "--color-primary-50: ".concat(blendColors(primaryColor, '#fff', 0.75), ";"),
        "--color-primary-100: ".concat(blendColors(primaryColor, '#fff', 0.6), ";"),
        "--color-primary-200: ".concat(blendColors(primaryColor, '#fff', 0.45), ";"),
        "--color-primary-300: ".concat(blendColors(primaryColor, '#fff', 0.3), ";"),
        "--color-primary-400: ".concat(blendColors(primaryColor, '#fff', 0.15), ";"),
        "--color-primary-500: ".concat(primaryColor, ";"),
        "--color-primary-600: ".concat(blendColors(primaryColor, '#000', 0.1), ";"),
        "--color-primary-700: ".concat(blendColors(primaryColor, '#000', 0.2), ";"),
        "--color-primary-800: ".concat(blendColors(primaryColor, '#000', 0.3), ";"),
        "--color-primary-900: ".concat(blendColors(primaryColor, '#000', 0.4), ";"),
        // Удалить когда избавимся от element ui
        "--color-primary-white-mix: ".concat(blendColors(primaryColor, '#fff'), ";"),
        "--color-primary-white-mix-1: ".concat(blendColors(primaryColor, '#fff', 0.1), ";"),
        "--color-primary-white-mix-2: ".concat(blendColors(primaryColor, '#fff', 0.2), ";"),
        "--color-primary-white-mix-3: ".concat(blendColors(primaryColor, '#fff', 0.3), ";"),
        "--color-primary-white-mix-4: ".concat(blendColors(primaryColor, '#fff', 0.4), ";"),
        "--color-primary-white-mix-5: ".concat(blendColors(primaryColor, '#fff', 0.5), ";"),
        "--color-primary-white-mix-6: ".concat(blendColors(primaryColor, '#fff', 0.6), ";"),
        "--color-primary-white-mix-7: ".concat(blendColors(primaryColor, '#fff', 0.7), ";"),
        "--color-primary-white-mix-8: ".concat(blendColors(primaryColor, '#fff', 0.8), ";"),
        "--color-primary-white-mix-9: ".concat(blendColors(primaryColor, '#fff', 0.9), ";"),
        "--color-primary-black-mix: ".concat(blendColors(primaryColor, '#000'), ";"),
        "--color-primary-black-mix-1: ".concat(blendColors(primaryColor, '#000', 0.1), ";"),
        "--color-primary-black-mix-2: ".concat(blendColors(primaryColor, '#000', 0.2), ";"),
        "--color-primary-black-mix-3: ".concat(blendColors(primaryColor, '#000', 0.3), ";"),
        "--color-primary-black-mix-4: ".concat(blendColors(primaryColor, '#000', 0.4), ";"),
        "--color-primary-black-mix-5: ".concat(blendColors(primaryColor, '#000', 0.5), ";"),
        "--color-primary-black-mix-6: ".concat(blendColors(primaryColor, '#000', 0.6), ";"),
        "--color-primary-black-mix-7: ".concat(blendColors(primaryColor, '#000', 0.7), ";"),
        "--color-primary-black-mix-8: ".concat(blendColors(primaryColor, '#000', 0.8), ";"),
        "--color-primary-black-mix-9: ".concat(blendColors(primaryColor, '#000', 0.9), ";"),
    ].join('');
};
export var ICON_COLORS = {
    ps: '#4185F3',
    ae: '#8A53FB',
    ai: '#F0951D',
    id: '#EE4894',
    xd: '#FD4093',
    archive: '#727272',
    audio: '#8850F9',
    word: '#3F83EE',
    excel: '#299338',
    pp: '#E44D4E',
    pdf: '#DC4A49',
    picture: '#4284F4',
    txt: '#727272',
    video: '#4284F4',
    code: '#727272',
    unknown: '#727272',
};
export var textHighlightColors = [
    '#FFFFFF',
    '#FFC671',
    '#17B198',
    '#4079FB',
    '#8166F2',
    '#E15682',
    '#D85252',
    '#838A94',
    '#000000',
];
