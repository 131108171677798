var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiBase } from '@common/api/apiBase';
import { httpClientApiSchool } from '@common/utils/HttpClient';
var WebhookApi = /** @class */ (function (_super) {
    __extends(WebhookApi, _super);
    function WebhookApi() {
        return _super.call(this) || this;
    }
    /**
     * Получить список вебхуков
     */
    WebhookApi.prototype.getWebhooks = function () {
        return httpClientApiSchool.get("settings/school-webhook/list");
    };
    /**
     * Создание вебхука
     *
     * @param fields
     */
    WebhookApi.prototype.createWebhook = function (fields) {
        return httpClientApiSchool.post("settings/school-webhook/create", fields);
    };
    /**
     * Изменение вебхука
     *
     * @param uuid
     * @param fields
     */
    WebhookApi.prototype.editWebhook = function (uuid, fields) {
        return httpClientApiSchool.post("settings/school-webhook/".concat(uuid, "/update"), fields);
    };
    /**
     * Удаление вебхука
     *
     * @param uuid
     */
    WebhookApi.prototype.deleteWebhook = function (uuid) {
        return httpClientApiSchool.delete("settings/school-webhook/".concat(uuid, "/remove"));
    };
    /**
     * Проверочный вебхук
     *
     * @param fields
     */
    WebhookApi.prototype.testWebhook = function (fields) {
        return httpClientApiSchool.post("settings/school-webhook/test", fields);
    };
    return WebhookApi;
}(ApiBase));
export default new WebhookApi();
