import { defineStore } from 'pinia';
import ApiCommon from '@common/api/apiCommon';
export var usePartnerProgramStore = defineStore('partnerProgram', {
    state: function () { return ({
        partnerInfo: {},
        partnerRequisites: null
    }); },
    getters: { isPartner: function (store) { return !!store.partnerInfo.uuid; } },
    actions: {
        getPartnerInfo: function () {
            var _this = this;
            return ApiCommon.getPartnerCurrent()
                .then(function (res) {
                var _a = res.data, partner = _a.partner, requisites = _a.requisites;
                _this.partnerInfo = partner;
                _this.partnerRequisites = requisites;
                return res;
            }).catch(function () { });
        },
        /**
         * Очистка стореджа от всех данных и кэша
         */
        clearStoreState: function () {
            this.$reset();
        },
    },
});
