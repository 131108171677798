/**
 * Enum string values.
 *
 * @enum {string}
 */
var FEATURES = {
    // возможности тестов
    LESSON_TEST_FEATURE_CODE: 'lessontestfeature',
    // возможности домашек
    LESSON_HOMEWORK_FEATURE_CODE: 'lessonhomeworkfeature',
    // возможности вебинаров
    LESSON_WEBINAR_FEATURE_CODE: 'lessonwebinarfeature',
    // доступ к апи
    API_FEATURE_CODE: 'apifeature',
    // доступ к вебхукам
    WEBHOOKS_FEATURE_CODE: 'schoolwebhook',
    // помощь в переносе
    RELOCATE_HELP_FEATURE_CODE: 'relocatehelpfeature',
    // группы пользователей
    USER_GROUPS_FEATURE_CODE: 'usergroupsfeature',
    // чат
    CHAT_FEATURE_CODE: 'chatfeature',
    // группы и каналы
    CHAT_CHANNELS_AND_GROUPS_FEATURE_CODE: 'chatchannelsandgroupsfeature',
    // настройка прав сотрудников
    ACCESS_RIGHTS_FEATURE_CODE: 'accessrightsfeature',
    // автопроверка домашек
    HOMEWORKS_AUTOACCEPT_FEATURE_CODE: 'homeworksautoacceptfeature',
    // смена цветовой палитры
    PLATFORM_CUSTOMIZATION_FEATURE_CODE: 'platformcustomizationfeature',
    // кастомный домен
    CUSTOM_DOMAIN_FEATURE_CODE: 'customdomainfeature',
    // вайт лейбл фукнции (скрыть данные о skillspace)
    WHITE_LABEL_FEATURE_CODE: 'whitelabelfeature',
    // аналитика по студентам
    STUDENTS_ANALYTICS: 'studentsanalytics',
    // тарифы курса
    COURSE_TARIFF_PLANS: 'coursetariffplans',
    // Сертификаты - база:
    CERTIFICATES_BASE: 'certbase',
    // Сертификаты - кастомизация:
    CERTIFICATES_CUSTOMIZATION: 'certcustom',
    // Сертификаты - расширенные возможности:
    CERTIFICATES_PLUS: 'certplus',
    // Промокоды для курсов
    COURSE_DISCOUNT_FEATURE: 'coursediscount',
    // Использование ограничений по промокодам
    COURSE_DISCOUNT_RESTRICTIONS_FEATURE: 'coursediscountrestrictions',
    // Завершение курса - расширенные возможности:
    COURSE_FINISH_RULE_GROUP: 'finishrulegroup',
    // Возможность обновления ролей сотрудников школы
    SCHOOL_EMPLOYEE_ROLE_UPDATE: 'schoolemployeeroleupdate',
    // Возможность создания / удаления ролей сотрудников школы
    SCHOOL_EMPLOYEE_ROLE_CREATE: 'schoolemployeerolecreate',
    // Возможность включать защиту на видео
    COURSE_VIDEO_PROTECTED: 'coursevideoprotected',
    // Возможность назначить куратора в группе
    GROUP_EMPLOYEE_RESPONSIBLE: 'groupemployeeresponsible',
    // Возможность включать защиту на текст
    COURSE_TEXT_PROTECTED: 'coursetextprotected',
    // Возможность включать защиту на текст
    CUSTOM_SMTP: 'customsmtp',
    // Возможность использовать авторизацию mos.ru
    AUTH_PROVIDER_MOSRU: 'auth_provider_mosru'
};
export { FEATURES as planFeatures, };
