var _a;
import { useCacheSession } from '@common/hooks/useCacheStorage/useCacheSession';
// const COUNTER_ID = envVars.YANDEX_COUNTER_ID
var COUNTER_ID = '54389497';
export var YMEventsEnum;
(function (YMEventsEnum) {
    YMEventsEnum["REGISTER"] = "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044F \u043D\u0430 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u0435";
    YMEventsEnum["MOVED_TO_REGISTER_PAGE"] = "\u0417\u0430\u0448\u0435\u043B \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438";
    YMEventsEnum["MOVED_TO_REGISTER_FORM"] = "\u0417\u0430\u0448\u0435\u043B \u043D\u0430 \u0444\u043E\u0440\u043C\u0443 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438";
    YMEventsEnum["COMPLETED_FIRST_STEP"] = "\u0417\u0430\u0432\u0435\u0440\u0448\u0438\u043B 1 \u0448\u0430\u0433 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438";
    YMEventsEnum["COMPLETED_SECOND_STEP"] = "\u0417\u0430\u0432\u0435\u0440\u0448\u0438\u043B 2 \u0448\u0430\u0433 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438";
    YMEventsEnum["COMPLETED_THIRD_STEP"] = "\u0417\u0430\u0432\u0435\u0440\u0448\u0438\u043B 3 \u0448\u0430\u0433 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438";
    YMEventsEnum["LOGIN_PLATFORM"] = "\u0412\u0445\u043E\u0434 \u043D\u0430 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u0443";
    YMEventsEnum["MOVED_TO_LOGIN_FORM"] = "\u0417\u0430\u0448\u0435\u043B \u043D\u0430 \u0444\u043E\u0440\u043C\u0443 \u0432\u0445\u043E\u0434\u0430";
    YMEventsEnum["LOGIN_AS_STUDENT"] = "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u043B\u0441\u044F \u043A\u0430\u043A \u0443\u0447\u0435\u043D\u0438\u043A";
    YMEventsEnum["CHANGED_PHONE"] = "\u0418\u0437\u043C\u0435\u043D\u0438\u043B \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430";
    YMEventsEnum["CONFIRMED_PHONE"] = "\u0423\u0441\u043F\u0435\u0448\u043D\u043E \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u043B \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430";
})(YMEventsEnum || (YMEventsEnum = {}));
var storageAnalytics = useCacheSession('analytics', (_a = {},
    _a[YMEventsEnum.MOVED_TO_REGISTER_PAGE] = false,
    _a[YMEventsEnum.MOVED_TO_REGISTER_FORM] = false,
    _a[YMEventsEnum.COMPLETED_FIRST_STEP] = false,
    _a[YMEventsEnum.COMPLETED_SECOND_STEP] = false,
    _a[YMEventsEnum.COMPLETED_THIRD_STEP] = false,
    _a[YMEventsEnum.MOVED_TO_LOGIN_FORM] = false,
    _a[YMEventsEnum.LOGIN_PLATFORM] = false,
    _a[YMEventsEnum.LOGIN_AS_STUDENT] = false,
    _a[YMEventsEnum.REGISTER] = false,
    _a[YMEventsEnum.CHANGED_PHONE] = false,
    _a[YMEventsEnum.CONFIRMED_PHONE] = false,
    _a));
var updateStorageAnalytics = function (eventName) {
    var storageValue = storageAnalytics.value[eventName];
    if (storageValue)
        return;
    storageAnalytics.value[eventName] = true;
};
export var resetStorageAnalytics = function () {
    var _a;
    storageAnalytics.value = (_a = {},
        _a[YMEventsEnum.MOVED_TO_REGISTER_PAGE] = false,
        _a[YMEventsEnum.MOVED_TO_REGISTER_FORM] = false,
        _a[YMEventsEnum.COMPLETED_FIRST_STEP] = false,
        _a[YMEventsEnum.COMPLETED_SECOND_STEP] = false,
        _a[YMEventsEnum.COMPLETED_THIRD_STEP] = false,
        _a[YMEventsEnum.MOVED_TO_LOGIN_FORM] = false,
        _a[YMEventsEnum.LOGIN_PLATFORM] = false,
        _a[YMEventsEnum.LOGIN_AS_STUDENT] = false,
        _a[YMEventsEnum.REGISTER] = false,
        _a[YMEventsEnum.CHANGED_PHONE] = false,
        _a[YMEventsEnum.CONFIRMED_PHONE] = false,
        _a);
};
var YandexAnalytics = /** @class */ (function () {
    function YandexAnalytics() {
    }
    YandexAnalytics.prototype.sendPlatformEvent = function (eventName) {
        if (storageAnalytics.value[eventName])
            return;
        try {
            window.ym(COUNTER_ID, 'reachGoal', eventName);
            updateStorageAnalytics(eventName);
        }
        catch (e) {
            console.error('sendPlatformEvent YM', e);
        }
    };
    return YandexAnalytics;
}());
export var yandexAnalytics = new YandexAnalytics();
