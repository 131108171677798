import { markRaw } from 'vue';
import { useAuthModalStore } from '@student/modules/stores/authModalStore';
import { useCourseStore } from '@student/modules/stores/courseStore';
import router from '@student/modules/router';
import CurrentPinia from "@common/utils/CurrentPinia";
export { CoreStore } from '@common/stores';
var piniaInstance = CurrentPinia.getInstance();
piniaInstance.use(function (_a) {
    var store = _a.store;
    store.$router = markRaw(router);
});
export var AuthModalStore = useAuthModalStore(piniaInstance);
export var CourseStore = useCourseStore(piniaInstance);
