export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No options available"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing found"])}
        }
      },
      "ru": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет вариантов для выбора"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не найдено"])}
        }
      },
      "es": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay opciones para seleccionar"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró nada"])}
        }
      },
      "kk": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау үшін нұсқалар жоқ"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ешнәрсе табылмады"])}
        }
      },
      "uz": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanlash"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanlash uchun variantlar yo'q"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hech narsa topilmadi"])}
        }
      },
      "de": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Optionen verfügbar"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichts gefunden"])}
        }
      },
      "fr": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune option à sélectionner"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rien trouvé"])}
        }
      },
      "it": {
        "select": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
          "noOptionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna opzione disponibile"])},
          "nothingFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato trovato"])}
        }
      }
    }
  })
}
