export var translations = {
    'ru': {
        'schoolCatalog': 'Каталог школы',
        'schoolCourses': 'Курсы школы',
        'schoolWebinars': 'Вебинары школы',
        'library': 'Библиотека',
        'libraryCourses': 'Курсы библиотеки',
        'libraryWebinars': 'Вебинары библиотеки',
        'courseCompletion': 'Завершение курса',
        'courseCertificate': 'Выдача сертификата',
        'webinarTranslation': 'Трансляция вебинара',
        'profile': 'Профиль',
        'profileSettings': 'Настройки профиля',
        'profileNotifications': 'Настройки уведомлений',
        'profilePartner': 'Партнерская программа',
        'notFound': 'Страница не найдена'
    },
    'en': {
        'schoolCatalog': 'School Catalog',
        'schoolCourses': 'School Courses',
        'schoolWebinars': 'School Webinars',
        'library': 'Library',
        'libraryCourses': 'Library Courses',
        'libraryWebinars': 'Library Webinars',
        'courseCompletion': 'Course Completion',
        'courseCertificate': 'Certificate Issuance',
        'webinarTranslation': 'Webinar Streaming',
        'profile': 'Profile',
        'profileSettings': 'Profile Settings',
        'profileNotifications': 'Notification Settings',
        'profilePartner': 'Partner Program',
        'notFound': 'Page Not Found'
    },
    'es': {
        'schoolCatalog': 'Catálogo de la escuela',
        'schoolCourses': 'Cursos de la escuela',
        'schoolWebinars': 'Webinarios de la escuela',
        'library': 'Biblioteca',
        'libraryCourses': 'Cursos de la biblioteca',
        'libraryWebinars': 'Webinarios de la biblioteca',
        'courseCompletion': 'Finalización del curso',
        'courseCertificate': 'Emisión de certificado',
        'webinarTranslation': 'Transmisión del seminario web',
        'profile': 'Perfil',
        'profileSettings': 'Configuración del perfil',
        'profileNotifications': 'Configuración de notificaciones',
        'profilePartner': 'Programa de socios',
        'notFound': 'Página no encontrada'
    },
    'kk': {
        'schoolCatalog': 'Мектеп каталогі',
        'schoolCourses': 'Мектеп курстары',
        'schoolWebinars': 'Мектеп вебинарлары',
        'library': 'Кітапхана',
        'libraryCourses': 'Кітапхана курстары',
        'libraryWebinars': 'Кітапхана вебинарлары',
        'courseCompletion': 'Курсты аяқтау',
        'courseCertificate': 'Сертификат беру',
        'webinarTranslation': 'Вебинар трансляциясы',
        'profile': 'Профиль',
        'profileSettings': 'Профиль параметрлері',
        'profileNotifications': 'Хабарландыру баптаулары',
        'profilePartner': 'Партнерлік бағдарлама',
        'notFound': 'Бет табылмады'
    },
    'uz': {
        'schoolCatalog': 'Maktab katalogi',
        'schoolCourses': 'Maktab kurslari',
        'schoolWebinars': 'Maktab vebinarlari',
        'library': 'Kutubxona',
        'libraryCourses': 'Kutubxona kurslari',
        'libraryWebinars': 'Kutubxona vebinarlari',
        'courseCompletion': 'Kursni yakunlash',
        'courseCertificate': 'Sertifikat berish',
        'webinarTranslation': 'Vebinar translyatsiyasi',
        'profile': 'Profil',
        'profileSettings': 'Profil sozlamalari',
        'profileNotifications': 'Bildirishnoma sozlamalari',
        'profilePartner': 'Hamkorlik dasturi',
        'notFound': 'Sahifa topilmadi'
    },
    'de': {
        'schoolCatalog': 'Schulkatalog',
        'schoolCourses': 'Schulkurse',
        'schoolWebinars': 'Schulwebinare',
        'library': 'Bibliothek',
        'libraryCourses': 'Bibliothekskurse',
        'libraryWebinars': 'Bibliothekswebinare',
        'courseCompletion': 'Kursabschluss',
        'courseCertificate': 'Zertifikatsausstellung',
        'webinarTranslation': 'Webinarübertragung',
        'profile': 'Profil',
        'profileSettings': 'Profileinstellungen',
        'profileNotifications': 'Benachrichtigungseinstellungen',
        'profilePartner': 'Partnerprogramm',
        'notFound': 'Seite nicht gefunden'
    },
    'fr': {
        'schoolCatalog': 'Catalogue de l\'école',
        'schoolCourses': 'Cours de l\'école',
        'schoolWebinars': 'Webinaires de l\'école',
        'library': 'Bibliothèque',
        'libraryCourses': 'Cours de la bibliothèque',
        'libraryWebinars': 'Webinaires de la bibliothèque',
        'courseCompletion': 'Achèvement du cours',
        'courseCertificate': 'Délivrance du certificat',
        'webinarTranslation': 'Diffusion du webinaire',
        'profile': 'Profil',
        'profileSettings': 'Paramètres du profil',
        'profileNotifications': 'Paramètres des notifications',
        'profilePartner': 'Programme de partenariat',
        'notFound': 'Page non trouvée'
    },
    'it': {
        'schoolCatalog': 'Catalogo della scuola',
        'schoolCourses': 'Corsi della scuola',
        'schoolWebinars': 'Webinar della scuola',
        'library': 'Biblioteca',
        'libraryCourses': 'Corsi della biblioteca',
        'libraryWebinars': 'Webinar della biblioteca',
        'courseCompletion': 'Completamento del corso',
        'courseCertificate': 'Emissione del certificato',
        'webinarTranslation': 'Trasmissione del webinar',
        'profile': 'Profilo',
        'profileSettings': 'Impostazioni del profilo',
        'profileNotifications': 'Impostazioni delle notifiche',
        'profilePartner': 'Programma di partnership',
        'notFound': 'Pagina non trovata'
    }
};
